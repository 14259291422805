import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  ResetRegister,
  SetCdcCategory,
  SetCdcName,
  SetCityCode,
  SetCurrentStep,
  SetEdit,
  SetEmail,
  SetPassword,
  SetPct,
  SetPhone,
  SetTerms,
  SetUserCdc,
  SetUserDocument,
} from './register.actions';
import { IRegisterStateModel } from './models/register-state.model';

const INITIAL_STATE: IRegisterStateModel = {
  steps: [
    '',
    'documento',
    'cdc',
    'apelido-do-imovel',
    'telefone',
    'token-telefone',
    'email',
    'senha',
    'termos-de-uso',
    'dados-cadastrais',
    'confirmacao-de-cadastro',
  ],
  currentStep: 0,
  isEditing: false,
  pct: 0,
  document: null,
  name: null,
  firstName: null,
  cdc: null,
  cdcName: null,
  phone: null,
  email: null,
  password: null,
  cityCode: null,
  cdcCategory: null,
  acceptedTerms: false,
};

@State<IRegisterStateModel>({
  name: 'register',
  defaults: INITIAL_STATE,
})
@Injectable()
export class RegisterState {
  /** Seletores */
  @Selector()
  public static getState(state: IRegisterStateModel) {
    return state;
  }

  @Selector()
  public static getUserName(state: IRegisterStateModel) {
    return state.firstName;
  }

  @Selector()
  public static getUserDocument(state: IRegisterStateModel) {
    return state.document;
  }

  @Selector()
  public static getUserCdc(state: IRegisterStateModel) {
    return state.cdc;
  }

  @Selector()
  public static getUserCdcName(state: IRegisterStateModel) {
    return state.cdcName;
  }

  @Selector()
  public static getUserPhone(state: IRegisterStateModel) {
    return state.phone;
  }

  @Selector()
  public static getUserEmail(state: IRegisterStateModel) {
    return state.email;
  }

  @Selector()
  public static getUserPassword(state: IRegisterStateModel) {
    return state.password;
  }

  @Selector()
  public static getPct(state: IRegisterStateModel) {
    return state.pct;
  }

  @Selector()
  public static getIsEditing(state: IRegisterStateModel) {
    return state.isEditing;
  }

  @Selector()
  public static getCurrentStep(state: IRegisterStateModel) {
    return state.currentStep;
  }

  @Selector()
  public static getSteps(state: IRegisterStateModel) {
    return state.steps;
  }

  @Selector()
  public static getCityCode(state: IRegisterStateModel) {
    return state.cityCode;
  }

  @Selector()
  public static getCdcCategory(state: IRegisterStateModel) {
    return state.cdcCategory;
  }

  @Selector()
  public static getTerms(state: IRegisterStateModel) {
    return state.acceptedTerms;
  }

  @Action(SetUserDocument)
  public setUserDocument(ctx: StateContext<IRegisterStateModel>, { payload }: SetUserDocument) {
    const state = ctx.getState();
    ctx.setState({ ...state, document: payload });
  }

  @Action(SetUserCdc)
  public setUserCdc(ctx: StateContext<IRegisterStateModel>, { payload }: SetUserCdc) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdc: payload });
  }

  @Action(SetCdcName)
  public setCdcName(ctx: StateContext<IRegisterStateModel>, { payload }: SetCdcName) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdcName: payload });
  }

  @Action(SetPhone)
  public setPhone(ctx: StateContext<IRegisterStateModel>, { payload }: SetPhone) {
    const state = ctx.getState();
    ctx.setState({ ...state, phone: payload });
  }

  @Action(SetEmail)
  public setEmail(ctx: StateContext<IRegisterStateModel>, { payload }: SetEmail) {
    const state = ctx.getState();
    ctx.setState({ ...state, email: payload });
  }

  @Action(SetPassword)
  public setPassword(ctx: StateContext<IRegisterStateModel>, { payload }: SetPassword) {
    const state = ctx.getState();
    ctx.setState({ ...state, password: payload });
  }

  @Action(SetPct)
  public setPct(ctx: StateContext<IRegisterStateModel>, { payload }: SetPct) {
    const state = ctx.getState();
    ctx.setState({ ...state, pct: payload });
  }

  @Action(SetEdit)
  public setEdit(ctx: StateContext<IRegisterStateModel>, { payload }: SetEdit) {
    const state = ctx.getState();
    ctx.setState({ ...state, isEditing: payload });
  }

  @Action(SetCurrentStep)
  public setCurrentStep(ctx: StateContext<IRegisterStateModel>, { payload }: SetCurrentStep) {
    const state = ctx.getState();
    ctx.setState({ ...state, currentStep: payload });
  }

  @Action(SetCityCode)
  public setCityCode(ctx: StateContext<IRegisterStateModel>, { payload }: SetCityCode) {
    const state = ctx.getState();
    ctx.setState({ ...state, cityCode: payload });
  }

  @Action(SetCdcCategory)
  public setCdcCategory(ctx: StateContext<IRegisterStateModel>, { payload }: SetCdcCategory) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdcCategory: payload });
  }

  @Action(SetTerms)
  public setTerms(ctx: StateContext<IRegisterStateModel>, { payload }: SetTerms) {
    const state = ctx.getState();
    ctx.setState({ ...state, acceptedTerms: payload });
  }

  @Action(ResetRegister)
  public reset(ctx: StateContext<IRegisterStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }
}
