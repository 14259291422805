<ng-container>
  <div class="wrapper scroll religa" *ngIf="onReconnection">
    <div class="header" *ngIf="onReconnection">
      <h1 class="is-bold is-size-24 title" data-cy="reconnection-modal-title" *ngIf="!onMobile">
        Canais de atendimento
      </h1>
    </div>

    <div class="content" *ngIf="selectedCity">
      <div class="cards">
        <!-- WhatsApp -->
        <app-accordion
          ht-clickable
          [minHeight]="minHeight"
          class="card whatsapp"
          [isOnMobile]="true"
        >
          <img
            accordion-image
            class="card__picture"
            [src]="baseImgUrl + '/img/attendance/whats.png'"
            alt="WhatsApp"
            *ngIf="(selectedCity?.configCustomerService.whatsapp | json) != '{}'"
          />

          <div class="card__text" accordion-text>
            <p
              class="has-text-blue is-size-16 is-bold"
              data-cy="reconnection-modal-whatsapp-header"
            >
              Whatsapp
            </p>
            <p class="is-size-14" data-cy="reconnection-modal-whatsapp-schedule">
              Horário de atendimento: <br />de segunda à sexta-feira das
              <span class="is-bold">08h</span> às <span class="is-bold">20h</span> e aos Sábados das
              <span class="is-bold">08h</span> às <span class="is-bold">14h</span>
            </p>
          </div>

          <div class="card__description">
            <a
              acnGtm
              gtmCategory="Link"
              gtmAction="Direcionamento_Whatsapp"
              class="link"
              href="https://wa.me/5511999880001"
              target="_blank"
              rel="noopener"
            >
              <p class="card__phone is-medium" data-cy="reconnection-modal-whatsapp-phone">
                {{ selectedCity?.configCustomerService.whatsapp.phone }}
              </p>
            </a>
            <p
              class="is-medium is-size-14"
              data-cy="reconnection-modal-whatsapp-desc"
              [innerHTML]="selectedCity?.configCustomerService.whatsapp.note"
            ></p>
          </div>
        </app-accordion>

        <!-- Telefones -->
        <app-accordion
          ht-clickable
          [minHeight]="minHeight"
          class="card phones"
          [isOnMobile]="true"
          *ngIf="
            (selectedCity?.configCustomerService.chat &&
              (selectedCity?.configCustomerService.phones | json) != '{}') ||
            selectedCity?.id === 9999
          "
        >
          <img
            accordion-image
            class="card__picture"
            [src]="baseImgUrl + '/img/attendance/telefone.png'"
            alt="Telefone"
          />

          <div class="card__text" accordion-text>
            <p class="has-text-blue is-size-16 is-bold" data-cy="reconnection-modal-phones-header">
              Telefones
            </p>
            <p class="is-size-14" data-cy="reconnection-modal-phones-schedule">
              Horário de atendimento: <br />24 horas, 7 dias da semana, inclusive aos sábados,
              domingos e feriados <br />
              Ouvidoria: segunda à sexta das <span class="is-bold">08h</span> às
              <span class="is-bold">18h</span>
            </p>
          </div>

          <div class="card__description">
            <!-- Quando o usuário tem uma cidade selecionada -->
            <div
              class="unities-phones"
              *ngIf="selectedCity?.configCustomerService.phones && selectedCity?.id != 9999"
            >
              <div class="tel">
                <p class="is-size-12 is-bold">
                  Atendimento ao cliente
                  {{ selectedCity?.nome }}
                </p>
                <p class="is-size-14">{{ phones.telAtendimento }}</p>
              </div>
              <div class="tel">
                <p class="is-size-12 is-bold">Para deficientes auditivos ou de fala</p>
                <p class="is-size-14">{{ phones.telAuditivo }}</p>
              </div>
              <acn-split></acn-split>
              <div class="hearing-text">
                <p>
                  Se você já tem um protocolo registrado e não está de acordo com a solução
                  apresentada, pode buscar a Ouvidoria de segunda à sexta das 08h às 18h.
                </p>
              </div>
              <div class="tel">
                <p class="is-size-12 is-bold">Ouvidoria</p>
                <p class="is-size-14">{{ phones.telOuvidoria }}</p>
              </div>
            </div>
            <!-- Quando o usuário não tem uma cidade selecionada -->
            <div class="unities-phones nocity" *ngIf="selectedCity?.id === 9999">
              <div class="tel" *ngFor="let unity of phoneData">
                <h4 class="tel-title">
                  {{ unity.label }}
                </h4>
                <span class="tel-number">{{ unity.number }}</span>
              </div>
            </div>
          </div>
        </app-accordion>

        <!-- Chat -->
        <app-accordion
          ht-clickable
          [minHeight]="minHeight"
          class="card chat"
          [isOnMobile]="true"
          *ngIf="
            isWebchatActive &&
            ((selectedCity?.configCustomerService.chat | json) != '{}' || selectedCity?.id === 9999)
          "
          data-cy="chat"
        >
          <img
            accordion-image
            class="card__picture"
            [src]="baseImgUrl + '/img/attendance/chat.png'"
            alt="Chat"
          />

          <div class="card__text" accordion-text>
            <p class="has-text-blue is-size-16 is-bold" data-cy="reconnection-modal-chat-header">
              Chat
            </p>
            <p class="is-size-14" data-cy="reconnection-modal-chat-schedule">
              Fale com um de nossos atendentes em tempo real, de segunda à sexta-feira das
              <span class="is-bold">8h</span> às <span class="is-bold">20h</span> e aos Sábados das
              <span class="is-bold">8h</span> às <span class="is-bold">14h</span>, inclusive
              feriados.
            </p>
          </div>

          <div class="card__description">
            <acn-split></acn-split>
            <a
              class="link is-bold is-size-14"
              (click)="openChatWindow()"
              data-cy="reconnection-modal-chat-talk"
            >
              Falar pelo chat
            </a>
          </div>
        </app-accordion>

        <!-- E-mail -->
        <app-accordion
          ht-clickable
          [minHeight]="minHeight"
          class="card email"
          *ngIf="selectedCity?.unidEmail"
          [isOnMobile]="true"
        >
          <img
            accordion-image
            class="card__picture"
            [src]="baseImgUrl + '/img/attendance/email.png'"
            alt="Email"
          />

          <div class="card__text" accordion-text>
            <p class="has-text-blue is-size-16 is-bold" data-cy="reconnection-modal-email-header">
              E-mail
            </p>
            <p class="is-size-14" data-cy="reconnection-modal-email-description">
              Escreva para <span class="is-bold">atendimento.sac@brkambiental.com.br</span> ou
              clique no botão abaixo.
            </p>
          </div>

          <div class="card__description">
            <acn-split></acn-split>
            <a
              class="link is-bold is-size-14"
              [routerLink]="
                onDashboard ? 'home-logado/atendimento/contato/email' : 'home/atendimento/contato'
              "
              data-cy="reconnection-modal-email-send"
              (click)="hideModal()"
              >Enviar E-mail</a
            >
          </div>
        </app-accordion>

        <!-- Lojas -->
        <app-accordion ht-clickable [minHeight]="minHeight" class="card stores" [isOnMobile]="true">
          <img
            accordion-image
            class="card__picture"
            [src]="baseImgUrl + '/img/attendance/lojas.png'"
            alt="Lojas"
          />

          <div class="card__text" accordion-text>
            <ng-container
              *ngIf="
                !selectedCity ||
                (selectedCity && !selectedCity?.isAttended) ||
                notDisplayAttendanceAgencies
              "
            >
              <p
                class="has-text-blue is-size-16 is-bold"
                data-cy="reconnection-modal-stores-select"
              >
                Encontre sua cidade
              </p>
              <p class="is-size-14" data-cy="reconnection-modal-stores-desc">
                São mais de <span class="is-bold">180 unidades</span> de atendimento em todo o
                Brasil.
              </p>
            </ng-container>

            <ng-container
              *ngIf="selectedCity && selectedCity?.isAttended && !notDisplayAttendanceAgencies"
            >
              <p
                class="has-text-blue is-size-16 is-bold"
                data-cy="reconnection-modal-stores-cities"
              >
                Lojas BRK Ambiental em {{ selectedCity?.nome }}
              </p>
            </ng-container>
          </div>

          <div class="card__description">
            <ng-container
              *ngIf="
                !selectedCity ||
                (selectedCity && !selectedCity?.isAttended) ||
                notDisplayAttendanceAgencies
              "
            >
              <acn-split></acn-split>
              <a
                class="link is-bold is-size-14"
                routerLink="/selecao-de-cidade"
                data-cy="reconnection-modal-stores-find"
                >Encontre sua cidade</a
              >
            </ng-container>

            <ng-container
              *ngIf="selectedCity && selectedCity?.isAttended && !notDisplayAttendanceAgencies"
            >
              <div class="nagativeMargin">
                <app-attendance-agencies class="home"></app-attendance-agencies>
              </div>
            </ng-container>
          </div>
        </app-accordion>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let text; scope: 'attendance'">
  <div class="attendance" *ngIf="isAttendance || onScheduleAttendance">
    <div class="content container">
      <div class="cards">
        <ng-container *ngIf="!onMobile && !onDashboard">
          <div class="left">
            <ng-container *ngTemplateOutlet="left"></ng-container>
          </div>

          <div class="right">
            <ng-container *ngTemplateOutlet="right"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="onMobile || onDashboard">
          <ng-container *ngTemplateOutlet="left"></ng-container>
          <ng-container *ngTemplateOutlet="right"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #left>
    <!-- WhatsApp -->
    <app-accordion
      ht-clickable
      [minHeight]="minHeight"
      class="card whatsapp"
      [isOnMobile]="onMobile"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Atendimento"
      [gtmLabel]="'Whatsapp'"
      *ngIf="(selectedCity?.configCustomerService.whatsapp | json) != '{}'"
    >
      <img
        accordion-image
        class="card__picture"
        [src]="baseImgUrl + '/img/attendance/whats.png'"
        alt="WhatsApp"
      />

      <div class="card__text" accordion-text>
        <p class="has-text-blue is-size-16 is-bold">Whatsapp</p>
        <p class="is-size-14" [innerHTML]="selectedCity?.configCustomerService.whatsapp.hours"></p>
      </div>

      <div class="card__description">
        <a class="link" href="https://wa.me/5511999880001" target="_blank" rel="noopener">
          <p class="card__phone is-medium">
            {{ selectedCity?.configCustomerService.whatsapp.phone }}
          </p>
        </a>
        <p
          class="is-medium is-size-14"
          [innerHTML]="selectedCity?.configCustomerService.whatsapp.note"
        ></p>
      </div>
    </app-accordion>

    <!-- Chat -->
    <app-accordion
      ht-clickable
      [minHeight]="minHeight"
      class="card chat"
      [isOnMobile]="onMobile"
      *ngIf="
        isWebchatActive &&
        ((selectedCity?.configCustomerService.chat | json) != '{}' || selectedCity?.id === 9999)
      "
      data-cy="chat"
    >
      <img
        accordion-image
        class="card__picture"
        [src]="baseImgUrl + '/img/attendance/chat.png'"
        alt="Chat"
      />

      <div class="card__text" accordion-text>
        <p class="has-text-blue is-size-16 is-bold">Chat</p>
        <p class="is-size-14" [innerHTML]="selectedCity?.configCustomerService.chat.hours"></p>
      </div>

      <div class="card__description">
        <acn-split></acn-split>
        <a class="link is-bold is-size-14 has-text-blue" (click)="openChatWindow()" rel="noopener"
          >Iniciar chat</a
        >
      </div>
    </app-accordion>

    <!-- Lojas -->
    <app-accordion
      ht-clickable
      [minHeight]="minHeight"
      class="card stores"
      [isOnMobile]="onMobile"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Atendimento"
      gtmLabel="lojas BRK"
    >
      <img
        accordion-image
        class="card__picture"
        [src]="baseImgUrl + '/img/attendance/lojas.png'"
        alt="Lojas"
      />

      <div class="card__text" accordion-text>
        <ng-container
          *ngIf="
            !selectedCity ||
            (selectedCity && !selectedCity?.isAttended) ||
            notDisplayAttendanceAgencies
          "
        >
          <p class="has-text-blue is-size-16 is-bold">Encontre sua cidade</p>
          <p class="is-size-14">
            São mais de <span class="is-bold">180 unidades</span> de atendimento em todo o Brasil.
          </p>
        </ng-container>

        <ng-container
          *ngIf="selectedCity && selectedCity?.isAttended && !notDisplayAttendanceAgencies"
        >
          <p class="has-text-blue is-size-16 is-bold">
            Lojas BRK Ambiental em {{ selectedCity?.nome }}
          </p>
        </ng-container>
      </div>

      <div class="card__description">
        <ng-container
          *ngIf="
            !selectedCity ||
            (selectedCity && !selectedCity?.isAttended) ||
            notDisplayAttendanceAgencies
          "
        >
          <acn-split></acn-split>
          <a class="link is-bold is-size-14" routerLink="/selecao-de-cidade">Encontre sua cidade</a>
        </ng-container>

        <ng-container
          *ngIf="selectedCity && selectedCity?.isAttended && !notDisplayAttendanceAgencies"
        >
          <div class="nagativeMargin">
            <app-attendance-agencies class="home"></app-attendance-agencies>
          </div>
        </ng-container>
      </div>
    </app-accordion>
  </ng-template>

  <ng-template #right>
    <!-- Telefones: aparece se NÃO houver card de chat -->
    <app-accordion
      ht-clickable
      [minHeight]="minHeight"
      class="card phones"
      [isOnMobile]="onMobile"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Atendimento"
      [gtmLabel]="'Telefones'"
    >
      <img
        accordion-image
        class="card__picture"
        [src]="baseImgUrl + '/img/attendance/telefone.png'"
        alt="Telefone"
      />

      <div class="card__text" accordion-text>
        <p class="has-text-blue is-size-16 is-bold">Telefones</p>
        <p class="is-size-14">
          Horário de atendimento: <br />24 horas, 7 dias da semana, inclusive aos sábados, domingos
          e feriados
        </p>
      </div>

      <div class="card__description">
        <!-- Quando o usuário tem uma cidade selecionada -->
        <div
          class="unities-phones"
          *ngIf="selectedCity?.configCustomerService.phones && selectedCity?.id != 9999"
        >
          <div class="tel">
            <p class="is-size-12 is-bold">
              Atendimento ao cliente
              {{ selectedCity?.nome }}
            </p>
            <p class="is-size-14">{{ phones.telAtendimento }}</p>
          </div>
          <div class="tel">
            <p class="is-size-12 is-bold">Para deficientes auditivos ou de fala</p>
            <p class="is-size-14">{{ phones.telAuditivo }}</p>
          </div>
          <acn-split class="p-2-collumns"></acn-split>
          <div class="hearing-text">
            <p>
              Se você já tem um protocolo registrado e não está de acordo com a solução apresentada,
              pode buscar a Ouvidoria de segunda à sexta das 08h às 18h.
            </p>
          </div>
          <div class="tel">
            <p class="is-size-12 is-bold">Ouvidoria</p>
            <p class="is-size-14">{{ phones.telOuvidoria }}</p>
          </div>
        </div>

        <!-- Quando o usuário não tem uma cidade selecionada -->
        <div class="unities-phones" *ngIf="selectedCity?.id === 9999">
          <div class="unities-phones__item" *ngFor="let unity of phoneData">
            <div class="wrapper">
              <a [attr.href]="onMobile ? 'tel:' + unity.number : null">
                <p class="card__phone is-medium">{{ unity.number }}</p>
              </a>
              <p class="city-name is-medium is-size-14">
                {{ unity.label }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </app-accordion>

    <!-- E-mail -->
    <app-accordion
      ht-clickable
      [minHeight]="minHeight"
      class="card email"
      [isOnMobile]="onMobile"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Atendimento"
      [gtmLabel]="'E-mail'"
      *ngIf="selectedCity?.unidEmail"
    >
      <img
        accordion-image
        class="card__picture"
        [src]="baseImgUrl + '/img/attendance/email.png'"
        alt="Email"
      />

      <div class="card__text" accordion-text>
        <p class="has-text-blue is-size-16 is-bold">E-mail</p>
        <p class="is-size-14" [innerHTML]="cardEmailMessage" data-test-id="card-email"></p>
      </div>

      <div class="card__description">
        <acn-split></acn-split>
        <a class="link is-bold is-size-14" (click)="startAttendance()" data-cy="email-button"
          >Enviar E-mail</a
        >
      </div>
    </app-accordion>
  </ng-template>
</ng-container>
