import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-schedule-attendance',
  templateUrl: './button-schedule-attendance.component.html',
  styleUrls: ['./button-schedule-attendance.component.scss'],
})
export class ButtonScheduleAttendanceComponent implements OnInit {
  @Input() attendanceUrl: string;

  public finalUrl;

  ngOnInit() {
    this.finalUrl = this.attendanceUrl?.replace('https://', '');
  }
}
