import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserState } from './user.state';
import {
  SetUser,
  SetRestorePasswordData,
  ResetRestorePasswordData,
  SetRestorePasswordType,
  Reset,
  SetFirstAccess,
  UpdateUserPhone,
  UpdateUserEmail,
} from './user.actions';

import { IRestorePassword } from './models/restore-password.model';
import { IUserInfo } from '@shared/models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  @Select(UserState.getUser)
  public user$: Observable<IUserInfo>;

  @Select(UserState.getUserPhone)
  public phone$: Observable<string>;

  @Select(UserState.getUserId)
  public id$: Observable<number>;

  @Select(UserState.getRestoreData)
  public restorePasswordData$: Observable<IRestorePassword>;

  @Select(UserState.getFirstAccess)
  public firstAccess$: Observable<boolean>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setUser(user: IUserInfo) {
    if (user.hasOwnProperty('nome') && user.nome) {
      const [firsName] = user.nome.split(' ');
      user = { ...user, primeiroNome: firsName };
    }

    return new SetUser(user);
  }

  @Dispatch()
  public updateUserPhone(phone: string) {
    return new UpdateUserPhone(phone);
  }

  @Dispatch()
  public updateUserEmail(email: string) {
    return new UpdateUserEmail(email);
  }

  @Dispatch()
  public reset() {
    return new Reset();
  }

  @Dispatch()
  public setUserRestoreData(data: IRestorePassword) {
    return new SetRestorePasswordData(data);
  }

  @Dispatch()
  public setUserRestoreType(data: string) {
    return new SetRestorePasswordType(data);
  }

  @Dispatch()
  public resetUserRestoreData() {
    return new ResetRestorePasswordData();
  }

  @Dispatch()
  public setFirstAccess(data: boolean) {
    return new SetFirstAccess(data);
  }

  public getUser() {
    return this._store.selectSnapshot(UserState.getUser);
  }

  public getUserName() {
    return this._store.selectSnapshot(UserState.getUserName);
  }

  public getUserEmail() {
    return this._store.selectSnapshot(UserState.getUserEmail);
  }

  public getFirstAccess() {
    return this._store.selectSnapshot(UserState.getFirstAccess);
  }

  public getUserPhone() {
    return this._store.selectSnapshot(UserState.getUserPhone);
  }
}
