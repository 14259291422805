/**
 * Model de definição de dados para erros
 */

export class IError {
  /**
   * Constructor
   * @param type type
   * @param code code
   * @param userMessage userMessage
   * @param developerMessage developerMessage
   * @param correlationId correlationId
   */
  constructor(
    public type: 'runtime' | 'http' | 'connection' | 'authorization' | 'authentication',
    public code: string | number,
    public userMessage: string,
    public developerMessage: string,
    public correlationId?: string,
  ) {}
}
