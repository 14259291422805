import { IReportLeakModel } from './models';

export class ResetReportLeak {
  public static readonly type = '[REPORT LEAK] Reset report leak';
}

export class AddReportLeak {
  public static readonly type = '[REPORT LEAK] Add report leak';
  constructor(public payload: IReportLeakModel) {}
}

export class AddNeighborhoodWaterSupply {
  public static readonly type = '[REPORT LEAK] Add neighborhood water supply';
  constructor(public payload: string) {}
}

export class AddWaterSupply {
  public static readonly type = '[REPORT LEAK] Add water supply';
  constructor(public payload: string) {}
}

export class AddProportion {
  public static readonly type = '[REPORT LEAK] Add leak proportion';
  constructor(public payload: string) {}
}

export class AddWhereItOccurs {
  public static readonly type = '[REPORT LEAK] Add leak where it occurs';
  constructor(public payload: string) {}
}

export class AddZipCode {
  public static readonly type = '[REPORT LEAK] Add leak zip code';
  constructor(public payload: number) {}
}

export class AddAddress {
  public static readonly type = '[REPORT LEAK] Add leak address';
  constructor(public payload: string) {}
}

export class AddReferencePoint {
  public static readonly type = '[REPORT LEAK] Add leak reference point';
  constructor(public payload: string) {}
}

export class AddName {
  public static readonly type = '[REPORT LEAK] Add name';
  constructor(public payload: string) {}
}

export class AddPhoneNumber {
  public static readonly type = '[REPORT LEAK] Add phone number';
  constructor(public payload: number) {}
}

export class AddDocument {
  public static readonly type = '[REPORT LEAK] Add document';
  constructor(public payload: number) {}
}

export class AddCdc {
  public static readonly type = '[REPORT LEAK] Add cdc';
  constructor(public payload: number) {}
}

export class AddNote {
  public static readonly type = '[REPORT LEAK] Add note';
  constructor(public payload: string) {}
}

export class AddSuccessMessage {
  public static readonly type = '[REPORT LEAK] Add success message';
  constructor(public payload: string) {}
}