import { Directive, HostListener, Input } from '@angular/core';

import { GtmService } from './gtm.service';

@Directive({
  selector: '[acnGtm]',
})
export class GtmDirective {
  @Input() gtmCategory: string;
  @Input() gtmAction: string;
  @Input() gtmLabel: string;

  @HostListener('click') onMouseClick() {
    this._gtm.setCustomEvent(this.gtmCategory, this.gtmAction, this.gtmLabel);
  }

  constructor(private _gtm: GtmService) {}
}
