import { Injectable, Injector } from '@angular/core';
import { of, zip } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { IRequest } from '@acn/angular';
import {
  ICard,
  ICardPaymentResponse,
  IErrorRegisterCard,
  IResponseRegisterCard,
} from '@features/payment/automatic-credit-card/services/models';
import { AutomaticCreditCardService } from '@features/payment/automatic-credit-card/services/automatic-credit-card.service';
import { BillingService } from '@features/billing/services/billing.service';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class CreditCardService extends AutomaticCreditCardService {
  private readonly PAY_CARD_URL = 'cartao/v2/pagamento';

  private _billingService: BillingService;

  constructor(protected _injector: Injector) {
    super(_injector);

    this._billingService = this._injector.get(BillingService);
  }

  nextStep() {
    const nextStep = this._stepsStore.nextStep();
    this.navigate(`/home-logado/pagamento/credito/${nextStep}`);
  }

  prevStep() {
    const prevStep = this._stepsStore.prevStep();

    if (prevStep) {
      this.navigate(`/home-logado/pagamento/credito/${prevStep}`);
      return;
    }

    window.history.back();
  }

  setPaymentSteps() {
    const steps = [
      'numero-cartao',
      'nome-titular',
      'validade-cartao',
      'codigo-seguranca',
      'termo-uso-pagamento-automatico',
    ];

    this._setSteps(steps);
  }

  payWithCard(cardNumber: string, cvv: string, cadastrarCartaoPagtoAutomatico = false) {
    this._loaderService.show();
    return zip(
      this.creditCard$,
      this._installationStore.selectedInstallation$,
      this._userStore.user$,
      this._citiesStore.selectedCity$,
      this.cardDocument$,
    ).pipe(
      switchMap(([creditCard, installation, user, city, cardDocument]) => {
        const [month, year] = creditCard.date.split('/');
        const [name, ...lastName] = user.nome.split(' ');
        const currentYear = new Date().getFullYear().toString().substr(0, 2);

        const body: any = {
          cliente: {
            codUnidade: city.idUnidade,
            idCidade: installation.idCidade,
            idLigacao: Number(installation.cdc),
            email: user.email,
            numTelefone: user.telMovel,
            primeiroNome: name,
            sobrenome: (lastName && lastName.join(' ')) || null,
          },
          doctoPagamento: {
            tipoDocumento: cardDocument.type,
            idDocumento: cardDocument.id,
            codBarras: cardDocument.barCode,
            vencimento: cardDocument?.dueDate ? this.parseFormatDate(cardDocument.dueDate) : null,
            valorPagamento: cardDocument.value,
            qtdParcelas: cardDocument.installments,
            idFaturas: cardDocument?.invoicesId,
          },
          cartao: {
            nomeTitular: creditCard.name,
            numCartao: cardNumber,
            anoVencimento: `${currentYear}${year}`,
            mesVencimento: month,
            cvv: cvv,
            enderecoCobranca: null,
          },
          enderecoIp: 'remoteAddress',
          cadastrarCartaoPagtoAutomatico,
        };

        const req: IRequest = {
          endPoint: this.PAY_CARD_URL,
          showLoading: true,
          body,
        };

        return this._connectorApi.post<IResponseRegisterCard>(req).pipe(
          switchMap((res) => {
            let cardPaymentResponse: ICardPaymentResponse;

            if (!res.retornoOperacao) {
              const cardInfo: ICard = {
                noUltimosDigitosCartao: res.numCartao,
                noAnoVencimentoCartao: res.anoVencimento,
                noMesVencimentoCartao: res.mesVencimento,
                bandeira: res.bandeira,
                regrasVencimento: res.regrasVencimento,
              };

              if (cadastrarCartaoPagtoAutomatico) {
                this._useTermsService.setCreditCardTerms(true);
                this._setCardInfo(cardInfo);
              }

              cardPaymentResponse = { status: true, msg: installation.cdc };
              return of(cardPaymentResponse);
            }

            cardPaymentResponse = { status: false, msg: res.respostaAdquirente.mensagemResposta };
            return of(cardPaymentResponse);
          }),
          switchMap((res) => {
            if (res.status) {
              this._billingService.resetBilling();
              return this._billingService.getBillingHistory().pipe(switchMap(() => of(res)));
            }

            return of(res);
          }),
          catchError((error) => {
            const _error: IErrorRegisterCard = error.error;
            this._useTermsService.setCreditCardTerms(false);
            const msg =
              _error && _error.mensagens
                ? _error.mensagens.join('<br/></br>')
                : 'Erro!  Tente novamente';

            return of({ status: false, msg, code: _error?.codigo });
          }),
          finalize(() => this._loaderService.hide()),
        );
      }),
    );
  }

  private parseFormatDate(dueDate: String) {
    return format(parse(dueDate.toString(), 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
  }
}
