import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { DuplicateState } from './duplicate.state';
import {
  ResetDuplicateStore,
  SetCurrentGuide,
  SetCurrentInvoice,
  SetHeaderBgSmall,
} from './duplicate.actions';
import { IGuideDetail, IInvoiceDetail } from '@features/duplicate/services/models';

@Injectable({
  providedIn: 'root',
})
export class DuplicateStoreService {
  @Select(DuplicateState.getCurrentInvoice)
  public currentInvoice$: Observable<IInvoiceDetail>;

  @Select(DuplicateState.getCurrentGuide)
  public currentGuide$: Observable<IGuideDetail>;

  @Select(DuplicateState.getHeaderBgSmall)
  public headerBgSmall$: Observable<boolean>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setCurrentInvoice(invoice: IInvoiceDetail) {
    return new SetCurrentInvoice(invoice);
  }

  @Dispatch()
  public setCurrentGuide(guide: IGuideDetail) {
    return new SetCurrentGuide(guide);
  }

  @Dispatch()
  public resetDuplicateStore() {
    return new ResetDuplicateStore();
  }

  @Dispatch()
  public setHeaderBgSmall(payload: boolean) {
    return new SetHeaderBgSmall(payload);
  }

  hasInvoiceOrGuide() {
    const guide = this._store.selectSnapshot(DuplicateState.getCurrentGuide);
    const invoice = this._store.selectSnapshot(DuplicateState.getCurrentInvoice);

    return !!(guide || invoice);
  }

  public getInvoicesIdFromGuide(): Array<number> {
    const guidesInvoice = this._store.selectSnapshot(DuplicateState.getCurrentGuide)?.guiaFaturas;

    if (Array.isArray(guidesInvoice)) {
      return guidesInvoice.map((invoice) => invoice.idFatura);
    }
    return [];
  }

  getCurrentInvoice(): IInvoiceDetail {
    return this._store.selectSnapshot(DuplicateState.getCurrentInvoice);
  }

  getCurrentGuide(): IGuideDetail {
    return this._store.selectSnapshot(DuplicateState.getCurrentGuide);
  }
}
