import { HomeUnloggedGuard } from '@shared/guards/home-unlogged.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AcnAuthenticationGuard, AcnChannelResolver } from '@acn/angular';
import { PageNotFoundComponent } from '@shared';
import { UnsupportedBrowserGuard } from '@shared/guards/unsupported-browser.guard';
// import { BrkPreloadingStrategy } from '@shared/services/brk-preloading-strategy/brk-preloading-strategy';
import { BrkPreloading } from '@shared/services/brk-preloading-strategy/brk-preloading';
import { BrkPreloadingPriorityEnum } from '@shared/services/brk-preloading-strategy/brk-preloading-priority.enum';

const routes: Routes = [
  {
    path: 'versao-navegador',
    loadChildren: () =>
      import('./features/page-unsupported-browser/page-unsupported-browser.module').then(
        (m) => m.PageUnsupportedBrowserModule,
      ),
  },
  {
    path: '',
    canActivate: [UnsupportedBrowserGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/geolocalization/geolocalization.module').then(
            (m) => m.GeolocalizationModule,
          ),
      },
      {
        path: 'cadastro',
        loadChildren: () =>
          import('./features/register/register.module').then((m) => m.RegisterModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./features/landing/landing.module').then((m) => m.LandingModule),
        canActivate: [HomeUnloggedGuard],
      },
      {
        path: 'login',
        loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
        data: {
          [BrkPreloading.key]: BrkPreloading.create({ priority: BrkPreloadingPriorityEnum.medium }),
        },
      },
      {
        path: 'cdc',
        loadChildren: () =>
          import('./features/installation/installation.module').then((m) => m.InstallationModule),
      },
      {
        path: 'selecao-de-cidade',
        loadChildren: () =>
          import('./features/select-city/select-city.module').then((m) => m.SelectCityModule),
        data: {
          [BrkPreloading.key]: BrkPreloading.create({ priority: BrkPreloadingPriorityEnum.high }),
        },
      },
      {
        path: 'web',
        loadChildren: () =>
          import('./features/landing/landing.module').then((m) => m.LandingModule),
        data: {
          channel: 'web',
        },
        resolve: {
          channel: AcnChannelResolver,
        },
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./features/landing/landing.module').then((m) => m.LandingModule),
        data: {
          channel: 'app',
        },
        resolve: {
          channel: AcnChannelResolver,
        },
      },
      {
        path: 'totem',
        loadChildren: () =>
          import('./features/landing/landing.module').then((m) => m.LandingModule),
        data: {
          channel: 'totem',
        },
        resolve: {
          channel: AcnChannelResolver,
        },
      },
      {
        path: 'home-logado',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AcnAuthenticationGuard],
        data: {
          breadcrumb: 'dashboard.breadcrumb.home',
        },
      },
      {
        path: 'politica-de-privacidade',
        loadChildren: () =>
          import('./features/privacy/privacy.module').then((m) => m.PrivacyModule),
      },
      {
        path: 'termos-de-uso',
        loadChildren: () => import('./features/terms/terms.module').then((m) => m.TermsModule),
      },
      {
        path: 'termos-de-uso-do-cartao',
        loadChildren: () => import('./features/terms/terms.module').then((m) => m.TermsModule),
      },
      // Definir as rotas antes do ":city"
      {
        path: ':city',
        loadChildren: () =>
          import('./features/geolocalization/geolocalization.module').then(
            (m) => m.GeolocalizationModule,
          ),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
];

// @dynamic
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Bug https://github.com/angular/angular/issues/22842
      // Não ativar o preloadingStrategy antes da atualização da versão 11.2.1: https://github.com/angular/angular/blob/main/CHANGELOG.md#1121-2021-02-17:~:text=router%3A%20fix%20load%20interaction%20of%20navigation%20and%20preload%20strategies%20(%2340389)%20(4906bf6)%2C%20closes%20%2326557%20%2322842%20%2326557%20%2326557%20%2322842%20%2326557
      // Esse bug faz com que os guards "canDeactivate" das rotas de lazyLoading sejam chamados
      // antes da hora certa, e acaba causando alguns efeitos estranhos no app
      // como limpar dados necessários para as telas
      // preloadingStrategy: BrkPreloadingStrategy,
      initialNavigation: 'enabled',
      enableTracing: false, // debug
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
