import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { validate } from './validate';
import { isPresent } from '../utils/is-present';
import { removeSpecialCharacters } from '../utils/remove-special-characters';
import { completeSmallDate } from '../utils/complete-small-date';
import { daysFromNow } from '../utils/days-from-now';

export class AcnFormValidations extends Validators {
  static getErrorMsg(fieldName: string, checktorName: string, checktorValue?: any) {
    const config = {
      documentExists: 'Documento já cadastrado',
      required: `Campo obrigatório.`,
      email: `E-mail inválido.`,
      multipleEmail: `E-mail inválido, o campo deve possuir um único e-mail válido.`,
      invalidCEP: 'CEP inválido.',
      invalidDate: 'Data inválida.',
      whitespace: `${checktorValue}`,
      invalidData: 'Dado inválido',
      onlyNumbers: 'Só são permitidos números',
      moreThanOne: 'Só são permitidos números maiores que 1',
      lessThanTheRegistredDate: `Não pode ser anterior à data informada ${checktorValue}`,
      invalidLoginPassword: 'Senha não pode conter partes do login',
      noFutureDate: 'A data não pode ser futura',
      differFrom: `Valor não pode ser igual ao de ${checktorValue}`,
      pattern: 'Campo inválido',
      equalsTo: `${checktorValue} não coincidem`,
      invalidCPF: 'CPF inválido',
      invalidCNPJ: 'CNPJ inválido',
      invalidCPFCNPJ: 'CPF ou CNPJ inválidos',
      invalidPhone: 'Telefone inválido',
      invalidCel: 'Celular inválido',
      invalidName: 'Nome inválido',
      invalidAgency: 'Agencia inválida',
      levenshtein: 'Apenas correção ortográfica',
      hourValidator: 'Horário inválido',
      minAge: `Data não pode ser menor que ${checktorValue} ano(s)`,
      maxAge: 'Data inválida. É preciso ter mais de 18 anos.',
      verifySameValueField: `Valor não pode ser igual ao inicial`,
      minlength: `Mínimo de ${checktorValue.requiredLength} caracteres.`,
      maxlength: `Máximo de ${checktorValue.requiredLength} caracteres.`,
      min: `Número mínimo ${checktorValue.min}`,
      max: `Número máximo ${checktorValue.max}`,
      minLengthMasked: `Mínimo de ${checktorValue} caracteres`,
      lackOfCharacters: 'Número de caractéres incorreto',
      checkOne: `Selecione ao menos um dos campos`,
      requiredFileType: `Apenas arquivos ${checktorValue}`,
    };

    return config[checktorName];
  }

  /**
   * @param min
   * Quantidade mínima de caracteres
   * @description
   * Remove os caracteres especiais e espaços do valor recebido e valida
   * se tem a quantidade mínima de caracteres
   */
  static minLengthMasked(min: number) {
    return (formControl: FormControl) => {
      const value = formControl.value;
      if (value) {
        const treatedValue = removeSpecialCharacters(value);
        return treatedValue.length < min ? { minLengthMasked: min } : null;
      }

      return null;
    };
  }

  /**
   * @param min
   * Mínimo de dias
   * @param msg
   * @description
   * Valida se a data inserida é maior que a data atual
   */
  static minLengthOfDaysFromMonth(min: number, msg = 'Data inválida') {
    return (formControl: FormControl) => {
      const value = formControl.value;

      if (value && value.length > 4) {
        const days = daysFromNow(completeSmallDate(value));
        return days < min ? { invalidDate: msg } : null;
      }

      return null;
    };
  }

  /**
   * Verifica se o CEP é válido
   *
   * @returns inválido caso CEP apresente erro, caso contrário, retorna válido
   */
  static cep(control: FormControl | AbstractControl) {
    const cep: string = control.value;
    return validate.cep(cep) ? { invalidCEP: { cep } } : null;
  }

  /**
   * Verifica se o CPF é válido
   *
   * @returns inválido caso CPF apresente erro, caso contrário, retorna válido
   */
  static cpf(control: FormControl | AbstractControl) {
    const cpf: string = control.value;
    const { code } = cpf ? validate.cpf(`${cpf}`) : { code: '' };
    return code ? { invalidCPF: { cpf } } : null;
  }

  /**
   * Verifica se o CNPJ é válido
   *
   * @returns inválido caso CNPJ apresente erro, caso contrário, retorna válido
   */
  static cnpj(control: AbstractControl) {
    const cnpj: string = control.value;
    const { code } = cnpj ? validate.cnpj(`${cnpj}`) : { code: '' };
    return code ? { invalidCNPJ: { cnpj } } : null;
  }

  /**
   * Verifica se A agencia bancária é válida
   *
   * @returns inválido caso agencia apresente erro, caso contrário, retorna válido
   */
  static agency(control: AbstractControl) {
    const agency: string = control.value;
    const { code } = agency ? validate.bankAgency(`${agency}`) : { code: '' };
    return code ? { invalidData: { agency } } : null;
  }

  /**
   * Verifica se a conta bancária é válida
   *
   * @returns inválido caso agencia apresente erro, caso contrário, retorna válido
   */
  static bankAccount(control: AbstractControl) {
    const account: string = control.value;
    const { code } = account ? validate.bankAccount(`${account}`) : { code: '' };
    return code ? { invalidData: { account } } : null;
  }

  /**
   * Verifica se a data de nascimento é valida
   *
   * @returns inválido caso agencia apresente erro, caso contrário, retorna válido
   */
  static birthAge(control: AbstractControl) {
    const age: string = control.value;
    const { code } = age ? validate.birthAge(`${age}`) : { code: '' };
    return code ? { maxAge: { age } } : null;
  }

  /**
   * @description valida se a pessoa é maior de idade com um formato de data especifico
   */
  static birthAgeFormatted(format: string): ValidatorFn {
    return (control) => {
      const age = control.value;
      const { code } = age ? validate.birthAge(`${age}`, format) : { code: '' };
      return code ? { maxAge: { age } } : null;
    };
  }

  /**
   * Verifica se o CNPJ ou CPF é válido
   *
   * @returns inválido caso CNPJ ou CPF apresente erro, caso contrário, retorna válido
   */
  static cpfCnpj(control: AbstractControl) {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const document: string = control.value;
    const codeCnpj = validate.cnpj(`${document}`);
    const codeCpf = validate.cpf(`${document}`);

    return (codeCpf && codeCpf.code === 0) || (codeCnpj && codeCnpj.code === 0)
      ? null
      : { invalidCPFCNPJ: { document } };
  }

  /**
   * Verifica se a Data é válida
   *
   * @returns inválido caso Data apresente erro, caso contrário, retorna válido
   */
  static date(control: AbstractControl) {
    const date: string = control.value;
    const { code } = date ? validate.data(`${date}`) : { code: '' };
    return code ? { invalidDate: { date } } : null;
  }

  /**
   * Verifica se o e-mail é válido
   *
   * @returns inválido caso e-mail apresente erro, caso contrário, retorna válido
   */
  static email(control: AbstractControl) {
    const email: string = control.value;
    const { code } = email ? validate.email(`${email}`) : { code: '' };
    return code ? { email: { email } } : null;
  }

  /**
   * Verifica se o e-mail é válido, se não for, retorna mensagem específica de erro
   *
   * @returns inválido caso e-mail apresente erro apresentando mensagem específica, caso contrário, retorna válido
   */
  static multipleEmail(control: AbstractControl) {
    const email: string = control.value;
    const { code } = email ? validate.email(`${email}`) : { code: '' };
    return code ? { multipleEmail: { email: email } } : null;
  }

  /**
   * Verifica se o Celular é válido
   *
   * @returns inválido caso celular apresente erro, caso contrário, retorna válido
   */
  static cellphone(control: AbstractControl) {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const celular: string = control.value;
    return validate.celular(celular) ? null : { invalidCel: true };
  }

  /**
   * Verifica se o Telefone é válido
   *
   * @returns inválido caso telefone apresente erro, caso contrário, retorna válido
   */
  static phone(control: AbstractControl) {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const phone: string = control.value;
    return validate.telefone(phone) ? null : { invalidPhone: true };
  }

  /**
   * Verifica se o Nome e valido
   *
   * @returns invalido caso nome apresente erro, caso contrario, retorna valido
   */
  static fullname(control: AbstractControl) {
    const fullname: string = control.value;
    const { code } = fullname ? validate.nomeCompleto(fullname) : { code: '' };

    return code ? { invalidName: { fullname } } : null;
  }

  static checkOne(form: FormGroup, controls: string[]) {
    const check = controls.some((el) => {
      const field = form.get(el);
      return field.value;
    });
    return check ? null : 'Selecione ao menos um dos campos';
  }

  static rejectSequence(control: FormControl) {
    const sequence = control.value;
    if (sequence) {
      const newValue = sequence.replace(/\D/g, '');

      const regex = /^(\d)\1+$/;
      return regex.test(newValue) ? { invalidData: true } : null;
    }
    return null;
  }

  static requiredFileType(type: string) {
    return (control: FormControl) => {
      const file = control.value;
      if (file) {
        const extension = file.name.split('.')[1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          return {
            requiredFileType: type,
          };
        }

        return null;
      }

      return null;
    };
  }

  /**
   * @param otherField
   * Nome do outro campo no form
   * @param label
   * @description
   * Valida se o campo atual e o campo informado tem os mesmos valores
   */
  static equalsTo(otherField: string, label: string) {
    return (formControl: FormControl) => {
      if (otherField == null) {
        throw new Error('É necessário informar um campo.');
      }

      if (!formControl.root || !(<FormGroup>formControl.root).controls) {
        return null;
      }

      const field = (<FormGroup>formControl.root).get(otherField);

      if (!field) {
        throw new Error('É necessário informar um campo válido.');
      }

      if (field.value !== formControl.value) {
        return { equalsTo: label };
      }

      return null;
    };
  }

  /**
   * @param msg
   * @description
   * Verifica se o valor inputado não é vazio
   */
  static noWhitespace(msg = 'O campo não pode ser vazio') {
    return (formControl: FormControl) => {
      const isWhitespace = (formControl.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: msg };
    };
  }
}
