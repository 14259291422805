<div [ngClass]="remainingSize > 0 ? 'closeToMaxUploadCapacity' : 'maxUploadCapacityReached'">
  <acn-card class="card">
    <acn-icon class="icon" icon="fa-exclamation-circle"></acn-icon>

    <div class="card__content">
      <ng-container *ngIf="remainingSize > emptyStorage">
        <ng-content select="[nearLimit]"></ng-content>
      </ng-container>

      <ng-container *ngIf="remainingSize <= emptyStorage">
        <ng-content select="[limitReached]"></ng-content>
      </ng-container>
    </div>
  </acn-card>
</div>
