import { IReconnectResponse } from './models/reconnect-response.model';
import { Injectable, Injector } from '@angular/core';
import { filter, switchMap, tap, catchError } from 'rxjs/operators';
import { of, zip, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AcnAuthenticationService, AcnConnectorService, IRequest } from '@acn/angular';

import {
  InstallationStoreService,
  BillingStoreService,
  CreditCardStoreService,
  IInstallation,
} from '@store/dashboard';
import { RedirectionStoreService, UserStoreService } from '@store/app';
import { IDebitResponse, IBillResponse, IBillHistory } from './models';
import { DuplicateService } from '@features/duplicate/services/duplicate.service';
import { BaseService } from '@shared/services';
import { CampanhaService } from '../../../shared/services/campanha/campanha.service';
@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public selectedInstallationId$ = this._installationStoreService.selectedInstallationId$.pipe(
    filter((item) => item !== null),
  );
  public selectedInstallation$ = this._installationStoreService.selectedInstallation$.pipe(
    filter((item) => item !== null),
  );
  public billingState$ = this._billingStoreService.billingState$;
  public getAllDebits$ = this._billingStoreService.getAllDebits$;
  public getAllBills$ = this._billingStoreService.getAllBills$;
  public loadedDebitos$ = this._billingStoreService.loadedDebitos$;
  public loadedBills$ = this._billingStoreService.loadedBills$;
  public getAllLoadedStatus$ = this._billingStoreService.getAllLoadedStatus$;
  public showDebits$ = this._billingStoreService.showDebits$;
  public payAll$ = this._billingStoreService.payAll$;
  public reconnection$ = this._billingStoreService.reconnection$;
  public cardInfo$ = this._creditCardStore.cardInfo$;
  public loadedReconnection$ = this._billingStoreService.loadedReconnection$;

  private readonly PAID_BILLS = 'conta/contasPagas';
  private readonly OPEN_BILLS = 'conta/v3/debitos';
  private readonly RECONNECTION_ALERT = 'servicos/consultaAvisoCorte';

  constructor(
    private _connectorApi: AcnConnectorService,
    private _installationStoreService: InstallationStoreService,
    private _billingStoreService: BillingStoreService,
    private _userStoreService: UserStoreService,
    private _duplicateService: DuplicateService,
    private _creditCardStore: CreditCardStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _router: Router,
    protected _injector: Injector,
    private _authService: AcnAuthenticationService,
    private _serviceCampanha: CampanhaService,
  ) {
    super(_injector);
  }

  setRedirectedFrom(url: string) {
    this._redirectionStore.setRedirectedFrom(url);
  }

  setShowDebits(status: boolean) {
    this._billingStoreService.showDebits(status);
  }

  getCampanhas(idServico: number | string, idTipoServico: number | null) {
    return this._serviceCampanha.getActiveCampanhas(idServico, idTipoServico);
  }

  getShowDebits() {
    return this._billingStoreService.getShowDebits();
  }

  payAll(status: boolean) {
    this._billingStoreService.payAll(status);
  }

  setReconnection(status: IReconnectResponse) {
    this._billingStoreService.reconnection(status);
  }

  getRedirectedUrl() {
    return this._authService.getRedirectUrl();
  }

  setLoadedReconnection(status: boolean) {
    this._billingStoreService.setLoadedReconnection(status);
  }

  getDebits(showLoading = false) {
    return zip(this._billingStoreService.billingState$, this.selectedInstallation$).pipe(
      switchMap(([state, selectedInstallation]) => {
        if (state.loadedDebitos) {
          return of({ totalDebitos: state.totalDebitos, debitos: state.debitos, state: true });
        }

        const req: IRequest = {
          endPoint: this.OPEN_BILLS,
          showLoading: showLoading,
          queryString: { codCdc: selectedInstallation.cdc, cidade: selectedInstallation.idCidade },
        };

        return this._connectorApi.get<IDebitResponse>(req);
      }),
      tap((res) => {
        if (!res.hasOwnProperty('state')) {
          this._billingStoreService.setDebits(res);
        }
      }),
      catchError(() => {
        this._billingStoreService.setDebitsLoaded(true);
        return of(false);
      }),
    );
  }

  setReconnectionStatus(installation: IInstallation) {
    const req: IRequest = {
      endPoint: this.RECONNECTION_ALERT,
      checkToken: false,
      showLoading: false,
      queryString: { cidade: installation.idCidade, codCdc: installation.cdc },
    };

    return this._connectorApi.get<IReconnectResponse>(req).pipe(
      switchMap((res) => {
        if (Object.keys(res).length > 0) {
          this.setReconnection(res);
        }
        this.setLoadedReconnection(true);
        return of(res);
      }),
      catchError(() => {
        this.setLoadedReconnection(true);
        return of(false);
      }),
    );
  }

  getPaidBills(rangeOfMonths: number = 12) {
    return zip(this._billingStoreService.billingState$, this.selectedInstallation$).pipe(
      switchMap(([state, selectedInstallation]) => {
        if (state.loadedContasPagas) {
          return of({ contasPagas: state.contasPagas, state: true });
        }

        const req: IRequest = {
          endPoint: this.PAID_BILLS,
          showLoading: false,
          queryString: {
            codCdc: selectedInstallation.cdc,
            cidade: selectedInstallation.idCidade,
            nrMeses: rangeOfMonths,
          },
        };
        return this._connectorApi.get<IBillResponse>(req);
      }),
      tap((res) => {
        if (!res.hasOwnProperty('state')) {
          this._billingStoreService.setBills(res);
        }
      }),
      catchError(() => {
        this._billingStoreService.setBillsLoaded(true);
        return of(false);
      }),
    );
  }

  getBillingHistory(): Observable<IBillHistory> {
    return zip(this.getDebits(), this.getPaidBills()).pipe(
      switchMap(([debits, bills]) => {
        let res = {};

        if (debits) {
          const debit = debits as IDebitResponse;
          res = { ...res, ...debit.debitos, totalDebitos: debit.totalDebitos };
        }

        if (bills) {
          const bill = bills as IBillResponse;
          res = { ...res, contasPagas: bill.contasPagas };
        }

        return of(res);
      }),
    );
  }

  getUserName() {
    return this._userStoreService.getUserName();
  }

  getUserEmail() {
    return this._userStoreService.getUserEmail();
  }

  getUserRedirectedFrom() {
    return this._redirectionStore.getRedirectedFrom();
  }

  hasBillingHistory() {
    return this._billingStoreService.hasHistory();
  }

  getInvoiceDetail(invoiceId: number) {
    return this._duplicateService.getInvoiceDetail(invoiceId).pipe(
      tap(() => {
        this._router.navigate(['/home-logado/historico-de-contas/segunda-via-de-conta']);
      }),
    );
  }

  getGuideDetail(invoiceList: Array<number>) {
    if (!invoiceList.length) {
      return of(false);
    }

    if (invoiceList.length > 1) {
      return this._duplicateService.getGuideDetail(invoiceList).pipe(
        tap(() => {
          this._router.navigate(['/home-logado/historico-de-contas/segunda-via-de-conta']);
        }),
      );
    }

    const [invoiceId] = invoiceList;
    return this.getInvoiceDetail(invoiceId);
  }

  resetBilling() {
    this._billingStoreService.resetBilling();
  }

  onDashboard(): boolean {
    return this._settingsStore.onDashboard();
  }
}
