import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { WebchatPosition } from '@features/webchat/model/position';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { map, switchMap } from 'rxjs/operators';
import { ActiveService } from '@shared/services/active-service/active-service';
import { servicesCategory, servicesList } from '@shared/enumerators';

@Injectable({ providedIn: 'root' })
export class WebchatService {
  constructor(private readonly selectCityService: SelectCityService) {}

  private readonly _action$ = new Subject<boolean>();

  readonly action$ = this._action$.asObservable();

  readonly isActive$ = this.selectCityService.selectedCity$.pipe(
    switchMap((city) => {
      if (!city) {
        // Se não tiver cidade selecionada, esconde o ícone
        return of(false);
      }
      // Se tiver cidade selecionada, veja se o serviço está ativo
      return this.selectCityService.selectedCityServices$.pipe(
        // Verifica se o serviço está ativo
        map((data) =>
          ActiveService.create(data).isServiceActive(
            servicesCategory.information,
            servicesList.webchat,
          ),
        ),
      );
    }),
  );

  open(): void {
    this._action$.next(true);
  }

  close(): void {
    this._action$.next(false);
  }

  validatePosition(position: string | null | undefined): WebchatPosition {
    const positions = new Set<WebchatPosition>([
      'bottom-left',
      'top-left',
      'bottom-right',
      'top-right',
    ]);
    const defaultPosition: WebchatPosition = 'bottom-right';
    return positions.has(position as WebchatPosition)
      ? (position as WebchatPosition)
      : defaultPosition;
  }
}
