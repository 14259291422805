<div
  data-cy="webchat"
  [class.is-hidden]="isHidden$ | async"
  [class.offset-bottom]="offsetBottom$ | async"
  id="webchat"
  [attr.data-chat-token]="token"
  [attr.data-chat-theme]="theme"
  [attr.data-position]="position"
  #webchat
>
  <div id="webchat-app"></div>
</div>
