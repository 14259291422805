import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { take, takeUntil } from 'rxjs/operators';

import { ICity, INewCity } from '@store/dashboard';
import { BaseComponent } from '@acn/angular';
import { LandingService } from '@features/landing/services/landing.service';
@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.scss'],
})
export class LandingHomeComponent extends BaseComponent implements OnInit {
  public onMobile = false;
  public selectedCity: ICity;
  public selectedCityInformation: INewCity;
  public isBrowser: boolean;
  public baseImgUrl: string;
  public imageDesktopURL: string;
  public backgroundMobileURL: string;
  public imageMobileURL: string;
  public backgroundImageUrl: string;
  public backgroundPosition: string = 'top center';
  public isActiveCampanha = true;
  public redirectUrl: string;

  constructor (private _landingService: LandingService, @Inject(PLATFORM_ID) private _platformId) {
    super();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit () {
    this.baseImgUrl = this._landingService.baseImgUrl;
    if (!this.isBrowser) {
      return;
    }
    this._setScreen();
    this._getSelectedCity();
    this._landingService
      .getCampanhas()
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.length > 0) {
          const campaign = data[data?.length - 1];

          this.redirectUrl = campaign.urlDestino
          this.imageMobileURL = campaign.iconHomeMobile;
          this.backgroundMobileURL = campaign.backgroundHomeMobile;
          this.imageDesktopURL = campaign.backgroundHomeDesktop;
          this.isActiveCampanha = true;
        } else {
          this._setBackgroundImageUrl();
          this.isActiveCampanha = false;
        }
      });
  }

  /**
   * Verifica se está em um device touch
   */
  private _setScreen () {
    this._landingService.onMobile$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.onMobile = data));
  }

  /**
   * Busca a cidade selecionada no store
   */
  private _getSelectedCity () {
    this._landingService.selectedCity$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.selectedCity = data;
      this.selectedCityInformation = data;
    });
  }

  /**
   * Define a imagem para background de acordo com o tipo do dispositivo
   */
  private _setBackgroundImageUrl () {
    this._landingService.onMobile$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.backgroundPosition = 'top center';
      const img = data ? 'home/landing-bg-mobile.jpg' : 'home/landing-bg-desktop.jpg';
      this.backgroundImageUrl = `url(${this.baseImgUrl}/img/${img})`;
    });
  }
}
