import { CitiesStoreService } from '@store/dashboard/states/cities';
import { BaseService, WINDOW } from '@shared/services';
import { Inject, Injectable, Injector } from '@angular/core';
import { ActivationEnd, Event, Router } from '@angular/router';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export function isActivationEnd(event: Event): event is ActivationEnd {
  return event instanceof ActivationEnd;
}

@Injectable({
  providedIn: 'root',
})
export class GeoagnosticService extends BaseService {
  public selectedCity$ = this._citiesStore.selectedCity$;

  constructor(
    protected _injector: Injector,
    private _citiesStore: CitiesStoreService,
    private _selectCityService: SelectCityService,
    private router: Router,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    super(_injector);
  }

  private _lastUrl: string | null = null;
  private readonly _destroy$ = new Subject<void>();

  public back(route: string, redirectUrl?: string): void {
    this.backUrlWindow();
    setTimeout(() => {
      if (this.router.url === '/home/servicos/' + route) {
        this._selectCityService.changeSelectedCity(null);
        this.router.navigate(['/home/servicos/info/' + (redirectUrl || route)]);
      }
    }, 200);
  }

  public backUrlWindow(): void {
    this.window.history.back();
  }

  setLastUrl(url: string | null): void {
    this._destroy$.next();
    this._lastUrl = url;
    if (!this._lastUrl) {
      return;
    }
    // Valida se o usuário trocar de página após acessar o site por uma página geoagnostica
    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter(isActivationEnd),
        filter(() => !!this._lastUrl),
      )
      .subscribe(() => {
        // Se a rota atual não incluir a última rota na string, provavelmente o usuário está indo pra outro lugar do app
        if (!this.router.url.includes(this._lastUrl.replace('/info', ''))) {
          this._lastUrl = null;
          this._destroy$.next();
        }
      });
  }

  redirectToLastUrl(defaultUrl: string): void {
    const url = this._lastUrl ?? defaultUrl;
    if (this._lastUrl) {
      // Se tiver a última url preenchida
      // Provavelmente o usuário está tentando voltar para a página geoagnostica
      this._selectCityService.changeSelectedCity(null);
      this.setLastUrl(null);
    }
    this.router.navigateByUrl(url);
  }
}
