import { IAcnDynamicComponentManifest } from '@acn/angular';

export const termUseDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'termUse.error',
    loadChildren: () =>
      import('../features/terms/components/error-modal-term-use/error-modal-term-use.module').then(
        (m) => m.ErrorModalTermUseModule,
      ),
  },
  {
    path: 'termUse.revocation',
    loadChildren: () =>
      import(
        '../features/terms/components/modal-revocation-term/modal-revocation-term.module'
      ).then((m) => m.ModalRevocationTermModule),
  },
  {
    path: 'termUse.cardError',
    loadChildren: () =>
      import(
        '../features/terms/components/modal-error-removing-card/modal-error-removing-card.module'
      ).then((m) => m.ModalErrorRemovingCardModule),
  },
  {
    path: 'termUse.success',
    loadChildren: () =>
      import(
        '../features/terms/components/successful-revocation-modal/successful-revocation-modal.module'
      ).then((m) => m.SuccessfulRevocationModalModule),
  },
  {
    path: 'termUse.successCard',
    loadChildren: () =>
      import(
        '../features/terms/components/successful-revocation-modal-card/successful-revocation-modal-card.module'
      ).then((m) => m.SuccessfulRevocationModalCardModule),
  },
  {
    path: 'termUse.successAccept',
    loadChildren: () =>
      import(
        '../features/terms/components/successful-accept-term-use-modal/successful-accept-term-use-modal.module'
      ).then((m) => m.SuccessfulAcceptTermUseModalModule),
  },
  {
    path: 'termUse.accept',
    loadChildren: () =>
      import('../features/terms/components/accept-terms-modal/accept-terms-modal.module').then(
        (m) => m.AcceptTermsModalModule,
      ),
  },
];
