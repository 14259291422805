import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ILog } from './models/log.model';
import { AcnConnectorService } from '../connector/acn-connector.service';
import { AcnGuidService } from '../../shared/services/acn-guid.service';

/**
 * Serviço para tratamento de Log Http
 */
@Injectable({
  providedIn: 'root',
})
export class AcnLogHttpService {
  /**
   * Constructor
   */
  constructor(private connector: AcnConnectorService, private guidService: AcnGuidService) {}

  /**
   * Salva e retorna as informações de log do servidor
   *
   * @returns retorna connector com as informações de log
   */
  logOnServer(message: string, metaData: ILog): Observable<any> {
    const body = [
      {
        apiError: metaData.apiError,
        message,
        logLevel: metaData.logLevel,
        dateTime: metaData.dateTime,
        hostname: metaData.hostname,
        href: metaData.href,
        navigatorInfo: metaData.navigatorInfo,
      },
    ];

    const headers = {
      'X-ACN-GUID': this.guidService.createGuid(),
    };

    return this.connector.request('log', { headers, body }).pipe(retry(3));
  }
}
