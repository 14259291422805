<ng-container *transloco="let text; scope: 'creditCard'">
  <div class="wrapper" [class.rotate]="creditCard.flipCard">
    <div
      class="content"
      [ngClass]="{
        'card--expired': expired,
        'card--toExpire': toExpire
      }"
    >
      <acn-card class="card card--front" [class.active]="active">
        <div class="flags">
          <div class="flag__rectangle"></div>
          <img class="flag" [src]="bgImage" alt="Marca cartão de crédito" />
        </div>
        <div
          class="card__number is-semi-bold"
          [class.active]="active"
          [ngClass]="mobile ? 'is-size-16' : 'is-size-20'"
        >
          {{ creditCard?.number }}
        </div>
        <div class="line is-size-10">
          <p>{{ text('creditCard.card.name') }}</p>
          <p>{{ text('creditCard.card.date') }}</p>
        </div>
        <div class="line">
          <p class="card__name is-size-14 is-semi-bold has-text-blue">{{ creditCard?.name }}</p>
          <p
            class="card__date is-size-14 is-semi-bold"
            [class.error]="creditCard?.dateError"
            [class.active]="active && !creditCard?.dateError"
          >
            {{ creditCard?.date }}
          </p>
        </div>
      </acn-card>

      <acn-card class="card card--back" [class.active]="active">
        <div class="card__stripe"></div>
        <div class="circle"></div>
        <div class="card__info">
          <div class="details">
            <div class="back_line"></div>
            <div class="back_line"></div>
            <div class="back_line"></div>
            <div class="back_line"></div>
          </div>
          <div class="value is-bold is-size-20" [class.active]="active && hasCode">
            {{ creditCard?.code }}
          </div>
        </div>
      </acn-card>
    </div>
  </div>
</ng-container>
