import { InjectionToken } from '@angular/core';
import { IConfiguration } from '@acn/angular';

/**
 * @description Used only for testing
 */
const testingEnvironment: IConfiguration = {
  baseUrl: '',
  baseHref: '',
  handTalkUrl: '',
  production: false,
  handTalkToken: '',
  captchaKey: '',
  rsaKey: '',
  webchatUrl: '',
  googleKey: '',
  privacyUrl: '',
  baseImgUrl: '',
  baseCMSImgUrl: '',
  features: '',
  webchatTheme: '',
  webchatToken: '',
  webchatPosition: '',
  logLevel: 0,
};

/**
 * @description This should be provided in the root module or the platform bootstrap
 */
export const ACN_CONFIGURATION = new InjectionToken<IConfiguration>('ACN_CONFIGURATION', {
  providedIn: 'root',
  factory: () => testingEnvironment,
});
