<a
  target="_blank"
  rel="noopener noreferrer"
  [href]="'//' + finalUrl"
  class="link is-bold is-size-14"
  acnGtm
  gtmCategory="Botao"
  gtmAction="Agendar_Atendimento_Presencial"
  gtmLabel="Agendar_Atendimento_Presencial"
  >
  Agendar atendimento presencial
  <acn-icon class="arrow-icon" icon="diagonal-arrow"></acn-icon>
</a>
