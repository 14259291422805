import { IServiceList, IServices } from '@features/services/models/city-services-list.model';
import { servicesList } from '@shared/enumerators';

export class ActiveServiceCategory {
  private constructor(private readonly category: IServiceList | null) {}

  static create(category: IServiceList | null): ActiveServiceCategory {
    return new ActiveServiceCategory(category);
  }

  private _getFunctionalityFromService(
    service: IServices | null,
    idFunctionality: servicesList,
  ): IServices | null {
    return (
      service?.funcionalidades?.find((functionality) => functionality.id === idFunctionality) ??
      null
    );
  }

  getService(idService: servicesList): IServices | null {
    return this.category?.servicos.find((service) => service.id === idService) ?? null;
  }

  getFunctionality(idService: servicesList, idFunctionality: servicesList): IServices | null {
    return this._getFunctionalityFromService(this.getService(idService), idFunctionality);
  }

  isServiceActive(idService: servicesList): boolean {
    return this.getService(idService)?.ativo ?? false;
  }

  isFunctionalityActive(idService: servicesList, idFunctionality: servicesList): boolean {
    return this.getFunctionality(idService, idFunctionality)?.ativo ?? false;
  }

  isMultipleFunctionalityActive<T extends servicesList>(
    idService: servicesList,
    idFunctionalities: T[],
  ): ReadonlyMap<T, boolean> {
    const service = this.getService(idService);
    const map = new Map<T, boolean>();
    for (const idFunctionality of idFunctionalities) {
      map.set(
        idFunctionality,
        this._getFunctionalityFromService(service, idFunctionality)?.ativo ?? false,
      );
    }
    return map;
  }

  isMultipleServiceActive<T extends servicesList>(idServices: T[]): ReadonlyMap<T, boolean> {
    const map = new Map<T, boolean>();
    for (const idService of idServices) {
      map.set(idService, this.isServiceActive(idService));
    }
    return map;
  }
}
