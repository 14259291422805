import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

import { SettingsStoreService } from '@store/app';

@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() appFeatureFlag: string | string[];
  @Input() appFeatureFlagOr = '';

  constructor(
    private _vcr: ViewContainerRef,
    private _tpl: TemplateRef<any>,
    private _settingsStore: SettingsStoreService,
  ) {}

  ngOnInit() {
    if (
      this._settingsStore.hasFeatures(this.appFeatureFlag) ||
      this._settingsStore.hasFeatures(this.appFeatureFlagOr)
    ) {
      this._vcr.createEmbeddedView(this._tpl);
    }
  }
}
