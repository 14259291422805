import { ITermUse } from '@features/terms/models/term-use.model';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';

import { AcnConnectorService, AcnLoaderService, IRequest } from '@acn/angular';
import {
  ICard,
  ICardInfo,
  IErrorRegisterCard,
  IRequestCreditCard,
  IResponseRegisterCard,
} from './models';
import { BaseService } from '@shared/services/base/base.service';
import {
  CitiesStoreService,
  CreditCardStoreService,
  ICreditCard,
  ICreditCardDocument,
  IInstallation,
  InstallationStoreService,
  StepStoreService,
} from '@store/dashboard';
import { UserStoreService, UseTermsStoreService } from '@store/app';

@Injectable({
  providedIn: 'root',
})
export class AutomaticCreditCardService extends BaseService {
  public selectedInstallation$: Observable<IInstallation>;
  public creditCard$: Observable<ICreditCard>;
  public cardInfo$: Observable<ICreditCard>;
  public cardTerms$: Observable<boolean>;
  public cardDocument$: Observable<ICreditCardDocument>;
  public resetDate$: Observable<boolean>;

  private readonly REGISTER_CARD_URL = 'cartao';
  private readonly CARD_INFO_URL = 'cartao';
  private readonly CARD_BRAND_URL = 'cartao/bandeira';
  private readonly GET_PRIVATE_TERMO_USO: string = 'termoUso/vigente/';
  private readonly POST_PRIVATE_TERMO_USO: string = 'termoUso/vigente/';
  protected _cardStoreService: CreditCardStoreService;
  protected _useTermsService: UseTermsStoreService;
  protected _decimalPipe: DecimalPipe;
  protected _connectorApi: AcnConnectorService;
  protected _citiesStore: CitiesStoreService;
  protected _installationStore: InstallationStoreService;
  protected _userStore: UserStoreService;
  protected _loaderService: AcnLoaderService;
  protected _stepsStore: StepStoreService;
  protected _router: Router;

  constructor(protected _injector: Injector) {
    super(_injector);

    this._citiesStore = this._injector.get(CitiesStoreService);
    this._installationStore = this._injector.get(InstallationStoreService);
    this._cardStoreService = this._injector.get(CreditCardStoreService);
    this._connectorApi = this._injector.get(AcnConnectorService);
    this._userStore = this._injector.get(UserStoreService);
    this._loaderService = this._injector.get(AcnLoaderService);
    this._useTermsService = this._injector.get(UseTermsStoreService);
    this._decimalPipe = this._injector.get(DecimalPipe);
    this._router = this._injector.get(Router);
    this._stepsStore = this._injector.get(StepStoreService);
    this.selectedInstallation$ = this._installationStore.selectedInstallation$;
    this.creditCard$ = this._cardStoreService.creditCard$;
    this.cardDocument$ = this._cardStoreService.cardDocument$;
    this.cardInfo$ = this._cardStoreService.cardInfo$;
    this.cardTerms$ = this._useTermsService.cardTerms$;
    this.resetDate$ = this._cardStoreService.resetDate$;
  }

  resetSteps() {
    this._stepsStore.resetSteps();
  }

  navigate(route: string) {
    this._router.navigate([route]);
  }

  setRegisterSteps() {
    const steps = [
      'numero-cartao',
      'nome-titular',
      'validade-cartao',
      'codigo-seguranca',
      'termo-uso-pagamento-automatico',
    ];

    this._setSteps(steps);
  }

  nextStep() {
    const nextStep = this._stepsStore.nextStep();
    this.navigate(`/home-logado/pagamento/credito-automatico/${nextStep}`);
  }

  navigateToStep(step: string) {
    this._stepsStore.navigateToStep(step);
    this._router.navigate([`/home-logado/pagamento/credito-automatico/${step}`]);
  }

  prevStep() {
    const prevStep = this._stepsStore.prevStep();

    if (prevStep) {
      this.navigate(`/home-logado/pagamento/credito-automatico/${prevStep}`);
      return;
    }

    this.navigate(`/home-logado/perfil`);
  }

  scrollTop(el: ElementRef) {
    this._screenService.scrollTop(el);
  }

  setCardNumber(number: string) {
    this._cardStoreService.setCardNumber(number);
  }

  setBrand(brand: number) {
    this._cardStoreService.setCardBrand(brand);
  }

  setName(name: string) {
    this._cardStoreService.setCardName(name);
  }

  setCardDate(date: string) {
    this._cardStoreService.setCardDate(date);
  }

  setType(type: 'a vista' | 'automatico') {
    this._cardStoreService.setType(type);
  }

  setResetDate(type: boolean) {
    this._cardStoreService.setResetDate(type);
  }

  setCardDateError(error: boolean) {
    this._cardStoreService.setCardDateError(error);
  }

  setCardFlip(flip: boolean) {
    this._cardStoreService.setCardFlip(flip);
  }

  setCardCode(code: string) {
    this._cardStoreService.setCardCode(code);
  }

  setCreditCardDocument(document: ICreditCardDocument) {
    this._cardStoreService.setCreditCardDocument(document);
  }

  resetCreditCardStore() {
    this._cardStoreService.resetCreditCard();
  }

  resetRegisterCreditCard() {
    this._cardStoreService.resetRegisterCreditCard();
  }

  automaticPaymentModal() {
    this.showModal({ componentId: 'credit-card.automatic' });
  }

  registerCard(cardNumber: string, cvv: string) {
    this._loaderService.show();
    return zip(
      this.creditCard$,
      this._installationStore.selectedInstallation$,
      this._userStore.user$,
      this._citiesStore.selectedCity$,
    ).pipe(
      switchMap(([creditCard, installation, user, city]) => {
        const [month, year] = creditCard.date.split('/');
        const [name, ...lastName] = user.nome.split(' ');
        const currentYear = new Date().getFullYear().toString().substr(0, 2);

        const body: IRequestCreditCard = {
          cliente: {
            codUnidade: city.idUnidade,
            idCidade: installation.idCidade,
            idLigacao: Number(installation.cdc),
            email: user.email,
            numTelefone: user.telMovel,
            primeiroNome: name,
            sobrenome: (lastName && lastName.join(' ')) || null,
          },
          cartao: {
            nomeTitular: creditCard.name,
            numCartao: cardNumber,
            anoVencimento: `${currentYear}${year}`,
            mesVencimento: month,
            cvv,
            enderecoCobranca: null,
          },
          enderecoIp: 'remoteAddress',
        };

        const req: IRequest = {
          endPoint: this.REGISTER_CARD_URL,
          showLoading: true,
          body,
        };

        return this._connectorApi.post<IResponseRegisterCard>(req).pipe(
          switchMap((res) => {
            if (!res.retornoOperacao) {
              const cardInfo: ICard = {
                noUltimosDigitosCartao: res.numCartao,
                noAnoVencimentoCartao: res.anoVencimento,
                noMesVencimentoCartao: res.mesVencimento,
                bandeira: res.bandeira,
                regrasVencimento: res.regrasVencimento,
              };

              this._setCardInfo(cardInfo);
              this._useTermsService.setCreditCardTerms(true);
              return of({ status: true, msg: installation.cdc });
            }

            return of({ status: false, msg: res.respostaAdquirente.mensagemResposta });
          }),
          catchError((error) => {
            const _error: IErrorRegisterCard = error.error;
            this._useTermsService.setCreditCardTerms(false);
            const msg =
              _error && _error.mensagens
                ? _error.mensagens.join('<br/>')
                : 'Erro!  Tente novamente';

            return of({ status: false, msg });
          }),
          finalize(() => this._loaderService.hide()),
        );
      }),
    );
  }

  getCardBrand(cardNumber: string) {
    const req: IRequest = {
      endPoint: this.CARD_BRAND_URL,
      showLoading: true,
      body: { numPrim6DigitosCartao: cardNumber.substr(0, 6) },
    };

    return this._connectorApi.post<{ bandeira: number }>(req).pipe(
      switchMap((data) => {
        this._cardStoreService.setCardBrand(data.bandeira);

        return of(true);
      }),
      catchError(() => {
        this._cardStoreService.setCardBrand(0);

        return of(true);
      }),
    );
  }

  getCardInfo(showLoading: boolean = false) {
    return zip(this._installationStore.selectedInstallation$, this._citiesStore.selectedCity$).pipe(
      switchMap(([installation, city]) => {
        const req: IRequest = {
          endPoint: this.CARD_INFO_URL,
          queryString: {
            codUnidade: city.idUnidade,
            noCidade: city.id,
            idLigacao: installation?.cdc,
            nrRegistros: 1,
          },
          showLoading: showLoading,
        };

        return this._connectorApi.get<ICardInfo>(req).pipe(
          switchMap((res) => {
            const [card] = res.cartoes;

            this._setCardInfo(card);

            this._useTermsService.setCreditCardTerms(true);

            return of(res);
          }),
          catchError((r) => {
            this._useTermsService.setCreditCardTerms(false);
            return of(r);
          }),
        );
      }),
    );
  }
  getTermUsePrivate(idTerm: number, idLigacao: string, idCidade: string) {
    const req: IRequest = {
      endPoint: this.GET_PRIVATE_TERMO_USO,
      queryString: {
        idTermo: idTerm,
        idLigacao: idLigacao,
        idCidade: idCidade,
      },
      showLoading: true,
      checkToken: true,
    };

    return this._connectorApi.get<ITermUse[]>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        if (error.status === 403) {
          return of(error.error);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  removeCard(): Observable<{ status: boolean; msg: string }> {
    return zip(this._installationStore.selectedInstallation$, this._citiesStore.selectedCity$).pipe(
      switchMap(([installation, city]) => {
        const req: IRequest = {
          endPoint: this.CARD_INFO_URL,
          showLoading: true,
          queryString: {
            codUnidade: city.idUnidade,
            noCidade: city.id,
            idLigacao: installation.cdc,
          },
        };

        return this._connectorApi.delete<ICardInfo>(req).pipe(
          switchMap((res) => {
            this._cardStoreService.resetCreditCard();
            this._useTermsService.setCreditCardTerms(false);
            return of({ status: true, msg: null });
          }),
          catchError((error) => {
            return of({ status: false, msg: null });
          }),
        );
      }),
    );
  }

  setRegisterTermUsePrivate(
    action: string,
    idTermoUsoVersao: number,
    idLigacao?: string,
    idCidade?: string,
  ) {
    const req: IRequest = {
      endPoint: this.POST_PRIVATE_TERMO_USO,
      checkToken: true,
      body: {
        acao: action,
        idLigacao,
      },
      queryString: {
        idTermoUsoVersao: idTermoUsoVersao,
        idCidade: idCidade,
      },
      showLoading: true,
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  protected _setCardInfo(card: ICard) {
    const displayData: ICreditCard = {
      number: `************${card.noUltimosDigitosCartao}`,
      date: `${this._decimalPipe.transform(
        card.noMesVencimentoCartao,
        '2.0',
      )}/${card.noAnoVencimentoCartao.toString().substr(2, 2)}`,
      brand: card.bandeira,
      expired: card?.regrasVencimento?.vencido,
      daysToExpire: card?.regrasVencimento?.aVencer,
      flagName: card.nomeBandeira,
    };

    this._cardStoreService.setCardInfo(displayData);
  }

  protected _setSteps(steps: Array<string>) {
    this._stepsStore.setSteps(steps);
    this._stepsStore.setCurrentStep(0);
  }
}
