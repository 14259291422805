<div>
  <div>
    <div [innerHTML]="message"></div>
  </div>
  <div class="partner-content">
    <h4 class="select-partner">Selecione o parceiro</h4>
    <div class="partner-container">
      <a
        *ngFor="let partner of partners"
        class="partner"
        (click)="selectPartner(partner)"
        acnGtm
        gtmCategory="Botao"
        [gtmAction]="'parceiro_selecionado_' + partner.nomeFornecedor"
      >
        <img [src]="partner.urlThumbnail" [alt]="partner.nomeFornecedor" />
      </a>
    </div>
  </div>
</div>
