import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceSimulatorService } from '../../features/invoice-simulator-service/service/invoice-simulator.service';

@Injectable({
  providedIn: 'root',
})
export class BackButtonGuard implements CanActivate {
  constructor(private _serviceInvoiceSimulator: InvoiceSimulatorService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    stateRoute: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.setBackButton(stateRoute);
    return true;
  }

  private setBackButton(stateRoute: RouterStateSnapshot) {
    const baseUrl = this._serviceInvoiceSimulator.getOnDashboard() ? 'home-logado' : 'home';
    if (stateRoute.url.includes('servicos') && !stateRoute.url.includes('simulador-tarifa')) {
      this._serviceInvoiceSimulator.setUrlBack(`/${baseUrl}/servicos`);
    } else {
      this._serviceInvoiceSimulator.setUrlBack(`/${baseUrl}`);
    }
  }
}
