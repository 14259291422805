import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { ITermAutomaticDebit } from '.';
import { SetSiteTerms, SetCreditCardTerms, SetAutomaticDebitTerms } from './use-terms.actions';

export interface UseTermsStateModel {
  site: boolean;
  creditCard: boolean;
  automaticDebit: ITermAutomaticDebit;
}

@State<UseTermsStateModel>({
  name: 'useTerms',
  defaults: {
    site: false,
    creditCard: false,
    automaticDebit: null
  },
})
@Injectable()
export class UseTermsState {
  @Selector()
  public static getState(state: UseTermsStateModel) {
    return state;
  }

  @Selector()
  public static getSiteTerms(state: UseTermsStateModel) {
    return state.site;
  }

  @Selector()
  public static getCreditCardTerms(state: UseTermsStateModel) {
    return state.creditCard;
  }

  @Selector()
  public static getAutomaticDebitTerms(state: UseTermsStateModel) {
    return state.automaticDebit;
  }

  @Action(SetSiteTerms)
  public setSiteTerms(ctx: StateContext<UseTermsStateModel>, { payload }: SetSiteTerms) {
    const stateModel = ctx.getState();
    ctx.setState({ ...stateModel, site: payload });
  }

  @Action(SetCreditCardTerms)
  public setCreditCardTerms(
    ctx: StateContext<UseTermsStateModel>,
    { payload }: SetCreditCardTerms,
  ) {
    const stateModel = ctx.getState();
    ctx.setState({ ...stateModel, creditCard: payload });
  }

  @Action(SetAutomaticDebitTerms)
  public setAutomaticDebitTerms(
    ctx: StateContext<UseTermsStateModel>,
    { payload }: SetAutomaticDebitTerms,
  ) {
    const stateModel = ctx.getState();
    ctx.setState({ ...stateModel, automaticDebit: payload });
  }
}
