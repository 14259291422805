import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ModalState } from './modal.state';
import { ShowModal, HideModal } from './modal.actions';
import { IModal } from './models/modal.model';

@Injectable({
  providedIn: 'root',
})
export class ModalStoreService {
  @Select(ModalState)
  public modalState$: Observable<IModal>;

  @Select(ModalState.getParams)
  public params$: Observable<any>;

  constructor(private store: Store) {}

  @Dispatch()
  public showModal(modal: IModal) {
    return new ShowModal(modal);
  }

  @Dispatch()
  public hideModal() {
    return new HideModal();
  }

  public getParamsSnapshot<T>(): T {
    return this.store.selectSnapshot(ModalState.getParams);
  }
}
