import { ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PageNotFoundComponent } from '@shared';
import { FormDirective, I18nModule, GtmDirective } from '@acn/angular';
import {
  SplitComponent,
  FooterComponent,
  AccordionComponent,
  LandingMenuComponent,
  SplitGradientComponent,
  ConsumptionStatusComponent,
  SideActionMenuComponent,
  SmsFormComponent,
  CreditCardDisplayComponent,
  StepMenuComponent,
  AutomaticDebitComponent,
  CreditOrDebitCardComponent,
  RegisteredCardComponent,
  AttendanceChannelsComponent,
  AttendanceAgenciesComponent,
  BillingForEmailCard,
  BackButtonComponent,
  InstallmentPartnersComponent,
  ButtonScheduleAttendanceComponent,
  PixQRCodeComponent,
  MainPaymentMethod,
} from './components/index';

import { ContentItemDirective, FeatureFlagDirective } from './directives/index';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { NotHolderWarningComponent } from './components/not-holder-warning/not-holder-warning.component';
import { FileUploadWarningsComponent } from './components/file-upload-warnings/file-upload-warnings.component';
import { HandTalkClickableDirective } from '@shared/directives/hand-talk-clickable.directive';
import { HandTalkSkipDirective } from '@shared/directives/hand-talk-skip.directive';
import { CpfOrCnpjPipe } from './pipes/cpf-or-cnpj.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MaskEmailPipe } from './pipes/email-mask.pipe';

@NgModule({
  imports: [CommonModule, I18nModule.forChild(''), RouterModule, ReactiveFormsModule, ClipboardModule],
  declarations: [
    PageNotFoundComponent,
    SplitComponent,
    FooterComponent,
    FormDirective,
    GtmDirective,
    FeatureFlagDirective,
    ConsumptionStatusComponent,
    CreditCardDisplayComponent,
    AccordionComponent,
    LandingMenuComponent,
    SplitGradientComponent,
    SideActionMenuComponent,
    SmsFormComponent,
    StepMenuComponent,
    ContentItemDirective,
    AutomaticDebitComponent,
    CreditOrDebitCardComponent,
    RegisteredCardComponent,
    AttendanceChannelsComponent,
    InstallmentPartnersComponent,
    AttendanceAgenciesComponent,
    AutoCompleteComponent,
    OnlyNumbersDirective,
    NotHolderWarningComponent,
    FileUploadWarningsComponent,
    HandTalkClickableDirective,
    HandTalkSkipDirective,
    CpfOrCnpjPipe,
    BillingForEmailCard,
    BackButtonComponent,
    ButtonScheduleAttendanceComponent,
    PixQRCodeComponent,
    MainPaymentMethod,
    MaskEmailPipe,
  ],
  exports: [
    SplitComponent,
    FooterComponent,
    ConsumptionStatusComponent,
    FormDirective,
    GtmDirective,
    FeatureFlagDirective,
    AccordionComponent,
    LandingMenuComponent,
    SideActionMenuComponent,
    SplitGradientComponent,
    ConsumptionStatusComponent,
    CreditCardDisplayComponent,
    SmsFormComponent,
    StepMenuComponent,
    ContentItemDirective,
    AutomaticDebitComponent,
    CreditOrDebitCardComponent,
    RegisteredCardComponent,
    AttendanceChannelsComponent,
    InstallmentPartnersComponent,
    AttendanceAgenciesComponent,
    AutoCompleteComponent,
    OnlyNumbersDirective,
    NotHolderWarningComponent,
    FileUploadWarningsComponent,
    HandTalkClickableDirective,
    HandTalkSkipDirective,
    CpfOrCnpjPipe,
    BillingForEmailCard,
    BackButtonComponent,
    ButtonScheduleAttendanceComponent,
    PixQRCodeComponent,
    MainPaymentMethod,
    MaskEmailPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
