import {
  INewInstallationAddressData,
  INewInstallationDocsData,
  INewInstallationHolderData,
  INewInstallationInstallationData,
} from './models/new-installation-state.model';
import { INewInstallationData, INewInstallationInfo } from '@features/new-installation/models';
import { IDocumentList } from '@features/new-installation/services/models/document-list.model';
import { IProtocol } from '@features/new-installation/services/models/new-installation-protocol.model';
export class ResetNewInstallation {
  public static readonly type = '[New Installation] Reset New Installation';
}

export class SetUrlBack {
  public static readonly type = '[New Installation] Set UrlBack';
  constructor(public payload: string) {}
}

export class SetNewInstallationData {
  public static readonly type = '[New Installation] Set newInstallationData';
  constructor(public payload: INewInstallationData) {}
}

export class SetNewInstallationDocuments {
  public static readonly type = '[New Installation] Set newInstallationDocuments';
  constructor(public payload: IDocumentList) {}
}

export class SetNewInstallationAddressData {
  public static readonly type = '[New Installation] Set Address data';
  constructor(public payload: INewInstallationAddressData) {}
}

export class SetHolderDocument {
  public static readonly type = '[New Installation] Set holderDocument';
  constructor(public payload: string) {}
}

export class SetHasDebits {
  public static readonly type = '[New Installation] Set hasDebits';
  constructor(public payload: boolean) {}
}

export class SetProtocolList {
  public static readonly type = '[New Installation] Set protocolList';
  constructor(public payload: Array<IProtocol>) {}
}

export class SetRequestingDocs {
  public static readonly type = '[New Installation] Set requesting docs';
  constructor(public payload: Array<INewInstallationDocsData>) {}
}

export class SetInstallationDocs {
  public static readonly type = '[New Installation] Set intallation docs';
  constructor(public payload: Array<INewInstallationDocsData>) {}
}

export class SetHolderData {
  public static readonly type = '[New Installation] Set holder data';
  constructor(public payload: INewInstallationHolderData) {}
}

export class SetSelectedNewInstallation {
  public static readonly type = '[New Installation] Set selected new installation';
  constructor(public payload: INewInstallationInfo) {}
}
export class SetInstallationData {
  public static readonly type = '[New Installation] Set installation data';
  constructor(public payload: INewInstallationInstallationData) {}
}
