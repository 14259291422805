import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { IModal, SettingsStoreService } from '@store/app';
import { GtmService, AcnConfigurationService, IConfiguration } from '@acn/angular';
import { ScreenService } from '@shared/services/screen/screen.service';

export abstract class BaseService {
  public onMobile$: Observable<boolean>;
  public baseImgUrl = '';
  public settings: IConfiguration;

  protected _settingsStore: SettingsStoreService;
  protected _screenService: ScreenService;
  protected _gtmService: GtmService;
  protected _configurationService: AcnConfigurationService;

  constructor(protected _injector: Injector) {
    this._settingsStore = this._injector.get(SettingsStoreService);
    this._screenService = this._injector.get(ScreenService);
    this._gtmService = this._injector.get(GtmService);
    this._configurationService = this._injector.get(AcnConfigurationService);

    this.onMobile$ = this._settingsStore.onMobile$;

    if (this._configurationService.settings) {
      this.baseImgUrl = this._configurationService.settings.baseImgUrl;
      this.settings = this._configurationService.settings;
    }
  }

  showModal(modal: IModal) {
    this._screenService.showModal(modal);
  }

  hideModal() {
    this._screenService.hideModal();
  }

  setNotification(type: string, title: string, content: string) {
    this._screenService.setNotification(type, title, content);
  }

  hasFeatures(featureFlags: string | Array<string>) {
    return this._settingsStore.hasFeatures(featureFlags);
  }
}
