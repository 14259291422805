import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { TokenSmsState } from './token-sms.state';
import { SetPhoneSms } from './token-sms.actions';
import { removeSpecialCharacters } from '@acn/angular';

@Injectable({
  providedIn: 'root',
})
export class TokenSmsStoreService {
  @Select(TokenSmsState.getPhone)
  public phone$: Observable<string>;

  constructor() {}

  @Dispatch()
  public setPhoneSms(phone: string) {
    return new SetPhoneSms(removeSpecialCharacters(phone));
  }
}
