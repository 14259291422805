import { IAcnDynamicComponentManifest } from '@acn/angular';

export const reconnectionDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'reconnection.modal',
    loadChildren: () =>
      import('./reconnection/components/reconnection-modal/reconnection-modal.module').then(
        (m) => m.ReconnectionModalModule,
      ),
  },
  {
    path: 'photo-tips.modal',
    loadChildren: () =>
      import('./auto-reconnection/components/modal/photo-tips.module').then(
        (m) => m.PhotoTipsModule,
      ),
  },
];
