<div class="billing-email-card">
  <div class="side-color"></div>
  <div class="card-content">
    <div class="text-section">
      <acn-form-check-box [attr.small]="!onMobile ? true : null" #checkbox [value]="true">
        <div class="checkbox-text">
          Desejo receber a minha conta no e-mail cadastrado acima
        </div>
        <div class="checkbox-help-text">O formato impresso não será mais entregue no seu endereço.</div>
      </acn-form-check-box>
      <div class="email-card-infos">
        <div [innerHTML]="sanetizeHTML(description)"></div>
      </div>
    </div>
    <div class="image-section">
      <img [src]="getUrlImage()" alt="Imagem lateral do card de menina mexendo no computador" />
    </div>
  </div>
</div>
