import { bankSharedValidation } from '@acn/angular/shared/validators/bank-shared-validation';

export function validateAgency(value: string) {
  if (!value) {
    return { code: 1, message: 'value is empty' };
  }

  const expReg: RegExp = /([0-9]|[a-zA-Z]){1,6}-?([0-9]|[a-zA-Z]){0,6}/;
  if (expReg.test(value)) {
    return bankSharedValidation(value);
  } else {
    return { code: 1, message: 'format invalid' };
  }
}
