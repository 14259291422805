<acn-card class="card">
  <div
    class="header"
    (click)="toggle()"
    [class.header--mobile]="isOnMobile"
    [ngStyle]="{ 'min-height': minHeight ? minHeight : null }"
  >
    <div class="header__image" *ngIf="hasImage">
      <ng-content select="[accordion-image]"></ng-content>
    </div>
    <div class="header__text">
      <ng-content select="[accordion-text]"></ng-content>
    </div>
    <acn-icon
      *ngIf="isV2 || !isOnMobile"
      [icon]="opened ? 'fa-angle-up' : 'fa-angle-down'"
      class="icon"
    ></acn-icon>
  </div>
  <hr class="divider" *ngIf="isV2 && opened" />
  <div
    class="content"
    [class.content--image]="!hasImage"
    [class.content--mobile]="isOnMobile"
    [class.active]="opened || (!isV2 && isOnMobile)"
  >
    <ng-content></ng-content>
  </div>
</acn-card>
