import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AcnAuthenticationService } from '../authentication/acn-authentication.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class AcnAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AcnAuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAccessToken();

    // console.log('req ', req);

    if (token && !req.url.includes('blob')) {
      req = this._addToken(req, token);
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const headerToken = event.headers.get('Authorization');

          if (headerToken) {
            this.authService.doLoginUser(headerToken, this.authService.getRefreshToken());
          }
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  private _addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
