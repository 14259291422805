import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceSimulatorService } from '../../features/invoice-simulator-service/service/invoice-simulator.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectServiceGuard implements CanActivate {
  constructor(private _serviceInvoiceSimulator: InvoiceSimulatorService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    stateRoute: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const baseUrl = this._serviceInvoiceSimulator.getOnDashboard() ? 'home-logado' : 'home';
    const urlRedirect = `/${baseUrl}/servicos`;
    this.router.navigate([urlRedirect]);
    return true;
  }
}
