import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  SetBills,
  SetDebits,
  Reset,
  ResetBilling,
  ShowDebits,
  PayAll,
  SetBillsLoaded,
  SetDebitsLoaded,
  Reconnection,
  SetLoadedReconnection,
} from './billing.actions';
import { IBill, IDebits, IReconnect } from './models';

export interface BillingStateModel {
  totalDebitos: number;
  debitos: IDebits;
  contasPagas: Array<IBill>;
  loadedDebitos: boolean;
  loadedContasPagas: boolean;
  showDebits: boolean;
  payAll: boolean;
  reconnection: IReconnect;
  loadedReconnection: boolean;
}

const INITIAL_STATE: BillingStateModel = {
  totalDebitos: 0,
  debitos: null,
  loadedDebitos: false,
  contasPagas: [],
  loadedContasPagas: false,
  showDebits: false,
  payAll: false,
  reconnection: null,
  loadedReconnection: false,
};

@State<BillingStateModel>({
  name: 'billing',
  defaults: INITIAL_STATE,
})
@Injectable()
export class BillingState {
  @Selector()
  public static getState(state: BillingStateModel) {
    return state;
  }

  @Selector()
  public static getShowDebits(state: BillingStateModel) {
    return state.showDebits;
  }

  @Selector()
  public static getLoadedReconnection(state: BillingStateModel) {
    return state.loadedReconnection;
  }

  @Selector()
  public static getPayAll(state: BillingStateModel) {
    return state.payAll;
  }

  @Selector()
  public static getReconnection(state: BillingStateModel) {
    return state.reconnection;
  }

  @Selector()
  public static getDebitStatus(state: BillingStateModel) {
    return state.loadedDebitos;
  }

  @Selector()
  public static getBillStatus(state: BillingStateModel) {
    return state.loadedContasPagas;
  }

  @Selector()
  public static getAllLoadedStatus(state: BillingStateModel) {
    return state.loadedContasPagas && state.loadedDebitos;
  }

  @Selector()
  public static getAllDebits(state: BillingStateModel) {
    return { debitos: state.debitos, totalDebitos: state.totalDebitos };
  }

  @Selector()
  public static getAllBills(state: BillingStateModel) {
    return { debitos: state.debitos, contasPagas: state.contasPagas };
  }

  @Action([Reset, ResetBilling])
  public reset(ctx: StateContext<BillingStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }

  @Action(SetDebits)
  public setDebits(ctx: StateContext<BillingStateModel>, { payload }: SetDebits) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      totalDebitos: payload.totalDebitos,
      debitos: payload.debitos,
      loadedDebitos: true,
    });
  }

  @Action(ShowDebits)
  public showDebits(ctx: StateContext<BillingStateModel>, { payload }: ShowDebits) {
    const state = ctx.getState();
    ctx.setState({ ...state, showDebits: payload });
  }

  @Action(PayAll)
  public payAll(ctx: StateContext<BillingStateModel>, { payload }: PayAll) {
    const state = ctx.getState();
    ctx.setState({ ...state, payAll: payload });
  }

  @Action(Reconnection)
  public reconnection(ctx: StateContext<BillingStateModel>, { payload }: Reconnection) {
    const state = ctx.getState();
    ctx.setState({ ...state, reconnection: payload });
  }

  @Action(SetBillsLoaded)
  public setBillsLoaded(ctx: StateContext<BillingStateModel>, { payload }: SetBillsLoaded) {
    const state = ctx.getState();
    ctx.setState({ ...state, loadedContasPagas: payload });
  }

  @Action(SetDebitsLoaded)
  public setDebitsLoaded(ctx: StateContext<BillingStateModel>, { payload }: SetDebitsLoaded) {
    const state = ctx.getState();
    ctx.setState({ ...state, loadedDebitos: payload });
  }

  @Action(SetLoadedReconnection)
  public setLoadedReconnection(
    ctx: StateContext<BillingStateModel>,
    { payload }: SetLoadedReconnection,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, loadedReconnection: payload });
  }

  @Action(SetBills)
  public setBills(ctx: StateContext<BillingStateModel>, { payload }: SetBills) {
    const state = ctx.getState();
    ctx.setState({ ...state, contasPagas: payload.contasPagas, loadedContasPagas: true });
  }
}
