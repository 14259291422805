import { IConfiguration, ILogLevel } from '@acn/angular';
import { CHANNEL_CONFIG } from '../app/config/channel.config';

/**
 * Arquivo de configuração para utilização do ambiente de PRD
 * (Basta substituir o apontamento da chave 'api' para a url do ambiente)
 */
export const environment: IConfiguration = {
  baseUrl: '',
  baseImgUrl: '',
  baseCMSImgUrl: '',
  baseHref: '',
  production: true,
  enablePreRender: true,
  enableServerSideRender: false,
  apiConfigs: null,
  logLevel: ILogLevel.INFO,
  defaultChannel: 'portal',
  channelSettings: CHANNEL_CONFIG,
  routerLogin: '/login',
  routerDashboard: '/home-logado',
  rsaKey: '',
  captchaKey: '',
  googleKey: '',
  smartAttendanceUrl: '',
  privacyUrl: '',
  webchatUrl: '',
  webchatTheme: 'blue',
  webchatToken: '',
  webchatPosition: 'bottom-right',
  handTalkToken: '',
  handTalkUrl: '',
};
