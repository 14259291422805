export function validatePhone(tel: any) {
  const telClean = tel.replace(/[^\d]+/g, '');

  const regex = /^(\d)\1+$/;

  if (regex.test(telClean)) {
    return false;
  }

  if (!(telClean.length === 10 || telClean.length === 11)) {
    return false;
  }
  if (telClean[0] === '0' || telClean[2] === '0') {
    return false;
  }

  return !(telClean.length === 11 && telClean[2] !== '9');
}
