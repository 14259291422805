// export function validateData(value) {
//   const values = value.split('/');
//   if (values[0] > 31 || values[1] > 12 || values[2] < 1000) {
//       return false;
//   }
//   return true;
// }

/**
 * Verifica se a data possui um valor válido
 *
 * @param value
 * @returns retorna um código e uma mensagem descrevendo o erro ou sucesso caso seja válido
 */
export function validateData(value: string) {
  if (!value) {
    return { code: 1, message: 'value is empty' };
  }
  // tslint:disable-next-line:max-line-length
  const expReg: RegExp = /^((0[1-9]|[12]\d)\/(0[1-9]|1[0-2])|30\/(0[13-9]|1[0-2])|31\/(0[13578]|1[02]))\/(19|20)?\d{4}$/;

  if (value.match(expReg)) {
    const dia: number = Number(value.substring(0, 2));
    const mes: number = Number(value.substring(3, 5));
    const ano: number = Number(value.substring(6, 10));
    if (ano % 4 !== 0 && mes === 2 && dia > 28) {
      return { code: 2, message: 'the month sent have twenty-eight days' };
    }
    if (ano % 4 === 0 && mes === 2 && dia >= 29) {
      return { code: 4, message: 'the month sent have twenty-nine days' };
    }
  } else {
    return { code: 3, message: 'format invalid' };
  }
  return { code: 0, message: 'success' };
}
