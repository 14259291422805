import { IAcnDynamicComponentManifest } from '@acn/angular';

export const technicalServicesDynamicComponentsManifestDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'technical-services.email-modal',
    loadChildren: () =>
      import(
        './technical-services/components/technical-services-email-modal/technical-services-email-modal.module'
      ).then((m) => m.TechnicalServicesEmailModalModule),
  },
];
