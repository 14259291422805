import { Inject, Injectable } from '@angular/core';
import { IConfiguration } from './models/configuration.model';
import { ACN_CONFIGURATION } from './acn-configuration.token';

/**
 * Serviço para gerenciamento de configurações
 */
@Injectable({ providedIn: 'root' })
export class AcnConfigurationService {
  constructor(@Inject(ACN_CONFIGURATION) public readonly settings: IConfiguration) {}
}
