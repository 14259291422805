import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  AddCitiesList,
  AddNotSelectedCity,
  AddSelectedCity,
  AddSelectedCityServices,
  ResetCities,
  ResetSelectedCity,
  SetRedirecTo,
  BreakingNewsNotice,
  AddBreakingNewsisClosed,
  ResetServices,
  AddWaterShortageAlert,
  ResetWaterShortageAlert,
  ResetCityAndServices,
} from './cities.actions';
import { ICity, INewCity } from './models/city.model';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { IBreakingNews } from '@features/attendance/models/breaking-news.model';

export interface ICitiesStateModel {
  citiesList: Array<ICity>;
  selectedCity: INewCity;
  redirectTo: string;
  notSelectedCity: INewCity;
  selectedCityServices: ICityServicesList;
  hasCitiesList: boolean;
  breakingNewsisClosed: boolean;
  breakingNewsNotice: IBreakingNews;
  waterShortageAlert?: string;
}

const INITIAL_STATE: ICitiesStateModel = {
  citiesList: [],
  selectedCity: null,
  redirectTo: null,
  notSelectedCity: null,
  selectedCityServices: null,
  hasCitiesList: false,
  breakingNewsisClosed: false,
  breakingNewsNotice: null,
  waterShortageAlert: null,
};

@State<ICitiesStateModel>({
  name: 'cities',
  defaults: INITIAL_STATE,
})
@Injectable()
export class CitiesState {
  @Selector()
  public static getState(state: ICitiesStateModel) {
    return state;
  }

  @Selector()
  public static getSelectedCity(state: ICitiesStateModel) {
    return state.selectedCity;
  }

  @Selector()
  public static getSelectedCityServices(state: ICitiesStateModel) {
    return state.selectedCityServices;
  }

  @Selector()
  public static getNotSelectedCity(state: ICitiesStateModel) {
    return state.notSelectedCity;
  }

  @Selector()
  public static getCitiesList(state: ICitiesStateModel) {
    return state.citiesList;
  }

  @Selector()
  public static getRedirectTo(state: ICitiesStateModel) {
    return state.redirectTo;
  }

  @Selector()
  public static getHasCitiesList(state: ICitiesStateModel) {
    return state.hasCitiesList;
  }

  @Selector()
  public static getBreakingNewsIsClosed(state: ICitiesStateModel) {
    return state.breakingNewsisClosed;
  }

  @Selector()
  public static breakingNewsNotice(state: ICitiesStateModel) {
    return state.breakingNewsNotice;
  }

  @Selector()
  public static waterShortageAlert(state: ICitiesStateModel) {
    return state.waterShortageAlert;
  }

  @Action(AddCitiesList)
  public addCitiesList(ctx: StateContext<ICitiesStateModel>, { payload }: AddCitiesList) {
    const state = ctx.getState();
    ctx.setState({ ...state, citiesList: [...payload], hasCitiesList: true });
  }

  @Action(AddBreakingNewsisClosed)
  public addBreakingNews(
    ctx: StateContext<ICitiesStateModel>,
    { payload }: AddBreakingNewsisClosed,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, breakingNewsisClosed: payload });
  }

  @Action(BreakingNewsNotice)
  public BreakingNewsNotice(ctx: StateContext<ICitiesStateModel>, { payload }: BreakingNewsNotice) {
    const state = ctx.getState();
    ctx.setState({ ...state, breakingNewsNotice: payload });
  }

  @Action(AddSelectedCity)
  public addSelectedCity(ctx: StateContext<ICitiesStateModel>, { payload }: AddSelectedCity) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedCity: payload, notSelectedCity: null });
  }

  @Action(AddSelectedCityServices)
  public addSelectedCityService(
    ctx: StateContext<ICitiesStateModel>,
    { payload }: AddSelectedCityServices,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedCityServices: payload });
  }

  @Action(AddNotSelectedCity)
  public addNotSelectedCity(ctx: StateContext<ICitiesStateModel>, { payload }: AddNotSelectedCity) {
    const state = ctx.getState();
    ctx.setState({ ...state, notSelectedCity: payload, selectedCity: null });
  }

  @Action(AddWaterShortageAlert)
  public addWaterShortageAlert(
    ctx: StateContext<ICitiesStateModel>,
    { payload }: AddWaterShortageAlert,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, waterShortageAlert: payload });
  }

  @Action(SetRedirecTo)
  public setRedirecTo(ctx: StateContext<ICitiesStateModel>, { payload }: SetRedirecTo) {
    const state = ctx.getState();
    ctx.setState({ ...state, redirectTo: payload });
  }

  @Action(ResetSelectedCity)
  public resetSelectedCity(ctx: StateContext<ICitiesStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedCity: null });
  }

  @Action(ResetServices)
  public resetServices(ctx: StateContext<ICitiesStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedCityServices: null });
  }

  @Action(ResetCities)
  public reset(ctx: StateContext<ICitiesStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, citiesList: [], hasCitiesList: false });
  }

  @Action(ResetWaterShortageAlert)
  public resetWaterShortageAlert(ctx: StateContext<ICitiesStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, waterShortageAlert: null });
  }

  @Action(ResetCityAndServices)
  public resetCityAndServices(ctx: StateContext<ICitiesStateModel>) {
    ctx.patchState({ selectedCity: null, selectedCityServices: null });
  }
}
