// import { generateCPF } from './../utils/generator';

// http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
// export function validateCPF(strCPF: any) {
//     strCPF = strCPF.replace(/[^\d]+/g, '');
//     if (strCPF.length !== 11) {
//         return false;
//     }
//     const restos = generateCPF(strCPF);

//     if (restos[0] !== parseInt(strCPF.substring(9, 10), 10)) {
//         return false;
//     }

//     if (restos[1] !== parseInt(strCPF.substring(10, 11), 10)) {
//         return false;
//     }
//     return true;
// }

// tslint:disable-next-line:cyclomatic-complexity
/**
 * Verifica se o CPF possui um valor válido
 *
 * @param cpf
 * @returns retorna um código e uma mensagem descrevendo o erro ou sucesso caso seja válido
 */
export function validateCPF(cpf: string) {
  if (!cpf) {
    return { code: 1, message: 'value is empty' };
  }

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) {
    return { code: 2, message: 'incorrect number of characters' };
  }

  const cpfInvalid = { code: 3, message: 'cpf invalid' };

  if (isFamiliarCpf(cpf)) {
    return cpfInvalid;
  }

  if (isInvalidFirstDigit(cpf)) {
    return cpfInvalid;
  }

  if (isInvalidSecontDigit(cpf)) {
    return cpfInvalid;
  }
  return { code: 0, message: 'success' };
}

/**
 * Verifica se o CPF é familiar
 *
 * @param cpf
 * @returns retorna um valor booleano caso seja verdadeiro ou falso
 */
function isFamiliarCpf(cpf: string): boolean {
  const regex = /^(\d)\1+$/;
  return !!cpf.match(regex);
}

/**
 * Verifica se o primeiro dígito é inválido
 *
 * @param cpf
 * @returns retorna um valor booleano caso seja verdadeiro ou falso
 */
function isInvalidFirstDigit(cpf: string): boolean {
  return validateDigitPosition(cpf, 9);
}

/**
 * Verifica se o segundo dígito é inválido
 *
 * @param cpf
 * @returns retorna um valor booleano caso seja verdadeiro ou falso
 */
function isInvalidSecontDigit(cpf: string): boolean {
  return validateDigitPosition(cpf, 10);
}

/**
 * Verifica se o dígito tem uma posição válida
 *
 * @param cpf
 * @param position
 * @returns retorna um valor booleano caso seja verdadeiro ou falso
 */
function validateDigitPosition(cpf: string, position: number): boolean {
  let add = 0;
  let rev: number;
  for (let i = 0; i < position; i++) {
    add += parseInt(cpf.charAt(i), 10) * (position + 1 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  return rev !== parseInt(cpf.charAt(position), 10);
}
