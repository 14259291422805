import { State, Action, Selector, StateContext } from '@ngxs/store';
import { ShowModal, HideModal } from './modal.actions';
import { Injectable } from '@angular/core';

import { IModal } from './models/modal.model';

@State<IModal>({
  name: 'modal',
  defaults: {
    componentId: null,
    params: null,
    canClose: false,
  },
})
@Injectable()
export class ModalState {
  @Selector()
  public static getState(state: IModal) {
    return state;
  }

  @Selector()
  public static getComponentId(state: IModal) {
    return state.componentId;
  }

  @Selector()
  public static getParams(state: IModal) {
    return state.params;
  }

  @Action(ShowModal)
  public showModal(ctx: StateContext<IModal>, { payload }: ShowModal) {
    const modal: IModal = {
      componentId: null,
      canClose: false,
      params: {},
      ...payload,
    };
    ctx.patchState(modal);
  }

  @Action(HideModal)
  public hideModal(ctx: StateContext<IModal>) {
    ctx.patchState({ componentId: null, params: {}, canClose: false });
  }
}
