import { BaseComponent } from "@acn/angular";
import { Component, Input } from "@angular/core";
import { ICity } from "@store/dashboard";

@Component({
    selector: 'app-landing-custom-campaing',
    templateUrl: './landing-custom-campaing.component.html',
    styleUrls: ['./landing-custom-campaing.component.scss'],
  })
export class LandingCustomCampaingComponent extends BaseComponent{
    @Input() public redirectUrl?: string;
    @Input() public imageDesktopURL?: string;
    @Input() public backgroundMobileURL?: string;
    @Input() public imageMobileURL?: string;
    @Input() public onMobile: boolean = false;
    @Input() public selectedCity: ICity;
}