import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { ISession } from './../../models/index';

/**
 * Serviço responsável pelo tratamento de Login
 */
@Injectable({
  providedIn: 'root',
})
export class AcnSessionService {
  /** @ignore */
  private $session = new BehaviorSubject<ISession | undefined>(undefined);

  /**
   * Construtor
   */
  constructor() {}

  /**
   * Define um objeto de sessao
   */
  public setSession(session: ISession): void {
    this.$session.next(session);
  }

  /**
   * @returns Retorna um Observable de $session
   */
  public getSession(): Observable<ISession> {
    return this.$session.asObservable();
  }

  /**
   * Limpa a sessao
   */
  public clear(): void {
    this.$session.next(undefined);
  }
}
