<div
  class="city"
  *ngIf="isOnMobile"
  routerLink="/selecao-de-cidade"
  acnGtm
  [gtmCategory]="gtmCategory"
  gtmAction="Escolher_Outra_Cidade"
>
  <p class="is-size-14">{{ 'landing.home.hero.yourCity' | transloco }}</p>
  <p class="city__name is-bold is-size-20">
    <acn-icon icon="fa-map-marker-alt"></acn-icon> {{ selectedCity?.nome }} - {{ selectedCity?.uf }}
  </p>
</div>

<div class="text" [ngClass]="{ 'is-medium': !isOnMobile }">
  <p class="text-header" [innerHTML]="selectedCity?.home?.title"></p>
  <p
    class="text-line"
    [ngClass]="{ 'is-medium': !isOnMobile }"
    [innerHTML]="selectedCity?.home?.subtitle"
  ></p>
</div>

<a
  [href]="selectedCity?.partner?.url"
  target="_blank"
  rel="noopener"
  class="partner__link"
  acnGtm
  [gtmCategory]="gtmCategory"
  [gtmAction]="'Site_' + selectedCity?.partner?.company"
>
  <acn-button
    [label]="selectedCity?.partner?.buttonLabel"
    class="button operation is-white is-fullWidth"
  ></acn-button>
</a>
<div
  *ngIf="isOnMobile"
  class="info"
  [innerHTML]="'landing.home.hero.moreInfoMobile' | transloco"
></div>
<div
  *ngIf="!isOnMobile"
  class="info is-medium"
  [innerHTML]="'landing.home.hero.moreInfo' | transloco"
></div>
<div class="image" *ngIf="!isOnMobile">
  <img
    [src]="
      isOnMobile
        ? baseImgUrl + '/img/home/landing-banner-mobile.png'
        : baseImgUrl + this.selectedCity?.home?.image
    "
    [alt]="selectedCity?.home?.title"
  />
</div>
<div class="buttons">
  <acn-button
    routerLink="/home/atendimento"
    label="{{ 'landing.home.hero.talkBrk' | transloco }}"
    class="button is-secondary--white is-fullWidth"
    acnGtm
    [gtmCategory]="gtmCategory"
    gtmAction="Falar_Com_BRK"
  ></acn-button>
  <acn-button
    label="{{ 'landing.home.hero.chooseCity' | transloco }}"
    class="button is-secondary--white is-fullWidth"
    routerLink="/selecao-de-cidade"
    acnGtm
    [gtmCategory]="gtmCategory"
    gtmAction="Escolher_Outra_Cidade"
  ></acn-button>
</div>

<div class="split" *ngIf="isOnMobile">
  <div class="split__circle">
    <acn-icon class="icon" icon="fa-arrow-circle-down" *ngIf="isOnMobile"></acn-icon>
  </div>
</div>
