import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { AcnGuidService } from '@acn/angular';
import { IAcnNotification, IAcnNotificationPosition } from '@acn/ui';

import { NotificationState } from './notification.state';
import { RemoveNotification, AddNotification } from './notification.actions';
import { SettingsStoreService } from '../settings/settings-store.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  @Select(NotificationState)
  public notification$: Observable<IAcnNotification[]>;

  constructor(private _guid: AcnGuidService, private _settingsStore: SettingsStoreService) {}

  @Dispatch()
  public add(notification: IAcnNotification) {
    const onMobile = this._settingsStore.getOnMobile();
    const baseTimeout = 10000;
    const defaultLength = 1;
    const additionalTimeout =
      Math.floor((notification.content?.length || defaultLength) / 120) * 1000;

    const _notification: IAcnNotification = {
      id: this._guid.createGuid(),
      ...notification,
      timeOut: baseTimeout + additionalTimeout,
      position: onMobile ? IAcnNotificationPosition.topFull : IAcnNotificationPosition.bottomRight,
    };

    return new AddNotification(_notification);
  }

  @Dispatch()
  public update(notification: IAcnNotification) {
    return new AddNotification(notification);
  }

  @Dispatch()
  public remove(id: string) {
    return new RemoveNotification(id);
  }
}
