import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@acn/angular';

import { IConsumptionStatus } from '@store/dashboard';
import { ConsumptionService } from '@features/consumption/services/consumption.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-consumption-status',
  templateUrl: './consumption-status.component.html',
  styleUrls: ['./consumption-status.component.scss'],
})
export class ConsumptionStatusComponent extends BaseComponent implements OnInit {
  @Input() content: IConsumptionStatus;

  public showCard = false;
  public loading = true;
  public gtmCategory = 'Botao';
  public displayPercentage = 0;

  constructor(private _consumptionService: ConsumptionService) {
    super();
  }

  ngOnInit() {
    this._consumptionService.consumptionVariation$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.loading = false;

        this.showCard = data && !!data.hasConsumptionHistory;
        if (!data || !data.hasConsumptionHistory) {
          return;
        }

        this.content = data;
        const { porcentagem } = this.content && this.content.consumptionVariation;
        this.displayPercentage = Math.abs(porcentagem);
      });
  }
}
