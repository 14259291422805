<app-landing-menu
  class="menu"
  [isOnMobile]="onMobile"
  [selectedCity]="selectedCity"
></app-landing-menu>
<section
  *ngIf="!isActiveCampanha; else elseBlock"
  class="header"
  [style.backgroundImage]="backgroundImageUrl"
  [style.backgroundPosition]="backgroundPosition"
>
  <div class="header__content">
    <ng-container *ngIf="(selectedCity && selectedCity.isAttended) || !selectedCity">
      <app-landing-banner
        class="container"
        [isOnMobile]="onMobile"
        [selectedCity]="selectedCityInformation"
        [urlBannerMobile]="baseImgUrl + '/img/home/landing-banner-mobile.png'"
      ></app-landing-banner>
    </ng-container>
    <ng-container *ngIf="selectedCity && !selectedCity.isAttended">
      <app-landing-banner-not-comercial
        class="container"
        [isOnMobile]="onMobile"
        [selectedCity]="selectedCityInformation"
      >
      </app-landing-banner-not-comercial>
    </ng-container>
  </div>
</section>
<ng-template #elseBlock>
  <app-landing-custom-campaing
    [onMobile]="onMobile"
    [redirectUrl]="redirectUrl"
    [imageDesktopURL]="imageDesktopURL"
    [backgroundMobileURL]="backgroundMobileURL"
    [imageMobileURL]="imageMobileURL"
    [selectedCity]="selectedCityInformation" 
  ></app-landing-custom-campaing>
</ng-template>
<section class="content">
  <ng-container *ngIf="selectedCityInformation?.home?.sanitationComponent">
    <section class="sanitation">
      <app-landing-sanitation
        [isOnMobile]="onMobile"
        [selectedCityInformation]="selectedCityInformation"
      ></app-landing-sanitation>
    </section>
  </ng-container>
  <section class="services">
    <app-landing-services
      [isOnMobile]="onMobile"
      [selectedCity]="selectedCity"
      *ngIf="(selectedCity && selectedCity.isAttended) || !selectedCity"
    ></app-landing-services>
  </section>

  <section class="tips">
    <app-landing-tips [isOnMobile]="onMobile" [selectedCity]="selectedCity"></app-landing-tips>
  </section>

  <section class="campaigns">
    <app-landing-campaigns></app-landing-campaigns>
  </section>

  <div class="card__news container">
    <acn-card class="news" *ngIf="selectedCity && selectedCity.isAttended">
      <p class="is-bold is-size-20 news__title">{{ 'landing.home.news.title' | transloco }}</p>
      <a [href]="selectedCity.newsUrl" target="_blank" rel="noopener" class="button__link">
        <acn-button class="is-fullWidth button">
          {{ 'landing.home.news.goToNews' | transloco }}
        </acn-button>
      </a>
    </acn-card>
  </div>
</section>
<footer class="footer">
  <app-footer [isOnMobile]="onMobile" [selectedCity]="selectedCity"></app-footer>
</footer>
