import { IAcnDynamicComponentManifest } from '@acn/angular';

export const invoiceSimulatorDynamicComponentsManifestDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'invoice-simulator.modal',
    loadChildren: () =>
      import(
        './invoice-simulator-service/components/invoice-simulator-modal/invoice-simulator-modal.module'
      ).then((m) => m.InvoiceSimulatorModalModule),
  },
];
