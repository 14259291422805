export class Reset {
  public static readonly type = '[RESET]';
}

export class SetRedirectedFrom {
  public static readonly type = '[REDIRECTION] Set origin url';
  constructor(public payload: string) {}
}
export class SetRedirectedTo {
  public static readonly type = '[REDIRECTION] Set redirection';
  constructor(public payload: string) {}
}
