import {
  Directive,
  ElementRef,
  HostListener,
  Self,
  Optional,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NgControl, AbstractControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { formControlError } from '../utils/form-control-error';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[acnFormField]',
})
export class FormDirective implements ControlValueAccessor, OnInit, OnDestroy {
  private innerValue: any;
  disabled = false;

  public formControl: AbstractControl;
  private onDestroy = new Subject();

  @HostListener('valueChanges', ['$event.detail'])
  listenForValueChange(value: any) {
    if (this.value !== value) {
      this.value = value;
    }
  }

  constructor(
    @Self()
    @Optional()
    private _ngControl: NgControl,
    private elementRef: ElementRef,
  ) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this._ngControl) {
      this.formControl = this._ngControl.control;
      this._checkIfWasTouched();
    }
  }

  private _checkIfWasTouched() {
    this.formControl.markAsTouched = () => {
      this._getErrorMessage();
    };

    this.formControl.statusChanges
      .pipe(
        takeUntil(this.onDestroy),
        filter((status) => status === 'INVALID'),
      )
      .subscribe(() => {
        this._getErrorMessage();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _getErrorMessage() {
    if (!this.formControl) {
      return;
    }

    this.elementRef.nativeElement.errorMsg = formControlError(
      this.formControl,
      this.elementRef.nativeElement.label,
    );
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this._onChangeCb(v);
      this._onTouchedCb(v);
      this.elementRef.nativeElement.setValue?.(v)?.catch?.();
    }
  }

  private _onChangeCb: (_: any) => void = () => {};
  private _onTouchedCb: (_: any) => void = () => {};

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this._onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.elementRef.nativeElement.disabled = isDisabled;
  }
}
