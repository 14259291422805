import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { StepsState } from './steps.state';
import { SetCurrentStep, SetSteps, ResetSteps } from './steps.actions';

@Injectable({
  providedIn: 'root',
})
export class StepStoreService {
  @Select(StepsState.getSteps)
  public steps$: Observable<Array<string>>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setCurrentStep(step: number) {
    return new SetCurrentStep(step);
  }

  @Dispatch()
  public setSteps(steps: string[]) {
    return new SetSteps(steps);
  }

  @Dispatch()
  public resetSteps() {
    return new ResetSteps();
  }

  hasSteps() {
    return this._store.selectSnapshot(StepsState.getSteps).length > 0;
  }

  navigateToStep(step: string) {
    const steps = this._store.selectSnapshot(StepsState.getSteps);
    this.setCurrentStep(steps.indexOf(step));
  }

  nextStep() {
    const currentStep = this._store.selectSnapshot(StepsState.getCurrentStep);
    const steps = this._store.selectSnapshot(StepsState.getSteps);

    if (currentStep + 1 < steps.length) {
      this.setCurrentStep(currentStep + 1);
      return steps[currentStep + 1];
    }

    return null;
  }

  prevStep() {
    const currentStep = this._store.selectSnapshot(StepsState.getCurrentStep);
    const steps = this._store.selectSnapshot(StepsState.getSteps);

    if (currentStep - 1 >= 0) {
      this.setCurrentStep(currentStep - 1);
      return steps[currentStep - 1];
    }

    return null;
  }
}
