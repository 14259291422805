export class SetSteps {
  public static readonly type = '[Steps] set steps';
  constructor(public payload: string[]) {}
}

export class SetCurrentStep {
  public static readonly type = '[Steps] set current step';
  constructor(public payload: number) {}
}

export class ResetSteps {
  public static readonly type = '[Steps] reset steps';
}

export class Reset {
  public static readonly type = '[RESET]';
}
