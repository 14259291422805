import { IAcnDynamicComponentManifest } from '@acn/angular';

export const duplicateDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'duplicate.barcode.modal',
    loadChildren: () =>
      import('./duplicate/components/duplicate-bar-modal/duplicate-bar-modal.module').then(
        (m) => m.DuplicateBarModalModule,
      ),
  },
  {
    path: 'duplicate.email.confirmation',
    loadChildren: () =>
      import('./duplicate/components/duplicate-modal-email/duplicate-modal-email.module').then(
        (m) => m.DuplicateModalEmailModule,
      ),
  },
  {
    path: 'duplicate.send.email.modal',
    loadChildren: () =>
      import(
        './duplicate/components/duplicate-send-email-modal/duplicate-send-email-modal.module'
      ).then((m) => m.DuplicateSendEmailModalModule),
  },
];
