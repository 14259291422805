import { IRequestCutPersonalData, IRequestCutStateModel } from './models';

import { TextualAnswers } from '@features/request-cut';
import { IRequestCutServiceOrder } from '@features/request-cut/services/models/request-cut-situation.model';
import {
  ICutTypeList,
  ICutTypes,
} from '@features/request-cut/services/models/request-cut-schedule.model';

export class SetRequestCut {
  public static readonly type = '[RequestCut] Set request cut';
  constructor(public payload: IRequestCutStateModel) {}
}

export class SetHydrometerAvailability {
  public static readonly type = '[RequestCut] Set hydrometer availability';
  constructor(public payload: TextualAnswers) {}
}

export class SetUrlBack {
  public static readonly type = '[RequestCut] Set url back';
  constructor(public payload: string) {}
}

export class SetCutServiceOrder {
  public static readonly type = '[RequestCut] Set service order';
  constructor(public payload: IRequestCutServiceOrder) {}
}

export class SetCutData {
  public static readonly type = '[RequestCut] Set cut data';
  constructor(public payload: ICutTypes) {}
}

export class SetPersonalData {
  public static readonly type = '[RequestCut] Set personal data';
  constructor(public payload: IRequestCutPersonalData) {}
}

export class SetSelectedCutType {
  public static readonly type = '[RequestCut] Set selected cut type';
  constructor(public payload: ICutTypeList) {}
}

export class ResetRequestCut {
  public static readonly type = '[RequestCut] Reset request cut';
}

export class SetProtocolCode {
  public static readonly type = '[RequestCut] Set protocolCode';
  constructor(public payload: string) {}
}
