import { IAcnDynamicComponentManifest } from '@acn/angular';

export const sharedDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'shared.upload-format-invalid',
    loadChildren: () =>
      import('./../components/modal-error-in-upload/modal-error-in-upload.module').then(
        (m) => m.ModalErrorInUploadModule,
      ),
  },
];
