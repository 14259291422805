<div class="panel">
  <p class="is-size-20 is-medium">
    {{ 'account.sms.typeCode' | transloco }}
  </p>
  <form [formGroup]="form" *ngIf="form" class="form">
    <div class="form__inputs" formGroupName="inputs">
      <acn-form-input
        type="tel"
        placeholder="0"
        class="large center code"
        [class.error]="hasError"
        formControlName="code1"
        hide-error
        acnFormField
        maxlength="1"
        #code
        id="code1"
        (inputFocus)="setFocus($event.detail)"
      ></acn-form-input>
      <acn-form-input
        type="tel"
        placeholder="0"
        class="large center code"
        [class.error]="hasError"
        formControlName="code2"
        hide-error
        acnFormField
        maxlength="1"
        #code
        (inputFocus)="setFocus($event.detail)"
      ></acn-form-input>
      <acn-form-input
        type="tel"
        placeholder="0"
        class="large center code"
        [class.error]="hasError"
        formControlName="code3"
        hide-error
        acnFormField
        maxlength="1"
        #code
        (inputFocus)="setFocus($event.detail)"
      ></acn-form-input>
      <acn-form-input
        type="tel"
        placeholder="0"
        class="large center code"
        [class.error]="hasError"
        formControlName="code4"
        hide-error
        acnFormField
        maxlength="1"
        #code
        (inputFocus)="setFocus($event.detail)"
      ></acn-form-input>
      <acn-form-input
        type="tel"
        placeholder="0"
        class="large center code"
        [class.error]="hasError"
        formControlName="code5"
        hide-error
        acnFormField
        maxlength="1"
        #code
        (inputFocus)="setFocus($event.detail)"
      ></acn-form-input>
    </div>
    <acn-form-error acnFormField formControlName="all" [class.show]="hasError"></acn-form-error>
  </form>
  <p class="is-light is-size-12 resend">
    <ng-container *ngIf="!showTimer">
      {{ 'account.sms.noreceive' | transloco }}
      &nbsp;
      <a
        class="resend__link"
        (click)="resendCode()"
        acnGtm
        [gtmCategory]="gtmCategory"
        gtmAction="Reenviar_codigo"
        >{{ 'account.sms.resend' | transloco }}</a
      >
    </ng-container>

    <ng-container *ngIf="showTimer">
      {{ 'account.sms.resend' | transloco }} {{ timer }}
      {{ 'account.sms.seconds' | transloco }}
    </ng-container>
  </p>
  <acn-button
    [label]="'next' | transloco"
    (click)="checkSubmit()"
    width="310px"
    data-cy="input-next"
    [attr.disabled]="!form.valid ? 'disabled' : null"
    acnGtm
    [gtmCategory]="gtmCategory"
    gtmAction="Salvar"
  ></acn-button>
</div>
