import {
  ICityServicesList,
  IServiceList,
  IServices,
} from '@features/services/models/city-services-list.model';
import { servicesCategory, servicesList } from '../../enumerators';
import { ActiveServiceCategory } from '@shared/services/active-service/active-service-category';

export class ActiveService {
  static create(cityServices: ICityServicesList | null | undefined): ActiveService {
    return new ActiveService(cityServices);
  }

  private constructor(private readonly cityServices: ICityServicesList | null | undefined) {}

  private _getListaServicos(): IServiceList[] {
    return this.cityServices?.listaServicos ?? [];
  }

  getCategory(idCategory: servicesCategory): ActiveServiceCategory {
    const category =
      this._getListaServicos().find((_category) => _category.nomeCategoria === idCategory) ?? null;
    return ActiveServiceCategory.create(category);
  }

  getService(idCategory: servicesCategory, idService: servicesList): IServices | null {
    return this.getCategory(idCategory).getService(idService);
  }

  getFunctionality(
    idCategory: servicesCategory,
    idService: servicesList,
    idFunctionality: servicesList,
  ): IServices | null {
    return this.getCategory(idCategory).getFunctionality(idService, idFunctionality);
  }

  isServiceActive(idCategory: servicesCategory, idService: servicesList): boolean {
    return this.getCategory(idCategory).isServiceActive(idService);
  }

  isFunctionalityActive(
    idCategory: servicesCategory,
    idService: servicesList,
    idFunctionality: servicesList,
  ): boolean {
    return this.getCategory(idCategory).isFunctionalityActive(idService, idFunctionality);
  }

  isMultipleFunctionalityActive<T extends servicesList>(
    idCategory: servicesCategory,
    idService: servicesList,
    idFunctionalities: T[],
  ): ReadonlyMap<T, boolean> {
    return this.getCategory(idCategory).isMultipleFunctionalityActive(idService, idFunctionalities);
  }

  isMultipleServiceActive<T extends servicesList>(
    idCategory: servicesCategory,
    idServices: T[],
  ): ReadonlyMap<T, boolean> {
    return this.getCategory(idCategory).isMultipleServiceActive(idServices);
  }
}
