import { RedirectionServices } from './../models/redirection-services.model';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { CitiesStoreService } from '@store/dashboard';
import { RedirectionStoreService } from '@store/app';
import { ActiveService } from '@shared/services/active-service/active-service';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UnselectedCityServiceGuard implements CanActivate {
  constructor(
    private _citiesStoreService: CitiesStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _router: Router,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this._isServer = isPlatformServer(platformId);
  }

  private readonly _isServer: boolean;

  static createRedirectTo(params: Array<RedirectionServices>): Array<RedirectionServices> {
    return params;
  }

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    return this._isServer || this._selectedService(next);
  }

  private _selectedService(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const selectedCity = !!this._citiesStoreService.getSelectedCity();
    if (selectedCity) {
      if (next.data.redirection) {
        const defaultRedirection = next.data.defaultRedirection ?? '/home/servicos';
        const selectedCityServices = this._citiesStoreService.getSelectedCityServices();
        const activeService = ActiveService.create(selectedCityServices);
        const redirectionServices: Array<RedirectionServices> = next.data.redirection;
        const redirection = [...redirectionServices]
          // reverse é necessário para manter o comportamento antigo
          .reverse()
          .find(({ serviceType, serviceId }) =>
            activeService.isServiceActive(serviceType, serviceId),
          );
        const redirectionPath = redirection?.redirectTo ?? defaultRedirection;
        return this._router.parseUrl(redirectionPath);
      }
      if (next.data.redirectTo) {
        return this._router.parseUrl(next.data.redirectTo);
      }
    }
    return !selectedCity;
  }
}
