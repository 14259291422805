import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, NgxsOnInit } from '@ngxs/store';

import {
  SetCurrentInvoice,
  SetCurrentGuide,
  Reset,
  ResetDuplicateStore,
  SetHeaderBgSmall,
} from './duplicate.actions';
import { IInvoiceDetail, IGuideDetail } from '@features/duplicate/services/models';

export interface DuplicateStateModel {
  currentInvoice: IInvoiceDetail;
  currentGuide: IGuideDetail;
  headerBgSmall: boolean;
}

const INITIAL_STATE: DuplicateStateModel = {
  currentInvoice: null,
  currentGuide: null,
  headerBgSmall: false,
};

@State<DuplicateStateModel>({
  name: 'duplicate',
  defaults: INITIAL_STATE,
})
@Injectable()
export class DuplicateState implements NgxsOnInit {
  @Selector()
  public static getState(state: DuplicateStateModel) {
    return state;
  }

  @Selector()
  public static getCurrentInvoice(state: DuplicateStateModel) {
    return state.currentInvoice;
  }

  @Selector()
  public static getCurrentGuide(state: DuplicateStateModel) {
    return state.currentGuide;
  }

  @Selector()
  public static getHeaderBgSmall(state: DuplicateStateModel) {
    return state.headerBgSmall;
  }

  @Action(SetCurrentInvoice)
  public setCurrentInvoice(ctx: StateContext<DuplicateStateModel>, { payload }: SetCurrentInvoice) {
    const state = ctx.getState();
    ctx.setState({ ...state, currentInvoice: payload, currentGuide: null });
  }

  @Action(SetCurrentGuide)
  public setCurrentGuide(ctx: StateContext<DuplicateStateModel>, { payload }: SetCurrentGuide) {
    const state = ctx.getState();
    ctx.setState({ ...state, currentGuide: payload, currentInvoice: null });
  }

  @Action([Reset, ResetDuplicateStore])
  public reset(ctx: StateContext<DuplicateStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }

  @Action([SetHeaderBgSmall])
  public setHeaderBgSmall(ctx: StateContext<DuplicateStateModel>, { payload }: SetHeaderBgSmall) {
    ctx.patchState({ headerBgSmall: payload });
  }

  ngxsOnInit(ctx: StateContext<DuplicateStateModel>): void {
    // O headerBgSmall precisa sempre iniciar como false.
    // Como estamos salvando o state no localStorage, pode acontecer dele estar como true, se a
    // pessoa acessar a tela de pagamento parcelado, fechar o navegador, e entrar novamente.
    // Esse bug seria quase imperceptivel para o usuário, mas melhor corrigir mesmo assim :D
    ctx.dispatch(new SetHeaderBgSmall(false));
  }
}
