import { InvoiceSimulatorFormData, ISimulator } from './models';
import { InvoiceApiSimulatorResponse } from '@store/dashboard/states/invoice-simulator/models/invoice-api-simulator-response';

export class InvoiceSimulatorAction {
  public static readonly type = '[InvoiceSimulator] Add item';
  constructor(public payload: string) {}
}

export class SetSimulator {
  public static readonly type = '[InvoiceSimulator] Set Simulator data';
  constructor(public payload: ISimulator) {}
}

export class ResetSimulator {
  public static readonly type = '[InvoiceSimulator] Reset InvoiceSimulator';
}

export class SetFormData {
  public static readonly type = '[InvoiceSimulator] Set Form Data';

  constructor(public payload: InvoiceSimulatorFormData) {}
}

export class SetApiData {
  public static readonly type = '[InvoiceSimulator] Set Api Data';
  constructor(public payload: InvoiceApiSimulatorResponse) {}
}

export class SetUrlBack {
  public static readonly type = '[InvoiceSimulator] Set Url Back';
  constructor(public payload: string) {}
}
