import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingComponent } from './components/landing';
import { LandingHomeComponent } from '@features/landing/components';
import { titleKey } from '@shared/services/title/title-key';
import { MetaTags } from '@shared/services/meta/meta-tags';
import { BrkPreloading } from '@shared/services/brk-preloading-strategy/brk-preloading';
import { BrkPreloadingPriorityEnum } from '@shared/services/brk-preloading-strategy/brk-preloading-priority.enum';
import { SelectedCityGuard } from '@shared/guards/selected-city.guard';
import { BackButtonGuard } from '../../shared/guards/back-button.guard';
import { RedirectServiceGuard } from '../../shared/guards/redirect-service.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: LandingHomeComponent,
        canActivate: [BackButtonGuard],
        data: {
          [titleKey]: 'Página Inicial - Minha BRK',
          [MetaTags.key]: MetaTags.create({
            description:
              'Página Inicial Minha BRK. Atendimento, serviços, consulte sua conta, solicite suporte, atualize cadastro.',
            robots: true,
          }),
        },
      },
      {
        path: 'atendimento',
        loadChildren: () =>
          import('../../features/attendance/attendance.module').then((m) => m.AttendanceModule),
        canActivate: [SelectedCityGuard],
        data: SelectedCityGuard.createRedirect('/home/info/canais/atendimento'),
      },
      {
        path: 'info/canais/atendimento',
        loadChildren: () =>
          import('../../features/support-channels/support-channels.module').then(
            (m) => m.SupportChannelsModule,
          ),
      },
      {
        path: 'servicos',
        loadChildren: () =>
          import('../../features/services/services.module').then((m) => m.ServicesModule),
        canActivate: [BackButtonGuard],
        data: {
          [BrkPreloading.key]: BrkPreloading.create({ priority: BrkPreloadingPriorityEnum.medium }),
        },
      },
      {
        path: 'redirect-servicos',
        component: LandingComponent,
        canActivate: [RedirectServiceGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
