import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { I18nModule } from '@acn/angular';
import { SharedModule } from '@shared/shared.module';
import { LandingRoutingModule } from '@features/landing/landing-routing.module';
import { LandingComponent } from '@features/landing/components/landing';
import {
  LandingBannerComponent,
  LandingBannerNotComercialComponent,
  LandingCampaignsComponent,
  LandingServicesComponent,
  LandingTipsComponent,
  LandingHomeComponent,
  LandingCustomCampaingComponent,
} from '@features/landing/components';
import { LandingSanitationComponent } from './components/landing-sanitation/landing-sanitation.component';
import { SupportChannelsModule } from '@features/support-channels/support-channels.module';

@NgModule({
  declarations: [
    LandingComponent,
    LandingBannerComponent,
    LandingBannerNotComercialComponent,
    LandingCampaignsComponent,
    LandingServicesComponent,
    LandingTipsComponent,
    LandingHomeComponent,
    LandingSanitationComponent,
    LandingCustomCampaingComponent
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    HttpClientModule,
    I18nModule.forChild('landing'),
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SupportChannelsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingModule {}
