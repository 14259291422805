import { AcnConfigurationService, BaseComponent } from '@acn/angular';
import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ServiceId } from '@shared/enumerators/service-id.enum';
import { MessageService } from '@shared/services/messages/messages.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'billing-for-email-card',
  templateUrl: './billing-for-email-card.component.html',
  styleUrls: ['./billing-for-email-card.component.scss'],
})
export class BillingForEmailCard extends BaseComponent implements OnInit, AfterViewInit {
  @Input() receiveEmail: boolean;
  @Input() onMobile: boolean;
  @ViewChild('checkbox') checkbox: ElementRef<HTMLAcnFormCheckBoxElement>;

  description: string;

  constructor (
    private _settings: AcnConfigurationService,
    private _messageService: MessageService,
    private _domSanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit () {
    this._loadCardMessage();
  }

  ngAfterViewInit () {
    this.checkbox.nativeElement.setValue(this.receiveEmail as any);
  }

  sanetizeHTML (htmlText: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(htmlText);
  }

  getUrlImage (): string {
    const finalPath = this.onMobile
      ? '/mobile-conta-por-email.png'
      : '/desktop-conta-por-email.png';

    return `${this._settings.settings.baseImgUrl}/img/conta-email${finalPath}`;
  }

  private _loadCardMessage (): void {
    const messageType = 'INFORMATIVO_CONTA_POR_EMAIL';

    this._messageService
      .getMessageByServiceIdAndType(ServiceId.TROCA_DE_TITULARIDADE, messageType)
      .pipe(take(1))
      .subscribe((data: { descricao: string }) => {
        this.description = data.descricao;
      });
  }
}
