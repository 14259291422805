import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';

import {
  AddLinkedInstallationList,
  AddUnlinkedInstallationList,
  SelectedInstallation,
  Reset,
  RemoveInstallation,
  RemoveSelectedInstallation,
  AddInstallation,
  SetEmailBilling,
  EditInstallation,
} from './installation.actions';
import { IInstallation } from './models/installation.model';

export interface InstallationStateModel {
  list: Array<IInstallation>;
  unlinked: Array<IInstallation>;
  selected: IInstallation;
}

@State<InstallationStateModel>({
  name: 'installation',
  defaults: {
    list: [],
    unlinked: [],
    selected: null,
  },
})
@Injectable()
export class InstallationState {
  @Selector()
  public static getState(state: InstallationStateModel) {
    return state;
  }

  @Selector()
  public static installationList(state: InstallationStateModel) {
    return state.list;
  }

  @Selector()
  public static installationUnlinkedList(state: InstallationStateModel) {
    return state.unlinked;
  }

  @Selector()
  public static selectedInstallation(state: InstallationStateModel) {
    return state.selected;
  }

  @Selector()
  public static selectedInstallationId(state: InstallationStateModel) {
    return state.selected.cdc;
  }

  @Action(Reset)
  public reset(ctx: StateContext<InstallationStateModel>) {
    ctx.setState({ list: [], unlinked: [], selected: null });
  }

  @Action(AddLinkedInstallationList)
  public addLinkedInstallationList(
    ctx: StateContext<InstallationStateModel>,
    { payload }: AddLinkedInstallationList,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, list: payload });
  }

  @Action(AddUnlinkedInstallationList)
  public addUnlinkedInstallationList(
    ctx: StateContext<InstallationStateModel>,
    { payload }: AddUnlinkedInstallationList,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, unlinked: payload });
  }

  @Action(AddInstallation)
  public addInstallation(ctx: StateContext<InstallationStateModel>, { payload }: AddInstallation) {
    const state = ctx.getState();
    const [cdc] = state.unlinked.filter((item) => item.cdc === payload.installationId);
    const rest = state.unlinked.filter((item) => item.cdc !== payload.installationId);
    ctx.setState({
      ...state,
      list: [...state.list, { ...cdc, isLinked: true, apelido: payload.nickname }],
      unlinked: [...rest],
    });
  }

  @Action(EditInstallation)
  public editInstallation(ctx: StateContext<InstallationStateModel>, { payload }: EditInstallation) {
    const state = ctx.getState();
    const installation = state.list.find((item) => item.cdc === payload.installationId);
    const index = state.list.findIndex((item) => item.cdc === payload.installationId);
    const editedInstallation = {
      ...installation,
      apelido: payload.nickname,
    }

    const newList = [...state.list];
    newList[index] = editedInstallation;

    ctx.setState({
      ...state,
      list: newList,
      selected: {
        ...state.selected,
        apelido: payload.installationId === state.selected.cdc ? payload.nickname : state.selected.apelido,
      }
    });
  }

  @Action(RemoveInstallation)
  public removeInstallation(
    ctx: StateContext<InstallationStateModel>,
    { payload }: RemoveInstallation,
  ) {
    const state = ctx.getState();
    const [cdc] = state.list.filter((item) => item.cdc === payload);
    const rest = state.list.filter((item) => item.cdc !== payload);
    ctx.setState({
      ...state,
      list: [...rest],
      unlinked: [...state.unlinked, { ...cdc, isLinked: false, apelido: '' }],
    });
  }

  @Action(SelectedInstallation)
  public selectInstallation(
    ctx: StateContext<InstallationStateModel>,
    { payload }: SelectedInstallation,
  ) {
    const state = ctx.getState();
    const [cdc] = state.list.filter((item) => item.cdc === payload);
    ctx.setState({ ...state, selected: cdc });
  }

  @Action(SetEmailBilling)
  public setEmailBilling(ctx: StateContext<InstallationStateModel>, { payload }: SetEmailBilling) {
    const state = ctx.getState();
    ctx.setState({ ...state, selected: { ...state.selected, hasEmailBilling: payload } });
  }

  @Action(RemoveSelectedInstallation)
  public removeSelectInstallation(ctx: StateContext<InstallationStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, selected: null });
  }
}
