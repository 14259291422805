import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AcnConfigurationService, BaseComponent, GtmService } from '@acn/angular';
import { WebchatService } from '@features/webchat/services/webchat.service';
import { WebchatPosition } from '@features/webchat/model/position';
import { SettingsStoreService } from '@store/app';
import { combineLatest, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { isActivationEnd } from '@features/geoagnostic/services/geoagnostic.service';
import { RouterHelperService } from '@shared/services/router/router-helper.service';
import { webchatOffsetKey } from '@features/webchat/components/webchat-offset-key';

@Component({
  selector: 'app-webchat',
  templateUrl: './webchat.component.html',
  styleUrls: ['./webchat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WebchatComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer2: Renderer2,
    private readonly webchatService: WebchatService,
    private readonly acnConfigurationService: AcnConfigurationService,
    private readonly settingsStoreService: SettingsStoreService,
    private readonly router: Router,
    private readonly gtmService: GtmService,
    private readonly routerHelperService: RouterHelperService,
  ) {
    super();
  }

  private _isScriptAlreadyCreated = false;
  private _isListenerAlreadyAttached = false;
  private _observer = new MutationObserver(() => {
    this._afterLibLoaded();
  });
  private _activationEnd$ = this.router.events.pipe(filter(isActivationEnd));
  private _offsetBottomRouteData$ = this.routerHelperService
    .selectLatestData()
    .pipe(map((data) => !!data[webchatOffsetKey]));

  readonly isHidden$ = this.webchatService.isActive$.pipe(
    map((active) => !active),
    tap((inactive) => {
      if (!inactive) {
        // Se o serviço estiver ativo, tenta criar o script
        this._createScript();
      }
    }),
  );

  readonly offsetBottom$ = combineLatest([
    this._activationEnd$.pipe(map(() => this.settingsStoreService.onDashboard())),
    this.settingsStoreService.onMobile$,
    this._offsetBottomRouteData$,
  ]).pipe(
    map(
      ([onDashboard, onMobile, offsetData]) =>
        (onDashboard && onMobile) || (!onMobile && offsetData),
    ),
  );

  @ViewChild('webchat', { static: true })
  readonly webchatElement: ElementRef<HTMLDivElement>;

  theme: string;
  token: string;
  position: WebchatPosition;

  private _createScript(): void {
    // Verifica se o script já foi criado
    if (this._isScriptAlreadyCreated) {
      // Se o script já foi criado, não faz nada
      return;
    }
    // Se o script ainda não foi criado, cria o script e adiciona ele ao body
    const element: HTMLScriptElement = this.renderer2.createElement('script');
    this.renderer2.setProperty(element, 'src', this.acnConfigurationService.settings.webchatUrl);
    this.renderer2.setProperty(element, 'async', true);
    this.renderer2.setProperty(element, 'id', 'script-webchat');

    fromEvent(element, 'load').subscribe(() => {
      this._afterLibLoaded();
      this._observer.observe(this.webchatElement.nativeElement, {
        subtree: true,
        childList: true,
      });
    });

    this.renderer2.appendChild(this.document.body, element);
    // Seta a variável de script já criado para true
    this._isScriptAlreadyCreated = true;
  }

  /**
   * @description Adiciona um event listener para mandar um
   * evento para o GTM.
   * @private
   */
  private _afterLibLoaded() {
    const launcher = this._getLauncher();
    if (this._isListenerAlreadyAttached || !launcher) {
      return;
    }
    launcher.setAttribute('ht-clickable', '');
    this._getCloseButton()?.setAttribute('ht-clickable', '');
    fromEvent(launcher, 'click')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const action = this._isOpened() ? 'Abrir_Webchat' : 'Fechar_Webchat';
        this.gtmService.setCustomEvent('Botao', action, 'Webchat');
      });
    this._isListenerAlreadyAttached = true;
    this._observer.disconnect();
  }

  private _getCloseButton(): HTMLDivElement | null {
    // Query selector necessário aqui, pois a biblioteca de webchat
    // não disponibiliza nenhuma api para pegar o elemento principal do chat
    return this.webchatElement.nativeElement.querySelector('.sc-header--close-button');
  }

  private _getLauncher(): HTMLDivElement | null {
    // Query selector necessário aqui, pois a biblioteca de webchat
    // não disponibiliza nenhuma api para abrir o chat, ou pegar o elemento principal do chat
    return this.webchatElement.nativeElement.querySelector('.sc-launcher');
  }

  private _isOpened(): boolean {
    return this._getLauncher().classList.contains('opened');
  }

  private _open(): void {
    if (this._isOpened()) {
      return;
    }
    this._getLauncher().click();
  }

  private _close(): void {
    if (!this._isOpened()) {
      return;
    }
    this._getLauncher().click();
  }

  ngOnInit(): void {
    // Pega o tema e o token das variáveis de ambiente
    this.theme = this.acnConfigurationService.settings.webchatTheme;
    this.token = this.acnConfigurationService.settings.webchatToken;
    this.position = this.webchatService.validatePosition(
      this.acnConfigurationService.settings.webchatPosition,
    );
    this.webchatService.action$
      .pipe(
        takeUntil(this.onDestroy),
        filter(() => this._isScriptAlreadyCreated),
      )
      .subscribe((action) => {
        if (action) {
          this._open();
        } else {
          this._close();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._observer.disconnect();
  }
}
