import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import { AcnLoaderService, BaseComponent, IError, ISecurityAccess } from '@acn/angular';
import { AppService } from './app.service';
import { MetaService } from '@shared/services/meta/meta.service';
import { TitleService } from '@shared/services/title/title.service';
import { WINDOW } from '@shared/services';
import { WebchatService } from '@features/webchat/services/webchat.service';
import { combineLatest } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public notifications$ = this.appService.notifications$.pipe(debounceTime(0));
  public onMobile = false;
  public isLoading$ = this.appService.isLoading$;
  public isBrowser = false;

  private _errorMsg: any;

  readonly notificationOffset$ = combineLatest([
    this.webchatService.isActive$,
    this.appService.onMobile$,
  ]).pipe(
    // Se o webchat estiver ativo e não for mobile,
    // os toasts precisam ficar 25px à esquerda do ícone
    // 110px = 25px (margin ícone) + 60px (tamanho ícone) + 25px (distância do toast)
    map(([webchatActive, onMobile]) => (!onMobile && webchatActive ? '110px' : null)),
    debounceTime(100),
  );

  @HostListener('window:resize')
  onResize(): void {
    if (this.isBrowser) {
      this._setVhUnity();
    }
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private readonly appService: AppService,
    private readonly elementRef: ElementRef,
    private readonly metaService: MetaService,
    private readonly titleService: TitleService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
    private readonly webchatService: WebchatService,
    private readonly renderer2: Renderer2,
    private readonly acnLoaderService: AcnLoaderService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Inicialização do componente
   */
  ngOnInit(): void {
    this._addPreConnectLink();
    this.metaService.init();
    this.titleService.init();
    this._setScreen();

    if (!this.isBrowser) {
      return;
    }

    this._setVhUnity();
    this._setErrorHandlers();
    this._getErrorMessages();
    this.appService.setFeatures();
    this.appService.setupGtm().pipe(takeUntil(this.onDestroy)).subscribe();
    this.appService.getCityList().pipe(take(1)).subscribe();
    this.acnLoaderService.startRoutingLoader();
    this._listenToLanguageChanges();
    this.listenSelectCityEvent();
  }

  ngOnDestroy(): void {
    this.metaService.destroy();
    this.titleService.destroy();

    if (!this.isBrowser) {
      return;
    }

    this.appService.resetCityList();
    this.appService.setBrewNewsIsClosed(false);
    this.acnLoaderService.destroy();
    super.ngOnDestroy();
  }

  /**
   * Chamada para remoção do notification no store.
   *
   * @param id
   */
  closeNotification(id: string): void {
    this.appService.removeNotification(id);
  }

  listenSelectCityEvent() {
    this.appService
      .getSelectedCityEvent()
      .selectedCity$.pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (!data) {
          return;
        }

        // evento caputura selecao de cidade console.log('cidade selecionada', data);
        this.appService.getSelectedCity();
      });
  }

  scrollTop(): void {
    if (!this.isBrowser) {
      return;
    }
    this.appService.scrollTop(this.elementRef);
  }

  private _setVhUnity(): void {
    const vh = this.window.innerHeight * 0.01;
    this.document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  private _getErrorMessages(): void {
    this.appService.translatedErrors$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((errors: any) => (this._errorMsg = errors));
  }

  /**
   * Listener for errors on application
   */
  private _setErrorHandlers(): void {
    this.appService.errors$.pipe(takeUntil(this.onDestroy)).subscribe((error: IError) => {
      if (error.type === 'runtime') {
        this._setNotification('is-danger', error.userMessage);
        if (error.developerMessage) {
          console.error(error.developerMessage);
        }
      } else {
        const msg = this._errorMsg[error.code] || error.userMessage;
        this._setNotification('is-danger', msg);
      }
    });

    this.appService.securityErrors$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((access: ISecurityAccess) => {
        if (access.type === 'invalidKey') {
          this._setNotification(
            'is-warning',
            'É necessário estar logado para acessar este serviço',
          );
          return;
        }
        if (access.type === 'forbidden') {
          this._setNotification('is-warning', 'Acesso negado');
        }
      });
  }

  /**
   * Listener for screen size
   */
  private _setScreen(): void {
    this.appService.setupOnMobile().pipe(takeUntil(this.onDestroy)).subscribe();

    this.appService.onMobile$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.onMobile = data;
    });
  }

  /**
   *
   * @param type
   * @param content
   *
   * Add notifications to store
   */
  private _setNotification(type: string, content: string): void {
    this.appService.addNotification({
      class: type,
      content: content,
    });
  }

  private _addPreConnectLink(): void {
    const link = this.renderer2.createElement('link');
    this.renderer2.setProperty(link, 'rel', 'preconnect');
    this.renderer2.setProperty(link, 'href', this.appService.settings.baseImgUrl);
    this.renderer2.appendChild(this.document.head, link);
  }

  private _listenToLanguageChanges(): void {
    this.translocoService.langChanges$.pipe(takeUntil(this.onDestroy)).subscribe((lang) => {
      this.renderer2.setAttribute(this.document.documentElement, 'lang', lang);
    });
  }
}
