import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { ModalComponent } from '@shared/components/modal/modal.component';

@NgModule({
  imports: [CommonModule, PortalModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalModule {}
