import { IAcnDynamicComponentManifest } from '@acn/angular';

export const pixDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'pix.modal.error',
    loadChildren: () =>
      import(
        '../features/duplicate/components/duplicate-pix-modal-error/duplicate-pix-modal-error.module'
      ).then((m) => m.DuplicatePixModalErrorModule),
  },
];
