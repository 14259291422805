import { ElementRef, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { UserStoreService } from '@store/app';
import { IInstallation, InstallationStoreService } from '@store/dashboard';
import { TermUseService } from '@features/terms/services/term-use.service';
import {
  AcnAuthenticationService,
  AcnAuthorization,
  AcnConnectorService,
  IRequest,
} from '@acn/angular';
import { IUserInfo } from '@shared/models/user-info.model';
import { BaseService } from '@shared/services';
import { IUpdateStatus } from '@features/account/models/update-status.model';
import { AutomaticDebitServiceStoreService } from '@store/home';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService {
  public term: boolean;
  public restorePasswordData$ = this._userStore.restorePasswordData$;

  public userName = this._userStore.getUserName();
  public siteTerm$ = this._termUseService.siteTerms$;

  private readonly RESET_PASSWORD_URL = 'login/v2/resetDados';
  private readonly RESET_EMAILSMS_URL = 'login/v2/resetEmailSms';
  private readonly USER_INFO_URL = 'usuario/v3';
  private readonly UPDATE_PASSWORD = 'login/resetSenha';

  constructor(
    private _authService: AcnAuthenticationService,
    private _authorizationService: AcnAuthorization,
    private _router: Router,
    private _connectorApi: AcnConnectorService,
    private _userStore: UserStoreService,
    private _termUseService: TermUseService,
    private _installationStore: InstallationStoreService,
    private _automaticDebitServiceStore: AutomaticDebitServiceStoreService,
    protected _injector: Injector,
  ) {
    super(_injector);
  }

  onLogin(login: string, password: string) {
    this._installationStore.reset();
    return this._authService.login(login, password).pipe(
      switchMap(() => {
        return this._authorizationService.getUserData();
      }),
      switchMap((userData) => {
        this._userStore.setUser(userData);

        const req: IRequest = {
          endPoint: this.USER_INFO_URL,
          checkToken: false,
          showLoading: true,
        };

        return this._connectorApi.get<IUserInfo>(req);
      }),
      switchMap((userInfo) => {
        const { cdcsVinculados, cdcsdesVinculados, ...userData } = userInfo;
        this._userStore.setUser(userData);

        const linked: Array<IInstallation> = cdcsVinculados.map((item) => {
          return { ...item, isLinked: true };
        });

        const unLinked: Array<IInstallation> = cdcsdesVinculados.map((item) => {
          return { ...item, isLinked: false };
        });

        this._installationStore.addLinkedInstallationList(linked);
        this._installationStore.addUnlinkedInstallationList(unLinked);

        this.navigateAfterLogin();

        return of(userInfo);
      }),
      catchError((error) => {
        if (error.status === 403) {
          this.setNotification(
            'is-danger',
            'Dados inválidos ou não cadastrados',
            error.error.message,
          );
          return of(false);
        }
        this.onUserNotFound(login);

        return of(false);
      }),
    );
  }

  navigateAfterLogin() {
    const firstAccess = this._userStore.getFirstAccess();

    let route = 'cdc/selecao';
    if (firstAccess) {
      route = 'cdc/vinculo';
      this._userStore.setFirstAccess(false);
    }
    this._router.navigate([route]);
  }

  onUserNotFound(params: any = null) {
    this.showModal({ componentId: 'login.genericModal', params });
  }

  onGoToAttendance() {
    this.showModal({ componentId: 'login.attendance', canClose: true });
  }

  onDuplicateNumber() {
    this.showModal({ componentId: 'login.duplicateNumber', canClose: true });
  }

  scrollTop(elementRef: ElementRef) {
    this._screenService.scrollTop(elementRef);
  }

  onForgotPassword(cpfCnpj: string) {
    const req: IRequest = {
      endPoint: this.RESET_PASSWORD_URL,
      checkToken: false,
      queryString: { cpfCnpj },
    };

    return this._connectorApi.get<{ email: string; telefone: string }>(req).pipe(
      map((data) => {
        this._userStore.setUserRestoreData({
          email: data.email,
          phone: data.telefone,
          document: cpfCnpj,
        });
        this._router.navigate(['login/envio-da-senha']);
        return data;
      }),
      catchError(() => {
        this.onUserNotFound(cpfCnpj);
        return of(false);
      }),
    );
  }

  onSendPassword(type: string) {
    return this._userStore.restorePasswordData$.pipe(
      take(1),
      switchMap((data) => {
        const req: IRequest = {
          endPoint: this.RESET_EMAILSMS_URL,
          checkToken: false,
          queryString: {
            email: type === 'email' ? true : null,
            cpfCnpj: data.document,
          },
        };

        return this._connectorApi.get(req);
      }),
      switchMap((data) => {
        this._userStore.setUserRestoreType(type);
        this._router.navigate(['login/senha-enviada']);
        return of(data);
      }),
      catchError((error) => {
        if (error.status === 403) {
          this.onDuplicateNumber();
          return of(false);
        }
        this.setNotification('is-danger', 'Erro', error.error.message);
        return of(false);
      }),
    );
  }

  onLogout() {
    this._authService.logout();
    this._installationStore.reset();
    this._automaticDebitServiceStore.resetBillingType();
    this._automaticDebitServiceStore.resetReplaceAutomaticDebit();
  }

  updatePassword(
    novaSenha: string,
    confirmarSenha: string,
    token: string,
  ): Observable<IUpdateStatus> {
    const req: IRequest = {
      endPoint: this.UPDATE_PASSWORD,
      showLoading: true,
      checkToken: false,
      body: { novaSenha, confirmarSenha, token },
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res: any) => {
        this._router.navigate(['login/senha-cadastrada']);
        return of({ status: true, message: res.message });
      }),
      catchError((error) => {
        if (error.status === 401) {
          this._router.navigate(['login/link-expirado']);
          return of({ status: false, message: error.error });
        } else {
          this.setNotification('is-danger', 'Erro', error.error.message);
          return of({ status: false, message: error.error });
        }
      }),
    );
  }
}
