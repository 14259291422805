import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { RedirectionState } from './redirection-store.state';
import { SetRedirectedFrom, SetRedirectedTo } from './redirection-store.actions';

@Injectable({
  providedIn: 'root',
})
export class RedirectionStoreService {
  @Select(RedirectionState.getRedirectedFrom)
  public redirectedFrom$: Observable<string>;

  @Select(RedirectionState.getRedirectedTo)
  public redirectedTo$: Observable<string>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setRedirectedFrom(data: string) {
    return new SetRedirectedFrom(data);
  }
  public getRedirectedFrom() {
    return this._store.selectSnapshot(RedirectionState.getRedirectedFrom);
  }

  @Dispatch()
  public setRedirectedTo(data: string) {
    return new SetRedirectedTo(data);
  }
  public getRedirectedTo() {
    return this._store.selectSnapshot(RedirectionState.getRedirectedTo);
  }
}
