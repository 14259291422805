import { IChangeHolderForm } from './models/change-holder-form.model';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { IChangeHolderStateModel } from './models/change-holder-state.model';
import { ChangeHolderState } from './change-holder.state';
import {
  SetCdc,
  SetDocument,
  SetIsCompany,
  SetForm,
  SetProtocolCode,
  ResetChangeHolder,
  SetCdcData,
  SetPropertyDocs,
  SetAcceptedDebts,
  SetPersonalDocs,
  SetNecessaryDocumentsInfo,
  SetCorroborativeDocs,
  SetSuccessMessage,
} from './change-holder.actions';
import { ICdcData } from './models/cdc-data.model';
import { IPropertyDocs } from './models/property-docs.model';

@Injectable({
  providedIn: 'root',
})
export class ChangeHolderStoreService {
  constructor (private _store: Store) {}

  @Select(ChangeHolderState)
  public changeHolderState$: Observable<IChangeHolderStateModel>;

  @Select(ChangeHolderState.getCdc)
  public cdc$: Observable<string>;

  @Select(ChangeHolderState.getDocument)
  public document$: Observable<string>;

  @Select(ChangeHolderState.getForm)
  public form$: Observable<any>;

  @Select(ChangeHolderState.getIsCompany)
  public isCompany$: Observable<boolean>;

  @Select(ChangeHolderState.getCdcData)
  public cdcData$: Observable<ICdcData>;

  @Select(ChangeHolderState.getProtocolCode)
  public protocolCode$: Observable<number>;

  @Select(ChangeHolderState.getAcceptedDebts)
  public acceptedDebts$: Observable<boolean>;

  @Select(ChangeHolderState.getPropertyDocs)
  public propertyDocs$: Observable<Array<IPropertyDocs>>;

  @Select(ChangeHolderState.getPersonalDocuments)
  public personalDocuments$: Observable<Array<IPropertyDocs>>;

  @Select(ChangeHolderState.getPersonalDocuments)
  public personalDocs$: Observable<Array<IPropertyDocs>>;

  @Select(ChangeHolderState.getCorroborativeDocuments)
  public corroborativeDocuments$: Observable<Array<IPropertyDocs>>;

  @Select(ChangeHolderState.getDocumentsInfo)
  public documentsInfo$: Observable<Array<object>>;

  @Select(ChangeHolderState.getSuccessMessage)
  public successMessage$: Observable<string>;

  @Dispatch()
  public setCdc (cdc: string) {
    return new SetCdc(cdc);
  }

  @Dispatch()
  public setDocument (document: string) {
    return new SetDocument(document);
  }

  @Dispatch()
  public setForm (form: IChangeHolderForm) {
    return new SetForm(form);
  }

  @Dispatch()
  public setIsCompany (isCompany: boolean) {
    return new SetIsCompany(isCompany);
  }

  @Dispatch()
  public setPropertyDocs (propertyDocs: Array<IPropertyDocs>) {
    return new SetPropertyDocs(propertyDocs);
  }

  @Dispatch()
  public setCdcData (cdcData: ICdcData) {
    return new SetCdcData(cdcData);
  }

  @Dispatch()
  public setProtocolCode (protocolCode: number) {
    return new SetProtocolCode(protocolCode);
  }

  @Dispatch()
  public setAcceptedDebts (acceptedDebts: boolean) {
    return new SetAcceptedDebts(acceptedDebts);
  }

  @Dispatch()
  public reset () {
    return new ResetChangeHolder();
  }

  @Dispatch()
  public setPersonalDocs (personalDocs: Array<IPropertyDocs>) {
    return new SetPersonalDocs(personalDocs);
  }

  @Dispatch()
  public setCorroborativeDocsOnStore (personalDocs: Array<IPropertyDocs>) {
    return new SetCorroborativeDocs(personalDocs);
  }

  @Dispatch()
  public setNecessaryDocumentsInfoOnStore (documentsInfo: Array<object>) {
    return new SetNecessaryDocumentsInfo(documentsInfo);
  }

  @Dispatch()
  public setSuccessMessage (successMessage: string) {
    return new SetSuccessMessage(successMessage);
  }

  getFormData (): IChangeHolderForm {
    return this._store.selectSnapshot(ChangeHolderState.getForm);
  }

  getIsCompany (): boolean {
    return this._store.selectSnapshot(ChangeHolderState.getIsCompany);
  }

  getDocumentsInfo (): Array<any> {
    return this._store.selectSnapshot(ChangeHolderState.getDocumentsInfo);
  }

  getSuccessData (): { successMessage: string, protocolCode: number } {
    return {
      successMessage: this._store.selectSnapshot(ChangeHolderState.getSuccessMessage),
      protocolCode: this._store.selectSnapshot(ChangeHolderState.getProtocolCode),
    };
  }
}
