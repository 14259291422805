<div class="agencies__container" *ngIf="agencies?.length > 0; else notFoundAgencie">
  <ng-container *ngFor="let agency of agencies">
    <acn-card class="card" [ngClass]="{ card__transparent: transparentCard }">
      <p class="is-size-14 is-bold">{{ agency?.agenciaAtendimento }}</p>
      <p class="is-size-14">{{ agency?.endereco }}</p>
      <p class="is-size-14">Horário de atendimento: {{ agency?.horarioAtendimento }}</p>
      <app-button-schedule-attendance
        *ngIf="agency.siteAgendamentoPresencial"
        [attendanceUrl]="agency.siteAgendamentoPresencial"
      ></app-button-schedule-attendance>
    </acn-card>
    <div class="store__split">
      <acn-split></acn-split>
    </div>
  </ng-container>
</div>

<ng-template #notFoundAgencie>
  <div class="not-found-message">
    <app-button-schedule-attendance
        [attendanceUrl]="attendanceUrl"
      ></app-button-schedule-attendance>
  </div>
</ng-template>
