import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ContactState, ContactStateModel } from './contact.state';
import { Observable } from 'rxjs';

import {
  SetContactName,
  SetContactCdc,
  SetContactEmail,
  SetContactCity,
  SetContactPhone,
  ResetContact,
} from './contact.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactStoreService {
  @Select(ContactState)
  public contactState$: Observable<ContactStateModel>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setContactName(name: string) {
    return new SetContactName(name);
  }

  public getContactName() {
    return this.contactState$.pipe(map((item) => item.name));
  }

  @Dispatch()
  public resetContact() {
    return new ResetContact();
  }

  @Dispatch()
  public setContactCdc(cdc: string) {
    return new SetContactCdc(cdc);
  }

  public getContactCdc() {
    return this.contactState$.pipe(map((item) => item.cdc));
  }

  @Dispatch()
  public setContactCity(city: string) {
    return new SetContactCity(city);
  }

  public getContactCity() {
    return this.contactState$.pipe(map((item) => item.city));
  }

  @Dispatch()
  public setContactEmail(email: string) {
    return new SetContactEmail(email);
  }

  public getContactEmail() {
    return this.contactState$.pipe(map((item) => item.email));
  }

  @Dispatch()
  public setContactPhone(phone: string) {
    return new SetContactPhone(phone);
  }

  public getContactPhone() {
    return this.contactState$.pipe(map((item) => item.phone));
  }
}
