import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import {
  ResetAutoReconnection,
  SetBody,
  SetCutRisk,
  SetDocument,
  SetEaselLocation,
  SetEmail,
  SetIdServicesSanLinkAV,
  SetName,
  SetPhone,
  SetProtocol,
  SetReconnectionTypes,
  SetSelectedReconnection,
  SetUrlBack,
} from './auto-reconnection.actions';
import { IAutoReconnectionStateModel } from './models';

const INITIAL_STATE: IAutoReconnectionStateModel = {
  body: {
    easelLocation: null,
    document: null,
    email: null,
    name: null,
    phone: null,
    acceptTerms: null,
  },
  urlBack: null,
  reconnectionTypes: null,
  selectedReconnection: null,
  cutRisk: null,
  protocol: null,
  idServicesSanLinkAV: null,
};

@State<IAutoReconnectionStateModel>({
  name: 'autoReconnection',
  defaults: INITIAL_STATE,
})
@Injectable()
export class AutoReconnectionState {
  @Selector()
  public static getState(state: IAutoReconnectionStateModel) {
    return state;
  }

  @Selector()
  public static getBody(state: IAutoReconnectionStateModel) {
    return state.body;
  }

  @Selector()
  public static getEaselLocation(state: IAutoReconnectionStateModel) {
    return state.body.easelLocation;
  }

  @Selector()
  public static getUrlBack(state: IAutoReconnectionStateModel) {
    return state.urlBack;
  }

  @Selector()
  public static getCutRisk(state: IAutoReconnectionStateModel) {
    return state.cutRisk;
  }

  @Selector()
  public static getReconnectionTypes(state: IAutoReconnectionStateModel) {
    return state.reconnectionTypes;
  }

  @Selector()
  public static getSelectedReconnection(state: IAutoReconnectionStateModel) {
    return state.selectedReconnection;
  }

  @Selector()
  public static getProtocol(state: IAutoReconnectionStateModel) {
    return state.protocol;
  }

  @Selector()
  public static getIdServicesSanLinkAV(state: IAutoReconnectionStateModel) {
    return state.idServicesSanLinkAV;
  }

  @Action([ResetAutoReconnection])
  public reset(ctx: StateContext<IAutoReconnectionStateModel>) {
    ctx.patchState(INITIAL_STATE);
  }

  @Action(SetEaselLocation)
  public setEaselLocation(
    ctx: StateContext<IAutoReconnectionStateModel>,
    { payload }: SetEaselLocation,
  ) {
    ctx.setState(patch({ body: patch({ easelLocation: payload }) }));
  }

  @Action(SetName)
  public setUserName(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetName) {
    ctx.setState(patch({ body: patch({ name: payload }) }));
  }

  @Action(SetPhone)
  public setPhone(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetPhone) {
    ctx.setState(patch({ body: patch({ phone: payload }) }));
  }

  @Action(SetEmail)
  public setEmail(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetEmail) {
    ctx.setState(patch({ body: patch({ email: payload }) }));
  }

  @Action(SetDocument)
  public setDocument(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetDocument) {
    ctx.setState(patch({ body: patch({ document: payload }) }));
  }

  @Action(SetUrlBack)
  public setUrlBack(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetUrlBack) {
    ctx.patchState({ urlBack: payload });
  }

  @Action(SetCutRisk)
  public setCutRisk(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetCutRisk) {
    ctx.patchState({ cutRisk: payload });
  }

  @Action(SetProtocol)
  public setProtocl(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetProtocol) {
    ctx.patchState({ protocol: payload });
  }

  @Action(SetBody)
  public setBody(ctx: StateContext<IAutoReconnectionStateModel>, { payload }: SetBody) {
    ctx.patchState({ body: payload });
  }

  @Action(SetIdServicesSanLinkAV)
  public setIdServicesSanLinkAV(
    ctx: StateContext<IAutoReconnectionStateModel>,
    { payload }: SetIdServicesSanLinkAV,
  ) {
    ctx.patchState({ idServicesSanLinkAV: payload });
  }

  @Action(SetSelectedReconnection)
  public setSelectedReconnection(
    ctx: StateContext<IAutoReconnectionStateModel>,
    { payload }: SetSelectedReconnection,
  ) {
    ctx.patchState({ selectedReconnection: payload });
  }

  @Action(SetReconnectionTypes)
  public setReconnectionTypes(
    ctx: StateContext<IAutoReconnectionStateModel>,
    { payload }: SetReconnectionTypes,
  ) {
    ctx.patchState({ reconnectionTypes: payload });
  }
}
