import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetIsLogin, SetIsEmail } from './login.actions';
import { LoginState, LoginStateModel } from './login.state';

@Injectable({
  providedIn: 'root',
})
export class LoginStoreService {
  @Select(LoginState.getState)
  public loginState$: Observable<LoginStateModel>;
  @Select(LoginState.isEmail)
  public isEmail$: Observable<boolean>;
  @Select(LoginState.isLogin)
  public isLogin$: Observable<any>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setIsLogin(isLogin: boolean) {
    return new SetIsLogin(isLogin);
  }
  @Dispatch()
  public setIsEmail(isEmail: boolean) {
    return new SetIsEmail(isEmail);
  }
}
