import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { CitiesStoreService } from '@store/dashboard';
import { RedirectionStoreService } from '@store/app';
import { ClientService } from '@features/services/services/client.service';
import { isPlatformServer } from '@angular/common';

class SelectedCityGuardRedirect {
  private constructor(public readonly redirect: string) {}

  static readonly key = '__redirectTo__';

  static create(redirect: string): SelectedCityGuardRedirect {
    return new SelectedCityGuardRedirect(redirect);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SelectedCityGuard implements CanActivate {
  constructor(
    private _citiesStoreService: CitiesStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _clientService: ClientService,
    private _router: Router,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this._isServer = isPlatformServer(platformId);
  }

  private readonly _isServer: boolean;

  static createRedirect(redirect: string): Data {
    return {
      [SelectedCityGuardRedirect.key]: SelectedCityGuardRedirect.create(redirect),
    };
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this._isServer || this._selectedCity(next, state);
  }

  private _selectedCity(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const selectedCity = !!this._citiesStoreService.getSelectedCity();
    const redirect: SelectedCityGuardRedirect = next.data[SelectedCityGuardRedirect.key];
    const hasRedirect = redirect instanceof SelectedCityGuardRedirect;

    if (!selectedCity) {
      if (!hasRedirect) {
        this._clientService.setNotification(
          'is-warning',
          '',
          'Este serviço requer que uma cidade seja selecionada',
        );
      }
      this._redirectionStore.setRedirectedFrom(state.url);
      const redirectPath = hasRedirect ? redirect.redirect : '/selecao-de-cidade';
      return this._router.parseUrl(redirectPath);
    }
    return selectedCity;
  }
}
