import { Injectable } from '@angular/core';
import { IRequest, AcnConnectorService } from '@acn/angular';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { Observable } from 'rxjs';
import { MessageContract } from './contracts/Message.contract';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private readonly BASE_URL = 'servicos/mensagemServico';

  constructor (
    private _connectorApi: AcnConnectorService,
    private _selectCityService: SelectCityService,
  ) {}

  getMessageByServiceIdAndType (
    serviceId: number,
    messageType: string,
  ): Observable<MessageContract> {
    const idCity = this._selectCityService.getSelectedCity()?.id;

    const request: IRequest = {
      endPoint: this.BASE_URL,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade: idCity,
        idServico: serviceId,
        tipoMensagem: messageType,
      },
    };

    return this._connectorApi.get(request);
  }
}
