export enum servicesList {
  agendarAtendimento = 1,
  notificarProblema = 3,
  notificarUmProblema = 56,
  debitoAutomatico = 4,
  acompanhamentoDeSolicitacoes = 9,
  segundaVia = 5,
  alterarDataVencimento = 6,
  codBarras = 8,
  trocaTitularidade = 10,
  automaticoCartaoCredito = 13,
  boleto = 14,
  autoleitura = 15,
  pix = 15,
  cartaDeServicos = 16,
  estruturaTarifaria = 17,
  qualidadeDaAgua = 18,
  declaracaoNegativaDeDebitos = 20,
  declaracaoAnualDeQuitacao = 21,
  notificarVazamento = 25,
  receberContasPorEmail = 36,
  laboratorioMovel = 38,
  recursosAdministrativos = 40,
  tabelaDeServicos = 41,
  servicosTecnicos = 42,
  simuladorTarifa = 55,
  segundaViaFacil = 44,
  segundaViaFacilCodBarras = 44,
  eventualCartaoCredito = 47,
  religa = 48,
  agendamentoInteligente = 49,
  religaAuto = 50,
  communication = 51,
  segundaViaFacilPix = 52,
  corteAPedido = 52,
  segundaViaFacilParcelaNaHora = 54,
  segundaViaParceleNaHora = 55,
  optInBySms = 56,
  optInByEmail = 57,
  webchat = 53,
  ligaNova = 54,
  calendarioLeitura = 57,
  manualDeAtendimento = 58,
}
