import { bankSharedValidation } from '@acn/angular/shared/validators/bank-shared-validation';

export function validateBankAccount(value: string) {
  if (!value) {
    return { code: 1, message: 'value is empty' };
  }

  const expReg: RegExp = /([0-9]|[a-zA-Z]){0,14}-?([0-9]|[a-zA-Z]){0,14}/;
  if (value.match(expReg)) {
    return bankSharedValidation(value);
  } else {
    return { code: 1, message: 'format invalid' };
  }
}
