export interface IBankRulesResponse {
  agenciaDV: BooleanString;
  arrecadador: string;
  contaDV: BooleanString;
  numeroBanco: number;
  insereDiretoNoSan: boolean;
  preencherZerosEsquerdaAG: BooleanString;
  preencherZerosEsquerdaCC: BooleanString;
  qtdDigitosAgDebAuto: number;
  qtdDigitosCcDebAuto: number;
  versaoLayoutDebAuto: number;
}

export type BooleanString = 'S' | 'N';

export const BooleanStringValues = {
  S: 'S',
  N: 'N',
}

