import { Component, ComponentRef, Injector } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { AppService } from '../../../app.service';
import { delay, filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  constructor(private readonly appService: AppService, private readonly injector: Injector) {}

  private readonly _isHafScreenModal$ = new BehaviorSubject(false);
  private _isOpening = false;

  readonly isHalfScreenModal$ = this._isHafScreenModal$.pipe(
    // Delay para evitar erro NG0100
    delay(0),
  );
  readonly modal$ = this.appService.modalState$.pipe(
    filter(() => {
      return !this._isOpening;
    }),
    switchMap(async (modal) => {
      if (!modal.componentId) {
        return { show: false, componentPortal: null, canCloseModal: false };
      }
      this._isOpening = true;
      const { component, moduleRef } = await this.appService.getModalComponentAndModule(
        modal.componentId,
        this.injector,
      );
      this._isOpening = false;
      if (!component) {
        return { show: false, componentPortal: null, canCloseModal: false };
      }
      return {
        show: true,
        componentPortal: new ComponentPortal(
          component,
          null,
          this.injector,
          moduleRef.componentFactoryResolver,
        ),
        canCloseModal: !!modal.canClose,
      };
    }),
  );

  closeModal(): void {
    this.appService.hideModal();
  }

  onModalAttached($event: CdkPortalOutletAttachedRef) {
    if (!($event instanceof ComponentRef)) {
      return;
    }
    this._isHafScreenModal$.next(!!$event.instance.isHalfScreenModal);
  }
}
