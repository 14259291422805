<ng-container *transloco="let text; scope: 'duplicate'">
  <acn-card class="card">
    <div class="card__content">
      <img class="img" [src]="imgUrl" alt="Débito automático" />
      <span
        *ngIf="bancoCadastrado; else notBankName"
        class="is-size-14"
        [innerHtml]="
          text('duplicate.automaticDebit.description').replace(flagBank, billingDetail.nomeBanco)
        "
      >
      </span>
      <ng-template #notBankName>
        <span
          class="is-size-14"
          [innerHtml]="text('duplicate.automaticDebit.descriptionWithoutDetails')"
        ></span>
      </ng-template>
    </div>
  </acn-card>
</ng-container>
