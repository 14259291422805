export class SetUserDocument {
  public static readonly type = '[Register] set user document';
  constructor(public payload: string) {}
}
export class SetUserCdc {
  public static readonly type = '[Register] set user cdc';
  constructor(public payload: string) {}
}
export class SetCdcName {
  public static readonly type = '[Register] set cdc name';
  constructor(public payload: string) {}
}

export class SetPct {
  public static readonly type = '[Register] set percentage';
  constructor(public payload: number) {}
}

export class SetPhone {
  public static readonly type = '[Register] set phone';
  constructor(public payload: string) {}
}

export class SetEmail {
  public static readonly type = '[Register] set email';
  constructor(public payload: string) {}
}
export class SetPassword {
  public static readonly type = '[Register] set password';
  constructor(public payload: string) {}
}

export class SetEdit {
  public static readonly type = '[Register] set is editing';
  constructor(public payload: boolean) {}
}

export class SetCurrentStep {
  public static readonly type = '[Register] set current step';
  constructor(public payload: number) {}
}

export class SetCityCode {
  public static readonly type = '[Register] set city code';
  constructor(public payload: number) {}
}

export class SetCdcCategory {
  public static readonly type = '[Register] set cdc category';
  constructor(public payload: string) {}
}

export class ResetRegister {
  public static readonly type = '[Register] RESET';
}

export class SetTerms {
  public static readonly type = '[Register] set register terms';
  constructor(public payload: boolean) {}
}
