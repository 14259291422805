import { IUpdateInstallationResponse } from './update-installation-response.model';
import { OptInTypeEnum } from '@features/opt-in/models/opt-in-type.enum';

export enum AddInstallationResponseTypeEnum {
  optin,
  emailBilling,
  installation,
}

export interface AddInstallationResponseModel extends IUpdateInstallationResponse {
  type: AddInstallationResponseTypeEnum;
  optinTypes?: OptInTypeEnum[];
}

export interface AddInstallationResponseModelWithOptinType extends AddInstallationResponseModel {
  optinType: string;
}
