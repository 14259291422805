const ESPACO = ' ';
const NOME_REGEX = /^[a-zA-ZÀ-Ÿ]+\s([a-zA-ZÀ-Ÿ]\s?)*[a-zA-ZÀ-Ÿ][a-zA-ZÀ-Ÿ]+\s?$/;

/**
 * @returns true caso o nome seja invalido
 */
export function validateFullname(nome: string) {
  const nomeCompleto = nome;

  if (!nomeCompleto || nomeCompleto === '') {
    return { code: 1, message: 'value is empty' };
  }

  if (
    !NOME_REGEX.test(nomeCompleto) ||
    !isNomeCompletoValido(nomeCompleto) ||
    !existeEspacoEntreNomes(nomeCompleto)
  ) {
    return { code: 1, message: 'format invalid' };
  }

  return { code: 0, message: 'success' };
}

/**
 * @returns true caso nome completo seja valido
 */
function isNomeCompletoValido(nomeCompleto: string): boolean {
  const nomeSplit = nomeCompleto.split(ESPACO);
  return nomeCompleto.length > 3 && nomeSplit[0].length >= 1 && nomeSplit[1].length >= 1;
}

/**
 * @returns true caso exista espaco entre os nomes
 */
function existeEspacoEntreNomes(nomeCompleto: string): boolean {
  return nomeCompleto.indexOf(ESPACO) > 0;
}
