import { Router } from '@angular/router';
import { AcnFormValidations, BaseFormComponent } from '@acn/angular';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { ICity } from '@store/dashboard';
import { take, takeUntil } from 'rxjs/operators';
import { GeoagnosticService } from '@features/geoagnostic/services/geoagnostic.service';

@Component({
  selector: 'app-support-channels',
  templateUrl: './support-channels.component.html',
  styleUrls: ['./support-channels.component.scss'],
})
export class SupportChannelsComponent extends BaseFormComponent implements OnInit, OnDestroy {
  @Input() selectedCity: ICity;
  public onMobile = false;
  public isBrowser: boolean;
  public cities: Array<any> = [];
  public gtmCategory = 'Botao';
  public baseImgUrl: string;
  public backgroundImageUrl: string;
  @HostBinding('class.dashboard') onDashboard = false;

  constructor(
    private _selectCityService: SelectCityService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _geoagnosticService: GeoagnosticService,
    @Inject(PLATFORM_ID) private _platformId,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit() {
    this.baseImgUrl = this._selectCityService.baseImgUrl;
    this._createForm();
    this._setScreen();
    if (!this.isBrowser) {
      return;
    }
    this._getCity();
    this._getSelectedCity();
  }

  private _setScreen() {
    this._geoagnosticService.onMobile$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.onMobile = data));
  }

  submit() {
    if (this.form && this.form.valid) {
      this._selectCityService
        .selectCity(this.form.value.city)
        .pipe(takeUntil(this.onDestroy))
        .subscribe((data) => {
          this.selectedCity = data;
          if (!data) {
            this._selectCityService.setNotification('is-warning', '', 'Cidade não encontrada.');
            return;
          }

          const url = '/home/atendimento';
          this._router.navigate([url]);
          this._selectCityService.setRedirectTo(null);
        });
    }
  }

  onClearForm() {
    this.form.reset();
  }

  getSelectedCity(city): void {
    this.form.get('city').setValue(city);
  }

  private _createForm() {
    this.form = this._formBuilder.group({
      city: [null, AcnFormValidations.required],
    });
  }

  private _getCity() {
    if (this.isBrowser) {
      this._selectCityService
        .getCity()
        .pipe(take(1))
        .subscribe((data) => {
          this.cities = [];
          data.forEach((_city) => {
            this.cities.push({ label: `${_city.nome} - ${_city.uf}`, value: _city.nome });
          });
        });
    }
  }

  private _getSelectedCity() {
    this._geoagnosticService.selectedCity$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      if (data) {
        this.selectedCity = data;
      }
    });
  }
}
