import { differenceInYears, parse } from 'date-fns';

export function validateBirthAge(value: string, format?: string) {
  if (format) {
    return validateBirthAgeFormatted(value, format);
  }

  const inputDate = new Date(value);
  const inputDateMs = inputDate.getTime();
  const currentDate = new Date(new Date().toUTCString());
  const currentDateMs = currentDate.getTime();

  // 18 anos === 568080000000ms
  if (currentDateMs - inputDateMs <= 568080000000) {
    return { code: 1, message: 'format invalid' };
  } else {
    return { code: 0, message: 'success' };
  }
}

function validateBirthAgeFormatted(value: string, format: string) {
  const inputDate = parse(value, format, new Date());
  const currentDate = new Date();

  const diff = differenceInYears(currentDate, inputDate);
  const _18_YEARS = 18;

  if (diff < _18_YEARS) {
    return { code: 1, message: 'format invalid' };
  }
  return { code: 0, message: 'success' };
}
