import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@acn/angular';
import { ITipCard } from './models/tip-card.model';
import { ICity } from '@store/dashboard/states/cities/models/city.model';

import { LandingService } from '../../services/landing.service';

@Component({
  selector: 'app-landing-tips',
  templateUrl: './landing-tips.component.html',
  styleUrls: ['./landing-tips.component.scss'],
})
export class LandingTipsComponent extends BaseComponent implements OnInit {
  public baseImgUrl: string;
  public contentBackgroundImageUrl: string;

  @ViewChild('carousel') carousel: ElementRef<HTMLAcnCarouselElement>;

  @Input() isOnMobile = false;
  @Input() selectedCity: ICity;

  public cardsList: Array<ITipCard>;

  constructor(private _landingService: LandingService) {
    super();
  }

  ngOnInit(): void {
    this.cardsList = [
      {
        drops: Array(4).fill(1),
        title: 'landing.home.tips.shower',
        description: 'landing.home.tips.shower-desc',
      },
      {
        drops: Array(1).fill(1),
        title: 'landing.home.tips.tap',
        description: 'landing.home.tips.tap-desc',
      },
      {
        drops: Array(4).fill(1),
        title: 'landing.home.tips.hose',
        description: 'landing.home.tips.hose-desc',
      },
      {
        drops: Array(3).fill(1),
        title: 'landing.home.tips.toilet-flush',
        description: 'landing.home.tips.toilet-flush-desc',
      },
      {
        drops: Array(7).fill(1),
        title: 'landing.home.tips.washing-machine',
        description: 'landing.home.tips.washing-machine-desc',
      },
    ];

    this.baseImgUrl = this._landingService.baseImgUrl;

    this._landingService.onMobile$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      const img = data ? `landing-tips-mobile.png` : 'landing-tips-desktop.png';
      this.contentBackgroundImageUrl = `url(${this.baseImgUrl}/img/home/${img})`;
    });
  }

  onSwipeLeft(event: any) {
    if (this.carousel) {
      this.carousel.nativeElement.next();
    }
  }

  onSwipeRight(event: any) {
    if (this.carousel) {
      this.carousel.nativeElement.prev();
    }
  }
}
