import { IAcnDynamicComponentManifest } from '@acn/angular';

export const reportDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'image-upload.error',
    loadChildren: () =>
      import('./report/components/upload-error-modal/upload-error-modal.module').then(
        (m) => m.UploadErrorModalModule,
      ),
  },
  {
    path: 'image-upload.preview',
    loadChildren: () =>
      import('./report/components/upload-preview-modal/upload-preview-modal.module').then(
        (m) => m.UploadPreviewModalModule,
      ),
  },
];
