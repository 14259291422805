import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { EasyDuplicateState } from './states/easy-duplicate';
import { AutomaticDebitServiceState } from './states/automatic-debit-service/automatic-debit-service.state';
import { ChangeExpiringDateState } from './states/change-expiring-date/change-expiring-date.state';
import { ReportLeakState } from './states/report-leak';

export * from './states/easy-duplicate';
export * from './states/change-expiring-date';
export * from './states/automatic-debit-service';
export * from './states/report-leak';

export const HomeStates = [
  EasyDuplicateState,
  ChangeExpiringDateState,
  AutomaticDebitServiceState,
  ReportLeakState,
];

@State({
  name: 'home',
  children: HomeStates,
})
@Injectable()
export class HomeStateModule {}
