<div
  data-cy="landing-services-carousel"
  class="content"
  [style.backgroundImage]="contentBackgroundImageUrl"
  (swipeleft)="onSwipeLeft($event)"
  (swiperight)="onSwipeRight($event)"
  [class.spaced]="selectedCity && !selectedCity.isAttended"
>
  <div class="container">
    <div class="header">
      <p
        class="is-medium header__line"
        [ngClass]="isOnMobile ? 'is-size-20' : 'is-size-40'"
        [innerHTML]="'landing.home.tips.title' | transloco"
        data-cy="title"
      ></p>
      <p class="header__line" [ngClass]="isOnMobile ? 'is-size-14' : 'is-size-20'" data-cy="text1">
        {{ 'landing.home.tips.tip-text-1' | transloco }}
      </p>
      <p class="header__line" [ngClass]="isOnMobile ? 'is-size-14' : 'is-size-20'" data-cy="text2">
        {{ 'landing.home.tips.tip-text-2' | transloco }}
      </p>
    </div>
    <div class="cards">
      <acn-carousel *ngIf="isOnMobile" class="carousel" #carousel hide-navigation>
        <ng-container *ngTemplateOutlet="cards"></ng-container>
      </acn-carousel>

      <ng-container *ngIf="!isOnMobile">
        <ng-container *ngTemplateOutlet="cards"></ng-container>
      </ng-container>
    </div>

    <a
      target="_blank"
      rel="noopener noreferrer"
      class="link__tips"
      href="https://consumoconsciente.brkambiental.com.br"
    >
      <acn-button class="button is-blue is-fullWidth">
        {{ 'landing.home.tips.moreTips' | transloco }}
      </acn-button>
    </a>
  </div>
</div>

<ng-template #cards>
  <acn-card class="card shadow-less" *ngFor="let card of cardsList">
    <div class="card__header">
      <acn-icon icon="fa-tint" class="icon-color" *ngFor="let drop of card.drops"></acn-icon>
      <acn-split></acn-split>
    </div>

    <div class="card__footer">
      <p class="has-text-blue is-size-20 is-medium" data-cy="cardTitle">
        {{ card.title | transloco }}
      </p>
      <span class="card__description is-medium" data-cy="cardDescription">{{
        card.description | transloco
      }}</span>
    </div>
  </acn-card>
</ng-template>
