import { ICreditCard, ICreditCardDocument } from './models';
export class SetNumber {
  public static readonly type = '[Credit-card] set card number';
  constructor(public payload: string) {}
}
export class SetType {
  public static readonly type = '[Credit-card] set card type process';
  constructor(public payload: 'automatico' | 'a vista') {}
}

export class SetResetDate {
  public static readonly type = '[Credit-card] set reset date process';
  constructor(public payload: boolean) {}
}

export class SetName {
  public static readonly type = '[Credit-card] set card name';
  constructor(public payload: string) {}
}

export class SetBrand {
  public static readonly type = '[Credit-card] set card brand';
  constructor(public payload: number) {}
}

export class SetDate {
  public static readonly type = '[Credit-card] set card date';
  constructor(public payload: string) {}
}

export class SetCode {
  public static readonly type = '[Credit-card] set card code';
  constructor(public payload: string) {}
}

export class SetCreditCardName {
  public static readonly type = '[creditCard] Set name';
  constructor(public payload: string) {}
}

export class SetCreditCardDateError {
  public static readonly type = '[creditCard] Set date error';
  constructor(public payload: boolean) {}
}

export class SetCreditCardFlip {
  public static readonly type = '[creditCard] Set flip card';
  constructor(public payload: boolean) {}
}

export class SetCreditCardDocument {
  public static readonly type = '[creditCard] Set credit card document';
  constructor(public payload: ICreditCardDocument) {}
}

export class ResetState {
  public static readonly type = '[Credit-card] reset state';
}

export class ResetRegisterState {
  public static readonly type = '[Credit-card] reset register state';
}

export class Reset {
  public static readonly type = '[RESET]';
}

export class SetCreditCardInfo {
  public static readonly type = '[creditCard] Set card info';
  constructor(public payload: ICreditCard) {}
}
