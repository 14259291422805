import { IAcnDynamicComponentManifest } from '@acn/angular';

export const attendanceDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'attendance.completed',
    loadChildren: () =>
      import(
        './attendance/components/attendance-modal-completed/attendance-modal-completed.module'
      ).then((m) => m.AttendanceModalCompletedModule),
  },
  {
    path: 'attendance.notCommercial',
    loadChildren: () =>
      import(
        './attendance/components/attendance-modal-not-commercial/attendance-modal-not-commercial.module'
      ).then((m) => m.AttendanceModalNotCommercialModule),
  },
];
