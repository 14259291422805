import { IPix } from '@features/duplicate/services/models/pix.model';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  SetOwner,
  SetDocType,
  SetDocument,
  SetCity,
  SetEasyDuplicateDoc,
  SetEasyDuplicateCdc,
  Reset,
  SetSelectedDuplicate,
  SetGroupedDuplicate,
  SetIsGrouped,
  SetPixData,
} from './easy-duplicate.actions';
import { DuplicateDetail, GroupedDuplicate, IDuplicate } from '@features/easy-duplicate';
import { ICity } from '@store/dashboard';

export interface EasyDuplicateStateModel {
  owner: boolean;
  documentType: string;
  document: string;
  city: ICity;
  cdcDuplicate: IDuplicate;
  docDuplicates: Array<IDuplicate>;
  isGrouped: boolean;
  selectedDuplicate: DuplicateDetail;
  groupedDuplicate: GroupedDuplicate;
  pixData: IPix;
}

const INITIAL_STATE: EasyDuplicateStateModel = {
  owner: null,
  documentType: null,
  document: null,
  city: null,
  cdcDuplicate: null,
  docDuplicates: null,
  isGrouped: null,
  selectedDuplicate: null,
  groupedDuplicate: null,
  pixData: null,
};

@State<EasyDuplicateStateModel>({
  name: 'easyDuplicate',
  defaults: INITIAL_STATE,
})
@Injectable()
export class EasyDuplicateState {
  @Selector()
  public static getState(state: EasyDuplicateStateModel) {
    return state;
  }

  @Selector()
  public static getOwner(state: EasyDuplicateStateModel) {
    return state.owner;
  }

  @Selector()
  public static getOwnerDocType(state: EasyDuplicateStateModel) {
    return state.documentType;
  }

  @Selector()
  public static getOwnerDocument(state: EasyDuplicateStateModel) {
    return state.document;
  }

  @Selector()
  public static getCity(state: EasyDuplicateStateModel) {
    return state.city;
  }

  @Selector()
  public static getDuplicate(state: EasyDuplicateStateModel) {
    return state.cdcDuplicate;
  }

  @Selector()
  public static getDuplicates(state: EasyDuplicateStateModel) {
    return state.docDuplicates;
  }

  @Selector()
  public static getIsGrouped(state: EasyDuplicateStateModel) {
    return state.isGrouped;
  }

  @Selector()
  public static getPixData(state: EasyDuplicateStateModel) {
    return state.pixData;
  }

  @Selector()
  public static getSelectedDuplicate(state: EasyDuplicateStateModel) {
    return state.selectedDuplicate;
  }

  @Selector()
  public static getGroupedDuplicate(state: EasyDuplicateStateModel) {
    return state.groupedDuplicate;
  }

  @Action(Reset)
  public reset(ctx: StateContext<EasyDuplicateStateModel>) {
    ctx.setState({ ...INITIAL_STATE });
  }

  @Action(SetOwner)
  public setOwner(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetOwner) {
    const state = ctx.getState();
    ctx.setState({ ...state, owner: payload });
  }

  @Action(SetDocType)
  public setOwnerDocType(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetDocType) {
    const state = ctx.getState();
    ctx.setState({ ...state, documentType: payload });
  }

  @Action(SetDocument)
  public setOwnerDocument(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetDocument) {
    const state = ctx.getState();
    ctx.setState({ ...state, document: payload });
  }

  @Action(SetCity)
  public setCity(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetCity) {
    const state = ctx.getState();
    ctx.setState({ ...state, city: payload });
  }

  @Action(SetEasyDuplicateDoc)
  public setEasyDuplicateDocState(
    ctx: StateContext<EasyDuplicateStateModel>,
    { payload }: SetEasyDuplicateDoc,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, docDuplicates: payload });
  }

  @Action(SetEasyDuplicateCdc)
  public setEasyDuplicateCdcState(
    ctx: StateContext<EasyDuplicateStateModel>,
    { payload }: SetEasyDuplicateCdc,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdcDuplicate: payload });
  }

  @Action(SetIsGrouped)
  public setSelection(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetIsGrouped) {
    const state = ctx.getState();
    ctx.setState({ ...state, isGrouped: payload });
  }

  @Action(SetSelectedDuplicate)
  public setSelectedDuplicate(
    ctx: StateContext<EasyDuplicateStateModel>,
    { payload }: SetSelectedDuplicate,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedDuplicate: payload });
  }

  @Action(SetGroupedDuplicate)
  public setGroupedDuplicate(
    ctx: StateContext<EasyDuplicateStateModel>,
    { payload }: SetGroupedDuplicate,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, groupedDuplicate: payload });
  }

  @Action(SetPixData)
  public setPixData(ctx: StateContext<EasyDuplicateStateModel>, { payload }: SetPixData) {
    const state = ctx.getState();
    ctx.setState({ ...state, pixData: payload });
  }
}
