import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ICreditCard, ICreditCardDocument } from './models';
import { CreditCardState } from './credit-card.state';
import { SetResetDate, SetType } from './credit-card.actions';
import {
  SetName,
  SetNumber,
  SetDate,
  SetBrand,
  SetCode,
  ResetState,
  SetCreditCardDateError,
  SetCreditCardFlip,
  SetCreditCardInfo,
  ResetRegisterState,
  SetCreditCardDocument,
} from './credit-card.actions';

@Injectable({
  providedIn: 'root',
})
export class CreditCardStoreService {
  @Select(CreditCardState.getCreditRegister)
  public creditCard$: Observable<ICreditCard>;

  @Select(CreditCardState.getCardInfo)
  public cardInfo$: Observable<ICreditCard>;

  @Select(CreditCardState.getCreditCardDocument)
  public cardDocument$: Observable<ICreditCardDocument>;

  @Select(CreditCardState.getType)
  public cardType$: Observable<string>;

  @Select(CreditCardState.getResetDate)
  public resetDate$: Observable<boolean>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setCardNumber(number: string) {
    return new SetNumber(number);
  }

  @Dispatch()
  public setCardName(name: string) {
    return new SetName(name.toUpperCase() || null);
  }

  @Dispatch()
  public setCardBrand(brand: number) {
    return new SetBrand(brand);
  }

  @Dispatch()
  public setType(type: 'automatico' | 'a vista') {
    return new SetType(type);
  }

  @Dispatch()
  public setResetDate(type: boolean) {
    return new SetResetDate(type);
  }

  @Dispatch()
  public setCardDate(date: string) {
    return new SetDate(date);
  }

  @Dispatch()
  public setCardDateError(error: boolean) {
    return new SetCreditCardDateError(error);
  }

  @Dispatch()
  public setCardFlip(flip: boolean) {
    return new SetCreditCardFlip(flip);
  }

  @Dispatch()
  public setCreditCardDocument(document: ICreditCardDocument) {
    return new SetCreditCardDocument(document);
  }

  @Dispatch()
  public setCardCode(code: string) {
    return new SetCode(code);
  }

  @Dispatch()
  public setCardInfo(cardInfo: ICreditCard) {
    return new SetCreditCardInfo(cardInfo);
  }

  @Dispatch()
  public resetCreditCard() {
    return new ResetState();
  }

  @Dispatch()
  public resetRegisterCreditCard() {
    return new ResetRegisterState();
  }
}
