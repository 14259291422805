<div *ngIf="useBarcode != null">
  <div *ngIf="useBarcode && barcode">
    <div class="item-content-bar-code">
      <p class="is-size-14 is-bold">{{ barcode }}</p>
    </div>

    <div
      class="item-content-copy-bar"
      acnGtm
      gtmCategory="Botao"
      gtmAction="Copiar_Boleto"
      (click)="toggleText()"
      [cdkCopyToClipboard]="barcode"
      ht-clickable
    >
      <acn-icon class="copy-icon" icon="fa clone-regular"></acn-icon>
      <p
        [innerHTML]="copied ? 'Copiado' : 'Copiar'"
        class="is-size-14 is-bold copy-line"
        data-cy="barcode"
      ></p>
    </div>
  </div>

  <div class="pix-qrcode-container" *ngIf="!useBarcode">
    <app-pix-qrcode
      [idBilling]="idBilling"
      [idCity]="idCity"
      [billingType]="billingType"
    ></app-pix-qrcode>
  </div>
</div>
