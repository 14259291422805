<ng-container *transloco="let text">
  <div class="campaigns container">
    <div class="blog__text">
      <div class="content">
        <p class="campaign__header">
          {{ text('landing.home.campaings.blog.header') }}
        </p>

        <p
          class="campaign__title is-bold"
          [innerHTML]="text('landing.home.campaings.blog.title')"
        ></p>

        <p class="campaign__content">
          {{ text('landing.home.campaings.blog.content') }}
        </p>
      </div>

      <div class="link">
        <acn-split></acn-split>
        <a
          href="https://blog.brkambiental.com.br/"
          target="_blank"
          rel="noopener"
          class="link is-bold"
          acnGtm
          gtmCategory="Botao"
          gtmAction="Blog"
          [gtmLabel]="text('landing.home.campaings.blog.link')"
        >
          {{ text('landing.home.campaings.blog.link') }}
        </a>
      </div>
    </div>

    <div class="blog__image" [style.backgroundImage]="backgroundImageKid"></div>

    <div class="study__text">
      <div class="content">
        <p class="campaign__header">
          {{ text('landing.home.campaings.study.header') }}
        </p>
        <p
          class="campaign__title is-bold"
          [innerHTML]="text('landing.home.campaings.study.title')"
        ></p>
        <p class="campaign__content" [innerHTML]="text('landing.home.campaings.study.content')"></p>
      </div>
      <div class="link">
        <acn-split></acn-split>
        <a
          class="link is-bold"
          href="https://mulheresesaneamento.com"
          target="_blank"
          rel="noopener"
          acnGtm
          gtmCategory="Botao"
          gtmAction="Estudo"
          [gtmLabel]="text('landing.home.campaings.study.link')"
          >{{ text('landing.home.campaings.study.link') }}</a
        >
      </div>
    </div>

    <div class="study__image" [style.backgroundImage]="backgroundImageGirl"></div>
  </div>
</ng-container>
