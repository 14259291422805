import { BaseComponent } from '@acn/angular';
import { Component, Inject } from '@angular/core';
import { WINDOW } from '@shared/services';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent extends BaseComponent {
  constructor (@Inject(WINDOW) private readonly window: Window) {
    super();
  }

  back () {
    this.window.history.back();
  }
}
