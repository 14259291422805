import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CalendarMeteringState } from './calendar-metering.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ICDCData } from './models/cdc-data.model';
import { SetCdcData } from './calendar-metering.actions';
@Injectable({
  providedIn: 'root',
})
export class CalendarMeteringStoreService {
  constructor (private _store: Store) {}

  getCdcData () {
    return this._store.selectSnapshot(CalendarMeteringState.getCdcData);
  }

  @Dispatch()
  public setCdcData (cdcData: ICDCData) {
    return new SetCdcData(cdcData);
  }
}
