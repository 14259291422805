<div class="registered">
  <div class="cards">
    <app-registered-card
      *ngIf="isCreditCard"
      [baseImgUrl]="baseImgUrl"
      [creditCard]="itemCreditCard"
    ></app-registered-card>

    <app-automatic-debit
      *ngIf="isDebitCard && !isCreditCard"
      [baseImgUrl]="baseImgUrl"
      [billingDetail]="billingDetail"
    ></app-automatic-debit>
  </div>
</div>
