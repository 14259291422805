<router-outlet (activate)="scrollTop()"></router-outlet>

<app-modal *ngIf="isBrowser"></app-modal>

<acn-notification
  class="notifications"
  *ngIf="notifications$ | async as notifications"
  [notifications]="notifications"
  (notificationRemoved)="closeNotification($event.detail)"
  [offset]="notificationOffset$ | async"
></acn-notification>
<acn-loading class="loading animated fadeIn black" [class.show]="isLoading$ | async">
  {{ 'wait' | transloco }}
</acn-loading>

<app-webchat *ngIf="isBrowser"></app-webchat>
<app-hand-talk *ngIf="isBrowser"></app-hand-talk>
