import { Component, OnInit, HostBinding } from '@angular/core';

import { SettingsStoreService } from '@store/app';
import { AcnConfigurationService, BaseComponent } from '@acn/angular';
import { takeUntil } from 'rxjs/operators';

/**
 * Componente responsável pelo gerenciamento da página Page Not Found da aplicação
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent extends BaseComponent implements OnInit {
  public onDashboard = false;
  public baseImgUrl: string;
  @HostBinding('style.background-image') background: string;

  constructor(
    private _settingsStore: SettingsStoreService,
    private _settings: AcnConfigurationService,
  ) {
    super();
  }

  /**
   * Inicialização do Componente
   */
  ngOnInit(): void {
    this.onDashboard = this._settingsStore.onDashboard();
    this.baseImgUrl = this._settings.settings.baseImgUrl;

    this._settingsStore.onMobile$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      const img = data ? 'bg_error_mobile.jpg' : 'bg_error_desk.jpg';
      this.background = `url(${this.baseImgUrl}/img/${img})`;
    });
  }
}
