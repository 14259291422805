import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, map } from 'rxjs/operators';

import { IRequest } from './models/request.model';
import { IConfiguration } from '../configuration/models/configuration.model';
import { AcnLoaderService } from './acn-loader.service';
import { IRequestOptions } from './models/request-options.model';
import { AcnConfigurationService } from '../configuration/acn-configuration.service';
import { AcnAuthenticationService } from '../../security/authentication/acn-authentication.service';

/**
 * Serviço responsável por gerenciar as funções com connector
 */
@Injectable({
  providedIn: 'root',
})
export class AcnConnectorService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private loaderService: AcnLoaderService,
    private _acnSettings: AcnConfigurationService,
    private _authenticationService: AcnAuthenticationService,
    private _router: Router,
  ) {}

  private get settings(): IConfiguration {
    return this._acnSettings.settings;
  }

  /**
   * Acn Connector Request
   *
   * @return an `Observable`
   */
  request(
    name: string,
    options?: {
      body?: any;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
    },
  ): Observable<object> {
    const operation = Object.assign(
      {
        method: 'GET',
      },
      this.settings.apiConfigs[name],
    );
    const url = `${this.settings.baseUrl}/api/${operation.path}`;

    return this.http.request<Observable<any>>(operation.method, url, options);
  }

  post<T>(request: IRequest): Observable<T> {
    this._removeNullParams(request.body);

    if (request.showLoading) {
      this.loaderService.show();
    }

    return this._checkTokenValidity(request.checkToken).pipe(
      switchMap(() => {
        return this.http.post<T>(
          `${this.settings.baseUrl}/api/${request.endPoint}`,
          request.body,
          this._setOptions(request),
        );
      }),
      finalize(() => this._hideLoanding(request)),
    );
  }

  get<T>(request: IRequest): Observable<T> {
    if (request.showLoading) {
      this.loaderService.show();
    }

    return this._checkTokenValidity(request.checkToken).pipe(
      switchMap(() => {
        return this.http.get<T>(
          `${this.settings.baseUrl}/api/${request.endPoint}`,
          this._setOptions(request),
        );
      }),
      finalize(() => this._hideLoanding(request)),
    );
  }

  put<T>(request: IRequest): Observable<T> {
    this._removeNullParams(request.body);

    if (request.showLoading) {
      this.loaderService.show();
    }

    return this._checkTokenValidity(request.checkToken).pipe(
      switchMap(() => {
        return this.http.put<T>(
          `${this.settings.baseUrl}/api/${request.endPoint}`,
          request.body,
          this._setOptions(request),
        );
      }),
      finalize(() => this._hideLoanding(request)),
    );
  }

  delete<T>(request: IRequest): Observable<T> {
    if (request.showLoading) {
      this.loaderService.show();
    }

    return this._checkTokenValidity(request.checkToken).pipe(
      switchMap(() => {
        return this.http.delete<T>(
          `${this.settings.baseUrl}/api/${request.endPoint}`,
          this._setOptions(request),
        );
      }),
      finalize(() => this._hideLoanding(request)),
    );
  }

  private _setOptions(request: IRequest) {
    const options: IRequestOptions = {};

    if (request.hasOwnProperty('fullResponse')) {
      options.observe = 'response';
    }

    if (request.hasOwnProperty('queryString')) {
      this._removeNullParams(request.queryString);
      options.params = {
        ...request.queryString,
      };
    }

    if (request.hasOwnProperty('withCredentials')) {
      options.withCredentials = request.withCredentials;
    }

    return options;
  }

  private _removeNullParams(obj: any) {
    for (const prop in obj) {
      if (obj[prop] && obj[prop].length <= 0) {
        delete obj[prop];
      }
    }
  }

  private _checkTokenValidity(checkToken = true) {
    if (!checkToken) {
      return of(true);
    }

    return this._authenticationService.isLoggedIn().pipe(
      switchMap((isLogged) => {
        if (!isLogged) {
          return this._authenticationService.getNewToken();
        }

        return of(isLogged);
      }),
      map((isLogged) => {
        if (!isLogged) {
          this._router.navigate([this._acnSettings.settings.routerLogin]);
          throw new Error('Sua sessão expirou, é necessário fazer o login novamente');
        }

        return isLogged;
      }),
    );
  }

  private _hideLoanding(request: IRequest): void {
    if (request?.showLoading) {
      this.loaderService.hide();
    }
  }
}
