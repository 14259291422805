import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AcnScreenService } from '@acn/angular';
import { SettingsStoreService } from '@store/app/states/settings/settings-store.service';
import { IModal, ModalStoreService, NotificationService } from '@store/app';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  constructor(
    private _acnScreenService: AcnScreenService,
    private _settingsStore: SettingsStoreService,
    private _notificationService: NotificationService,
    private _modalStore: ModalStoreService,
  ) {}

  private readonly _scrollTopDashboard$ = new Subject<void>();
  readonly scrollTopDashboard$ = this._scrollTopDashboard$.asObservable();

  isOnMobile() {
    return this._acnScreenService.screenSize$.pipe(
      map((screenSizes) => {
        const onMobile = screenSizes.width <= 1023;
        this._settingsStore.setOnMobile(onMobile);
        return onMobile;
      }),
    );
  }

  scrollTop(elementRef: ElementRef) {
    elementRef.nativeElement.scrollTop = 0;
  }

  setScrollWidth(elementRef: ElementRef) {
    const boundaries = elementRef && elementRef.nativeElement.getBoundingClientRect();
    const width = window.innerWidth - boundaries.width;
    document.documentElement.style.setProperty('--scrollbar-width', `${width}px`);
  }

  setNotification(type: string, title: string, content: string) {
    this._notificationService.add({
      class: type,
      title,
      content,
    });
  }

  showModal(modal: IModal) {
    this._modalStore.showModal(modal);
  }

  hideModal() {
    this._modalStore.hideModal();
  }

  scrollTopDashboard(): void {
    this._scrollTopDashboard$.next();
  }
}
