import { State, Action, Selector, StateContext } from '@ngxs/store';
import { ResetReportStore, SetFiles } from './report.actions';

import { IReportProblemFile } from '@features/report/models/report-problem.model';
import { Injectable } from '@angular/core';

export interface ReportStateModel {
  files: Array<IReportProblemFile>;
}

const INITIAL_STATE = {
  files: [],
};

@State<ReportStateModel>({
  name: 'report',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ReportState {
  @Selector()
  public static getState(state: ReportStateModel) {
    return state;
  }

  @Selector()
  public static getFiles(state: ReportStateModel) {
    return state.files;
  }

  @Action([ResetReportStore])
  public reset(ctx: StateContext<ReportStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }

  @Action(SetFiles)
  public add(ctx: StateContext<ReportStateModel>, { payload }: SetFiles) {
    const state = ctx.getState();
    ctx.setState({ ...state, files: payload });
  }
}
