import { ElementRef, Injectable, Injector } from '@angular/core';
import { switchMap, catchError } from 'rxjs/operators';
import { of, zip } from 'rxjs';

import { AcnConnectorService, IRequest } from '@acn/angular';
import { UserStoreService } from '@store/app';
import { RouterService } from '@store/router/router.service';
import { DuplicateService } from '@features/duplicate/services/duplicate.service';

import {
  ConsumptionStoreService,
  InstallationStoreService,
  CitiesStoreService,
  IConsumptionMonth,
  BillingStoreService,
} from '@store/dashboard';
import { BaseService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public selectedInstallation$ = this._installationStore.selectedInstallationId$;
  public installation$ = this._installationStore.selectedInstallation$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public user$ = this._userStore.user$;
  public consumptionVariation$ = this._consumptionStore.consumptionVariation$;
  public consumptionHistory$ = this._consumptionStore.consumptionHistory$;
  public loadedReconnection$ = this._billingStoreService.loadedReconnection$;
  public reconnection$ = this._billingStoreService.reconnection$;

  private readonly INSTALLATION_CONSUMPTION_URL = 'usuario/consumo';

  constructor(
    private _billingStoreService: BillingStoreService,
    private _connectorApi: AcnConnectorService,
    private _consumptionStore: ConsumptionStoreService,
    private _installationStore: InstallationStoreService,
    private _citiesStore: CitiesStoreService,
    private _routerService: RouterService,
    private _duplicateService: DuplicateService,
    private _userStore: UserStoreService,
    protected _injector: Injector,
  ) {
    super(_injector);
  }

  setShowDebts(status: boolean) {
    this._billingStoreService.showDebits(status);
  }

  getConsumptionHistory(rangeOfMonths: number = 12) {
    return zip(this._consumptionStore.consumptionHistory$, this.installation$).pipe(
      switchMap(([history, installation]) => {
        if (history.length) {
          return of({ state: true, history });
        }

        const req: IRequest = {
          endPoint: this.INSTALLATION_CONSUMPTION_URL,
          showLoading: true,
          queryString: {
            nrCDC: installation.cdc,
            idCidade: installation.idCidade,
            nrMeses: rangeOfMonths,
          },
        };

        return this._connectorApi.get<Array<IConsumptionMonth>>(req);
      }),
      switchMap((res) => {
        if (!res.hasOwnProperty('state')) {
          const response = res as Array<IConsumptionMonth>;
          this._consumptionStore.setConsumptionHistory(response.reverse());
          return of(res);
        }

        const { history } = res as { state: true; history: Array<IConsumptionMonth> };
        return of(history);
      }),
      catchError((e) => {
        return of([]);
      }),
    );
  }

  onConsumption() {
    return this._routerService.getCurrentUrl().includes('consumo');
  }

  getInvoiceDetail(invoiceId: number) {
    return this._duplicateService.getInvoiceDetail(invoiceId);
  }

  resetInvoice() {
    return this._duplicateService.setCurrentInvoice(null);
  }

  scrollTop(elementRef: ElementRef) {
    this._screenService.scrollTop(elementRef);
  }

  resetState() {
    this._consumptionStore.resetState();
  }
}
