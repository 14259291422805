import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AcnChannelService } from './acn-channel.service';
import { BaseComponent } from '../../shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'acn-channel',
  templateUrl: './acn-channel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcnChannelComponent extends BaseComponent implements OnInit {
  private currentChannel: string;
  private channelSettings;

  @Input() descriptionKey: string;

  public validChannel: boolean;

  /** @ignore */
  constructor(private cdr: ChangeDetectorRef, private channelService: AcnChannelService) {
    super();
  }

  ngOnInit() {
    this.channelSettings = this.channelService.getChannelSettings();

    this.channelService
      .getChannel()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.currentChannel = data;

        if (this._validateChannel() && this._validateVisibility()) {
          this.validChannel = true;
          this.cdr.detectChanges();
        }
      });
  }

  private _validateChannel(): boolean {
    return this.channelSettings.hasOwnProperty(this.currentChannel);
  }

  private _validateVisibility(): boolean {
    return this.channelSettings[this.currentChannel]['visibility'][this.descriptionKey];
  }
}
