import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AcnChannelService } from './acn-channel.service';

/**
 * Serviço responsável pelo tratamento de Login
 */
@Injectable({
  providedIn: 'root',
})
export class AcnChannelResolver {
  constructor(private channelService: AcnChannelService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const channel = route.data.channel;
    this.channelService.setChannel(channel);
    return of(channel);
  }
}
