<div
  class="city"
  *ngIf="isOnMobile && selectedCity"
  routerLink="/selecao-de-cidade"
  acnGtm
  [gtmCategory]="gtmCategory"
  gtmAction="Escolher_Cidade"
>
  <p class="is-size-14">{{ 'landing.home.hero.yourCity' | transloco }}</p>
  <p class="city__name is-bold is-size-20">
    <acn-icon icon="fa-map-marker-alt"></acn-icon>
    {{ selectedCity?.nome }} - {{ selectedCity?.uf }}
  </p>
</div>

<div class="text is-medium">
  <ng-container *ngIf="selectedCity">
    <h1
      class="text-header"
      [innerHTML]="'landing.home.hero.title' | transloco: { break: break }"
    ></h1>
    <p
      class="text-line is-medium"
      [innerHTML]="'landing.home.hero.subtitle' | transloco"
      *ngIf="!isOnMobile"
    ></p>
  </ng-container>
  <ng-container *ngIf="!selectedCity">
    <h1 class="text-header" [innerHTML]="'landing.home.hero.titleNoCity' | transloco"></h1>
  </ng-container>
</div>

<div class="image">
  <img
    (click)="goToLink()"
    [src]="
      isOnMobile
        ? urlBannerMobile
        : baseImgUrl + '/img/home/landing-banner-desktop.png'
    "
    alt="Landing banner"
    loading="lazy"
  />
</div>

<div class="text is-medium" *ngIf="isOnMobile && selectedCity">
  <ng-container>
    <p class="text-line is-medium" [innerHTML]="'landing.home.hero.subtitle' | transloco"></p>
  </ng-container>
</div>
<div class="buttons">
  <acn-button
    *ngIf="selectedCity"
    class="button is-white is-fullWidth"
    routerLink="/home/servicos"
    acnGtm
    [gtmCategory]="gtmCategory"
    gtmAction="{{ 'landing.home.hero.accessServices' | transloco }}"
  >
    {{ 'landing.home.hero.accessServices' | transloco }}
  </acn-button>

  <acn-button
    *ngIf="selectedCity"
    class="button is-secondary--white is-fullWidth"
    routerLink="/login"
    acnGtm
    [gtmCategory]="gtmCategory"
    gtmAction="{{ 'landing.home.hero.accessLogin' | transloco }}"
  >
    {{ 'landing.home.hero.accessLogin' | transloco }}
  </acn-button>

  <ng-container *ngIf="!selectedCity">
    <acn-button
      class="button is-white is-fullWidth"
      routerLink="/selecao-de-cidade"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Escolher_Cidade"
    >
      {{ 'landing.home.services.chooseMyCity' | transloco }}
    </acn-button>
    <a
      class="link"
      target="_blank"
      rel="noopener"
      href="https://www.brkambiental.com.br/quem-somos"
      acnGtm
      [gtmCategory]="gtmCategory"
      gtmAction="Site_Institucional"
    >
      <acn-button class="button is-secondary--white is-fullWidth">
        {{ 'landing.home.hero.accessInstitutional' | transloco }}
      </acn-button>
    </a>
  </ng-container>
</div>
