import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AutomaticDebitServiceState, AutomaticDebitServiceStateModel } from './automatic-debit-service.state';
import {
  AddBillingType,
  AddBanksList,
  AddSelectedBank,
  AddReplaceAutomaticDebit,
  AddBankData,
  Reset,
  ResetBanks,
  ResetSelectedBank,
  AddNoticeType,
  ResetReplaceAutomaticDebit,
  ResetBillingType,
  AddIsAutomaticDebitNewFlow
 } from './automatic-debit-service.actions';
import { IBank } from '@features/services/models/bank.model';
import { IBillingType } from '@features/account/models/billing-type.model';
import { IBankData } from './models/bankData.model';


@Injectable({
  providedIn: 'root',
})
export class AutomaticDebitServiceStoreService {
  @Select(AutomaticDebitServiceState.getState)
  public automaticDebitServiceState$: Observable<AutomaticDebitServiceStateModel>;

  @Select(AutomaticDebitServiceState.getSelectedBank)
  public selectedBank$: Observable<IBank>;

  @Select(AutomaticDebitServiceState.getBankData)
  public bankData$: Observable<IBankData>;

  @Select(AutomaticDebitServiceState.getBanks)
  public banks$: Observable<Array<IBank>>;

  @Select(AutomaticDebitServiceState.getBillingType)
  public billingType$: Observable<IBillingType>;

  @Select(AutomaticDebitServiceState.getReplaceAutomaticDebit)
  public replaceAutomaticDebit$: Observable<boolean>;

  constructor(private _store: Store) {}

  public getBanks() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getBanks);
  }

  public getBankData() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getBankData);
  }

  public getSelectedBank() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getSelectedBank);
  }

  public getBillingType() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getBillingType);
  }

  public getNoticeType() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getNoticeType);
  }

  public getReplaceAutomaticDebit() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getReplaceAutomaticDebit);
  }

  public getIsAutomaticDebitNewFlow() {
    return this._store.selectSnapshot(AutomaticDebitServiceState.getIsAutomaticDebitNewFlow);
  }

  @Dispatch()
  public resetState() {
    return new Reset();
  }

  @Dispatch()
  public resetSelectedBank() {
    return new ResetSelectedBank();
  }

  @Dispatch()
  public resetReplaceAutomaticDebit() {
    return new ResetReplaceAutomaticDebit();
  }

  @Dispatch()
  public resetBanks() {
    return new ResetBanks();
  }

  @Dispatch()
  public resetBillingType() {
    return new ResetBillingType();
  }

  @Dispatch()
  public setSelectedBank(bank: IBank) {
    return new AddSelectedBank(bank);
  }

  @Dispatch()
  public setBankData(bankData: IBankData) {
    return new AddBankData(bankData);
  }

  @Dispatch()
  public setBanks(banks: Array<IBank>) {
    return new AddBanksList(banks);
  }

  @Dispatch()
  public setReplaceAutomaticDebit(replace: boolean) {
    return new AddReplaceAutomaticDebit(replace);
  }

  @Dispatch()
  public setBillingType(billingType: IBillingType) {
    return new AddBillingType(billingType);
  }

  @Dispatch()
  public setNoticeType(noticeType: number) {
    return new AddNoticeType(noticeType);
  }

  @Dispatch()
  public setIsAutomaticDebitNewFlow(isNewFlow: boolean) {
    return new AddIsAutomaticDebitNewFlow(isNewFlow);
  }
}
