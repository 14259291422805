import { IConsumptionMonth } from './models/index';

export class SetConsumptionHistory {
  public static readonly type = '[Consumption] Set consumption history';
  constructor(public payload: Array<IConsumptionMonth>) {}
}

export class ResetState {
  public static readonly type = '[Consumption] reset state';
}

export class Reset {
  public static readonly type = '[RESET]';
}
