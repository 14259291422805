import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { HandTalkService } from '@features/hand-talk/services/hand-talk.service';
import { AcnConfigurationService, BaseComponent, GtmService } from '@acn/angular';
import { fromEvent } from 'rxjs';
import { WINDOW } from '@shared/services';
import { DOCUMENT } from '@angular/common';
import { HandTalk } from '@features/hand-talk/models/hand-talk';
import { takeUntil } from 'rxjs/operators';
import { HandTalkOptions } from '@features/hand-talk/models/hand-talk-options';

@Component({
  selector: 'app-hand-talk',
  template: '',
})
export class HandTalkComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly handTalkService: HandTalkService,
    private readonly acnConfigurationService: AcnConfigurationService,
    private readonly renderer2: Renderer2,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly gtmService: GtmService,
  ) {
    super();
  }

  private _handTalk: HandTalk | null = null;
  private _handTalkConfig: HandTalkOptions | null = null;
  private _isScriptAlreadyCreated = false;
  private _pageSpeechAlreadyListening = false;
  private _observerPageSpeech = new MutationObserver(() => {
    this._addGTMPageSpeechListener();
  });

  private _createScript(): void {
    if (this._isScriptAlreadyCreated) {
      return;
    }
    const { handTalkUrl } = this.acnConfigurationService.settings;
    const script = this.renderer2.createElement('script');
    this.renderer2.setProperty(script, 'src', handTalkUrl);
    this.renderer2.setProperty(script, 'async', true);
    this.renderer2.setProperty(script, 'id', 'script-hand-talk');
    fromEvent(script, 'load').subscribe(() => {
      this._afterScriptLoaded();
    });
    this.renderer2.appendChild(this.document.body, script);
    this._isScriptAlreadyCreated = true;
  }

  /**
   * @description Verifica se o leitor de sites está ativo.
   * Não tem problema acessar o "sessionStorage" aqui, pois
   * esse componente só é ativo se estiver em um browser. {@link AppComponent} template
   * @private
   */
  private _isPageReaderActivated(): boolean {
    return this.window.sessionStorage.getItem('HTPageReaderActivated') === 'true';
  }

  /**
   * @description O plugin do HandTalk não oferece nenhuma API para escutar
   * os eventos do leitor de sites, portanto tivemos que buscar o botão
   * e escutar um evento de clique para disparar os eventos do GTM
   * @private
   */
  private _addGTMPageSpeechListener(): void {
    // Se já foi adicionado o event listener ou o leitor de sites não está ativo, não faz nada
    if (this._pageSpeechAlreadyListening || !this._handTalk || !this._handTalkConfig?.pageSpeech) {
      return;
    }
    // Regex para buscar o botão do leitor
    const buttonIconAriaLabelRegex = /Ativar.*Leitor/i;
    // Busca todos os botões do hand talk
    const buttons = this.elementRef.nativeElement.querySelectorAll('button');
    // Busca o botão do leitor
    const buttonPageSpeech = Array.from(buttons).find((button) => {
      const ariaLabel = button.getAttribute('aria-label') ?? '';
      return buttonIconAriaLabelRegex.test(ariaLabel);
    });
    // Se não encontrar o botão, ele pode não ter sido carregado ainda
    // Ou não está ativado para o site
    if (!buttonPageSpeech) {
      return;
    }
    // Adicionar event listener para click e dispara os eventos do GTM
    fromEvent(buttonPageSpeech, 'click')
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        const activated = this._isPageReaderActivated();
        const action = activated ? 'Abrir_HandTalk_Leitor' : 'Fechar_HandTalk_Leitor';
        this.gtmService.setCustomEvent('Botao', action, 'HandTalk');
      });
    // Seta a flag para não entrar mais nesse método
    this._pageSpeechAlreadyListening = true;
    // Desconecta o MutationObserver
    this._observerPageSpeech.disconnect();
  }

  /**
   * @description Usa os eventos do plugin hand talk para mandar os
   * eventos para o GTM
   * @private
   */
  private _addGTMLibrasListeners(): void {
    if (!this._handTalk) {
      return;
    }
    // Optional chaining necessário, pois o plugin é externo
    // e não tem como saber se esse método será removido no futuro
    this._handTalk.on?.('activated', () => {
      this.gtmService.setCustomEvent('Botao', 'Abrir_HandTalk_Libras', 'HandTalk');
    });
    this._handTalk.on?.('deactivated', () => {
      this.gtmService.setCustomEvent('Botao', 'Fechar_HandTalk_Libras', 'HandTalk');
    });
  }

  private _afterScriptLoaded(): void {
    const {
      handTalkToken,
      handTalkAvatar,
      handTalkAlign,
      handTalkSide,
    } = this.acnConfigurationService.settings;
    this._handTalkConfig = this.handTalkService.validateOptions({
      side: handTalkSide,
      avatar: handTalkAvatar,
      align: handTalkAlign,
      token: handTalkToken,
      maxTextSize: 500,
      clickables: [
        '[routerLink]',
        '[ht-clickable]',
        '.ht-clickable',
        'acn-form-check-box',
        'acn-form-radio-button',
        'acn-form-toggle-button',
        'acn-form-toggle',
        'acn-image-upload',
        'input[type=radio]',
        'acn-card-selection',
      ],
      exceptions: ['[ht-skip]'],
      pageSpeech: true,
      parentElement: this.elementRef.nativeElement,
      mobileConfig: {
        opacity: 0,
      },
    });
    this._handTalk = new this.window.HT(this._handTalkConfig);
    this._addGTMPageSpeechListener();
    this._addGTMLibrasListeners();
    if (this._handTalkConfig.pageSpeech) {
      // Só escuta as mudanças do elemento se o leitor de sites estiver ativo
      this._observerPageSpeech.observe(this.elementRef.nativeElement, {
        subtree: true,
        childList: true,
      });
    }
  }

  ngAfterViewInit(): void {
    this._createScript();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._handTalk?.destroy?.();
    this._observerPageSpeech.disconnect();
  }
}
