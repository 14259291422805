export enum statusCode {
  continue = 100,
  ok = 200,
  created = 201,
  accepted = 202,
  badRequest = 400,
  unauthorized = 401,
  paymentRequired = 402,
  forbidden = 403,
  notFound = 404,
  preconditionFailed = 412,
  internalServerError = 500,
}
