import { Injectable, Injector } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AcnConnectorService, IRequest, normalize } from '@acn/angular';
import { CitiesStoreService, ICity, IInfoCity, INewCity } from '@store/dashboard';
import { BaseService } from '@shared/services';
import { RedirectionStoreService } from '@store/app';
import { IBreakingNews } from '@features/attendance/models/breaking-news.model';
import { ICityServicesList } from '@features/services/models/city-services-list.model';

@Injectable({
  providedIn: 'root',
})
export class SelectCityService extends BaseService {
  public selectedCity$ = this._citiesStore.selectedCity$;
  public selectedCityServices$ = this._citiesStore.selectedCityServices$;
  public breakingNewsNotice$ = this._citiesStore.breakingNewsNotice$;

  private readonly CITIES_URL = 'cidades';
  private readonly INFO_CITIES_URL = 'infoCidades/v2';
  private readonly BREAKING_NEWS_URL = 'breakingNews/breakingNews';
  private readonly SERVICES_CITY_URL = 'servicos/servicosCidade';

  constructor (
    private _citiesStore: CitiesStoreService,
    private _connectorApi: AcnConnectorService,
    protected _injector: Injector,
    private _redirectionStore: RedirectionStoreService,
  ) {
    super(_injector);
  }

  getCity (): Observable<ICity[]> {
    return this.getCityList().pipe(
      switchMap((cities) => {
        const newList = [...cities].sort(function (a, b) {
          if (a.nome > b.nome) {
            return 1;
          }
          if (a.nome < b.nome) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        return of(newList);
      }),
    );
  }

  getCityServicesData (idCity: number): Observable<ICityServicesList> {
    const req: IRequest = {
      endPoint: this.SERVICES_CITY_URL,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<ICityServicesList>(req);
  }

  getCityServices (idCity: number): Observable<ICityServicesList> {
    return this.getCityServicesData(idCity).pipe(
      tap((res) => {
        this.changeSelectedCityServices(res);
      }),
    );
  }

  getInfoCities (idCity: string | number, idUnit: string | number): Observable<IInfoCity> {
    const req: IRequest = {
      endPoint: this.INFO_CITIES_URL,
      checkToken: false,
      showLoading: false,
      queryString: { idCity: idCity, idUnit: idUnit },
    };

    return this._connectorApi.get<IInfoCity>(req);
  }
  selectCity (city: string | number) {
    const [initial] =
      typeof city === 'string'
        ? this._citiesStore.findCity(normalize(city).toLowerCase())
        : this._citiesStore.findCityById(city);

    if (!initial) {
      return of(null);
    }

    const cityWithHash = this.mapHash(initial);

    this.getBreakingNews(cityWithHash.id).subscribe();
    return this.setInfoCities(cityWithHash.id, cityWithHash.idUnidade);
  }

  setInfoCities (idCity, idUnit) {
    return this.getInfoCities(idCity, idUnit).pipe(
      map((infoCity) => {
        const uf = infoCity.unitCompanyCityState
          ? infoCity.unitCompanyCityState.split('-')[1].trim()
          : '';
        return {
          id: infoCity.idCity,
          idUnidade: infoCity.idUnit,
          nome: infoCity.cityName,
          uf: uf,
          ufHash: infoCity.unitHash,
          isAttended: infoCity.isAttended,
          phones: {
            telAtendimento: infoCity.configCustomerService.phones.customerService as string,
            telOuvidoria: infoCity.configCustomerService.phones.ombudsman as string,
            telAuditivo: infoCity.configCustomerService.phones.pwd as string,
          },
          hash: '',
          partner: {
            company: infoCity.partner.company,
            url: infoCity.partner.url,
            buttonOrder: infoCity.partner.buttonOrder,
            buttonLabel: infoCity.partner.buttonLabel,
          },
          newsUrl: infoCity.newsUrl,
          chatUrl: infoCity.chatUrl,
          unidCep: infoCity.unitCep,
          unidCidade: infoCity.unitName,
          unidCnpj: infoCity.unitCnpj,
          unidEndereco: infoCity.unitAddress,
          unidEmail: infoCity.unitEmail,
          unidIncricaoEstadual: infoCity.unitStateRegistration,
          unidRazao: infoCity.unitCompanyName,
          unidUf: '',
          horaWhatsApp: infoCity.configCustomerService.whatsapp.hours,
          partnerText: infoCity.partner.company,
          configCustomerService: infoCity.configCustomerService,
          home: infoCity.home,
          taxStructureUrl: infoCity.taxStructureUrl,
          banks: infoCity.banks,
          technicalServicesEmail: infoCity.technicalServicesEmail,
          technicalServicesFeeEmail: infoCity.technicalServicesFeeEmail,
        };
      }),
      tap((city) => {
        if (city.id === 9999) {
          this.setNotSelectedCity(city);
        } else {
          this.changeSelectedCity(city);
        }
      }),
      catchError((error) => of({ status: false, message: error.error })),
    );
  }

  private mapHash (result: ICity) {
    const hash = normalize(result.nome).replace(/ /gi, '-').toLowerCase();
    result = { ...result, hash };
    return result;
  }

  changeSelectedCity (city: INewCity) {
    this._citiesStore.addSelectedCity(city);
  }

  setBreakingNewsIsClosed (hasLoaded: boolean) {
    this._citiesStore.addBreakingNewsIsClosed(hasLoaded);
  }

  changeSelectedCityServices (city: ICityServicesList) {
    this._citiesStore.addSelectedCityServices(city);
  }

  setNotSelectedCity (city: INewCity) {
    this._citiesStore.addNotSelectedCity(city);
  }

  getCityList () {
    return this._citiesStore.citiesList$.pipe(
      switchMap((list) => {
        if (this._citiesStore.getHasCitiesList()) {
          return of({ state: true, list });
        }

        const req: IRequest = {
          endPoint: this.CITIES_URL,
          checkToken: false,
          showLoading: true,
        };

        return this._connectorApi.get<Array<ICity>>(req).pipe(
          switchMap((item) => {
            return of({ state: false, list: item });
          }),
        );
      }),
      switchMap((res) => {
        if (!res.state) {
          this._citiesStore.addCitiesList(res.list);
        }

        return of(res.list);
      }),
    );
  }

  getRedirectTo () {
    return this._redirectionStore.getRedirectedFrom();
  }

  setRedirectTo (url: string) {
    this._redirectionStore.setRedirectedFrom(url);
  }

  setCallback (url: string) {
    this._redirectionStore.setRedirectedFrom(url);
  }

  resetCityList () {
    this._citiesStore.resetCities();
  }

  getSelectedCity () {
    return this._citiesStore.getSelectedCity();
  }

  setBreakingNewsNotice (breakingNewsNotice: IBreakingNews) {
    this._citiesStore.breakingNewsNotice(breakingNewsNotice);
  }

  getBreakingNews (idCidade) {
    const req: IRequest = {
      endPoint: this.BREAKING_NEWS_URL,
      checkToken: false,
      showLoading: true,
      queryString: { idCidade },
    };

    return this._connectorApi.get<IBreakingNews>(req).pipe(
      switchMap((res) => {
        const breakingNewsArray = [];
        if (Object.entries(res).length > 0) {
          breakingNewsArray.push(res);
          this.setBreakingNewsNotice(res);
          this.setBreakingNewsIsClosed(false);
        } else {
          this.setBreakingNewsIsClosed(null);
          this.setBreakingNewsNotice(null);
        }
        return breakingNewsArray;
      }),
      catchError(() => {
        window.sessionStorage.removeItem('closeBreakingNews');
        this.setBreakingNewsNotice(null);
        return of(false);
      }),
    );
  }

  getSelectedCityServices (): ICityServicesList | undefined {
    return this._citiesStore.getSelectedCityServices();
  }

  resetSelectedCityAndServices (): void {
    this._citiesStore.resetCityAndServices();
  }
}
