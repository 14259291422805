import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  Reset,
  ResetRegisterState,
  ResetState,
  SetBrand,
  SetCode,
  SetCreditCardDateError,
  SetCreditCardDocument,
  SetCreditCardFlip,
  SetCreditCardInfo,
  SetDate,
  SetName,
  SetNumber,
  SetResetDate,
  SetType,
} from './credit-card.actions';
import { ICreditCard } from './models';
import { ICreditCardState } from './models/credit-card-state.model';

const INITIAL_REGISTER_STATE = {
  number: null,
  name: null,
  date: null,
  code: null,
  brand: 0,
  dateError: false,
  flipCard: false,
};

const INITIAL_STATE: ICreditCardState = {
  register: INITIAL_REGISTER_STATE,
  info: null,
  document: null,
  type: 'automatico',
  resetDate: false,
};

@State<ICreditCardState>({
  name: 'creditCard',
  defaults: INITIAL_STATE,
})
@Injectable()
export class CreditCardState {
  @Selector()
  public static getState(state: ICreditCardState) {
    return state;
  }
  @Selector()
  public static getType(state: ICreditCardState) {
    return state.type;
  }

  @Selector()
  public static getResetDate(state: ICreditCardState) {
    return state.resetDate;
  }

  @Selector()
  public static getCardInfo(state: ICreditCardState) {
    return state.info;
  }

  @Selector()
  public static getCreditRegister(state: ICreditCardState) {
    return state.register;
  }

  @Selector()
  public static getCreditCardDocument(state: ICreditCardState) {
    return state.document;
  }

  @Action(SetNumber)
  public SetNumber(ctx: StateContext<ICreditCardState>, { payload }: SetNumber) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, number: payload } });
  }

  @Action(SetName)
  public setName(ctx: StateContext<ICreditCardState>, { payload }: SetName) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, name: payload } });
  }

  @Action(SetBrand)
  public setBrand(ctx: StateContext<ICreditCardState>, { payload }: SetBrand) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, brand: payload } });
  }

  @Action(SetDate)
  public setDate(ctx: StateContext<ICreditCardState>, { payload }: SetDate) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, date: payload } });
  }

  @Action(SetCode)
  public setCode(ctx: StateContext<ICreditCardState>, { payload }: SetCode) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, code: payload } });
  }

  @Action(SetCreditCardDateError)
  public setCreditCardDateError(
    ctx: StateContext<ICreditCardState>,
    { payload }: SetCreditCardDateError,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, dateError: payload } });
  }

  @Action(SetCreditCardFlip)
  public setCreditCardFlip(ctx: StateContext<ICreditCardState>, { payload }: SetCreditCardFlip) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...state.register, flipCard: payload } });
  }

  @Action(SetCreditCardInfo)
  public setCreditCardInfo(ctx: StateContext<ICreditCardState>, { payload }: SetCreditCardInfo) {
    const state = ctx.getState();
    ctx.setState({ ...state, info: { ...state.info, ...payload } });
  }

  @Action(SetCreditCardDocument)
  public setCreditCardDocument(
    ctx: StateContext<ICreditCardState>,
    { payload }: SetCreditCardDocument,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, document: payload });
  }

  @Action(SetType)
  public setCreditCardType(ctx: StateContext<ICreditCardState>, { payload }: SetType) {
    const state = ctx.getState();
    ctx.setState({ ...state, type: payload });
  }

  @Action(SetResetDate)
  public SetResetDate(ctx: StateContext<ICreditCardState>, { payload }: SetResetDate) {
    const state = ctx.getState();
    ctx.setState({ ...state, resetDate: payload });
  }

  @Action(ResetRegisterState)
  public resetState(ctx: StateContext<ICreditCardState>) {
    const state = ctx.getState();
    ctx.setState({ ...state, register: { ...INITIAL_REGISTER_STATE } });
  }

  @Action([ResetState, Reset])
  public reset(ctx: StateContext<ICreditCard>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }
}
