<ng-container *ngIf="modal$ | async as modalState">
  <!--
    *ngIf é necessário aqui pois o acn-modal precisa ser renderizado novamente quando
    o canCloseModal é alterado, caos essa renderização não aconteça, o conteúdo do
    modal é renderizado no lugar errado
  -->
  <ng-container *ngIf="modalState.canCloseModal; else modal">
    <ng-container *ngTemplateOutlet="modal; context: { close: true }"></ng-container>
  </ng-container>

  <ng-template #modal let-close="close">
    <acn-modal
      [class.active]="modalState.show"
      [class.modal]="isHalfScreenModal$ | async"
      [close]="!!close"
      (modalClosed)="closeModal()"
    >
      <ng-container
        [cdkPortalOutlet]="modalState.componentPortal"
        (attached)="onModalAttached($event)"
      ></ng-container>
    </acn-modal>
  </ng-template>
</ng-container>
