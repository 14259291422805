import { Compiler, Inject, Injectable, Injector, NgModuleFactory, Type } from '@angular/core';
import { DYNAMIC_COMPONENT } from '../dynamic-component.token';
import { LoadChildrenCallback } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AcnDynamicComponentLoaderService {
  constructor(
    @Inject(DYNAMIC_COMPONENT) private _lazyWidgets: Record<string, LoadChildrenCallback>,
    private readonly compiler: Compiler,
  ) {}

  async getComponentAndModuleType(componentId: string, injector: Injector | null = null) {
    const moduleTypeOrModuleFactory = await this._lazyWidgets[componentId]();
    let module: Type<any>;
    let moduleFactory: NgModuleFactory<any>;
    if (moduleTypeOrModuleFactory instanceof NgModuleFactory) {
      moduleFactory = moduleTypeOrModuleFactory;
      module = moduleFactory.moduleType;
    } else {
      moduleFactory = await this.compiler.compileModuleAsync(moduleTypeOrModuleFactory);
      module = moduleTypeOrModuleFactory;
    }
    const moduleRef = moduleFactory.create(injector);
    const moduleWithEntry: Type<any> & { entry?: Type<any> } = module;
    if (ngDevMode && !moduleWithEntry.entry) {
      console.error(
        `${module.name} está sem a propriedade estatica 'entry', não será possivel abrir o modal`,
      );
    }
    return {
      component: moduleWithEntry.entry,
      module,
      moduleFactory,
      moduleRef,
    };
  }
}
