import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';

import {
  SetCdc,
  SetDocument,
  SetIsCompany,
  SetForm,
  SetProtocolCode,
  ResetChangeHolder,
  SetCdcData,
  SetPropertyDocs,
  SetAcceptedDebts,
  SetPersonalDocs,
  SetCorroborativeDocs,
  SetNecessaryDocumentsInfo,
  SetSuccessMessage,
} from './change-holder.actions';
import { IChangeHolderStateModel } from './models/change-holder-state.model';

const INITIAL_STATE: IChangeHolderStateModel = {
  cdc: null,
  documentNumber: null,
  isCompany: null,
  form: null,
  cdcData: null,
  propertyDocs: null,
  protocolCode: null,
  acceptedDebts: true,
  personalDocs: null,
  corroborativeDocs: [],
  documentsInfo: null,
  successMessage: null
};

@State<IChangeHolderStateModel>({
  name: 'changeHolder',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ChangeHolderState {
  @Selector()
  public static getState(state: IChangeHolderStateModel) {
    return state;
  }

  @Selector()
  public static getCdc(state: IChangeHolderStateModel) {
    return state.cdc;
  }

  @Selector()
  public static getProtocolCode(state: IChangeHolderStateModel) {
    return state.protocolCode;
  }

  @Selector()
  public static getDocument(state: IChangeHolderStateModel) {
    return state.documentNumber;
  }

  @Selector()
  public static getIsCompany(state: IChangeHolderStateModel) {
    return state.isCompany;
  }

  @Selector()
  public static getForm(state: IChangeHolderStateModel) {
    return state.form;
  }

  @Selector()
  public static getPropertyDocs(state: IChangeHolderStateModel) {
    return state.propertyDocs;
  }

  @Selector()
  public static getCdcData(state: IChangeHolderStateModel) {
    return state.cdcData;
  }

  @Selector()
  public static getAcceptedDebts(state: IChangeHolderStateModel) {
    return state.acceptedDebts;
  }

  @Selector()
  public static getPersonalDocuments(state: IChangeHolderStateModel) {
    return state.personalDocs;
  }

  @Selector()
  public static getCorroborativeDocuments (state: IChangeHolderStateModel) {
    return state.corroborativeDocs;
  }

  @Selector()
  public static getDocumentsInfo (state: IChangeHolderStateModel) {
    return state.documentsInfo;
  }

  @Selector()
  public static getSuccessMessage (state: IChangeHolderStateModel) {
    return state.successMessage;
  }

  @Action(ResetChangeHolder)
  public reset(ctx: StateContext<IChangeHolderStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, ...INITIAL_STATE });
  }

  @Action(SetCdc)
  public setCdc(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetCdc) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdc: payload });
  }

  @Action(SetDocument)
  public setDocument(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetDocument) {
    const state = ctx.getState();
    ctx.setState({ ...state, documentNumber: payload });
  }

  @Action(SetIsCompany)
  public setIsCompany(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetIsCompany) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      isCompany: payload,
    });
  }

  @Action(SetPropertyDocs)
  public setPropertyDocs(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetPropertyDocs) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      propertyDocs: payload,
    });
  }

  @Action(SetForm)
  public setForm(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetForm) {
    const state = ctx.getState();
    ctx.setState({ ...state, form: payload });
  }

  @Action(SetCdcData)
  public setCdcData(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetCdcData) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      cdcData: payload,
    });
  }

  @Action(SetProtocolCode)
  public setProtocolCode(ctx: StateContext<IChangeHolderStateModel>, { payload }: SetProtocolCode) {
    const state = ctx.getState();
    ctx.setState({ ...state, protocolCode: payload });
  }

  @Action(SetAcceptedDebts)
  public setAcceptedDebts(
    ctx: StateContext<IChangeHolderStateModel>,
    { payload }: SetAcceptedDebts,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, acceptedDebts: payload });
  }

  @Action(SetPersonalDocs)
  public setPersonalDocs (
    context: StateContext<IChangeHolderStateModel>,
    { payload }: SetPersonalDocs,
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      personalDocs: payload,
    });
  }

  @Action(SetCorroborativeDocs)
  public setCorroborativeDocs (
    context: StateContext<IChangeHolderStateModel>,
    { payload }: SetCorroborativeDocs,
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      corroborativeDocs: payload,
    });
  }

  @Action(SetNecessaryDocumentsInfo)
  public setNecessaryDocumentsInfoOnStore (
    context: StateContext<IChangeHolderStateModel>,
    { payload }: SetNecessaryDocumentsInfo,
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      documentsInfo: payload,
    });
  }

  @Action(SetSuccessMessage)
  public setSuccessMessage (
    context: StateContext<IChangeHolderStateModel>,
    { payload }: SetSuccessMessage,
  ) {
    const state = context.getState();
    context.setState({ ...state, successMessage: payload });
  }
}