import { SetChangeExpiringDate } from './change-expiring-date.actions';
import { Observable } from 'rxjs';
import {
  ChangeExpiringDateState,
  ChangeExpiringDateStateModel,
} from './change-expiring-date.state';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Injectable({
  providedIn: 'root',
})
export class ChangeExpiringDateStoreService {
  @Select(ChangeExpiringDateState.getState)
  public changeExpiringDateState$: Observable<ChangeExpiringDateStateModel>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setChangeExpiringDate(changeExpiringDate: boolean) {
    return new SetChangeExpiringDate(changeExpiringDate);
  }
}
