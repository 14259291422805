import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ICreditCard } from '@store/dashboard';

@Component({
  selector: 'app-registered-card',
  templateUrl: './registered-card.component.html',
  styleUrls: ['./registered-card.component.scss'],
})
export class RegisteredCardComponent implements OnChanges {
  public bgImage: string;
  public onMobile = false;
  public isExpired: boolean;
  public nearExpire: boolean;
  @Input() creditCard: ICreditCard;
  @Input() baseImgUrl: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.creditCard && this.creditCard) {
      this.isExpired = this.setIsExpired();
      this.nearExpire = this.setNearExpired();
    }

    if (changes.baseImgUrl && this.baseImgUrl) {
      this._setImage();
    }
  }

  setIsExpired(): boolean {
    return this.creditCard.expired;
  }

  setNearExpired(): boolean {
    return !this.isExpired && !!this.creditCard.daysToExpire && this.creditCard.daysToExpire <= 60;
  }

  private _setImage() {
    this.bgImage = `${this.baseImgUrl}/img/credit-card/flag-${this.creditCard.brand}.svg`;
  }
}
