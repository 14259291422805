import { AcnConnectorService, IRequest } from '@acn/angular';
import { ElementRef, Inject, Injectable, Injector } from '@angular/core';
import {
  IDuplicatePdfFile,
  IGuideDetail,
  IInvoiceDetail,
} from '@features/duplicate/services/models';
import { ClientService } from '@features/services/services/client.service';
import { BaseService } from '@shared/services/base/base.service';
import { UserStoreService } from '@store/app';
import {
  CitiesStoreService,
  ConsumptionStoreService,
  CreditCardStoreService,
  DuplicateStoreService,
  ICreditCardDocument,
  IInstallation,
  INewCity,
  InstallationStoreService,
  StepStoreService,
} from '@store/dashboard';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, pluck, switchMap, tap } from 'rxjs/operators';
import { IPix } from './models/pix.model';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { DOCUMENT } from '@angular/common';
import { format, parse } from 'date-fns';
import { CrmProtocolService, ICrmList } from '@shared/services/crm-protocol/crm-protocol.service';
import { servicesList } from '@shared/enumerators';
import { CampanhaService } from '../../../shared/services/campanha/campanha.service';

@Injectable({
  providedIn: 'root',
})
export class DuplicateService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public currentInvoice$ = this._duplicateStoreService.currentInvoice$;
  public currentGuide$ = this._duplicateStoreService.currentGuide$;
  public consumptionVariation$ = this._consumptionStoreService.consumptionVariation$;
  public cardInfo$ = this._creditCardStoreService.cardInfo$;
  public selectedCityServices$ = this._citiesStoreService.selectedCityServices$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public selectedInstallation$ = this._installationStoreService.selectedInstallation$;
  public headerBgSmall$ = this._duplicateStoreService.headerBgSmall$;

  private readonly INVOICE_DETAIL = 'conta/v3/detalheFatura';
  private readonly GUIDE_DETAIL = 'conta/v2/guiasPagamento';
  private readonly INVOICE_DOWNLOAD = 'conta/downloadFatura';
  private readonly GUIDE_DOWNLOAD = 'conta/downloadGuia';
  private readonly INVOICE_BY_EMAIL = 'conta/enviaEmailFatura';
  private readonly BILL_BY_EMAIL = 'conta/enviaEmailGuia';
  private readonly GENERATE_QRCODE = 'pix/gerar';
  private readonly PAYMENT_IN_INSTALLMENTS_URL = 'parcelamento/gerarUrl';

  constructor(
    private _connectorApi: AcnConnectorService,
    private _installationStoreService: InstallationStoreService,
    private _citiesStore: CitiesStoreService,
    private _duplicateStoreService: DuplicateStoreService,
    private _userStoreService: UserStoreService,
    private _consumptionStoreService: ConsumptionStoreService,
    private _stepStore: StepStoreService,
    protected _injector: Injector,
    private _creditCardStoreService: CreditCardStoreService,
    private _citiesStoreService: CitiesStoreService,
    private _clientService: ClientService,
    private _crmProtocol: CrmProtocolService,
    private _serviceCampanha: CampanhaService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    super(_injector);
  }

  getCampanhas(idServico: number | string, idTipoServico: number | null) {
    return this._serviceCampanha.getActiveCampanhas(idServico, idTipoServico);
  }

  getInvoiceDetail(invoiceId: number) {
    let ignoreCrm: boolean;
    return this._installationStoreService.selectedInstallation$.pipe(
      switchMap((installation) => {
        const req: IRequest = {
          endPoint: this.INVOICE_DETAIL,
          showLoading: true,
          queryString: {
            codCdc: installation.cdc,
            cidade: installation.idCidade,
            idFatura: invoiceId,
          },
        };
        let crmKeyInput = this.getCrmKeyInput();
        ignoreCrm = this._crmProtocol.hasCrmList(crmKeyInput);
        req.queryString.ignorarCrm = ignoreCrm;
        return this._connectorApi.get<IInvoiceDetail>(req).pipe(
          tap(() => {
            if (!ignoreCrm) {
              this._crmProtocol.setCrmList(crmKeyInput);
            }
          }),
        );
      }),
      switchMap((invoice) => {
        this.setCurrentInvoice(invoice);
        return of(true);
      }),
    );
  }

  getGuideDetail(invoiceList: Array<number>) {
    let ignoreCrm: boolean;
    return this._installationStoreService.selectedInstallation$.pipe(
      switchMap((installation) => {
        const req: IRequest = {
          endPoint: this.GUIDE_DETAIL,
          showLoading: true,
          queryString: { codCdc: installation.cdc, idCidade: installation.idCidade },
          body: { faturas: invoiceList },
        };

        let crmKeyInput = this.getCrmKeyInput();
        ignoreCrm = this._crmProtocol.hasCrmList(crmKeyInput);
        req.queryString.ignorarCrm = ignoreCrm;
        return this._connectorApi.post<{ guia: IGuideDetail }>(req).pipe(
          tap(() => {
            if (!ignoreCrm) {
              this._crmProtocol.setCrmList(crmKeyInput);
            }
          }),
        );
      }),
      switchMap((guide) => {
        this.setCurrentGuide(guide.guia);
        return of(true);
      }),
    );
  }

  getSelectedCityServices(idCity) {
    return this._clientService.getCityServices(idCity);
  }

  getSelectedCityServicesSnapshot(): ICityServicesList {
    return this._citiesStore.getSelectedCityServices();
  }

  setCurrentInvoice(invoice: IInvoiceDetail) {
    this._duplicateStoreService.setCurrentInvoice(invoice);
  }

  setCurrentGuide(guide: IGuideDetail) {
    this._duplicateStoreService.setCurrentGuide(guide);
  }

  getInvoicesIdFromGuide(): Array<number> {
    return this._duplicateStoreService.getInvoicesIdFromGuide();
  }

  getUserEmail() {
    return this._userStoreService.getUserEmail();
  }

  setStep(steps: Array<string>) {
    this._stepStore.setSteps(steps);
  }

  scrollTop(elementRef: ElementRef) {
    this._screenService.scrollTop(elementRef);
  }

  getSelectedCity(): INewCity {
    return this._citiesStore.getSelectedCity();
  }

  getCurrentInvoice(): IInvoiceDetail {
    return this._duplicateStoreService.getCurrentInvoice();
  }

  getCurrentGuide(): IGuideDetail {
    return this._duplicateStoreService.getCurrentGuide();
  }

  getSelectedInstallation(): IInstallation {
    return this._installationStoreService.getSelectedInstallation();
  }

  scrollTopDashboard(): void {
    this._screenService.scrollTopDashboard();
  }

  getCrmKeyInput(): ICrmList {
    return {
      idService: servicesList.segundaVia,
      idCidade: this._citiesStore.getSelectedCity().id,
      cpfCnpj: this._userStoreService.getUser().cpfCnpj,
      cdc: this._installationStoreService.getSelectedInstallation().cdc,
    };
  }

  downloadPdf(duplicateId: number, fileName: string, type: 'invoice' | 'guide') {
    return this._installationStoreService.selectedInstallation$.pipe(
      switchMap((installation) => {
        const req: IRequest = {
          endPoint: type === 'invoice' ? this.INVOICE_DOWNLOAD : this.GUIDE_DOWNLOAD,
          showLoading: true,
          queryString: {
            codCdc: installation.cdc,
            idDocument: duplicateId,
            idCidade: installation.idCidade,
          },
        };

        return this._connectorApi.get<IDuplicatePdfFile>(req);
      }),
      switchMap((data) => {
        this._createAndDownloadInvoice(data.pdf, fileName);

        return of(true);
      }),
    );
  }

  resetDuplicateStore() {
    this._duplicateStoreService.resetDuplicateStore();
  }

  setCreditCardDocument(document: ICreditCardDocument) {
    this._creditCardStoreService.setCreditCardDocument(document);
  }

  private _createAndDownloadInvoice(data: string, fileName: string) {
    const linkSource = 'data:application/pdf;base64,' + data;
    const downloadLink = this._document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}.pdf`;
    downloadLink.click();
  }

  sendInvoiceByEmail() {
    return combineLatest([
      this._duplicateStoreService.currentInvoice$,
      this._duplicateStoreService.currentGuide$,
      this._installationStoreService.selectedInstallation$,
    ]).pipe(
      switchMap(([invoice, guide, installation]) => {
        const documentId = (invoice && invoice.idFatura) || guide.idGuia;

        const req: IRequest = {
          endPoint: invoice && invoice.idFatura ? this.INVOICE_BY_EMAIL : this.BILL_BY_EMAIL,
          showLoading: true,
          queryString: {
            codCdc: installation.cdc,
            idCidade: installation.idCidade,
            idDocument: documentId,
          },
        };
        return this._connectorApi.post(req);
      }),
    );
  }

  getQRCode(creditCardDocument: ICreditCardDocument) {
    const req: IRequest = {
      endPoint: this.GENERATE_QRCODE,
      checkToken: false,
      showLoading: true,
      body: {
        cliente: {
          codUnidade: creditCardDocument.unitCode,
          idCidade: this._installationStoreService.getSelectedInstallation().idCidade.toString(),
          idLigacao: this._installationStoreService.getSelectedInstallation().cdc.toString(),
          email: this._userStoreService.getUserEmail().toString(),
          numTelefone: this._userStoreService.getUserPhone().toString(),
          nome: this._userStoreService.getUserName().toString(),
        },
        doctoPagamento: {
          tipoDocumento: creditCardDocument.type,
          idDocumento: creditCardDocument.id.toString(),
          codBarras: creditCardDocument.barCode,
          vencimento: format(
            parse(creditCardDocument.dueDate.toString(), 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd',
          ),
          valor: creditCardDocument.value.toString(),
        },
        qrcode: {
          retornarCodigoPix: true,
          retornarURLImagem: false,
          larguraImagemPixels: 225,
          retornarBase64Imagem: true,
        },
      },
    };
    return this._connectorApi.post<IPix>(req);
  }

  getPaymentInInstallmentsUrl(
    idCidade: number,
    idLigacao: string,
    idPagamento: number,
    tipo: string,
  ): Observable<string> {
    const req: IRequest = {
      endPoint: this.PAYMENT_IN_INSTALLMENTS_URL,
      checkToken: true,
      showLoading: true,
      queryString: { idCidade, idLigacao, idPagamento, tipo },
    };
    return this._connectorApi.get<{ url: string }>(req).pipe(
      pluck('url'),
      catchError((error) => {
        this.setNotification('is-warning', 'Serviço indisponível', 'Tente novamente mais tarde.');
        return throwError(error);
      }),
    );
  }

  setHeaderBgSmall(payload: boolean) {
    this._duplicateStoreService.setHeaderBgSmall(payload);
  }
}
