import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandTalkComponent } from './components/hand-talk/hand-talk.component';

@NgModule({
  declarations: [HandTalkComponent],
  exports: [HandTalkComponent],
  imports: [CommonModule],
})
export class HandTalkModule {}
