export function daysFromNow(endDate: string) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const [endDay, endMonth, endYear] = endDate.split('/');
  const dt1 = new Date();
  const dt2 = new Date(`${endYear}/${endMonth}/${endDay}`);

  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      oneDay,
  );
}
