import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@acn/angular';
import { ScreenService } from '@shared/services';
import { interval } from 'rxjs';
import { PixService } from '@shared/services/pix/pix.service';

const FIFTEEN_MINUTES = 15 * 60;

@Component({
  selector: 'app-pix-qrcode',
  templateUrl: './pix-qrcode.component.html',
  styleUrls: ['./pix-qrcode.component.scss'],
})
export class PixQRCodeComponent extends BaseComponent implements OnInit {
  @Input() idBilling: number;
  @Input() idCity: number;
  @Input() billingType: string;

  public gtmLabel: string;
  public copied = false;
  public pixCode: string;
  public baseImgPix: string;
  public isExpired = false;
  public error: boolean = false;

  private timeLeft: number = FIFTEEN_MINUTES;

  constructor (
    private _screenService: ScreenService,
    private _pixService: PixService,
  ) {
    super();
  }

  ngOnInit (): void {
    this._startClock();
    this._getQRCode();
  }

  renewQrCode () {
    this.isExpired = false;
    this.timeLeft = FIFTEEN_MINUTES;
    this._getQRCode();
    this._startClock();
  }

  toggleText () {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 5000);
    this._screenService.setNotification(
      'is-success',
      'Código PIX copiado para a área de transferência',
      '',
    );
  }

  presentModalErrorPix () {
    this._screenService.showModal({
      componentId: 'pix.modal.error',
      canClose: false,
      params: {},
    });
  }

  private _startClock () {
    const subscription = interval(1000).subscribe(() => {
      this.timeLeft--;

      if (this.timeLeft <= 0) {
        this.isExpired = true;
        subscription.unsubscribe();
      }
    });
  }

  private _getQRCode () {
    this._pixService.getPix(this.billingType, this.idBilling, this.idCity).subscribe({
      next: (response) => {
        this.pixCode = response.qrcode?.codigoPix;
        this.baseImgPix = 'data:image/jpeg;base64,' + response.qrcode.encodedImg64;
      },
      error: () => {
        this.presentModalErrorPix();
      },
    });
  }
}
