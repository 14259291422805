<ng-container *transloco="let text">
  <div class="container">
    <acn-icon class="logo" icon="logo"></acn-icon>

    <h1 class="error">{{ text('notFound.error') }}</h1>

    <acn-card class="box">
      <div class="box__img">
        <img [src]="baseImgUrl + '/img/404.png'" alt="Página não encontrada - Erro 404" />
      </div>
      <div class="box__content">
        <h2 class="has-text-blue is-bold">{{ text('notFound.ops') }}!</h2>
        <p class="box__text">{{ text('notFound.errorContent') }}</p>
      </div>
    </acn-card>

    <a [routerLink]="onDashboard ? '/home-logado' : '/home'">
      <acn-button [label]="text('notFound.back')" class="is-white" width="310px"></acn-button>
    </a>
  </div>
</ng-container>
