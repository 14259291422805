import { Injectable, Injector, Type } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterHelperService } from '@shared/services/router/router-helper.service';
import { isObservable, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { titleKey } from '@shared/services/title/title-key';
import { TitleResolver, TitleType } from '@shared/services/title/title-resolver';
import { ActivatedRouteSnapshot } from '@angular/router';

function isTitleResolver(type: any): type is Type<TitleResolver> {
  return typeof type === 'function';
}

@Injectable({ providedIn: 'root' })
export class TitleService {
  constructor(
    private readonly title: Title,
    private readonly routerHelperService: RouterHelperService,
    private readonly injector: Injector,
  ) {}

  private readonly _destroy$ = new Subject<void>();
  private _isDestroyed = false;

  private _selectData(): Observable<string | null | undefined> {
    return this.routerHelperService.selectLatestSnapshot().pipe(
      map((snapshot) => {
        let title: TitleType = snapshot.data[titleKey];
        let state = snapshot;
        while (state.firstChild) {
          state = state.firstChild;
          if (state.data[titleKey]) {
            title = state.data[titleKey];
          }
        }
        return { snapshot, title };
      }),
      distinctUntilChanged(({ title: titleA }, { title: titleB }) => {
        // Não emite nenhum valor se o title não mudou
        return !isTitleResolver(titleA) && !isTitleResolver(titleB) && titleA === titleB;
      }),
      switchMap(({ title, snapshot }) => {
        // Verifica se o title é um @Injectable
        if (!isTitleResolver(title)) {
          // Se não for, então é uma 'string', só retorna
          return Promise.resolve(title);
        }
        // Cria um Injector novo para que o TitleResolver possa injetar o ActivatedRouteSnapshot
        const injector = Injector.create({
          parent: this.injector,
          providers: [{ provide: ActivatedRouteSnapshot, useValue: snapshot }],
        });
        // Pega a instancia do TitleResolver e executa o método resolve
        const resolver = injector.get(title);
        const resolved = resolver.resolve(snapshot);
        // Se for Observable, só retorna
        if (isObservable(resolved)) {
          return resolved;
        }

        // Se for qualquer outro valor, precisa retornar em uma promise devido ao switchMap
        return Promise.resolve(resolved);
      }),
      // Verifica de novo se o title não é o mesmo, caso o resultado venha de um resolver
      distinctUntilChanged(),
    );
  }

  init(): void {
    if (this._isDestroyed) {
      throw new Error('TitleService should not be started again after it was destroyed');
    }
    this._selectData()
      .pipe(takeUntil(this._destroy$))
      .subscribe((title) => {
        this.title.setTitle(title || 'Minha BRK');
      });
  }

  destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._isDestroyed = true;
  }
}
