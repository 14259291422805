export enum ServiceId {
    AGENDAR_ATENDIMENTO = 1,
    ATENDIMENTO = 2,
    NOTIFICAR_PROBLEMA = 3,
    DEBITO_AUTOMATICO = 4,
    SEGUNDA_VIA_DE_CONTA = 5,
    ALTERAR_DATA_VENCIMENTO = 6,
    MINHAS_SOLICITACOES = 9,
    TROCA_DE_TITULARIDADE = 10,
    RELIGA = 12,
    PAGAMENTO_AUTOMATICO_COM_CARTAO_DE_CREDITO = 13,
    AUTOLEITURA = 15,
    CARTA_DE_SERVICOS = 16,
    ESTRUTURA_TARIFARIA = 17,
    PAGAMENTO_COM_CARTAO_DE_CREDITO = 19,
    DECLARACAO_NEGATIVA = 20,
    DECLARACAO_ANUAL_DE_DEBITOS = 21,
    NOTIFICACAO_DE_VAZAMENTO = 25,
    ALTERAR_EMAIL = 33,
    ALTERAR_CELULAR = 34,
    CONTAS_PAGAS = 35,
    RECEBER_FATURA_POR_EMAIL = 36,
    HISTORICO_DE_CONSUMO = 37,
    LABORATORIO_MOVEL = 38,
    ONDE_PAGAR_SUA_CONTA = 39,
    RECURSOS_ADMINISTRATIVOS = 40,
    TABELA_DE_SERVICOS = 41,
    VIABILIDADE_TECNICA_DE_REDE = 42,
    VISUALIZAR_OU_ALTERAR_DADOS_CADASTRAIS = 43,
    SEGUNDA_VIA_FACIL = 44,
    CANAIS_DE_ATENDIMENTO = 45,
    ADICIONAR_OU_REMOVER_IMOVEIS = 46,
    NOVOS_LOTEAMENTOS = 47,
    RELIGA_INFORMATIVA = 48,
    AGENDAMENTO_AUTOMATIZADO = 49,
    RELIGACAO = 50,
    COMUNICADOS = 51,
    CORTE_A_PEDIDO = 52,
    WEBCHAT = 53,
    LIGACAO_NOVA = 54,
    SIMULADOR_DE_FATURA = 55,
    NOTIFICAR_UM_PROBLEMA_AUTOMATIZADO = 56,
    CALENDARIO_LEITURA = 57,
    PAGINA_PRINCIPAL = 'PAGINA_PRINCIPAL'
  }
  