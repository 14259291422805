import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { TokenSmsStoreService } from '@store/app';
import { IRequest, AcnConnectorService } from '@acn/angular';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  public phone$ = this._tokenSmsService.phone$;

  private readonly TOKEN_SMS_URL = 'cadastro/tokenSms';

  constructor(
    private _connectorApi: AcnConnectorService,
    private _tokenSmsService: TokenSmsStoreService,
  ) {}

  setPhone(phone: string) {
    this._tokenSmsService.setPhoneSms(phone);
  }

  validateCodeSms(code: string) {
    return this.phone$.pipe(
      switchMap((phone) => {
        const req: IRequest = {
          endPoint: this.TOKEN_SMS_URL,
          queryString: { telefone: phone, token: code },
          showLoading: true,
        };

        return this._connectorApi.get(req).pipe(
          switchMap(() => {
            return of(true);
          }),
        );
      }),
    );
  }

  getSmsVerification() {
    return this.phone$.pipe(
      switchMap((phone) => {
        const req: IRequest = {
          endPoint: this.TOKEN_SMS_URL,
          body: {
            telefone: phone,
          },
          showLoading: true,
        };

        return this._connectorApi.post(req).pipe(
          switchMap(() => {
            return of(true);
          }),
        );
      }),
    );
  }
}
