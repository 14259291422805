export interface ICustomDataLayer {
  cidade?: string;
  codCidade?: number;
  cdc?: string;
  status?: string;
  userId?: number;
}

export class CustomDataLayer implements ICustomDataLayer {
  public cidade: string;
  public codCidade: number;
  public cdc: string;
  public status: string;
  public userId: number;

  constructor(
    { cidade, codCidade, cdc, status, userId }: ICustomDataLayer = {
      cidade: null,
      codCidade: null,
      cdc: null,
      status: 'off',
      userId: null,
    },
  ) {
    this.cidade = cidade || undefined;
    this.codCidade = codCidade || undefined;
    this.cdc = cdc || undefined;
    this.status = status || 'off';
    this.userId = userId || undefined;
  }
}
