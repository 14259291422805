import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.scss'],
})
export class StepMenuComponent {
  @Input() mobile = false;
  @Output() back = new EventEmitter<boolean>();

  goBack() {
    this.back.emit(true);
  }
}
