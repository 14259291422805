export function caretTraps(mask: any) {
  const strCaretTrap = '[]';
  const indexes = [];

  const indexOfCaretTrap = mask.indexOf(strCaretTrap);
  const hasMask = indexOfCaretTrap !== -1;

  while (hasMask) {
    indexes.push(indexOfCaretTrap);
    mask.splice(indexOfCaretTrap, 1);
  }

  return { maskWithoutCaretTraps: mask, indexes };
}
