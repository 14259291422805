import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

import { NotificationState } from './states/notification';
import { SettingsState } from './states/settings';
import { ModalState } from './states/modal';
import { TokenSmsState } from './states/token-sms/token-sms.state';
import { UserState } from './states/user';
import { UseTermsState } from './states/use-terms/use-terms.state';
import { RedirectionState } from './states/redirection/redirection-store.state';
import { AttendanceState } from './states/attendance/attendance.state';

export * from './states/user';
export * from './states/notification';
export * from './states/settings';
export * from './states/modal';
export * from './states/token-sms';
export * from './states/use-terms';
export * from './states/redirection';
export * from './states/attendance';

export const AppStates = [
  NotificationState,
  SettingsState,
  ModalState,
  UserState,
  TokenSmsState,
  UseTermsState,
  RedirectionState,
  AttendanceState,
];

@State({
  name: 'app',
  children: AppStates,
})
@Injectable()
export class AppStateModule {}
