import { FormArray, FormGroup } from '@angular/forms';
import { BaseComponent } from './base.component';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'lib-base-form-component',
  template: '',
})
export abstract class BaseFormComponent extends BaseComponent {
  form: FormGroup;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.onSubmit();
        break;
    }
  }

  /** @ignore */
  protected constructor() {
    super();
  }

  abstract submit();

  /**
   * Verifica se o form está válido e faz o submit
   */
  onSubmit(send = true) {
    this.removeFocus();

    if (this.form.valid) {
      if (send) {
        this.submit();
      }
      return true;
    } else {
      this.checkValidationsForm(this.form);
      return false;
    }
  }

  /**
   * Valida todos os campos do form de uma vez só
   */
  checkValidationsForm(formGroup: FormGroup | FormArray) {
    let errors: any;
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsDirty();
      control.markAsTouched();
      errors = control.errors;
      // console.log(control.errors, field);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.checkValidationsForm(control);
      }
    });

    return errors;
  }

  /**
   * @description
   * Remove o focus do último elmento ativo para não ficar submetendo o form toda hora
   */
  removeFocus() {
    const activeEl: any = document.activeElement;
    activeEl.blur();
  }

  /**
   * Reseta o form
   */
  resetForm() {
    this.form.reset();
  }
}
