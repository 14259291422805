import { IAcnDynamicComponentManifest } from '@acn/angular';

export const geolocalizationDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'geolocalization.denyLocation',
    loadChildren: () =>
      import('../features/geolocalization/components/deny-localization/deny-localization.module').then(
        (m) => m.DenyLocalizationModule,
      ),
  },
];
