<ng-container>
  <div>
    <section class="container-qrcode">
      <div class="back-ground-qrcode">
        <img
          *ngIf="baseImgPix"
          [ngClass]="isExpired && 'pix-expired'"
          [src]="baseImgPix"
          alt="PIX"
        />
      </div>
    </section>

    <div class="expired-pix-button" *ngIf="isExpired">
      <div>QRCode expirado!</div>
      <acn-button
        label="Gerar novo código Pix"
        class="is-secondary --button blue"
        gtmCategory="Botao"
        gtmAction="solicita_novo_qrcode"
        data-cy="renew-pix"
        (click)="renewQrCode()"
        acnGtm
      ></acn-button>
    </div>

    <div class="pix-instructions">
      <div>1. Abra o app do seu banco e acesse o item PIX.</div>
      <div>2. Escolha pagar com QR code e escaneie o código.</div>
      <div>3. Confirme as informações e finalize o pagamento.</div>
    </div>

    <div class="split">
      <app-split label="ou"></app-split>
    </div>

    <acn-button
      [label]="copied ? 'Código Pix copiado' : 'Pix Copia e Cola'"
      [cdkCopyToClipboard]="pixCode"
      (click)="toggleText()"
      class="is-secondary is-fullWidth --button blue"
      gtmCategory="Botao"
      icon="fa clone-regular"
      gtmAction="Copia_Cod_Pix"
      data-cy="duplicate-pix-modal-button-copyPix"
      acnGtm
    >
    </acn-button>
  </div>
</ng-container>
