import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';

import { IUser, ISession } from './../../models/index';
import { AcnSessionService } from '../session/acn-session.service';

/**
 * Serviço responsável pelo gerenciamento de permissoes do usuario logado
 */
@Injectable({
  providedIn: 'root',
})
export class AcnAuthorization {
  /** @ignore */
  private user: IUser = undefined;

  /** @ignore */
  private $user = new BehaviorSubject<IUser | undefined>(undefined);

  /**
   * Constructor
   */
  constructor(private acnSessionService: AcnSessionService) {}

  /**
   * Pega as permissoes do usuário
   *
   * @returns Retorna as permissoes do usuário
   */
  public getUserData(): Observable<IUser> {
    if (this.user !== undefined) {
      return of(Object.assign({}, this.user));
    }

    return this.acnSessionService.getSession().pipe(
      map((result: ISession) => {
        if (result) {
          this.user = result.loggedUser;
          return Object.assign({}, this.user);
        }
      }),
      catchError((e) => {
        this.$user.next(undefined);
        return throwError(e);
      }),
    );
  }

  /**
   * Limpa a role
   */
  public clear(): void {
    this.user = undefined;
    this.$user.next(undefined);
  }

  /**
   * Com o usuário logado, valida o perfil de acesso
   *
   * @returns Retorna um observable com um valor booleano caso seja verdadeiro ou falso
   */
  public userHasProfiles(profiles: string[]): Observable<boolean> {
    return this.getUserData().pipe(
      map((hasProfile) => {
        // if (hasProfile) {
        //   const profileArray = this.user.permissions.filter((x) => profiles.includes(x));
        //   return profileArray.length > 0;
        // }
        return false;
      }),
    );
  }

  /**
   * Com o usuário logado, verifica se usuario possui a permissao de acesso
   *
   * @returns Retorna um observable com um valor booleano caso seja verdadeiro ou falso
   */
  public userHasPermission(permissions: string[]): Observable<boolean> {
    return this.getUserData().pipe(
      map((hasPermission) => {
        // if (hasPermission) {
        //   const permissionArray = this.user.permissions.filter((x) => permissions.includes(x));
        //   return permissionArray.length > 0;
        // }
        return false;
      }),
    );
  }

  /**
   *
   * @returns Retorna um observable de $user
   */
  getObservableUser(): Observable<IUser> {
    return this.$user.asObservable();
  }
}
