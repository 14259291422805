import { ElementRef, Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import {
  AcnConfigurationService,
  AcnDynamicComponentLoaderService,
  AcnErrorService,
  AcnLoaderService,
  AcnSecurityAccessService,
  CustomDataLayer,
} from '@acn/angular';
import { BaseService } from '@shared/services/base/base.service';
import { IAcnNotification } from '@acn/ui';
import {
  ModalStoreService,
  NotificationService,
  RedirectionStoreService,
  UserStoreService,
} from '@store/app';
import { CitiesStoreService, InstallationStoreService } from '@store/dashboard';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  public modalState$ = this._modalService.modalState$;
  public isLoading$ = this._loaderService.isLoading$;
  public translatedErrors$ = this._translocoService.selectTranslateObject('errors');
  public notifications$ = this._notificationService.notification$;
  public errors$ = this._errorService.error$;
  public securityErrors$ = this._securityAccessService.securityErrors$;

  private readonly DEFAULT_FEATURES = ['pagamento_cartao', 'cartao_avista', 'pix'];

  constructor(
    _injector: Injector,
    private _securityAccessService: AcnSecurityAccessService,
    private _notificationService: NotificationService,
    private _lazyLoader: AcnDynamicComponentLoaderService,
    private _modalService: ModalStoreService,
    private _loaderService: AcnLoaderService,
    private _translocoService: TranslocoService,
    private _errorService: AcnErrorService,
    private _citiesService: SelectCityService,
    private _userStore: UserStoreService,
    private _installationStore: InstallationStoreService,
    private _configuration: AcnConfigurationService,
    private _citiesStore: CitiesStoreService,
    private _selectCityService: SelectCityService,
    private _redirectionStore: RedirectionStoreService,
    private _router: Router,
  ) {
    super(_injector);
  }

  addNotification(notification: IAcnNotification) {
    this._notificationService.add(notification);
  }

  removeNotification(id: string) {
    this._notificationService.remove(id);
  }

  getModalComponentAndModule(componentId: string, injector: Injector | null = null) {
    return this._lazyLoader.getComponentAndModuleType(componentId, injector);
  }

  scrollTop(el: ElementRef) {
    this._screenService.scrollTop(el);
  }

  setupOnMobile() {
    return this._screenService.isOnMobile();
  }

  setupGtm() {
    return combineLatest([
      this._citiesService.selectedCity$,
      this._userStore.user$,
      this._installationStore.selectedInstallationId$,
    ]).pipe(
      tap(([selectedCity, user, installationId]) => {
        this._gtmService.dimensions = {
          ...new CustomDataLayer({
            cidade: selectedCity?.nome ?? null,
            codCidade: selectedCity?.id ?? null,
            cdc: installationId,
            userId: user?.id ?? null,
            status: user ? 'logado' : 'off',
          }),
        };
        this._gtmService.trigger();
      }),
    );
  }

  getCityList() {
    return this._citiesService.getCityList();
  }

  setBrewNewsIsClosed(value: boolean) {
    this._citiesService.setBreakingNewsIsClosed(value);
  }

  setFeatures() {
    const defaultFeatures =
      this._configuration.settings.env && this._configuration.settings.env === 'local'
        ? this.DEFAULT_FEATURES
        : [];
    const features =
      (this._configuration.settings.features && this._configuration.settings.features.split(',')) ||
      defaultFeatures;
    this._settingsStore.setAvailableFeatures(features);
  }

  selectCity(city: number, redirect?: string) {
    return this._selectCityService
      .selectCity(city)
      .pipe(take(1))
      .subscribe((data) => {
        if (!data) {
          this._selectCityService.setNotification('is-warning', '', 'Cidade não encontrada.');
          if (typeof redirect !== 'undefined') {
            this._router.navigate([redirect]);
          }
          return;
        }
      });
  }

  setInfoCities() {
    return this._selectCityService.setInfoCities(9999, 999).pipe(take(1)).subscribe();
  }

  getSelectedCity() {
    return this._selectCityService.getSelectedCity();
  }

  getSelectedCityEvent() {
    return this._selectCityService;
  }

  getSelectedCityServices() {
    return this._citiesStore.getSelectedCityServices();
  }

  setRedirectTo(url: string) {
    this._citiesStore.setRedirectTo(url);
    this._redirectionStore.setRedirectedFrom(url);
  }

  resetCityList() {
    this._citiesService.resetCityList();
  }
}
