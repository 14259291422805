import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebchatComponent } from './components/webchat.component';

@NgModule({
  declarations: [WebchatComponent],
  exports: [WebchatComponent],
  imports: [CommonModule],
})
export class WebchatModule {}
