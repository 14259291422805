import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { CustomDataLayer, ICustomDataLayer } from './models/index';
import { WINDOW } from '@shared/services/window/window.token';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private _currentSet: ICustomDataLayer;

  private readonly _isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
    this._generateEmptyDimensionsSet();
    this.trigger();
  }

  set dimensions(dimensions: ICustomDataLayer) {
    this._currentSet = { ...this._currentSet, ...dimensions };
  }

  trigger(): void {
    if (!this._isBrowser) {
      return;
    }
    this.window.dataLayer?.push(this._currentSet);
  }

  setCustomEvent(evCategory: string, evAction: string, evLabel: string, event = 'fireEvent'): void {
    if (!this._isBrowser) {
      return;
    }
    this.window.dataLayer?.push({ event, evCategory, evAction, evLabel });
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = { ...new CustomDataLayer() };
  }
}
