import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ICalendarMeteringModel } from './models/cdc-data.model';
import { Injectable } from '@angular/core';
import { SetCdcData } from './calendar-metering.actions';

const INITIAL_STATE: ICalendarMeteringModel = {
  cdcData: {
    bairro: null,
    cep: null,
    logradouro: null,
    complemento: null,
    numero: null,
    valorDebitos: null,
  },
};

@State<ICalendarMeteringModel>({
  name: 'calendarMetering',
  defaults: INITIAL_STATE,
})
@Injectable()
export class CalendarMeteringState {
  @Selector()
  public static getCdcData (state: ICalendarMeteringModel) {
    return state.cdcData;
  }

  @Action(SetCdcData)
  public setCdc(context: StateContext<ICalendarMeteringModel>, { payload }: SetCdcData) {
    const state = context.getState();
    context.setState({ ...state, cdcData: payload });
  }
}
