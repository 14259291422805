import { Component, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { map, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@acn/angular';
import { AttendanceService } from '@features/attendance/services/attendance.service';
import { ICityPhone, ICityPhonesNotSelected, INewCity } from '@store/dashboard';
import { combineLatest } from 'rxjs';
import { IAttendanceAgencies } from '@store/dashboard/states/cities/models/attendance-agency.model';
import { WebchatService } from '@features/webchat/services/webchat.service';
import { ActiveService } from '@shared/services/active-service/active-service';
import { servicesCategory, servicesList } from '@shared/enumerators';
import { Router } from '@angular/router';
import { MessageService } from '@shared/services/messages/messages.service';
import { ServiceId } from '@shared/enumerators/service-id.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-attendance-channels',
  templateUrl: './attendance-channels.component.html',
  styleUrls: ['./attendance-channels.component.scss'],
})
export class AttendanceChannelsComponent extends BaseComponent implements OnInit {
  public onMobile = false;
  public selectedCity: INewCity;
  public attendanceCity: INewCity;
  public isBrowser: boolean;
  public phones: ICityPhone;
  public gtmCategory = 'UX';
  public baseImgUrl: string;
  public phoneData: ICityPhonesNotSelected[];
  public agencies: IAttendanceAgencies[] = [];
  public notDisplayAttendanceAgencies = true;
  public path: string;
  public cardEmailMessage: SafeHtml;
  public onReconnection = false;
  public onScheduleAttendance = false;
  public isAttendance = false;
  public minHeight = '120px';
  public isWebchatActive = false;

  private _defaultCardEmailMessage: string;
  private _publicPath: string;

  private LOGGED = 'CARTAO_EMAIL_PRIVADO';
  private NOT_LOGGED = 'CARTAO_EMAIL_PUBLICO';

  @HostBinding('class.dashboard') onDashboard = false;
  public lgpdFormLink: string;

  constructor(
    private _attendanceService: AttendanceService,
    @Inject(PLATFORM_ID) private _platformId: string,
    private readonly webchatService: WebchatService,
    private readonly router: Router,
    private _domSanitizer: DomSanitizer,
    private readonly _messageService: MessageService,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit() {
    this.onDashboard = this._attendanceService.onDashboard();
    this.baseImgUrl = this._attendanceService.settings.baseImgUrl;
    this.lgpdFormLink = this._attendanceService.settings.privacyUrl;
    this.setAttendance();
    this.setOnReconnection();
    this.setScheduleAttendance();
    this._loadCardMessage();

    this._defaultCardEmailMessage = `<br />A Minha BRK oferece diversos serviços de forma automatizada para agilizar as suas necessidades tais como <b> 2ª via, pix, parcelamento, troca de titularidade, religação, entre muitos outros.</b> <br /> <br /><a href="/${this._publicPath}/servicos" style="display: contents !important">Clique Aqui</a> para conhecer e solicitar os nossos serviços disponíveis.<br /><br /><b>Importante:</b> Em caso de <b>emergência</b> como <b>falta de água</b> e <b>vazamento de esgoto</b>, entre em contato através do nosso telefone para um atendimento prioritário.<br /><br /> Para outros assuntos que não estão na Minha BRK clique no botão abaixo.<br />Nosso prazo de resposta por aqui é de até 48 horas úteis.<br />`;

    if (!this.isBrowser) {
      return;
    }
    this._setScreen();
    this._setAttendanceCity();
    this._getSelectedCity();
    this._selectAgenciesByIdCity();
    this._setWebchatActive();
  }
  openChatWindow(): boolean {
    if (!this.isBrowser) {
      return;
    }

    this.webchatService.open();
    return false;
  }

  hideModal() {
    this._attendanceService.hideModal();
  }

  startAttendance() {
    if (this.selectedCity.id !== 9999 && !this.selectedCity.isAttended) {
      this._attendanceService.showModal({ componentId: 'attendance.notCommercial' });
      return;
    }

    const link = this.onDashboard
      ? 'home-logado/atendimento/contato/email'
      : 'home/atendimento/contato';

    this._attendanceService.navigate(link);
  }

  setOnReconnection() {
    this.path = this.router.url;
    if (this.path === '/home/servicos/religa' || this.path === '/home-logado/servicos/religa') {
      this.onReconnection = true;
    }
  }

  setScheduleAttendance() {
    this.path = this.router.url;
    if (this.path.includes('agendar-atendimento')) {
      this.onScheduleAttendance = true;
    }
  }

  setAttendance() {
    this.path = this.router.url;
    if (this.path === '/home-logado/atendimento' || this.path === '/home/atendimento') {
      this.isAttendance = true;
    }
  }

  /**
   * Get telefone das unidades
   */
  private _getPhones() {
    this.phoneData = this.selectedCity.configCustomerService.phones
      .customerService as ICityPhonesNotSelected[];

    this.phoneData = this.phoneData.slice().sort((a, b) => a.label.localeCompare(b.label));
    this.phoneData = [
      ...this.phoneData,
      ...this.selectedCity.configCustomerService.phones.pwd,
    ] as ICityPhonesNotSelected[];
  }

  /**
   * Verifica se está em um device touch
   */
  private _setScreen() {
    this._attendanceService.onMobile$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.onMobile = data));
  }

  /**
   * Busca a cidade selecionada no store
   */
  private _getSelectedCity() {
    combineLatest([
      this._attendanceService.selectedCity$,
      this._attendanceService.notSelectedCity$,
    ]).subscribe(([selectedCity, notSelectedCity]) => {
      if (selectedCity) {
        this.selectedCity = selectedCity;
        this.phones = selectedCity.phones;
      } else if (notSelectedCity) {
        this.selectedCity = notSelectedCity;
        const ouvid = notSelectedCity.configCustomerService.phones
          .ombudsman as ICityPhonesNotSelected[];
        const pwd = notSelectedCity.configCustomerService.phones.pwd as ICityPhonesNotSelected[];
        this.phones = {
          telAtendimento: '',
          telAuditivo: pwd[0].number,
          telOuvidoria: ouvid[0].number,
        };
        this._getPhones();
      }
    });
  }

  private _setAttendanceCity() {
    this._attendanceService.selectedCity$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      if (data) {
        this.attendanceCity = data;
      }
    });
  }

  private _selectAgenciesByIdCity(): void {
    if (this.selectedCity && this.selectedCity.isAttended) {
      this._attendanceService
        .getAgencies(this.selectedCity.id)
        .pipe(takeUntil(this.onDestroy))
        .subscribe((stores: IAttendanceAgencies[]) => {
          this.agencies = stores;
          this.notDisplayAttendanceAgencies = this.agencies.length <= 0;
        });
    }
  }

  private _setWebchatActive(): void {
    this._attendanceService.selectedCityServices$
      .pipe(
        takeUntil(this.onDestroy),
        map((services) => ActiveService.create(services)),
      )
      .subscribe((services) => {
        this.isWebchatActive = services.isServiceActive(
          servicesCategory.information,
          servicesList.webchat,
        );
      });
  }

  private _sanitizeHTML(textHTML: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(textHTML);
  }

  private _loadCardMessage() {
    this.path = this.router.url;
    this._publicPath = this.path === '/home-logado/atendimento' ? 'home-logado' : 'home';
    const messageType = this.path === '/home-logado/atendimento' ? this.LOGGED : this.NOT_LOGGED;

    this._messageService
      .getMessageByServiceIdAndType(ServiceId.CANAIS_DE_ATENDIMENTO, messageType)
      .subscribe(
        (data) => {
          if (!data?.descricao) {
            this.cardEmailMessage = this._sanitizeHTML(this._defaultCardEmailMessage);
          }
          this.cardEmailMessage = this._sanitizeHTML(data.descricao);
        },
        () => {
          this.cardEmailMessage = this._sanitizeHTML(this._defaultCardEmailMessage);
        },
      );
  }
}
