import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-upload-warnings',
  templateUrl: './file-upload-warnings.component.html',
  styleUrls: ['./file-upload-warnings.component.scss'],
})
export class FileUploadWarningsComponent {
  @Input() remainingSize = 10;
  @Input() emptyStorage = 0;
  @Input() remainingSizeThreshold = 5;
}
