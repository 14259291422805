export class SetOnMobileAction {
  public static readonly type = '[Settings] Set on mobile';
  constructor(public payload: boolean) {}
}

export class SetUnsupportedBrowser {
  public static readonly type = '[Settings] Set unsupported browser agreement';
  constructor(public payload: boolean) {}
}

export class SetAvailableFeatures {
  public static readonly type = '[Settings] Set available features';
  constructor(public payload: Array<string>) {}
}
