import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { AcnConfigurationService } from '@acn/angular/core/configuration';
import { TRANSLOCO_SERVER_I18N } from './transloco-server-i18n.token';
import { isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';
import cacheBusting from '../../../../../../i18n-cache-busting.json';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AcnConfigurationService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(TRANSLOCO_SERVER_I18N) @Optional() serverI18nTranslations: Translation | null,
  ) {
    this._serverI18nTranslations = serverI18nTranslations ?? {};
    this._isServer = isPlatformServer(this.platformId);
  }

  private readonly _serverI18nTranslations: Translation;
  private readonly _isServer: boolean;

  getTranslation(lang: string): Observable<Translation> | Promise<Translation> {
    if (this._isServer) {
      return Promise.resolve(this._serverI18nTranslations[lang]);
    }
    const path = `${this.appConfig.settings.baseUrl}/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
    return this.http.get(path);
  }
}
