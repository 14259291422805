// import { generateCNPJ } from './../utils/generator';

// export function validateCNPJ(cnpj: any) {
//     cnpj = cnpj.replace(/[^\d]+/g, '');
//     const tamanho = cnpj.length - 2;
//     const digitos = cnpj.substring(tamanho);
//     const resultados = generateCNPJ(cnpj);
//     if (resultados[0] !== parseInt(digitos.charAt(0), 10)) {
//         return false;
//     }

//     if (resultados[1] !== parseInt(digitos.charAt(1), 10)) {
//         return false;
//     }
//     return true;
// }

// tslint:disable-next-line:cyclomatic-complexity
/**
 * Verifica se o CNPJ possui um valor válido
 *
 * @param cnpj
 * @returns retorna um código e uma mensagem descrevendo o erro ou sucesso caso seja válido
 */
export function validateCNPJ(cnpj: string) {
  if (!cnpj) {
    return { code: 1, message: 'value is empty' };
  }
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) {
    return { code: 2, message: 'incorrect number of characters' };
  }

  const cnpjInvalid = { code: 3, message: 'cnpj invalid' };

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return cnpjInvalid;
  }

  let tamanho: number = cnpj.length - 2;
  let numeros: string = cnpj.substring(0, tamanho);
  const digitos: string = cnpj.substring(tamanho);
  let soma = 0;
  let pos: number = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado: number = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) {
    return cnpjInvalid;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) {
    return cnpjInvalid;
  }
  return { code: 0, message: 'success' };
}
