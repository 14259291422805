import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { BrowserService } from '../services/browser/browser.service';
import { SettingsStoreService } from '@store/app';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UnsupportedBrowserGuard implements CanActivate {
  constructor(
    private readonly browserService: BrowserService,
    private readonly settingsStoreService: SettingsStoreService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (
      !isPlatformBrowser(this.platformId) ||
      this.settingsStoreService.getUnsupportedAgreement() ||
      this.browserService.isBrowserValid()
    ) {
      return true;
    }
    this.browserService.setLastUrl(state.url);
    return this.router.parseUrl('/versao-navegador');
  }
}
