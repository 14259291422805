import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { IError } from './models/error.model';
import { AcnErrorService } from './error-service';
import { AcnLogService } from '../log/log.service';
import { AcnGuidService } from '../../shared/services/acn-guid.service';

/**
 * Serviço para identificação de Error
 */
@Injectable()
export class AcnErrorHandlerService implements ErrorHandler {
  /**
   * Constructor
   */
  constructor(
    private acnGuidService: AcnGuidService,
    private acnLogService: AcnLogService,
    private acnErrorService: AcnErrorService,
  ) {}

  /**
   * Identifica e retorna runtime error
   *
   * @returns retorna um ErrorModel com especificações de runtime
   */
  private _runtimeError(acnLogService: AcnLogService, error: Error): IError {
    const message = error.message ? error.message : error.toString();
    acnLogService.error('', this.acnGuidService.createGuid() + '-' + message + ' - ' + error.stack);

    return new IError('runtime', '999', message, error.stack);
  }

  /**
   * Identifica e retorna http error
   *
   * @returns retorna qual tipo de http error foi encontrado
   */
  private _httpError(acnLogService: AcnLogService, error: HttpErrorResponse) {
    const msg = error.error.message || error.message;
    const code = error.error.code || error.status;
    const errorObj = new IError('http', code.toString(), msg, '');

    if (typeof error.error === 'string' || error.error.correlationId === undefined) {
      errorObj.correlationId = this.acnGuidService.createGuid();
    }

    switch (error.status) {
      // Connection Refused
      case -1:
      case 0:
        errorObj.type = 'connection';
        break;
      // Unauthorized
      case 401:
        errorObj.type = 'authorization';
        errorObj.userMessage = 'Usuário ou senha não encontrados';
        acnLogService.error('', error.message);
        break;
      // Not Found
      case 404:
        acnLogService.error('', error.message);
        break;
      case 504:
        errorObj.userMessage = 'Sem resposta do servidor';
        acnLogService.error('', error.message);
        break;
      // 500 - Internal Server Error e outros erros
      default:
        break;
    }

    // Exibe erro no console
    return errorObj;
  }

  /**
   * Identifica e retorna os tipos de erros
   */
  handleError(error: Error | HttpErrorResponse) {
    let errorModel: IError;

    if (error instanceof HttpErrorResponse) {
      errorModel = this._httpError(this.acnLogService, error);
    } else {
      errorModel = this._runtimeError(this.acnLogService, error);
    }

    this.acnErrorService.next(errorModel);
  }
}
