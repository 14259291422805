import { isPresent } from '@acn/angular';

export interface IMetaTags {
  /**
   * @description adiciona <meta name="summary" content="{{summary}}" />
   */
  summary?: string;
  /**
   * @description adiciona <meta name="keywords" content="{{keywords}}" />
   */
  keywords?: string;
  /**
   * @description adiciona <meta name="description" content="{{description}}" />
   */
  description?: string;

  /**
   * @description adiciona <meta name="robots" content="index, follow" />
   */
  robots?: boolean;
}

export class MetaTags {
  private constructor({ robots, ...params }: IMetaTags) {
    this.params = new Map<string, string>();
    if (isPresent(robots)) {
      const content = robots ? 'index, follow' : 'noindex, nofollow';
      this.params.set('robots', content);
    }
    // Filtra somente os itens com algum valor
    const entries = Object.entries(params).filter(([, value]) => value);
    for (const [key, value] of entries) {
      this.params.set(key, value);
    }
  }

  static readonly key = '__meta__';
  static readonly tags: Array<keyof IMetaTags> = ['robots', 'summary', 'keywords', 'description'];

  readonly params: Map<string, string>;

  static create(params: IMetaTags): MetaTags {
    return new MetaTags(params);
  }
}
