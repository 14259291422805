import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AcnConnectorService, IRequest } from '@acn/angular';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private readonly GM_URL = 'localizacao';
  private readonly GM_AUTOCOMPLETE = 'localizacao/autocompletar';

  constructor(private _connectorApi: AcnConnectorService) {}

  getInputAddress(input: string) {
    const req: IRequest = {
      endPoint: this.GM_AUTOCOMPLETE,
      showLoading: false,
      checkToken: false,
      queryString: { input: input },
    };

    return this._connectorApi.get(req).pipe(
      map((res: any) => {
        let input = [];

        for (const item of res.predictions) {
          let city = {
            description: item.description,
            place_id: item.place_id,
          };
          input.push(city);
        }
        return input;
      }),
    );
  }

  getGeoLocationCity(lat: number, lng: number) {
    const req: IRequest = {
      endPoint: this.GM_URL,
      showLoading: true,
      checkToken: false,
      queryString: { latlng: `${lat},${lng}` },
    };

    return this._connectorApi.get(req).pipe(
      map((res: any) => {
        const [address] = res.results;
        let city: string;
        let state: string;
        let inputAddress = address.formatted_address;

        for (const item of address.address_components) {
          if ((item.types as string[]).includes('administrative_area_level_2')) {
            city = item.long_name;
          }

          if ((item.types as string[]).includes('administrative_area_level_1')) {
            state = item.short_name;

            if (!city) {
              city = item.long_name;
            }
          }
        }

        return { city, state, inputAddress };
      }),
    );
  }

  getGeoLocationCityPlaceId(place_id: string) {
    const req: IRequest = {
      endPoint: this.GM_URL,
      showLoading: true,
      checkToken: false,
      queryString: { place_id: place_id },
    };

    return this._connectorApi.get(req).pipe(
      map((res: any) => {
        let location = res.results[0].geometry;
        return { location };
      }),
    );
  }
}
