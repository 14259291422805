import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AutoReconnectionState } from './auto-reconnnection.state';
import {
  IAutoReconnection,
  IAutoReconnectionStateModel,
  IReconnectionSchedule,
  IReconnectionTypes,
} from './models';
import {
  SetEaselLocation,
  ResetAutoReconnection,
  SetUrlBack,
  SetBody,
  SetReconnectionTypes,
  SetSelectedReconnection,
  SetCutRisk,
  SetProtocol,
  SetIdServicesSanLinkAV,
} from './auto-reconnection.actions';
import { easelLocationOptions } from '@features/auto-reconnection/enumerators/easel-location-options.enum';

@Injectable({
  providedIn: 'root',
})
export class AutoReconnectionStoreService {
  @Select(AutoReconnectionState.getState)
  public autoReconnectionState$: Observable<IAutoReconnectionStateModel>;

  @Select(AutoReconnectionState.getEaselLocation)
  public easelLocation$: Observable<boolean>;

  @Select(AutoReconnectionState.getReconnectionTypes)
  public reconnectionTypes$: Observable<IReconnectionSchedule>;

  @Select(AutoReconnectionState.getSelectedReconnection)
  public selectedReconnection$: Observable<IReconnectionTypes>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setEaselLocation(easelLocation: easelLocationOptions) {
    return new SetEaselLocation(easelLocation);
  }

  @Dispatch()
  public setUrlBack(url: string) {
    return new SetUrlBack(url);
  }

  @Dispatch()
  public setProtocol(protocol: string) {
    return new SetProtocol(protocol);
  }

  @Dispatch()
  public reset() {
    return new ResetAutoReconnection();
  }

  @Dispatch()
  public setBody(body: IAutoReconnection) {
    return new SetBody(body);
  }

  @Dispatch()
  public setReconnectionTypes(reconnectionTypes: IReconnectionSchedule) {
    return new SetReconnectionTypes(reconnectionTypes);
  }

  @Dispatch()
  public setCutRisk(cutRisk: boolean) {
    return new SetCutRisk(cutRisk);
  }

  @Dispatch()
  public setSelectedReconnection(reconnectionTypes: IReconnectionTypes) {
    return new SetSelectedReconnection(reconnectionTypes);
  }

  @Dispatch()
  public setIdServicesSanLinkAV(idServicesSanLinkAV: number) {
    return new SetIdServicesSanLinkAV(idServicesSanLinkAV);
  }

  getEaselLocation(): easelLocationOptions {
    return this._store.selectSnapshot(AutoReconnectionState.getEaselLocation);
  }

  getUrlBack(): string {
    return this._store.selectSnapshot(AutoReconnectionState.getUrlBack);
  }

  getCutRisk(): boolean {
    return this._store.selectSnapshot(AutoReconnectionState.getCutRisk);
  }

  getProtocol(): string {
    return this._store.selectSnapshot(AutoReconnectionState.getProtocol);
  }

  getBody(): IAutoReconnection {
    return this._store.selectSnapshot(AutoReconnectionState.getBody);
  }

  getReconnectionTypes(): IReconnectionSchedule {
    return this._store.selectSnapshot(AutoReconnectionState.getReconnectionTypes);
  }

  getSelectedReconnection(): IReconnectionTypes {
    return this._store.selectSnapshot(AutoReconnectionState.getSelectedReconnection);
  }

  getIdServicesSanLinkAV(): number {
    return this._store.selectSnapshot(AutoReconnectionState.getIdServicesSanLinkAV);
  }
}
