import {
  APP_BASE_HREF,
  CommonModule,
  DecimalPipe,
  isPlatformBrowser,
  registerLocaleData,
} from '@angular/common';
import {
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  Inject,
  LOCALE_ID,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsStoreModule } from '@store/store.module';
import localePt from '@angular/common/locales/pt';

import {
  AcnAuthInterceptor,
  AcnConfigurationService,
  AcnErrorHandlerService,
  DYNAMIC_COMPONENT,
  IAcnDynamicComponentManifest,
  IConfiguration,
  SERVER_CONFIG,
} from '@acn/angular';

import { SharedModule } from '@shared/shared.module';
import { LandingModule } from '@features/landing/landing.module';
import { sharedDynamicComponentsDto } from '@shared/utils/shared-dynamic-components-manifest.dto';
import { acnUiDynamicComponentsDto } from '@shared/utils/acn-ui-dynamic-components-manifest.dto';
import { geolocalizationDynamicComponentsDto } from '@features/geolocalization-dynamic-components-manifest.dto';
import { registerDynamicComponentsDto } from '@features/register-dynamic-components-manifest.dto';
import { attendanceDynamicComponentsDto } from '@features/attendance-dynamic-components-manifest.dto';
import { duplicateDynamicComponentsDto } from '@features/duplicate-dynamic-components-manifest.dto';
import { loginDynamicComponentsDto } from '@features/login-dynamic-components-manifest.dto';
import { accountDynamicComponentsDto } from '@features/account-dynamic-components-manifest.dto';
import { creditCardDynamicComponentsDto } from '@features/credit-card-dynamic-components-manifest.dto';
import { reconnectionDynamicComponentsDto } from '@features/reconnection-dynamic-components-manifest.dto';
import { pixDynamicComponentsDto } from '@features/pix-dynamic-components-manifest.dto';
import { reportDynamicComponentsDto } from '@features/report-dynamic-components-manifest.dto';
import { termUseDynamicComponentsDto } from '@features/term-use-dynamic-components-manifest.dto';
import { automaticDebitTermOfUseDynamicComponentsDto } from '@features/automatic-debit-term-of-use-manifest.dto';
import { informationServicesDynamicComponentsDto } from '@features/information-services-components-manifest.dto';
import { automaticDebitServiceDynamicComponentsDto } from '@features/automatic-debit-service-components-manifest.dto';
import { LackOfWaterDynamicComponentsDto } from '@features/lack-of-water-dynamic-components-manifest.dto';
import { WINDOW, WindowProvider } from '@shared/services/window/window.token';
import { technicalServicesDynamicComponentsManifestDto } from '@features/technical-services-dynamic-components-manifest.dto';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrkHammerConfig } from '../hammer.config';
import { environment } from '@env';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ACN_CONFIGURATION } from '@acn/angular/core/configuration/acn-configuration.token';
import { invoiceSimulatorDynamicComponentsManifestDto } from '@features/invoice-simulator-dynamic-components-manifest.dto';
import { LoadChildrenCallback } from '@angular/router';
import { billingDynamicComponentsManifestDto } from '@features/campaign-modal-dynamic-components-manifest.dto';

// Array de components dinâmicos
export const dynamicComponents: Array<IAcnDynamicComponentManifest> = [
  ...acnUiDynamicComponentsDto,
  ...sharedDynamicComponentsDto,
  ...geolocalizationDynamicComponentsDto,
  ...registerDynamicComponentsDto,
  ...loginDynamicComponentsDto,
  ...attendanceDynamicComponentsDto,
  ...duplicateDynamicComponentsDto,
  ...creditCardDynamicComponentsDto,
  ...accountDynamicComponentsDto,
  ...reconnectionDynamicComponentsDto,
  ...pixDynamicComponentsDto,
  ...reportDynamicComponentsDto,
  ...termUseDynamicComponentsDto,
  ...automaticDebitServiceDynamicComponentsDto,
  ...informationServicesDynamicComponentsDto,
  ...automaticDebitTermOfUseDynamicComponentsDto,
  ...LackOfWaterDynamicComponentsDto,
  ...technicalServicesDynamicComponentsManifestDto,
  ...invoiceSimulatorDynamicComponentsManifestDto,
  ...billingDynamicComponentsManifestDto,
];

// Factory to DYNAMIC_COMPONENT
export function lazyArrayToObj() {
  const result: Record<string, LoadChildrenCallback> = {};
  for (const w of dynamicComponents) {
    result[w.path] = w.loadChildren;
  }
  return result;
}

registerLocaleData(localePt, 'pt');

/*
  core.module
  Interceptors, global components and singleton services that
  you load to be used to instantiate your app.
  Don't import modules or non-singleton service
*/
@NgModule({
  imports: [CommonModule, HttpClientModule, NgxsStoreModule, LandingModule, SharedModule],
  providers: [
    WindowProvider,
    {
      provide: ACN_CONFIGURATION,
      useFactory: (
        serverConfig: Partial<IConfiguration> | null,
        platformId: string,
        window: Window,
      ) => {
        // Verificação necessária, pois no server side rendering não teremos essa variável;
        if (isPlatformBrowser(platformId) && window['$$__brk-config']) {
          return window['$$__brk-config'];
        }
        return { ...environment, ...serverConfig };
      },
      deps: [[new Optional(), new Inject(SERVER_CONFIG)], PLATFORM_ID, WINDOW],
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: BrkHammerConfig },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_INTERCEPTORS, useClass: AcnAuthInterceptor, multi: true },
    { provide: DYNAMIC_COMPONENT, useFactory: lazyArrayToObj },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: ErrorHandler, useClass: AcnErrorHandlerService },
    DecimalPipe,
    { provide: RECAPTCHA_LANGUAGE, useValue: 'pt-BR' },
    {
      provide: RECAPTCHA_SETTINGS,
      deps: [AcnConfigurationService],
      useFactory: (config: AcnConfigurationService): RecaptchaSettings => ({
        siteKey: config.settings.captchaKey,
      }),
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`,
      );
    }
  }
}
