import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { ISecurityAccess } from './../models/security-access.model';

/**
 * Serviço responsável pelo gerenciamento de acesso de segurança
 */
@Injectable({
  providedIn: 'root',
})
export class AcnSecurityAccessService {
  /** @ignore */
  private securitySubject = new BehaviorSubject<ISecurityAccess>({
    route: '',
    type: 'init',
  });

  public securityErrors$ = this.securitySubject.asObservable();

  /** @ignore */
  constructor() {}

  /**
   * Pega o próximo objeto
   *
   * @param value
   */
  next(value: ISecurityAccess) {
    this.securitySubject.next(value);
  }
}
