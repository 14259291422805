import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Data, Event, Router } from '@angular/router';
import { filter, map, observeOn, pluck } from 'rxjs/operators';
import { asyncScheduler, Observable } from 'rxjs';

function isActivationEnd(event: Event): event is ActivationEnd {
  return event instanceof ActivationEnd;
}

@Injectable({ providedIn: 'root' })
export class RouterHelperService {
  constructor(private readonly router: Router) {}

  selectLatestSnapshot(): Observable<ActivatedRouteSnapshot> {
    return this.router.events.pipe(
      filter(isActivationEnd),
      observeOn(asyncScheduler),
      pluck('snapshot'),
    );
  }

  selectLatestData(): Observable<Data> {
    return this.selectLatestSnapshot().pipe(
      map((snapshot) => {
        let state = snapshot;
        let data: Data = {};
        while (state.firstChild) {
          data = { ...data, ...state.data };
          state = state.firstChild;
        }
        return { ...data, ...state.data };
      }),
    );
  }
}
