import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ResetSimulator,
  SetApiData,
  SetFormData,
  SetSimulator,
  SetUrlBack,
} from './invoice-simulator.actions';
import { IInvoiceSimulatorState } from './models';
import { Injectable } from '@angular/core';

const INITIAL_STATE: IInvoiceSimulatorState = {
  simulator: null,
  formData: null,
};

@Injectable()
@State<IInvoiceSimulatorState>({
  name: 'invoiceSimulator',
  defaults: INITIAL_STATE,
})
export class InvoiceSimulatorState {
  @Selector()
  public static getState(state: IInvoiceSimulatorState) {
    return state;
  }

  @Selector()
  public static getSimulator(state: IInvoiceSimulatorState) {
    return state.simulator;
  }

  @Selector()
  public static getFormData(state: IInvoiceSimulatorState) {
    return state.formData;
  }

  @Selector()
  public static getApiData(state: IInvoiceSimulatorState) {
    return state.apiData;
  }

  @Selector()
  public static getUrlBack(state: IInvoiceSimulatorState) {
    return state.urlBack;
  }

  @Action(SetSimulator)
  public setNewInstallationData(
    ctx: StateContext<IInvoiceSimulatorState>,
    { payload }: SetSimulator,
  ) {
    ctx.patchState({ simulator: payload });
  }

  @Action([ResetSimulator])
  public reset(ctx: StateContext<IInvoiceSimulatorState>) {
    ctx.patchState(INITIAL_STATE);
  }

  @Action(SetFormData)
  public setFormData(ctx: StateContext<IInvoiceSimulatorState>, { payload }: SetFormData) {
    ctx.patchState({ formData: payload });
  }

  @Action(SetApiData)
  public setApiData(ctx: StateContext<IInvoiceSimulatorState>, { payload }: SetApiData) {
    ctx.patchState({ apiData: payload });
  }

  @Action(SetUrlBack)
  public setUrlBack(ctx: StateContext<IInvoiceSimulatorState>, { payload }: SetUrlBack) {
    ctx.patchState({ urlBack: payload });
  }
}
