import {
  IDebitResponse,
  IBillResponse,
  IReconnectResponse,
} from '@features/billing/services/models';

export class SetDebits {
  public static readonly type = '[Billing] Set debits';
  constructor(public payload: IDebitResponse) {}
}

export class SetBills {
  public static readonly type = '[Billing] Set bills';
  constructor(public payload: IBillResponse) {}
}

export class SetBillsLoaded {
  public static readonly type = '[Billing] Set bills loaded';
  constructor(public payload: boolean) {}
}

export class SetDebitsLoaded {
  public static readonly type = '[Billing] Set debits loaded';
  constructor(public payload: boolean) {}
}
export class ShowDebits {
  public static readonly type = '[Billing] Show debits';
  constructor(public payload: boolean) {}
}

export class PayAll {
  public static readonly type = '[Billing] Pay all debits';
  constructor(public payload: boolean) {}
}

export class Reconnection {
  public static readonly type = '[Billing] Set reconnection warning';
  constructor(public payload: IReconnectResponse) {}
}

export class SetLoadedReconnection {
  public static readonly type = '[Billing] Set loaded reconnection';
  constructor(public payload: boolean) {}
}

export class ResetBilling {
  public static readonly type = '[Billing] reset';
}

export class Reset {
  public static readonly type = '[RESET]';
}
