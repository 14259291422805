import { IBankData } from './models/bankData.model';
import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';

import { IBank } from '@features/services/models/bank.model';
import {
  ResetBanks,
  ResetSelectedBank,
  Reset,
  AddBanksList,
  AddSelectedBank,
  AddBankData,
  AddBillingType,
  ResetNoticeType,
  AddNoticeType,
  ResetReplaceAutomaticDebit,
  AddReplaceAutomaticDebit,
  ResetBillingType,
  AddIsAutomaticDebitNewFlow,
} from './automatic-debit-service.actions';
import { IBillingType } from '@features/account/models/billing-type.model';

export interface AutomaticDebitServiceStateModel {
  billingType: IBillingType;
  selectedBank: IBank;
  banksList: Array<IBank>;
  noticeType: number;
  replaceAutomaticDebit: boolean;
  bankData: IBankData;
  isAutomaticDebitNewFlow: boolean;
}

const INITIAL_STATE: AutomaticDebitServiceStateModel = {
  billingType: null,
  selectedBank: null,
  banksList: null,
  noticeType: null,
  replaceAutomaticDebit: null,
  bankData: null,
  isAutomaticDebitNewFlow: false,
};

@State<AutomaticDebitServiceStateModel>({
  name: 'automaticDebitService',
  defaults: INITIAL_STATE,
})
@Injectable()
export class AutomaticDebitServiceState {
  @Selector()
  public static getState (state: AutomaticDebitServiceStateModel) {
    return state;
  }

  @Selector()
  public static getSelectedBank (state: AutomaticDebitServiceStateModel) {
    return state.selectedBank;
  }

  @Selector()
  public static getReplaceAutomaticDebit (state: AutomaticDebitServiceStateModel) {
    return state.replaceAutomaticDebit;
  }

  @Selector()
  public static getBanks (state: AutomaticDebitServiceStateModel) {
    return state.banksList;
  }

  @Selector()
  public static getBankData (state: AutomaticDebitServiceStateModel) {
    return state.bankData;
  }

  @Selector()
  public static getBillingType (state: AutomaticDebitServiceStateModel) {
    return state.billingType;
  }

  @Selector()
  public static getNoticeType (state: AutomaticDebitServiceStateModel) {
    return state.noticeType;
  }

  @Selector()
  public static getIsAutomaticDebitNewFlow (state: AutomaticDebitServiceStateModel) {
    return state.isAutomaticDebitNewFlow;
  }

  @Action(ResetSelectedBank)
  public resetSelectedBank (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedBank: null });
  }

  @Action(ResetReplaceAutomaticDebit)
  public resetReplaceAutomaticDebit (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, replaceAutomaticDebit: null });
  }

  @Action(ResetBanks)
  public resetBanks (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, banksList: null });
  }

  @Action(Reset)
  public reset (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    ctx.setState({ ...INITIAL_STATE });
  }

  @Action(ResetNoticeType)
  public resetNoticeType (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, noticeType: null });
  }

  @Action(ResetBillingType)
  public resetBillingType (ctx: StateContext<AutomaticDebitServiceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, billingType: null });
  }

  @Action(AddSelectedBank)
  public addSelectedBank (
    ctx: StateContext<AutomaticDebitServiceStateModel>,
    { payload }: AddSelectedBank,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, selectedBank: payload });
  }

  @Action(AddReplaceAutomaticDebit)
  public addReplaceAutomaticDebit (
    ctx: StateContext<AutomaticDebitServiceStateModel>,
    { payload }: AddReplaceAutomaticDebit,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, replaceAutomaticDebit: payload });
  }

  @Action(AddIsAutomaticDebitNewFlow)
  public addIsAutomaticDebitNewFlow (
    context: StateContext<AutomaticDebitServiceStateModel>,
    { payload }: AddIsAutomaticDebitNewFlow,
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      isAutomaticDebitNewFlow: payload,
    });
  }

  @Action(AddBanksList)
  public addBanks (ctx: StateContext<AutomaticDebitServiceStateModel>, { payload }: AddBanksList) {
    const state = ctx.getState();
    ctx.setState({ ...state, banksList: payload });
  }

  @Action(AddBankData)
  public addBankData (ctx: StateContext<AutomaticDebitServiceStateModel>, { payload }: AddBankData) {
    const state = ctx.getState();
    ctx.setState({ ...state, bankData: payload });
  }

  @Action(AddBillingType)
  public addBillingType (
    ctx: StateContext<AutomaticDebitServiceStateModel>,
    { payload }: AddBillingType,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, billingType: payload });
  }

  @Action(AddNoticeType)
  public addNoticeType (
    ctx: StateContext<AutomaticDebitServiceStateModel>,
    { payload }: AddNoticeType,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, noticeType: payload });
  }
}
