import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ReportState, ReportStateModel } from './report.state';
import { IReportProblemFile } from '@features/report/models/report-problem.model';

import { ResetReportStore, SetFiles } from './report.actions';

@Injectable({
  providedIn: 'root',
})
export class ReportStoreService {
  @Select(ReportState.getState)
  public easyInvoiceState$: Observable<ReportStateModel>;

  @Select(ReportState.getFiles)
  public getFiles$: Observable<Array<IReportProblemFile>>;

  constructor(private _store: Store) {}

  public getFiles() {
    return this._store.selectSnapshot(ReportState.getFiles);
  }

  @Dispatch()
  public resetReportStore() {
    return new ResetReportStore();
  }

  @Dispatch()
  public setFiles(files: Array<IReportProblemFile>) {
    return new SetFiles(files);
  }
}
