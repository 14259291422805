import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NewInstallationState } from './new-installation.state';
import {
  INewInstallationAddressData,
  INewInstallationDocsData,
  INewInstallationHolderData,
  INewInstallationInstallationData,
  INewInstallationStateModel,
} from './models';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {
  SetUrlBack,
  ResetNewInstallation,
  SetNewInstallationData,
  SetNewInstallationDocuments,
  SetNewInstallationAddressData,
  SetRequestingDocs,
  SetHolderDocument,
  SetHasDebits,
  SetProtocolList,
  SetHolderData,
  SetSelectedNewInstallation,
  SetInstallationData,
  SetInstallationDocs,
} from './new-installation.actions';
import { INewInstallationData, INewInstallationInfo } from '@features/new-installation/models';
import { IDocumentList } from '@features/new-installation/services/models/document-list.model';
import { IProtocol } from '@features/new-installation/services/models/new-installation-protocol.model';

@Injectable({
  providedIn: 'root',
})
export class NewInstallationStoreService {
  @Select(NewInstallationState.getState)
  public newInstallationState$: Observable<INewInstallationStateModel>;

  @Select(NewInstallationState.getAddressData)
  public newInstallationAddressData$: Observable<INewInstallationAddressData>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setUrlBack(url: string) {
    return new SetUrlBack(url);
  }

  @Dispatch()
  public setNewInstallationData(newInstallationData: INewInstallationData) {
    return new SetNewInstallationData(newInstallationData);
  }

  @Dispatch()
  public setNewInstallationDocuments(documents: IDocumentList) {
    return new SetNewInstallationDocuments(documents);
  }

  @Dispatch()
  public setNewInstallationAddressData(addressData: INewInstallationAddressData) {
    return new SetNewInstallationAddressData(addressData);
  }

  @Dispatch()
  public setNewInstallationRequestingDocs(requestingDocs: Array<INewInstallationDocsData>) {
    return new SetRequestingDocs(requestingDocs);
  }

  @Dispatch()
  public setNewInstallationInstallationDocs(installationDocs: Array<INewInstallationDocsData>) {
    return new SetInstallationDocs(installationDocs);
  }

  @Dispatch()
  public setHolderDocument(document: string) {
    return new SetHolderDocument(document);
  }

  @Dispatch()
  public setHolderData(holderData: INewInstallationHolderData) {
    return new SetHolderData(holderData);
  }

  @Dispatch()
  public setHasDebits(hasDebits: boolean) {
    return new SetHasDebits(hasDebits);
  }

  @Dispatch()
  public setProtocolList(protocolList: Array<IProtocol>) {
    return new SetProtocolList(protocolList);
  }

  @Dispatch()
  public setInstallationData(installationData: INewInstallationInstallationData) {
    return new SetInstallationData(installationData);
  }

  @Dispatch()
  public reset() {
    return new ResetNewInstallation();
  }

  @Dispatch()
  public setSelectedNewInstallation(selectedNewInstallation: INewInstallationInfo) {
    return new SetSelectedNewInstallation(selectedNewInstallation);
  }

  getUrlBack(): string {
    return this._store.selectSnapshot(NewInstallationState.getUrlBack);
  }

  getNewInstallationData(): INewInstallationData {
    return this._store.selectSnapshot(NewInstallationState.getInstallationData);
  }

  getHolderData(): INewInstallationHolderData {
    return this._store.selectSnapshot(NewInstallationState.getHolderData);
  }

  getNewInstallationDocuments(): IDocumentList {
    return this._store.selectSnapshot(NewInstallationState.getInstallationDocuments);
  }

  getNewInstallationAddressData(): INewInstallationAddressData {
    return this._store.selectSnapshot(NewInstallationState.getAddressData);
  }

  getHolderDocument(): string {
    return this._store.selectSnapshot(NewInstallationState.getHolderDocument);
  }

  getHasDebits(): boolean {
    return this._store.selectSnapshot(NewInstallationState.getHasDebits);
  }

  getProtocolList(): Array<IProtocol> {
    return this._store.selectSnapshot(NewInstallationState.getProtocolList);
  }

  getNewInstallationRequestingDocs(): Array<INewInstallationDocsData> {
    return this._store.selectSnapshot(NewInstallationState.getRequestingDocs);
  }

  getNewInstallationInstallationDocs(): Array<INewInstallationDocsData> {
    return this._store.selectSnapshot(NewInstallationState.getInstallationDocs);
  }

  getSelectedNewInstallation(): INewInstallationInfo {
    return this._store.selectSnapshot(NewInstallationState.getSelectedNewInstallation);
  }

  getInstallationData(): INewInstallationInstallationData {
    return this._store.selectSnapshot(NewInstallationState.getInstalationData);
  }
}
