import { Injectable } from '@angular/core';

import { AcnConnectorService, IRequest } from '@acn/angular';
import { IPix } from '@features/duplicate/services/models/pix.model';

@Injectable({
  providedIn: 'root',
})
export class PixService {
  private readonly PUBLIC_PIX_URL = 'pix/v2/gerar';

  constructor (private _connectorApi: AcnConnectorService) {
  }

  getPix (tipo: string, idFaturaGuia: number, idCidade: number) {
    const req: IRequest = {
      endPoint: this.PUBLIC_PIX_URL,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        tipo,
      },
      body: {
        idFaturaGuia: idFaturaGuia,
        qrcode: {
          retornarCodigoPix: true,
          retornarURLImagem: false,
          larguraImagemPixels: 225,
          retornarBase64Imagem: true,
        },
      },
    };
    return this._connectorApi.post<IPix>(req);
  }
}
