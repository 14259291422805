import { Injectable, KeyValueDiffers } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MetaTags } from '@shared/services/meta/meta-tags';
import { map, takeUntil } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { RouterHelperService } from '@shared/services/router/router-helper.service';

@Injectable({ providedIn: 'root' })
export class MetaService {
  constructor(
    private readonly keyValueDiffers: KeyValueDiffers,
    private readonly meta: Meta,
    private readonly routerHelperService: RouterHelperService,
  ) {}

  private readonly _destroy$ = new Subject<void>();
  private readonly _keyValueDiffer = this.keyValueDiffers.find({}).create<string, string>();
  private _isDestroyed = false;

  private _selectData(): Observable<Map<string, string>> {
    return this.routerHelperService.selectLatestData().pipe(
      map((data) => {
        const meta: MetaTags | null | undefined = data[MetaTags.key];
        let metaTags: Map<string, string> = new Map();
        // Só retorna o que tem no 'data' se for uma instância de MetaTags, para evitar erros
        if (meta instanceof MetaTags) {
          metaTags = meta.params;
        }
        return metaTags;
      }),
    );
  }

  init(): void {
    if (this._isDestroyed) {
      throw new Error('MetaService should not be started again after it was destroyed');
    }
    this._selectData()
      .pipe(takeUntil(this._destroy$))
      .subscribe((meta) => {
        const diff = this._keyValueDiffer.diff(meta);
        if (!diff) {
          return;
        }
        diff.forEachAddedItem((item) => {
          this.meta.addTag({ name: item.key, content: item.currentValue });
        });
        diff.forEachChangedItem((item) => {
          this.meta.updateTag({ name: item.key, content: item.currentValue });
        });
        diff.forEachRemovedItem((item) => {
          this.meta.removeTag(`name='${item.key}'`);
        });
      });
  }

  destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._isDestroyed = true;
  }
}
