/**
 * @returns Remove caracteres especiais da string
 */
export function removeSpecialCharacters(value: string): string {
  if (typeof value !== 'string') {
    return null;
  }
  // return value.replace(/[^\w\s]/gi, '');
  return value.replace(/[^a-zA-Z0-9]/g, '');
}
