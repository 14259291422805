import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfOrCnpj',
})
export class CpfOrCnpjPipe implements PipeTransform {
  private readonly _maxLengthCnpj = 14;

  transform(value: string): string {
    if (value) {
      value = this._formatDocument(value);
      value = this._applyMaskForCpfOrCnpj(value);
    }
    return value;
  }

  private _formatDocument(value: string): string {
    value = value.replace(/\D/g, '');

    if (value.length > this._maxLengthCnpj) {
      const start = 0;
      value = value.substring(start, this._maxLengthCnpj);
    }

    return value;
  }

  private _applyMaskForCpfOrCnpj(value: string): string {
    switch (value.length) {
      case 4:
        value = value.replace(/(\d{3})(\d+)/, '$1.$2');
        break;
      case 5:
        value = value.replace(/(\d{3})(\d+)/, '$1.$2');
        break;
      case 6:
        value = value.replace(/(\d{3})(\d+)/, '$1.$2');
        break;
      case 7:
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
        break;
      case 8:
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
        break;
      case 9:
        value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
        break;
      case 10:
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
        break;
      case 11:
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
        break;
      case 12:
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3/$4');
        break;
      case 13:
      case 14:
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
        break;
    }

    return value;
  }
}
