export enum ServiceTypeId {
  AGENDAR_ATENDIMENTO_LOJA = 1,
  ATENDIMENTO = 2,
  NOTIFICAR_UM_PROBLEMA = 3,
  SOLICITACAO_CADASTRO_MINHA_BRK = 4,
  SOLICITACAO_DESCADASTRO_MINHA_BRK = 5,
  DOWNLOAD_PDF = 6,
  EMAIL = 7,
  CODIGO_DE_BARRAS = 8,
  GUIA_COM_ACRESCIMOS = 9,
  ALTERAR_DATA_VENCIMENTO = 10,
  ACOMPANHAMENTO_SOLICITACOES = 13,
  SOLICITACAO_DE_TROCA_DE_TITULARIDADE = 14,
  PIX = 15,
  AVISO_RELIGA = 16,
  PAGAMENTO_AUTOMATICO_COM_CARTAO_DE_CREDITO = 17,
  PAGAMENTO_COM_CARTAO_DE_CREDITO = 18,
  AUTOLEITURA = 20,
  CARTA_DE_SERVICOS = 21,
  ESTRUTURA_TARIFARIA = 22,
  DECLARACAO_NEGATIVA = 25,
  DECLARACAO_ANUAL_DE_DEBITOS = 26,
  NOTIFICACAO_DE_VAZAMENTO = 30,
  ALTERAR_EMAIL = 31,
  ALTERAR_CELULAR = 32,
  BAIXAR_2_VIA_PAGA = 33,
  ENVIAR_FATURA_PAGA_POR_EMAIL = 34,
  CONTAS_POR_EMAIL = 35,
  HISTORICO_DE_CONSUMO = 36,
  LABORATORIO_MOVEL = 37,
  ONDE_PAGAR_SUA_CONTA = 38,
  RECURSOS_ADMINISTRATIVOS = 39,
  TABELA_DE_SERVICOS = 40,
  VIABILIDADE_TECNICA_DE_REDE = 41,
  VISUALIZAR_OU_ALTERAR_DADOS_CADASTRAIS = 42,
  GUIA_COM_ACRESCIMO = 43,
  CODIGO_DE_BARRAS_SEGUNDA_VIA = 44,
  DOWNLOAD_PDF_SEGUNDA_VIA = 45,
  CANAIS_DE_ATENDIMENTO = 46,
  PAGAMENTO_COM_CARTAO_DE_CREDITO_SEGUNDA_VIA = 47,
  ADICIONAR_OU_REMOVER_IMOVEIS = 48,
  NOVOS_LOTEAMENTOS = 49,
  RELIGA_INFORMATIVA = 50,
  AGENDAMENTO_AUTOMATIZADO = 51,
  PIX_SEGUNDA_VIA = 52,
  RELIGACAO = 53,
  PARCELE_NA_HORA = 54,
  SMS = 56,
  EMAIL_COMUNICADOS = 57,
  CORTE_A_PEDIDO_NORMAL = 58,
  WEBCHAT = 59,
  LIGACAO_NOVA = 60,
  DETALHES_FATURA = 62,
  CONSULTA_DETALHE_DA_FATURA = 63,
  SIMULADOR_DE_FATURA = 64,
  FALTA_DE_AGUA = 65,
  VAZAMENTO = 66,
  ESGOTO = 67,
  OUTROS = 68,
  FALTA_DE_AGUA_NOTIFICAR_PROBLEMA = 69,
  VAZAMENTO_NOTIFICAR_PROBLEMA = 70,
  ESGOTO_NOTIFICAR_PROBLEMA = 71,
  OUTRO = 72,
  CALENDARIO_LEITURA = 74,
  PAGAR_COM_CARTAO = 97,
  PAGAR_COM_PIX = 98,
}
