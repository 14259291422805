import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { IBillingDetail } from '@features/account/models/billing-detail.model';

@Component({
  selector: 'app-automatic-debit',
  templateUrl: './automatic-debit.component.html',
  styleUrls: ['./automatic-debit.component.scss'],
})
export class AutomaticDebitComponent implements OnInit, OnChanges {
  @Input() billingDetail: IBillingDetail;
  public flagBank = '{bank}';
  @Input() baseImgUrl: string;
  public imgUrl = ' ';
  public bancoCadastrado: boolean;
  private _notBank = 'este imóvel possui débito automático cadastrado.';

  ngOnInit(): void {
    this._setImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.billingDetail) {
      this.bancoCadastrado = this.billingDetail?.nomeBanco?.toLowerCase() !== this._notBank;
    }
  }

  private _setImage(): void {
    this.imgUrl = `${this.baseImgUrl}/img/profile/deb_automatic.png`;
  }
}
