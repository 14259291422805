import { IConfiguration } from '@acn/angular';
import { environment } from '@env';

export async function getAcnConfiguration(): Promise<IConfiguration> {
  const response = await fetch(
    `${environment.baseHref}/assets/config/appConfig.json?v=${String(new Date().getTime())}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
      },
    },
  );
  if (response.ok) {
    const config = await response.json();
    return { ...environment, ...config };
  }
  return environment;
}
