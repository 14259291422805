import { IAcnDynamicComponentManifest } from '@acn/angular';

export const registerDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'register.documentError',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-document-error/register-modal-document-error.module'
      ).then((m) => m.RegisterModalDocumentErrorModule),
  },
  {
    path: 'register.documentExist',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-document-exist/register-modal-document-exist.module'
      ).then((m) => m.RegisterModalDocumentExistModule),
  },
  {
    path: 'register.confirmByEmail',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-confirm-by-email/register-modal-confirm-by-email.module'
      ).then((m) => m.RegisterModalConfirmByEmailModule),
  },
  {
    path: 'register.confirmBySms',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-confirm-by-sms/register-modal-confirm-by-sms.module'
      ).then((m) => m.RegisterModalConfirmBySmsModule),
  },
  {
    path: 'register.findCdc',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-find-cdc/register-modal-find-cdc.module'
      ).then((m) => m.RegisterModalFindCdcModule),
  },
  {
    path: 'register.privacyPolicy',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-privacy-policy/register-modal-privacy-policy.module'
      ).then((m) => m.RegisterModalPrivacyPolicyModule),
  },
  {
    path: 'register.genericModal',
    loadChildren: () =>
      import(
        '../features/register/components/register-modal-generic/register-modal-generic.module'
      ).then((m) => m.RegisterModalGenericModule),
  },
];
