<app-landing-menu
  class="menu"
  [isOnMobile]="onMobile"
  [selectedCity]="selectedCity"
  *ngIf="!onDashboard"
>
</app-landing-menu>

<ng-container *transloco="let text">
  <div class="page-wrapper container">
    <div class="circle circle--bottom-right" *ngIf="!onMobile && !onDashboard"></div>

    <div class="header">
      <div class="header__container">
        <h1
          class="title"
          [ngClass]="onMobile ? 'is-bold is-size-24' : 'is-bold is-size-40'"
          data-cy="easyDuplicate-title"
        >
          {{ text('supportChannels.attendance.title') }}
        </h1>
      </div>
      <acn-icon class="icon" icon="fa-arrow-circle-down" *ngIf="onMobile"></acn-icon>
    </div>
    <div class="header__bg" *ngIf="onMobile"></div>

    <div class="content">
      <ng-container *ngIf="!selectedCity || (selectedCity && !selectedCity.isAttended)">
        <div class="chooseCity">
          <p
            class="is-medium chooseCity__text is-size-16"
            [innerHTML]="text('supportChannels.attendance.text')"
            data-cy="easyDuplicate-choose-city-txt"
          ></p>
          <div class="content animated">
            <div class="content__form">
              <div class="form">
                <form [formGroup]="form" *ngIf="form" class="input-form">
                  <app-auto-complete
                    [form]="form"
                    formControlTitle="city"
                    class="select"
                    [label]="'supportChannels.attendance.cityName' | transloco"
                    [placeholder]="'supportChannels.attendance.placeholder' | transloco"
                    [notFoundMsg]="'supportChannels.attendance.autoCompleteNotFound' | transloco"
                    [showAfter]="3"
                    [textEnd]="form.value.city ? 'Limpar' : null"
                    (clickedEndIcon)="onClearForm()"
                    (selectedValue)="getSelectedCity($event)"
                    [removeOptionsFromList]="true"
                    nowrap
                    [items]="cities"
                    itemKey="label"
                    itemValue="value"
                  ></app-auto-complete>
                </form>
                <div class="buttons">
                  <acn-button
                    routerLinkActive="router-link-active"
                    class="is-fullWidth"
                    (click)="submit()"
                    [attr.disabled]="form && !form.valid ? 'disabled' : null"
                    acnGtm
                    [gtmCategory]="gtmCategory"
                    gtmAction="Acessar_Atendimento"
                  >
                    {{ 'supportChannels.attendance.submit' | transloco }}
                  </acn-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<footer class="footer" *ngIf="!onDashboard">
  <app-footer [isOnMobile]="onMobile" [selectedCity]="selectedCity"></app-footer>
</footer>
