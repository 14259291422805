import { Inject, Injectable } from '@angular/core';
import { Browser, detect } from 'detect-browser';
import { WINDOW } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class BrowserService {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  private readonly _supportedBrowsers: ReadonlyMap<Browser, number> = new Map<Browser, number>()
    .set('android', 7)
    .set('chrome', 86)
    .set('firefox', 82)
    .set('safari', 12)
    // Chrome iOS
    .set('crios', 86)
    // Facebook/Instagram Android
    .set('chromium-webview', 86)
    // Facebook iOS
    .set('facebook', 340)
    // Instagram iOS
    .set('instagram', 220)
    // Samsung internet (Chromium 83)
    // https://en.wikipedia.org/wiki/Samsung_Internet#:~:text=Version%2013.0.1.64-,Chromium%2083,-November%2019%2C%202020
    .set('samsung', 17)
    // Safari iOS
    .set('ios', 12)
    // Firefox iOS
    .set('fxios', 82)
    // Edge Chromium
    .set('edge-chromium', 86)
    // Edge iOS
    .set('edge-ios', 86)
    // iOS webview (Safari)
    // https://en.wikipedia.org/wiki/Safari_version_history#Safari_12_2
    .set('ios-webview', 606)
    // Opera
    // https://en.wikipedia.org/wiki/History_of_the_Opera_web_browser#:~:text=85.%5B142%5D-,Opera%2072,-was%20released%20on
    .set('opera', 72);

  private _lastUrl: string | null = null;

  /**
   * @description Set the last url to be used in {@link PageUnsupportedBrowserComponent}
   * @param {string | null} url
   */
  setLastUrl(url: string | null): void {
    this._lastUrl = url;
  }

  /**
   * @description Get the last url set by the {@link UnsupportedBrowserGuard}
   * @returns {string}
   */
  getLastUrl(): string {
    return this._lastUrl;
  }

  isBrowserValid(): boolean {
    // Get browser info
    const browserInfo = detect(this.window.navigator.userAgent);

    // detect() might return null
    if (!browserInfo) {
      return false;
    }

    // If it's a bot, then return true
    if (browserInfo.type === 'bot' || browserInfo.type === 'bot-device') {
      return true;
    }

    // If it's a browser, and has an entry in our map, then check the version
    if (browserInfo.type === 'browser' && this._supportedBrowsers.has(browserInfo.name)) {
      const versionSupported = this._supportedBrowsers.get(browserInfo.name);
      const [version] = browserInfo.version.split('.');
      // Return if the version is greater or equal
      return parseInt(version, 10) >= versionSupported;
    }

    // If it's not a bot, nor a browser, return false
    return false;
  }
}
