import { Injectable, Injector } from '@angular/core';

import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

import { AcnConnectorService, IRequest } from '@acn/angular';
import { BaseService } from '@shared/services';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { CitiesStoreService } from '@store/dashboard';

@Injectable({
  providedIn: 'root',
})
export class LackOfWaterService extends BaseService {
  private readonly URL_GET_WARNINGS: string = 'servicos/consultaAvisos';

  constructor(
    private _connectorApi: AcnConnectorService,
    protected _injector: Injector,
    private _selectCityService: SelectCityService,
    private _citiesStoreService: CitiesStoreService,
  ) {
    super(_injector);
  }

  public checkWaterShortageWarning(selectedCityId?: number | string) {
    const idCity = selectedCityId ?? this._selectCityService.getSelectedCity()?.id;

    const req: IRequest = {
      endPoint: this.URL_GET_WARNINGS,
      queryString: {
        idCity: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<{ description: string }>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public getWaterShortageAlert(): string {
    return this._citiesStoreService.getWaterShortageAlert();
  }
}
