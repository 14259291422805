import { AcnConnectorService, IRequest } from '@acn/angular';
import { Injectable, Injector } from '@angular/core';
import { IOptIn } from '@features/opt-in/models/opt-in.model';
import { SetOptIn } from '@features/opt-in/models/set-opt-in.model';
import { BaseService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OptInService extends BaseService {
  constructor(injector: Injector, private readonly _connectorApi: AcnConnectorService) {
    super(injector);
  }

  private readonly OPT_IN_URL = 'servicos/optIn';

  getOptIn(idCidade: number, cdc: string | number): Observable<IOptIn> {
    const req: IRequest = {
      endPoint: this.OPT_IN_URL,
      showLoading: true,
      queryString: {
        idLigacao: cdc,
        idCidade,
      },
    };

    return this._connectorApi.get<IOptIn>(req);
  }

  setOptIn(idCidade: number, cdc: number | string, body: SetOptIn): Observable<void> {
    const req: IRequest = {
      endPoint: this.OPT_IN_URL,
      showLoading: true,
      queryString: {
        idLigacao: cdc,
        idCidade: idCidade,
      },
      body: body,
    };

    return this._connectorApi.post<void>(req);
  }
}
