import { ICdcData } from './models/cdc-data.model';
import { IChangeHolderForm } from './models/change-holder-form.model';
import { IPropertyDocs } from './models/property-docs.model';
export class SetCdc {
  public static readonly type = '[ChangeHolder] set cdc';
  constructor(public payload: string) {}
}

export class SetDocument {
  public static readonly type = '[ChangeHolder] set document';
  constructor(public payload: string) {}
}

export class SetIsCompany {
  public static readonly type = '[ChangeHolder] set isCompany';
  constructor(public payload: boolean) {}
}

export class SetPropertyDocs {
  public static readonly type = '[ChangeHolder] set propertyDocs';
  constructor(public payload: Array<IPropertyDocs>) {}
}
export class SetForm {
  public static readonly type = '[ChangeHolder] set form';
  constructor(public payload: IChangeHolderForm) {}
}

export class SetCdcData {
  public static readonly type = '[ChangeHolder] set cdcData';
  constructor(public payload: ICdcData) {}
}

export class SetProtocolCode {
  public static readonly type = '[ChangeHolder] set protocolCode';
  constructor(public payload: number) {}
}

export class ResetChangeHolder {
  public static readonly type = '[ChangeHolder] RESET';
}

export class SetAcceptedDebts {
  public static readonly type =
    '[ChangeHolder] set if the user has accepted or not the installation debts';
  constructor(public payload: boolean) {}
}

export class SetPersonalDocs {
  public static readonly type = '[ChangeHolder] set personalDocs';
  constructor(public payload: Array<IPropertyDocs>) {}
}

export class SetNecessaryDocumentsInfo {
  public static readonly type = '[ChangeHolder] set necessary documents information';
  constructor(public payload: Array<object>) {}
}

export class SetCorroborativeDocs {
  public static readonly type = '[ChangeHolder] set corroborativeDocs';
  constructor(public payload: Array<IPropertyDocs>) {}
}

export class SetSuccessMessage {
  public static readonly type = '[ChangeHolder] set success message';
  constructor(public payload: string) {}
}
