<ng-container *transloco="let text; scope: 'duplicate'">
  <div class="registered" *ngIf="creditCard">
    <div class="cards">
      <acn-card class="card">
        <p
          class="credit-title is-size-14"
          *ngIf="!onMobile"
          [innerHtml]="text('duplicate.registeredCard.title')"
        ></p>
        <div class="card__content is-bold">
          <div class="flags">
            <div class="flag__rectangle"></div>
            <img class="flag" [src]="bgImage" alt="Marca cartão de crédito" />
          </div>
          <p
            class="is-size-14"
            [innerHtml]="text('duplicate.registeredCard.card', { card: creditCard.number })"
          ></p>
          <a
            class="edit-ml"
            [routerLink]="['/home-logado/pagamento/credito-automatico/editar-pagamento-automatico']"
            [innerHtml]="text('duplicate.registeredCard.edit')"
          >
          </a>
        </div>
        <div class="warningCard" *ngIf="isExpired || nearExpire">
          <div class="header">
            <div class="header__box" *ngIf="!isExpired && nearExpire">
              <span class="circle circle__toExpire">
                <acn-icon icon="fas fa-exclamation" class="circle__icon"></acn-icon>
              </span>
              <p class="is-bold" [innerHtml]="text('duplicate.warningCard.expiringCard')"></p>
            </div>

            <div class="header__box" *ngIf="isExpired">
              <span class="circle circle__expired">
                <acn-icon icon="fas fa-times" class="circle__icon"></acn-icon>
              </span>
              <p class="is-bold" [innerHtml]="text('duplicate.warningCard.expiredCard')"></p>
            </div>
          </div>

          <p class="content" [innerHtml]="text('duplicate.warningCard.registerAnotherCard')"></p>
        </div>
      </acn-card>
    </div>
  </div>
</ng-container>
