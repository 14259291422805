import { ElementRef, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { AcnConnectorService, IRequest } from '@acn/angular';
import { BaseService } from '@shared/services';
import { UserStoreService } from '@store/app';
import { StepStoreService } from '@store/dashboard';
import { InstallationService } from '@features/installation/services/installation.service';
import { IUpdateStatus } from '@features/account/models';
import { SmsService } from '@shared/services/sms/sms.service';
import { CreditCardService } from '@features/payment/credit-card/services/credit-card.service';
import { TermUseService } from '@features/terms/services/term-use.service';

import { IBillingType } from '@features/account/models/billing-type.model';
import { ClientService } from '@features/services/services/client.service';
import { IOptIn } from '@features/opt-in/models/opt-in.model';
import { SetOptIn } from '@features/opt-in/models/set-opt-in.model';
import { OptInService } from '@features/opt-in/services/opt-in.service';
import { EmailBillingResponseModel } from '@features/installation/services/models/email-billing-response.model';
import { IUpdateInstallationResponse } from '@features/installation/services/models/update-installation-response.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public user$ = this._userStore.user$;
  public selectedInstallation$ = this._installationService.selectedInstallation$;
  public installationList$ = this._installationService.installationList$;
  public installationUnlinkedList$ = this._installationService.installationUnlinkedList$;
  public steps$ = this._stepStore.steps$;
  public userId$ = this._userStore.id$;
  public automaticDebitTerms$ = this._termUseService.automaticDebitTerms$;
  public translatedNotifications$ = this._translocoService.selectTranslateObject('notifications');
  public translatedCdcLength$ = this._translocoService.selectTranslateObject(
    'cdcLength',
    {},
    'account',
  );
  public translatedAccountMsg$ = this._translocoService.selectTranslateObject(
    'messages',
    {},
    'account',
  );

  public translatedEmailBillingMsg$ = this._translocoService.selectTranslateObject(
    'emailBilling.messages',
    {},
    'installation',
  );

  public cardInfo$ = this._creditCardService.cardInfo$;

  private readonly PROFILE_UPDATE_PHONE = 'usuario/alteraTelefone';
  private readonly PROFILE_UPDATE_EMAIL = 'usuario/alteraEmail';
  private readonly PROFILE_UPDATE_PASSWORD = 'usuario/alteraSenha';
  private readonly PROFILE_BILLING_TYPE = 'perfil/tipoCobrancaV2';

  constructor(
    private _connectorApi: AcnConnectorService,
    private _userStore: UserStoreService,
    private _installationService: InstallationService,
    private _stepStore: StepStoreService,
    protected _injector: Injector,
    private _router: Router,
    private _translocoService: TranslocoService,
    private _smsService: SmsService,
    private _creditCardService: CreditCardService,
    private _termUseService: TermUseService,
    private _clientService: ClientService,
    private optInService: OptInService,
  ) {
    super(_injector);
  }

  onDashboard() {
    return this._settingsStore.onDashboard();
  }

  navigateToUpdate(route) {
    this._router.navigate([route]);
  }

  getCardInfo(showLoading: boolean = false) {
    return this._creditCardService.getCardInfo(showLoading);
  }

  setSteps(steps: Array<string>) {
    this._stepStore.setSteps(steps);
  }

  resetSteps() {
    this._stepStore.resetSteps();
  }

  scrollTop(el: ElementRef) {
    this._screenService.scrollTop(el);
  }

  getServices(idCity) {
    return this._clientService.getCityServices(idCity);
  }

  updatePhone(): Observable<IUpdateStatus> {
    return this._smsService.phone$.pipe(
      switchMap((phone) => {
        const req: IRequest = {
          endPoint: this.PROFILE_UPDATE_PHONE,
          showLoading: true,
          body: { tel_movel: phone },
        };

        return this._connectorApi.post(req).pipe(
          switchMap((res: any) => {
            this._userStore.updateUserPhone(phone);
            return of({ status: true, message: res.message });
          }),
          catchError((error) => of({ status: false, message: error.error })),
        );
      }),
    );
  }

  updateEmail(email: string): Observable<IUpdateStatus> {
    const req: IRequest = {
      endPoint: this.PROFILE_UPDATE_EMAIL,
      showLoading: true,
      body: { email },
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res: any) => {
        this._userStore.updateUserEmail(email);
        return of({ status: true, message: res.message });
      }),
      catchError((error) => of({ status: false, message: error.error })),
    );
  }

  updatePassword(currentPassword: string, newPassword: string): Observable<IUpdateStatus> {
    const req: IRequest = {
      endPoint: this.PROFILE_UPDATE_PASSWORD,
      showLoading: true,
      body: { senhaAtual: currentPassword, novaSenha: newPassword },
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res: any) => {
        return of({ status: true, message: res.message });
      }),
      catchError((error) => of({ status: false, message: error.error })),
    );
  }

  billingType(codCdc: string, idCidade: number, hasGenericError: boolean) {
    const req: IRequest = {
      endPoint: this.PROFILE_BILLING_TYPE,
      showLoading: true,
      queryString: {
        codCdc: codCdc,
        idCidade: idCidade,
      },
    };

    return this._connectorApi.get<IBillingType>(req).pipe(
      catchError(() => {
        if (hasGenericError) {
          this.setNotification(
            'is-warning',
            'Atenção!',
            'Caso você já tenha débito automático cadastrado, não se esqueça de descadastrar.',
          );
        }

        return of(false);
      }),
    );
  }

  getEmailBillingStatus(
    idCidade: number,
    cdc: string,
    showLoading = true,
  ): Observable<EmailBillingResponseModel> {
    return this._installationService.getEmailBillingStatus(idCidade, cdc, showLoading);
  }

  setEmailBillingStatus(
    idCidade: number,
    cdc: string,
    delivery: boolean,
  ): Observable<IUpdateInstallationResponse> {
    return this._installationService.setEmailBillingStatus(idCidade, cdc, delivery, true).pipe(
      tap(() => {
        this._installationService.setEmailBilling(delivery);
      }),
    );
  }

  navigate(route: string) {
    this._router.navigate([route]);
  }

  getSmsVerification(phone: string) {
    this._smsService.setPhone(phone);
    return this._smsService.getSmsVerification();
  }

  getOptIn(idCidade: number, cdc: string | number): Observable<IOptIn> {
    return this.optInService.getOptIn(idCidade, cdc);
  }

  setOptIn(idCidade: number, cdc: number | string, body: SetOptIn): Observable<any> {
    return this.optInService.setOptIn(idCidade, cdc, body);
  }
}
