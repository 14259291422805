import { BaseComponent } from '@acn/angular';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AttendanceService } from '@features/attendance/services/attendance.service';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { ClientService } from '@features/services/services/client.service';
import { INewCity } from '@store/dashboard';

import { IAttendanceAgencies } from '@store/dashboard/states/cities/models/attendance-agency.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-attendance-agencies',
  templateUrl: './attendance-agencies.component.html',
  styleUrls: ['./attendance-agencies.component.scss'],
})
export class AttendanceAgenciesComponent extends BaseComponent implements OnInit {
  agencies: Array<IAttendanceAgencies>;
  private _selectedCity: INewCity;
  public attendanceUrl = this._clientService.settings.smartAttendanceUrl;

  @Input() showOnlyWithLink? = false;
  @Input() transparentCard? = false;

  constructor(
    private _attendanceService: AttendanceService,
    private _selectCityService: SelectCityService,
    private _cd: ChangeDetectorRef,
    private _clientService: ClientService
  ) {
    super();
  }

  ngOnInit(): void {
    this._getSelectedCity();

    this._getAgencies();
  }

  private _getSelectedCity(): void {
    this._selectedCity = this._selectCityService.getSelectedCity();
  }

  private _getAgencies(): void {
    this._attendanceService
      .getAgencies(this._selectedCity.id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((list) => {
        if (this.showOnlyWithLink) {
          this.agencies = list.filter((item) => item.siteAgendamentoPresencial);
          if(this.agencies?.length > 0) {
            this._attendanceService.setUseDefaultAttendancePlatform(false);
          }
        } else {
          this.agencies = list;
        }
        this._cd.detectChanges();
      });
  }
}
