import { Component, Input } from '@angular/core';
import { BaseComponent } from '@acn/angular';

import { ICreditCard } from '@store/dashboard';

@Component({
  selector: 'app-credit-card-display',
  templateUrl: './credit-card-display.component.html',
  styleUrls: ['./credit-card-display.component.scss'],
})
export class CreditCardDisplayComponent extends BaseComponent {
  @Input() mobile = false;
  @Input() expired = false;
  @Input() toExpire = false;

  public bgImage: string;
  public active = false;
  public hasCode = false;

  @Input() set creditCard(value: ICreditCard) {
    this.hasCode = !!value?.code;
    const number = value?.number ? this._completeNumber(value.number) : this._completeNumber('');
    const code = value?.code ? this._completeNumber(value.code, 3) : this._completeNumber('', 3);
    this.active = !!Object.values(value).find((item) => item);
    this._creditCard = { ...value, number, code };
    this._setImage();
  }

  get creditCard() {
    return this._creditCard;
  }

  @Input() baseImgUrl: string;
  private _creditCard: ICreditCard;

  private _setImage() {
    this.bgImage = `${this.baseImgUrl}/img/credit-card/flag-${this.creditCard.brand}.svg`;
  }

  private _chunkNumber(number: string) {
    return number.match(/.{1,4}/g).join(' ');
  }

  private _completeNumber(value: string, length: number = 16) {
    while (value.length < length) {
      value += '*';
    }

    return this._chunkNumber(value);
  }
}
