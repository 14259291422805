import { AutomaticDebitServiceStoreService, ChangeExpiringDateStoreService } from '@store/home';
import { Injectable, Injector } from '@angular/core';
import { AcnAuthenticationService, AcnConnectorService, IRequest } from '@acn/angular';

import { ITermAutomaticDebit, UseTermsStoreService } from '@store/app';
import {
  CitiesStoreService,
  IInstallation,
  InstallationStoreService,
  StepStoreService,
} from '@store/dashboard';
import { BaseService } from '@shared/services/base/base.service';
import { RegisterStoreService } from '@store/register/states/register/register-store.service';

import { throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, switchMap, take } from 'rxjs/operators';

import { ITermUse } from './../models/term-use.model';
import { typesTermsUse } from '@shared/enumerators/types-terms-use.enum';
import { statusCode } from '@shared/enumerators/status-code.enum';
import { IBillingType } from '@features/account/models/billing-type.model';
import { meiosPagamento } from '@shared/enumerators/meiosPagamento.enum';
import { Router } from '@angular/router';
import { servicesCategory } from '@shared/enumerators/servicesCategory.enum';
import { servicesList } from '@shared/enumerators/servicesList.enum';
import { CreditCardService } from '@features/payment/credit-card/services/credit-card.service';
import { RouterService } from '@store/router/router.service';

@Injectable({
  providedIn: 'root',
})
export class TermUseService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public siteTerms$ = this._useTerms.siteTerms$;
  public automaticDebitTerms$ = this._useTerms.automaticDebitTerms$;
  public changeExpiringDate$ = this._changeExpiringDate.changeExpiringDateState$;
  public registerTerms$ = this._registerStore.acceptedTerms$;
  public versionCardTermsUse: number;
  public versionPlatformTermsUse: number;
  public cdc: string;
  public idCity: number;
  public cardTerm: ITermUse;
  public platformTerm: ITermUse;
  private _pendingSiteTerm: boolean = false;

  private GET_PUBLIC_TERMO_USO: string = 'termoUso/public/vigentes/';
  private GET_PRIVATE_TERMO_USO: string = 'termoUso/vigente/';
  private POST_PRIVATE_TERMO_USO: string = 'termoUso/vigente/';
  private POST_PUBLIC_TERMO_USO: string = 'termoUso/public/vigente/';

  constructor(
    private _changeExpiringDate: ChangeExpiringDateStoreService,
    private _stepStore: StepStoreService,
    private _useTerms: UseTermsStoreService,
    private _registerStore: RegisterStoreService,
    protected _injector: Injector,
    private _connectorApi: AcnConnectorService,
    private _automaticDebitStoreService: AutomaticDebitServiceStoreService,
    private _authService: AcnAuthenticationService,
    private _router: Router,
    private _installationStore: InstallationStoreService,
    private _citiesStore: CitiesStoreService,
    private _creditCardService: CreditCardService,
    private _routerService: RouterService,
  ) {
    super(_injector);
  }

  setSteps(steps: Array<string>) {
    this._stepStore.setSteps(steps);
  }

  resetSteps() {
    this._stepStore.resetSteps();
  }

  getSiteTerms() {
    return this._useTerms.getSiteTerms();
  }

  setSiteTerms(status: boolean) {
    this._useTerms.setSiteTerms(status);
  }

  getCreditCardTerms() {
    return this._useTerms.getCreditCardTerms();
  }

  getAutomaticDebitTerms() {
    return this._useTerms.getAutomaticDebitTerms();
  }

  setCreditCardTerms(status: boolean) {
    this._useTerms.setCreditCardTerms(status);
  }

  setAutomaticDebitTerms(status: ITermAutomaticDebit) {
    this._useTerms.setAutomaticDebitTerms(status);
  }

  onRegister() {
    return this._settingsStore.onRegister();
  }

  setRegisterTerms(status: boolean) {
    this._registerStore.setTerms(status);
  }

  getBillingType(): IBillingType {
    return this._automaticDebitStoreService.getBillingType();
  }

  registeredAutomaticDebit(item: boolean | IBillingType): boolean {
    return typeof item !== 'boolean' && item?.idTipoCobranca === meiosPagamento.debitoAutomatico;
  }

  getTermUse(idTerm: number) {
    const req: IRequest = {
      endPoint: this.GET_PUBLIC_TERMO_USO,
      queryString: {
        idTermo: idTerm,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<ITermUse[]>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getTermUsePlatformPrivate(idTerm: number) {
    const req: IRequest = {
      endPoint: this.GET_PRIVATE_TERMO_USO,
      queryString: {
        idTermo: idTerm,
      },
      showLoading: true,
      checkToken: true,
    };

    return this._connectorApi.get<ITermUse[]>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getTermUseCardPrivate(idTerm: number, idLigacao?: string, idCidade?: number) {
    const req: IRequest = {
      endPoint: this.GET_PRIVATE_TERMO_USO,
      queryString: {
        idTermo: idTerm,
        idLigacao: idLigacao,
        idCidade: idCidade,
      },
      showLoading: true,
      checkToken: true,
    };

    return this._connectorApi.get<ITermUse[]>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  setRegisterTermUse(idTermoUsoVersao: number, idCliente: number, action: string) {
    const req: IRequest = {
      endPoint: this.POST_PUBLIC_TERMO_USO,
      checkToken: false,
      body: {
        acao: action,
      },
      queryString: {
        idTermoUsoVersao: idTermoUsoVersao,
        idCliente: idCliente,
      },
      showLoading: true,
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  setRegisterTermUsePrivate(
    action: string,
    idTermoUsoVersao: number,
    idCidade?: number,
    idLigacao?: string,
  ) {
    const req: IRequest = {
      endPoint: this.POST_PRIVATE_TERMO_USO,
      checkToken: true,
      body: {
        acao: action,
        idLigacao: idLigacao,
      },
      queryString: {
        idTermoUsoVersao: idTermoUsoVersao,
        idCidade: idCidade,
      },
      showLoading: true,
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res) => {
        if (Object.keys(res).length > 0) {
          throwError(res);
        }
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  private acceptanceOfPendingTermsOfUse(): boolean {
    const billingType = this.getBillingType();

    if (this.registeredAutomaticDebit(billingType)) {
      if (this.originOfDebtIsSan(billingType)) {
        let termAutomaticDebit: ITermAutomaticDebit;
        let active = this.automaticDebitTermAccepted(billingType);

        termAutomaticDebit = {
          active: active,
          bankId: billingType.detalheCobranca[0].codigoBanco,
          bankName: billingType.detalheCobranca[0].nomeBanco,
        };

        this.setAutomaticDebitTerms(termAutomaticDebit);

        return !active;
      }
    }
    this.setAutomaticDebitTerms({ active: false, bankId: null, bankName: null });

    return false;
  }

  public checkTerms(installation: IInstallation) {
    this._pendingSiteTerm = false;

    this.getTermUsePlatformPrivate(typesTermsUse.platformUse)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res[0].descricaoTipoTermo === 'Termo de Uso da Plafatorma') {
            this.setSiteTerms(true);
          }
          this._setCardTerms(installation);
        },
        (error) => {
          if (error.status === statusCode.forbidden) {
            if (error.error[0].descricaoTipoTermo === 'Termo de Uso da Plafatorma') {
              this._setSiteTermsError(error);
              this._pendingSiteTerm = true;
            }
          } else {
            this._pendingSiteTerm = false;
          }
          this._setCardTerms(installation);
        },
      );
  }

  private _setCardTerms(installation: IInstallation) {
    let pendingDebtTerm = this.acceptanceOfPendingTermsOfUse();
    let pendingCardTerm = false;

    this._creditCardService
      .getCardInfo()
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res?.cartoes?.length) {
            this.getTermUseCardPrivate(
              typesTermsUse.cardUse,
              installation.cdc,
              installation.idCidade,
            )
              .pipe(take(1))
              .subscribe(
                () => {
                  this.setCreditCardTerms(true);
                  this.redirectUser(
                    this._pendingSiteTerm,
                    pendingDebtTerm,
                    pendingCardTerm,
                    installation,
                  );
                },
                (error) => {
                  if (error.status === statusCode.forbidden) {
                    this.cardTerm = error.error[0];
                    this.versionCardTermsUse = this.cardTerm.versaoTermo[0].idTermoUsoVersao;
                    this.setCreditCardTerms(false);
                    pendingCardTerm = true;
                  } else {
                    pendingCardTerm = false;
                  }
                  this.redirectUser(
                    this._pendingSiteTerm,
                    pendingDebtTerm,
                    pendingCardTerm,
                    installation,
                  );
                },
              );
          } else {
            this.setCreditCardTerms(true);
            this.redirectUser(
              this._pendingSiteTerm,
              pendingDebtTerm,
              pendingCardTerm,
              installation,
            );
          }
        },
        () => {
          this.redirectUser(this._pendingSiteTerm, pendingDebtTerm, pendingCardTerm, installation);
        },
      );
  }

  private _setSiteTermsError(error): void {
    this.platformTerm = error.error[0];
    this.versionPlatformTermsUse = this.platformTerm.versaoTermo[0].idTermoUsoVersao;
    this.setSiteTerms(false);
  }

  public redirectUser(site: boolean, debt: boolean, card: boolean, inst: IInstallation) {
    if (site || debt || card) {
      this._router.navigate(['cdc/gerencia-termos']);
    } else if (this._doesNotComeFromTermManagement() && inst.riscoCorte && inst.titular) {
      this._router.navigate(['cdc/risco-de-corte']);
    } else {
      let redirectUrl: string = this._setRedirectUrl();
      this._router.navigateByUrl(this._router.parseUrl(redirectUrl));
      this._authService.redirectUrl = undefined;
    }
  }

  private _doesNotComeFromTermManagement(): boolean {
    const termManagement: string = '/cdc/gerencia-termos';
    return this._routerService.getCurrentUrl() !== termManagement;
  }

  private _setRedirectUrl(): string {
    const redirectUrlValue = this._authService.getRedirectUrl();
    const selectedInstallation = this._installationStore.getSelectedInstallation();
    const automaticDebitServiceUrl = '/home-logado/servicos/debito-automatico';
    let redirectUrl: string;
    let hasAutomaticDebt: boolean = false;

    if (redirectUrlValue === automaticDebitServiceUrl) {
      if (selectedInstallation.titular) {
        this._citiesStore.getSelectedCityServices().listaServicos.forEach((service) => {
          if (service.nomeCategoria === servicesCategory.payment) {
            const automaticDebt = service.servicos.find(
              (el) => el.id === servicesList.debitoAutomatico,
            );
            hasAutomaticDebt = automaticDebt.ativo;
          }
        });
        if (!hasAutomaticDebt) {
          redirectUrl = '/home-logado/servicos';
          this.setNotification('is-warning', 'Atenção', 'cidade não possui o serviço');
        } else redirectUrl = redirectUrlValue;
      } else {
        redirectUrl = 'home-logado';
        this.setNotification('is-warning', 'Atenção', 'serviço indisponível');
      }
    } else {
      redirectUrl = redirectUrlValue || 'home-logado';
    }
    return redirectUrl;
  }

  public originOfDebtIsSan(billingType: IBillingType): boolean {
    return (
      billingType?.detalheCobranca?.length &&
      billingType?.detalheCobranca[0]?.origemDebAuto?.descricao?.toUpperCase() === 'SAN'
    );
  }

  public automaticDebitTermAccepted(billingType: IBillingType): boolean {
    return (
      billingType?.detalheCobranca?.length &&
      billingType?.detalheCobranca[0]?.termoUso?.aceiteVigente
    );
  }

  public setNotification(type: string, title: string, content: string) {
    super.setNotification(type, title, content);
  }
}
