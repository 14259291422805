import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { SetPhoneSms } from './token-sms.actions';

export interface TokenSmsStateModel {
  phone: string;
}

@State<TokenSmsStateModel>({
  name: 'tokenSms',
  defaults: {
    phone: null,
  },
})
@Injectable()
export class TokenSmsState {
  @Selector()
  public static getPhone(state: TokenSmsStateModel) {
    return state.phone;
  }

  @Action(SetPhoneSms)
  public setPhoneSms(ctx: StateContext<TokenSmsStateModel>, { payload }: SetPhoneSms) {
    const state = ctx.getState();
    ctx.setState({ ...state, phone: payload });
  }
}
