<div id="autocompleteContainer" #containerAutoComplete [formGroup]="form">
  <acn-form-input
    type="text"
    acnFormField
    [formControlName]="formControlTitle"
    ngDefaultControl
    [label]="label"
    [errorMsg]="errorMsg"
    [mask]="mask"
    [placeholder]="placeholder"
    [value]="value"
    [iconEnd]="iconEnd"
    [textEnd]="textEnd"
    [iconStart]="iconStart"
    [smallIcon]="smallIcon"
    (valueChanges)="onFilter($event)"
    (click)="onClickOutSide($event)"
    #inputElement
    id="autocompleteAcnFormInput"
  ></acn-form-input>

  <div #optionsListElements class="list">
    <div #optionsListContentAutoComplete class="list-content animated fadeIn">
      <div class="list-items-list" #selectOptionsAutoComplete>
        <acn-form-select-option
          *ngFor="let item of items"
          [label]="item[itemKey]"
          [value]="item[itemValue]"
          (click)="selectItem(item)"
        ></acn-form-select-option>
        <div *ngIf="!itemsWereFound" class="not-found">
          <p>{{ notFoundMsg }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
