import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AcnCoreModule, I18nModule } from '@acn/angular';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebchatModule } from '@features/webchat/webchat.module';
import { HandTalkModule } from '@features/hand-talk/hand-talk.module';
import { ModalModule } from '@shared/components/modal/modal.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HammerModule,
    CoreModule,
    AcnCoreModule,
    HttpClientModule,
    I18nModule.forRoot(),
    WebchatModule,
    HandTalkModule,
    ModalModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
