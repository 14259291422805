import { Component, Input, HostBinding } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() isOnMobile = false;
  @Input() hasImage = true;
  @HostBinding('class.opened') @Input() opened = false;
  @Input() minHeight: string;

  @HostBinding('class.v2') isV2 = false;

  @Input()
  set v2(v2: BooleanInput) {
    this.isV2 = coerceBooleanProperty(v2);
  }

  toggle() {
    if (!this.isV2 && this.isOnMobile) {
      return;
    }
    this.opened = !this.opened;
  }
}
