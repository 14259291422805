import { Directive, ElementRef, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
  constructor(
    public readonly elementRef: ElementRef,
    @Inject(DOCUMENT) public readonly document: Document,
  ) {}

  public readonly navigationKeys = new Set([
    'Home',
    'End',
    'Left',
    'Right',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Tab',
    'Escape',
  ]);

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      this._isItANavigationKey(event.key) ||
      this._isAShortcutToSelectAll(event) ||
      this._isAShortcutToCopy(event) ||
      this._isAShortcutToPaste(event) ||
      this._isAShortcutToCut(event)
    ) {
      return;
    }

    if (/^\d*$/.test(event.key)) {
      return;
    }
    event.preventDefault();
  }

  private _isItANavigationKey(key: string): boolean {
    return this.navigationKeys.has(key);
  }

  private _isAShortcutToSelectAll(event: KeyboardEvent): boolean {
    return event.key.toLocaleLowerCase() === 'a' && this._commandOrCtrlWasPressed(event);
  }

  private _isAShortcutToCopy(event: KeyboardEvent): boolean {
    return event.key.toLocaleLowerCase() === 'c' && this._commandOrCtrlWasPressed(event);
  }

  private _isAShortcutToPaste(event: KeyboardEvent): boolean {
    return event.key.toLocaleLowerCase() === 'v' && this._commandOrCtrlWasPressed(event);
  }

  private _isAShortcutToCut(event: KeyboardEvent): boolean {
    return event.key.toLocaleLowerCase() === 'x' && this._commandOrCtrlWasPressed(event);
  }

  private _commandOrCtrlWasPressed(event: KeyboardEvent): boolean {
    return event.metaKey || event.ctrlKey;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput = event.clipboardData?.getData('text/plain').replace(/\D/g, '') ?? null;
    this.document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer?.getData('text').replace(/\D/g, '') ?? null;
    this.elementRef.nativeElement.focus();
    this.document.execCommand('insertText', false, textData);
  }
}
