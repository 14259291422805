import { differentDigits } from '../utils/different-digits';

export function validateCEP(value: string) {
  if (value) {
    const cep: string = value.replace(/[^\d]+/g, '');

    if (cep.length !== 8) {
      return true;
    }

    return !differentDigits(cep);
  }

  return false;
}
