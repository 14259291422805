import { IAcnNotification } from '@acn/ui';

export class AddNotification {
  static readonly type = '[Notification] add notification';
  constructor(public payload: IAcnNotification) {}
}

export class UpdateNotification {
  static readonly type = '[Notification] update notification';
  constructor(public payload: IAcnNotification) {}
}

export class RemoveNotification {
  static readonly type = '[Notification] remove notification';
  constructor(public id: string) {}
}
