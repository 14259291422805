import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ht-clickable], [htClickable], [routerLink]',
})
/**
 * @description Diretiva apenas para auto complete
 */
export class HandTalkClickableDirective {
  @HostBinding('class.ht-clickable') htClickable = true;
}
