import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  Reset,
  ResetContact,
  SetContactCdc,
  SetContactCity,
  SetContactEmail,
  SetContactName,
  SetContactPhone,
} from './contact.actions';
import { Injectable } from '@angular/core';

export interface ContactStateModel {
  name: string;
  cdc: string;
  city: string;
  email: string;
  phone: string;
  description: string;
}

const INITIAL_STATE = {
  email: null,
  name: null,
  cdc: null,
  city: null,
  phone: null,
  description: null,
};

@State<ContactStateModel>({
  name: 'contact',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ContactState {
  @Selector()
  public static getState(state: ContactStateModel) {
    return state;
  }

  @Action(SetContactName)
  public setContactName(ctx: StateContext<ContactStateModel>, { payload }: SetContactName) {
    const state = ctx.getState();
    ctx.setState({ ...state, name: payload });
  }

  @Action(SetContactCdc)
  public setContacCdc(ctx: StateContext<ContactStateModel>, { payload }: SetContactCdc) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdc: payload });
  }

  @Action(SetContactEmail)
  public setContacEmail(ctx: StateContext<ContactStateModel>, { payload }: SetContactEmail) {
    const state = ctx.getState();
    ctx.setState({ ...state, email: payload });
  }

  @Action(SetContactCity)
  public setContacCity(ctx: StateContext<ContactStateModel>, { payload }: SetContactCity) {
    const state = ctx.getState();
    ctx.setState({ ...state, city: payload });
  }

  @Action(SetContactPhone)
  public setContacPhone(ctx: StateContext<ContactStateModel>, { payload }: SetContactPhone) {
    const state = ctx.getState();
    ctx.setState({ ...state, phone: payload });
  }

  @Action(ResetContact)
  public resetContact(ctx: StateContext<ContactStateModel>) {
    ctx.setState({ ...INITIAL_STATE });
  }

  @Action(Reset)
  public reset(ctx: StateContext<ContactStateModel>) {
    ctx.setState({ ...INITIAL_STATE });
  }
}
