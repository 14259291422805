import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { normalizeString } from '@acn/angular';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent implements OnInit, AfterViewInit, OnChanges {
  /** representa o título do campo de texto(input) */
  @Input() label: string;
  /** Mensagem a ser exibida em caso de algum erro */
  @Input() errorMsg: string;
  /** Mensagem a ser exibida em caso de nenhum item ser encontrado após a realização de uma busca */
  @Input() notFoundMsg: string;
  /** Valor a ser exibido no campo de texto(input) */
  @Input() value: string;
  /** O campo deve ser exibido apenas para leitura? */
  @Input() readonly = false;
  /** Tipo de máscara que pode ser usada */
  @Input() mask: string;
  /** Mensagem de fundo que pode ser usada */
  @Input() placeholder: string;
  /** Define a partir de quantos caracteres deve exibir a lista de opções */
  @Input() showAfter: number;
  /** Ícone que pode ser exibido no fim do campo de texto(input) */
  @Input() iconEnd: string;
  /** Texto que pode ser exibido no fim do campo de texto(input), ex: limpar, salvar */
  @Input() textEnd: string;
  /** Ícone que pode ser exibido no início do campo de texto(input) */
  @Input() iconStart: string;
  /** Define se a classe nowrap deve ser aplicada */
  @Input() nowrap: boolean;
  /** Define se a classe para reduzir o tamanho dos ícones deve ser aplicada */
  @Input() smallIcon: boolean;
  /** Valor opcional. Define o limite de opções a ser exibida na lista */
  @Input() numberOfResults?: number;
  /** Os elementos a serem exibidos como opções */
  @Input() items: any[];
  /** O nome da propriedade a ser exibida como label ou chave para cada elemento da lista de opções */
  @Input() itemKey: string;
  /** O nome da propriedade que corresponde ao valor dos elementos da lista de opções */
  @Input() itemValue: string;
  /** Propriedade opcional, pois já possui um valor padrão. Define se a filtragem dos itens deve ser executada,
   * pois, o array de opções pode já ser enviado filtrado */
  @Input() runFilter = true;
  /** Referência oa formulário que usará o componente */
  @Input() form: FormGroup;
  /** Representa o nome do formControlName relacionado ao formulário que fará uso do componente*/
  @Input() formControlTitle: string;
  /** Flag utilizada para determinar se a lista de opções deve ser removida após o
   * evento de clickedEndIcon ser disparado*/
  @Input() removeOptionsFromList = false;

  public intactCopyItems: Array<any> = [];
  public maxListHeight = false;
  public isActive = true;
  public itemsWereFound = false;
  public windowHeight: number;

  /** É disparado sempre que a seleção de uma das opções é realizada. Envia a propriedade value do item selecionado */
  @Output() selectedValue: EventEmitter<any> = new EventEmitter();
  /** É disparado sempre após uma nova busca. Envia um valor booleano indicando se nada foi encontrado(true) ou se foi(false) */
  @Output() notFound: EventEmitter<boolean> = new EventEmitter();
  /** É disparado sempre que a seleção de uma das opções é realizada. Envia o objeto selecionado */
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  /** É disparado sempre que o valor do input é alterado */
  @Output() textFieldValueChanged: EventEmitter<any> = new EventEmitter();
  /** É disparado sempre que um click no input é identificado */
  @Output() clickOnInput: EventEmitter<any> = new EventEmitter();
  /** É disparado sempre que um click no ícone ou texto do fim do input(iconEnd ou textEnd) é identificado */
  @Output() clickedEndIcon: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputElement') inputEl: ElementRef<HTMLAcnFormInputElement>;
  @ViewChild('optionsListElements') optionsList!: ElementRef<HTMLDivElement>;
  @ViewChild('selectOptionsAutoComplete') selectOptions: ElementRef;
  @ViewChild('optionsListContentAutoComplete') optionsListContentAutoCompleteEl: ElementRef;

  constructor(
    private elRef: ElementRef,
    private _renderer: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.getWindowHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.items) {
      this.intactCopyItems = this.items;
    }
  }

  ngAfterViewInit() {
    if (this.readonly) {
      this._renderer.setAttribute(this.inputEl.nativeElement, 'readonly', 'true');
    }
  }

  public selectItem(item: any) {
    this.selectedItem.emit(item);
  }

  onClickOutSide(event: MouseEvent) {
    event.stopPropagation();
    this.clickOnInput.emit(event);

    if (this.elRef.nativeElement !== event.target) {
      this.isActive = false;
      this._removeStyleClass(this.optionsList, 'show');
      return;
    }

    if (!this.isActive && !this.showAfter) {
      this.isActive = true;
      this._addStyleClass(this.optionsList, 'show');
    }
  }

  @HostListener('selectedOption', ['$event'])
  onSelectedOption(evt: CustomEvent) {
    evt.stopPropagation();

    this.inputEl.nativeElement.value = evt.detail.label;
    this.textFieldValueChanged.emit(evt.detail.value);
    this.selectedValue.emit(evt.detail.value);

    this.value = evt.detail.value;
    this.isActive = false;
    this._removeStyleClass(this.optionsList, 'show');
    this._addStyle(this.optionsList, 'display', 'none');
  }

  @HostListener('resize', ['$event'])
  onResize(event: Event) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    event.stopPropagation();
    this.getWindowHeight();
  }

  @HostListener('inputClicked')
  theEndIconHasBeenClicked() {
    this.isActive = false;
    this._removeStyleClass(this.optionsList, 'show');
    this.clickedEndIcon.emit();
    this._clearOptionsList();
  }

  onFilter(event: CustomEvent) {
    event.stopPropagation();
    this.textFieldValueChanged.emit(event.detail); // onFilter é invocado sempre que o input dispara um valueChanges

    if (this.showAfter && event.detail.length >= this.showAfter) {
      this._addStyle(this.optionsList, 'display', 'initial');
      this.isActive = true;
      this._addStyleClass(this.optionsList, 'show');
    }

    if (this.showAfter && event.detail.length < this.showAfter) {
      this._addStyle(this.optionsList, 'display', 'none');
      this.isActive = false;
      this._removeStyleClass(this.optionsList, 'show');
    }

    if (event.detail && this.runFilter) {
      if (this.showAfter && event.detail.length < this.showAfter) {
        return;
      }

      const filteredItems = [];
      this.intactCopyItems.forEach((item) => {
        const _label = this._removeAccents(item[this.itemKey].toLowerCase());
        const _search = this._removeAccents(event.detail.toLowerCase());
        if (_label.includes(_search)) {
          filteredItems.push(item);
        }
      });

      this.items = filteredItems;
      this._noItemsWereFound();

      if (this.numberOfResults) {
        this.items = this.items.slice(0, this.numberOfResults);
      }

      if (!this.itemsWereFound && !this.notFoundMsg) {
        this._addStyle(this.optionsList, 'display', 'none');
      }

      this._checkBoundaries();

      return;
    } else {
      this._noItemsWereFound();
    }

    this._checkBoundaries();
  }

  getWindowHeight() {
    this.windowHeight =
      document.getElementsByTagName('body')[0].clientHeight ||
      document.documentElement.clientHeight ||
      window.innerHeight;
  }

  private _noItemsWereFound(): void {
    const notFound = !this.items?.length;
    this.itemsWereFound = !notFound;
    this.notFound.emit(notFound);
  }

  private _checkBoundaries() {
    setTimeout(() => {
      const boundaries = this.optionsList.nativeElement.getBoundingClientRect();
      this.maxListHeight = boundaries.height >= 300;

      if (this.maxListHeight) {
        this._addStyleClass(this.optionsListContentAutoCompleteEl, 'scroll');
      }

      this._addStyle(this.optionsList, 'top', '58px');
      this._removeStyleClass(this.optionsList, 'is-up');

      if (boundaries.top + boundaries.height + 20 > this.windowHeight) {
        const newHeight = this.maxListHeight
          ? boundaries.height + 10 // paddings & margins
          : boundaries.height - 24;

        this._addStyle(this.optionsList, 'top', `${Math.ceil(newHeight) * -1}px`);
        this._addStyleClass(this.optionsList, 'is-up');
      }
    }, 0);
  }

  private _removeAccents(str) {
    return normalizeString(str);
  }

  private _addStyleClass(el: ElementRef, className: string): void {
    this._renderer.addClass(el.nativeElement, className);
  }

  private _removeStyleClass(el: ElementRef, className: string): void {
    this._renderer.removeClass(el.nativeElement, className);
  }

  private _addStyle(el: ElementRef, prop: string, value: string): void {
    this._renderer.setStyle(el.nativeElement, prop, value);
  }

  private _clearOptionsList(): void {
    if (this.removeOptionsFromList) {
      this._addStyle(this.optionsList, 'display', 'none');
    }
  }
}
