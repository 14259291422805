import { Injectable, Injector } from '@angular/core';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { AcnConnectorService, IRequest } from '@acn/angular';
import { Duplicate, DuplicateDetail, GroupedDuplicate } from '@features/easy-duplicate';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { BaseService } from '@shared/services/base/base.service';
import { CitiesStoreService, ICity } from '@store/dashboard';
import { EasyDuplicateStoreService } from '@store/home/states/easy-duplicate/easy-duplicate-store.service';
import { IDuplicate, IPdf } from '../models/easy-duplicate';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { IPix } from '@features/duplicate/services/models/pix.model';
import { CrmProtocolService, ICrmList } from '@shared/services/crm-protocol/crm-protocol.service';
import { servicesList } from '@shared/enumerators';
import { HttpErrorResponse } from '@angular/common/http';
import { CampanhaService } from '../../../shared/services/campanha/campanha.service';
import { ServiceId } from '@shared/enumerators/service-id.enum';

export type DocumentType = 'cpfCnpj' | 'cdc';

@Injectable({
  providedIn: 'root',
})
export class EasyDuplicateService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public getDocument$ = this._easyDuplicateStoreService.getDocument$;
  public getCity$ = this._easyDuplicateStoreService.getCity$;
  public docDuplicates$ = this._easyDuplicateStoreService.getDocDuplicates$;
  public cdcDuplicate$ = this._easyDuplicateStoreService.getCdcDuplicate$;
  public isGrouped$ = this._easyDuplicateStoreService.isGrouped$;
  public groupedDuplicate$ = this._easyDuplicateStoreService.groupedDuplicate$;
  public selectedDuplicate$ = this._easyDuplicateStoreService.selectedDuplicate$;
  public pixData$ = this._easyDuplicateStoreService.pixData$;
  private readonly GET_DUPLICATES_DOC = '2viaFacil/consultaCpfCnpj';
  private readonly GET_DUPLICATES_CDC = '2viaFacil/consultaCdc';
  private readonly GET_DUPLICATE_DETAIL = '2viaFacil/detalheFaturaV2';
  private readonly GET_DUPLICATE_DOWNLOAD = '2viaFacil/downloadFatura';
  private readonly GET_GROUPED_DUPLICATES = '2viaFacil/guiasPagamento';
  private readonly GET_GROUPED_DUPLICATES_DOWNLOAD = '2viaFacil/downloadGuia';
  private readonly EASY_DUPLICATE_LIST = 'home/servicos/segunda-via-facil/lista';
  private readonly EASY_DUPLICATE_INSTALLATION = 'home/servicos/segunda-via-facil/selecao-de-cdc';
  private readonly SERVICES_CITY_URL = 'servicos/servicosCidade';
  private readonly EASY_DUPLICATE_PIX_URL = '2viaFacil/gerarPix';
  private readonly PAYMENT_IN_INSTALLMENTS_PUBLIC_URL = 'parcelamento/public/gerarUrl';

  /**
   * @description Snapshot dos serviços por cidade para ser usado no guard do parcelamento.
   * Não será armazenado na 'store', pois é salvo no 'localStorage', e não há necessidade desses
   * dados ficarem na 'store', além da possibilidade de haver manipulação no localStorage para acessar a tela
   * @type {ICityServicesList}
   * @private
   */
  private _selectedCityServices: ICityServicesList | null = null;

  constructor(
    private _citiesStore: CitiesStoreService,
    private selectCityService: SelectCityService,
    private _easyDuplicateStoreService: EasyDuplicateStoreService,
    private _connectorApi: AcnConnectorService,
    protected _injector: Injector,
    private router: Router,
    private _crmProtocol: CrmProtocolService,
    private _serviceCampanha: CampanhaService,
  ) {
    super(_injector);
  }

  getCampanhas(idServico: number | string, idTipoServico: number | null) {
    return this._serviceCampanha.getActiveCampanhas(idServico, idTipoServico);
  }
  getCities() {
    return this.selectCityService.getCity();
  }

  findCity(city: string) {
    return this.selectCityService.selectCity(city);
  }

  onDashboard() {
    return this._settingsStore.onDashboard();
  }

  setOwner(owner: boolean): void {
    this._easyDuplicateStoreService.setOwner(owner);
  }

  getOwner(): boolean {
    return this._easyDuplicateStoreService.getOwner();
  }

  setDocumentType(docType: string): void {
    this._easyDuplicateStoreService.setOwnerDocType(docType);
  }

  getOwnerDocType(): string {
    return this._easyDuplicateStoreService.getOwnerDocType();
  }

  setDocument(document: string): void {
    this._easyDuplicateStoreService.setOwnerDocument(document);
  }

  setCdcDebts(debts: IDuplicate) {
    this._easyDuplicateStoreService.setEasyDuplicateCdc(debts);
  }

  getOwnerDocument(): string {
    return this._easyDuplicateStoreService.getOwnerDocument();
  }

  setCity(city: ICity): void {
    this._easyDuplicateStoreService.setCity(city);
  }

  getCity(): ICity {
    return this._easyDuplicateStoreService.getCity();
  }

  getIsGrouped(): boolean {
    return this._easyDuplicateStoreService.getIsGrouped();
  }

  getGroupedDuplicate(): GroupedDuplicate {
    return this._easyDuplicateStoreService.getGroupedDuplicate();
  }

  getSelectedDuplicate() {
    return this._easyDuplicateStoreService.getSelectedDuplicate();
  }

  getDocDuplicates(): Array<IDuplicate> {
    return this._easyDuplicateStoreService.getDocDuplicates();
  }

  getCdcDuplicates(): IDuplicate {
    return this._easyDuplicateStoreService.getCdcDuplicate();
  }

  resetState() {
    return this._easyDuplicateStoreService.resetState();
  }

  getDuplicates(docType: DocumentType, idCity: string, doc: string, recaptcha: string) {
    return docType === 'cpfCnpj'
      ? this.getDuplicatesByDocument(idCity, doc, recaptcha)
      : this.getDuplicatesByCDC(idCity, doc, recaptcha);
  }

  setSelection(isGrouped: boolean): void {
    this._easyDuplicateStoreService.setIsGrouped(isGrouped);
  }

  getCrmKeyInput(): ICrmList {
    return {
      idService: servicesList.segundaViaFacil,
      idCidade: this._citiesStore.getSelectedCity().id,
      cpfCnpj: this._easyDuplicateStoreService.getOwnerDocument(),
      cdc: this._easyDuplicateStoreService.getCdcDuplicate().codCdc,
      cdcNumber: this._easyDuplicateStoreService.getCdcDuplicate().cdcNumber,
    };
  }

  getCityServices(idCity: number) {
    const req: IRequest = {
      endPoint: this.SERVICES_CITY_URL,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<ICityServicesList>(req).pipe(
      tap((services) => {
        this._selectedCityServices = services;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  getDuplicatesByDocument(idCity: string, document: string, recaptcha: string) {
    const req: IRequest = {
      endPoint: this.GET_DUPLICATES_DOC,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade: idCity,
        documento: document,
        tokenRecaptcha: recaptcha,
      },
    };

    return this._connectorApi.get<Array<Duplicate>>(req).pipe(
      map((response) => {
        return response
          .map((cdc) => {
            return {
              ...cdc,
              debitos: [
                ...cdc.debitos.emAberto.map((debito) => {
                  return {
                    ...debito,
                    status: 'Em Aberto',
                  };
                }),
                ...cdc.debitos.atrasada.map((debito) => {
                  return { ...debito, status: 'Atrasada' };
                }),
                ...cdc.debitos.lancamentosFuturos.map((debito) => {
                  return {
                    ...debito,
                    status: 'Lançamento Futuro',
                  };
                }),
              ],
            };
          })
          .sort((a, b) => Number(a.codCdc) - Number(b.codCdc));
      }),
      tap((response) => {
        this._easyDuplicateStoreService.setEasyDuplicateDoc(response);
        this.router.navigate([this.EASY_DUPLICATE_INSTALLATION]);
      }),
      catchError((error) => {
        if (error.status !== 400 && error.status !== 404) {
          this.setNotification(
            'is-danger',
            'Erro',
            'Ocorreu um erro durante a pesquisa. Tente novamente mais tarde.',
          );
          return of(false);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  getDuplicatesByCDC(idCity: string, cdcCode: string, recaptcha: string) {
    const req: IRequest = {
      endPoint: this.GET_DUPLICATES_CDC,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade: idCity,
        codCdc: cdcCode,
        tokenRecaptcha: recaptcha,
      },
    };
    return this._connectorApi.get<Duplicate>(req).pipe(
      map((response) => {
        return {
          ...response[0],
          debitos: [
            ...response[0].debitos.emAberto.map((debito) => {
              return { ...debito, status: 'Em Aberto' };
            }),
            ...response[0].debitos.atrasada.map((debito) => {
              return { ...debito, status: 'Atrasada' };
            }),
            ...response[0].debitos.lancamentosFuturos.map((debito) => {
              return {
                ...debito,
                status: 'Lançamento Futuro',
              };
            }),
          ],
        };
      }),
      tap((response) => {
        this._easyDuplicateStoreService.setEasyDuplicateDoc([]);
        this._easyDuplicateStoreService.setEasyDuplicateCdc(response);
        if (response.totalDebitos === 0) {
          this.router.navigate([this.EASY_DUPLICATE_INSTALLATION]);
        } else {
          this.router.navigate([this.EASY_DUPLICATE_LIST]);
        }
      }),
      catchError((error) => {
        if (error.status !== 400 && error.status !== 404) {
          this.setNotification(
            'is-danger',
            'Erro',
            'Ocorreu um erro durante a pesquisa. Tente novamente mais tarde.',
          );
          return of(false);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  getDuplicateDetail(idCidade, idFatura, tokenRecaptcha: string) {
    let ignoreCrm: boolean;
    const req: IRequest = {
      endPoint: this.GET_DUPLICATE_DETAIL,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        idFatura,
        tokenRecaptcha,
      },
    };
    let crmKeyInput = this.getCrmKeyInput();
    ignoreCrm = this._crmProtocol.hasCrmList(crmKeyInput);
    req.queryString.ignorarCrm = ignoreCrm;
    return this._connectorApi.get<DuplicateDetail>(req).pipe(
      tap((res: DuplicateDetail) => {
        if (!ignoreCrm) {
          this._crmProtocol.setCrmList(crmKeyInput);
        }
        this._easyDuplicateStoreService.setSelectedDuplicate(res);
        this.setSelection(false);
        this.router.navigate(['/home/servicos/segunda-via-facil/resumo']);
      }),
      catchError(() => {
        this.setNotification('is-warning', 'Serviço indisponível', 'Tente novamente mais tarde.');
        return of(false);
      }),
    );
  }

  private _handleDownloadError(error: HttpErrorResponse) {
    if (error.status === 400 || error.status === 403) {
      this.setNotification('is-danger', 'Erro', error.error?.message);
      return of(false);
    }
    this.setNotification(
      'is-danger',
      'Erro',
      'Não foi possível realizar o download da 2ª via, tente novamente mais tarde.',
    );
    return of(false);
  }

  getDuplicatesDetailsDownload(idCidade, idFatura) {
    const req: IRequest = {
      endPoint: this.GET_DUPLICATE_DOWNLOAD,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        idFatura,
      },
    };

    return this._connectorApi.post<IPdf>(req).pipe(
      pluck('pdf'),
      catchError((error) => this._handleDownloadError(error)),
    );
  }

  getGuia(idCidade, faturas: number[], tokenRecaptcha: string) {
    let ignoreCrm: boolean;
    const req: IRequest = {
      endPoint: this.GET_GROUPED_DUPLICATES,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        tokenRecaptcha,
      },
      body: { faturas: faturas },
    };
    let crmKeyInput = this.getCrmKeyInput();
    ignoreCrm = this._crmProtocol.hasCrmList(crmKeyInput);
    req.queryString.ignorarCrm = ignoreCrm;
    return this._connectorApi.post<GroupedDuplicate>(req).pipe(
      tap((res: GroupedDuplicate) => {
        if (!ignoreCrm) {
          this._crmProtocol.setCrmList(crmKeyInput);
        }
        this._easyDuplicateStoreService.setGroupedDuplicate(res);
        this.setSelection(true);
        this.router.navigate(['/home/servicos/segunda-via-facil/resumo']);
      }),
      catchError(() => {
        this.setNotification('is-warning', 'Serviço indisponível', 'Tente novamente mais tarde.');
        return of(false);
      }),
    );
  }

  getDuplicatesGuiaDownload(idCidade: number, idGuia: number) {
    const req: IRequest = {
      endPoint: this.GET_GROUPED_DUPLICATES_DOWNLOAD,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        idGuia,
      },
    };

    return this._connectorApi.post<IPdf>(req).pipe(
      pluck('pdf'),
      catchError((error) => this._handleDownloadError(error)),
    );
  }

  getPix(idCidade: number, tipo: string, idFaturaGuia: number, tokenRecaptcha: string) {
    const req: IRequest = {
      endPoint: this.EASY_DUPLICATE_PIX_URL,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade,
        tipo,
        tokenRecaptcha,
      },
      body: {
        idFaturaGuia: idFaturaGuia,
        qrcode: {
          retornarCodigoPix: true,
          retornarURLImagem: false,
          larguraImagemPixels: 225,
          retornarBase64Imagem: true,
        },
      },
    };
    return this._connectorApi.post<IPix>(req).pipe(
      tap((response) => {
        this._easyDuplicateStoreService.setPixData(response);
        this.router.navigate(['/home/servicos/segunda-via-facil/pix']);
      }),
      catchError(() => {
        this.setNotification('is-warning', 'Serviço indisponível', 'Tente novamente mais tarde.');
        return of(false);
      }),
    );
  }

  getPaymentInInstallmentsUrl(
    idCidade: number,
    idPagamento: number,
    tipo: string,
  ): Observable<string> {
    const req: IRequest = {
      endPoint: this.PAYMENT_IN_INSTALLMENTS_PUBLIC_URL,
      checkToken: false,
      showLoading: true,
      queryString: { idCidade, idPagamento, tipo },
    };
    return this._connectorApi.get<{ url: string }>(req).pipe(
      pluck('url'),
      catchError((error) => {
        this.setNotification('is-warning', 'Serviço indisponível', 'Tente novamente mais tarde.');
        return throwError(error);
      }),
    );
  }

  getSelectedCityServices(): ICityServicesList | null {
    return this._selectedCityServices;
  }
}
