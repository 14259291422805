import { Injectable } from '@angular/core';
import { Selector, Store, Select } from '@ngxs/store';
import { RouterState, RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin';
import { RouterStateModel } from './router-state.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  public currentUrl$: Observable<string> = this._store
    .select(RouterState)
    .pipe(map((stateModel) => stateModel.state.url));

  @Select(RouterService.data) data$: Observable<any>;
  @Select(RouterService.params) params$: Observable<any>;
  @Select(RouterService.queryParams) queryParams$: Observable<any>;

  @Selector([RouterState])
  static data({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state.data;
  }

  @Selector([RouterState])
  static params({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state.params;
  }

  @Selector([RouterState])
  static queryParams({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state.queryParams;
  }

  @Selector([RouterState])
  static url({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state.url;
  }

  constructor(private _store: Store) {}

  getCurrentUrl() {
    return this._store.selectSnapshot(RouterState.url);
  }

  getRouterData() {
    return this._store.selectSnapshot(RouterService.data);
  }
}
