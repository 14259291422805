import { AcnConnectorService, IRequest } from '@acn/angular';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { BaseService } from '@shared/services';
import { SelectCityService } from '../../select-city/services/select-city.service';
import { pluck, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Category, CategoryResponse } from '../model/category';
import { BillingType, BillingTypeResponse } from '../model/billing.type';
import {
  InvoiceSimulatorFormData,
  CitiesStoreService,
  InvoiceSimulatorStoreService,
  ISimulator,
} from '@store/dashboard';
import { InvoiceApiSimulatorResponse } from '@store/dashboard/states/invoice-simulator/models/invoice-api-simulator-response';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSimulatorService extends BaseService {
  public selectedCity$ = this._citiesStore.selectedCity$;
  private readonly URL_CATEGORY_INVOICE_SIMULATOR = 'simulador/consultaCategoriaImovel';
  private readonly URL_BILLING_TYPE = 'simulador/consultaTipoFaturamento';
  private readonly INVOICE_SIMULATOR_URL = 'simulador/geracaoFatura';

  constructor(
    protected _injector: Injector,
    private _citiesStore: CitiesStoreService,
    private _connectorApi: AcnConnectorService,
    private _selectCityService: SelectCityService,
    private _invoiceSimulatorState: InvoiceSimulatorStoreService,
    private _router: Router,
  ) {
    super(_injector);
  }

  public getOnDashboard(): boolean {
    return this._settingsStore.onDashboard();
  }

  public scrollTop(): void {
    this._screenService.scrollTopDashboard();
  }

  public getCategories(): Observable<Category[]> {
    const idCity = this._selectCityService.getSelectedCity()?.id;

    const req: IRequest = {
      endPoint: this.URL_CATEGORY_INVOICE_SIMULATOR,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<CategoryResponse>(req).pipe(pluck('categoria'));
  }

  public getBillingType(): Observable<BillingType[]> {
    const idCity = this._selectCityService.getSelectedCity()?.id;

    const req: IRequest = {
      endPoint: this.URL_BILLING_TYPE,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<BillingTypeResponse>(req).pipe(pluck('tipoFaturamento'));
  }

  public setSimulator(simulator: ISimulator) {
    return this._invoiceSimulatorState.setSimulator(simulator);
  }

  public getSimulator(): ISimulator {
    return this._invoiceSimulatorState.getSimulator();
  }

  public resetSimulator() {
    return this._invoiceSimulatorState.reset();
  }

  public redirectToHomeService(baseUrl: string) {
    return this._router.navigate([`/${baseUrl}/servicos`]);
  }

  public getUrlBack(): string {
    return this._invoiceSimulatorState.getUrlBack();
  }

  public scrollIntoView(elementRef: ElementRef): void {
    elementRef.nativeElement.scrollIntoView();
  }

  public getEntityScreenFromStore() {
    return this._invoiceSimulatorState.getFormData();
  }

  public setFormData(entity: InvoiceSimulatorFormData) {
    this._invoiceSimulatorState.setFormData(entity);
  }

  public getInvoiceSimulation(data: InvoiceSimulatorFormData) {
    const idCidade = this._citiesStore.getSelectedCity().id;
    const req: IRequest = {
      endPoint: this.INVOICE_SIMULATOR_URL,
      checkToken: false,
      showLoading: true,
      body: {
        idCidade: idCidade,
        idCategoriaSan: data.category.idCategoriaSan,
        idTipoFaturamentoSan: data.billingType.idTipoFaturamentoSan,
        consumoInformado: data.volume,
        nrEconomias: data.quantity,
      },
    };

    this.setFormData(data);
    return this._connectorApi.post<ISimulator>(req).pipe(
      tap((res) => {
        this.setSimulator(res);
      }),
    );
  }

  public setApiData(data: InvoiceApiSimulatorResponse): void {
    this._invoiceSimulatorState.setApiData(data);
  }

  getApiData(): InvoiceApiSimulatorResponse {
    return this._invoiceSimulatorState.getApiData();
  }

  setUrlBack(url: string) {
    this._invoiceSimulatorState.setUrlBack(url);
  }
}
