import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  ResetSimulator,
  SetApiData,
  SetFormData,
  SetSimulator,
  SetUrlBack,
} from './invoice-simulator.actions';
import { InvoiceSimulatorState } from './invoice-simulator.state';
import { IInvoiceSimulatorState, InvoiceSimulatorFormData, ISimulator } from './models';
import { InvoiceApiSimulatorResponse } from '@store/dashboard/states/invoice-simulator/models/invoice-api-simulator-response';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSimulatorStoreService {
  @Select(InvoiceSimulatorState.getState)
  public invoiceSimulatorState$: Observable<IInvoiceSimulatorState>;

  @Select(InvoiceSimulatorState.getSimulator)
  public simulator$: Observable<ISimulator>;

  @Select(InvoiceSimulatorState.getFormData)
  public formData$: Observable<InvoiceSimulatorFormData>;

  @Select(InvoiceSimulatorState.getFormData)
  public apiData$: Observable<InvoiceApiSimulatorResponse>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setSimulator(simulator: ISimulator) {
    return new SetSimulator(simulator);
  }

  @Dispatch()
  public reset() {
    return new ResetSimulator();
  }

  @Dispatch()
  public setFormData(formData: InvoiceSimulatorFormData) {
    return new SetFormData(formData);
  }

  @Dispatch()
  public setApiData(apiData: InvoiceApiSimulatorResponse) {
    return new SetApiData(apiData);
  }

  @Dispatch()
  public setUrlBack(url: string) {
    return new SetUrlBack(url);
  }

  getSimulator(): ISimulator {
    return this._store.selectSnapshot(InvoiceSimulatorState.getSimulator);
  }

  getFormData(): InvoiceSimulatorFormData | undefined {
    return this._store.selectSnapshot(InvoiceSimulatorState.getFormData);
  }

  getApiData(): InvoiceApiSimulatorResponse | undefined {
    return this._store.selectSnapshot(InvoiceSimulatorState.getApiData);
  }

  getUrlBack(): string {
    return this._store.selectSnapshot(InvoiceSimulatorState.getUrlBack);
  }
}
