import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@shared/services/base/base.service';
import {
  BillingStoreService,
  CitiesStoreService,
  INewCity,
  InstallationStoreService,
} from '@store/dashboard';
import { ClientService } from '@features/services/services/client.service';
import { ITermAutomaticDebit, RedirectionStoreService } from '@store/app';
import { AutomaticDebitServiceStoreService } from '@store/home/states/automatic-debit-service/automatic-debit-service-store.service';

import { AcnConnectorService, IRequest } from '@acn/angular';

import { IBank } from '@features/services/models/bank.model';
import { IPartnerBank } from '@features/automatic-debit-service/models/partner-bank.model';
import { IBillingType } from '@features/account/models/billing-type.model';
import { meiosPagamento } from '@shared/enumerators/meiosPagamento.enum';
import { IBankData } from '@store/home/states/automatic-debit-service/models/bankData.model';
import { ITermUse } from '@features/terms/models/term-use.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TermUseService } from '@features/terms/services/term-use.service';
import { actionsTermUse } from '@shared/enumerators/actions-term-use.enum';
import { IBankRules } from '../models/bank-rules.model';
import { IBankRulesResponse, BooleanStringValues } from '../models/reponses/bank-rules.response';
import { ServiceTypeId } from '@shared/enumerators/service-type-id.enum';

@Injectable({
  providedIn: 'root',
})
export class AutomaticDebitServiceService extends BaseService {
  private readonly GET_DATA_FOR_FORM: string = 'debitoAutomatico/bff/v1/regras-formulario/banco';
  private readonly REGISTER_AUTOMATIC_DEBT_V2: string = 'debitoAutomatico/bff/v2/solicitacao';
  private readonly REGISTER_AUTOMATIC_DEBT: string = 'debitoAutomatico';
  private URL_BANK_LIST: string = 'debitoAutomatico/consultaBancosParceiros';
  private readonly GET_PRIVATE_TERMO_USO: string = 'termoUso/vigente';
  private readonly REVOKE_TERMS_USE_URL: string = 'termoUso/revogarTermosDeUsoDoDebitoAutomatico';

  public onMobile$ = this._settingsStore.onMobile$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public notSelectedCity$ = this._citiesStore.notSelectedCity$;
  public getAllDebits$ = this._billingStoreService.getAllDebits$;
  public installationStore$ = this._installationStore.selectedInstallation$;
  public selectedBank$ = this._automaticDebitStoreService.selectedBank$;
  public bankData$ = this._automaticDebitStoreService.bankData$;
  public billingType$ = this._automaticDebitStoreService.billingType$;
  public automaticDebit$ = this._automaticDebitStoreService.automaticDebitServiceState$;

  constructor (
    private _citiesStore: CitiesStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _billingStoreService: BillingStoreService,
    private _clientService: ClientService,
    protected _injector: Injector,
    private _installationStore: InstallationStoreService,
    private _connectorApi: AcnConnectorService,
    private _automaticDebitStoreService: AutomaticDebitServiceStoreService,
    private _termsUseService: TermUseService,
    private _router: Router,
  ) {
    super(_injector);
  }

  onDashboard () {
    return this._settingsStore.onDashboard();
  }

  returnAfterCityChoosen (path: string) {
    this._clientService.setCallbackOnChooseCity(path);
  }

  userRedirected (currentRoute: string) {
    this._redirectionStore.setRedirectedFrom(currentRoute);
  }

  getRedirectedFrom (): string {
    return this._redirectionStore.getRedirectedFrom();
  }

  errorInvoiceModal () {
    this.showModal({ componentId: 'automatic-debit-service.error' });
  }

  setBankData (bankData: IBankData) {
    this._automaticDebitStoreService.setBankData(bankData);
  }

  setIsAutomaticDebitNewFlow (isAutomaticDebitFlow: boolean) {
    this._automaticDebitStoreService.setIsAutomaticDebitNewFlow(isAutomaticDebitFlow);
  }

  getIsAutomaticDebitNewFlow (): boolean {
    return this._automaticDebitStoreService.getIsAutomaticDebitNewFlow();
  }

  getBanks (cityId: number) {
    const req: IRequest = {
      endPoint: this.URL_BANK_LIST,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade: cityId,
      },
    };

    return this._connectorApi.get(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  mapBankProperties (banks: Array<IPartnerBank>): Array<IBank> {
    let bankList: Array<IBank> = [];

    banks.forEach((bank) => {
      bankList.push({
        id: bank.codigoBanco,
        bankName: bank.nomeBanco,
        order: bank.ordemApresentacao,
        imageUrl: bank.urImagemIcone,
        bankUrl: bank.urlBanco,
        redirectToBank: bank.redirecionaBanco.toUpperCase() === 'S',
        allowForm: bank.apresentarFormulario.toUpperCase() === 'S',
      });
    });

    return this.sortTheBanksInAscendingOrder(bankList);
  }

  getTermUsePrivate (idTerm: number, idLigacao: string, idCidade: string) {
    const req: IRequest = {
      endPoint: this.GET_PRIVATE_TERMO_USO,
      queryString: {
        idTermo: idTerm,
        idLigacao: idLigacao,
        idCidade: idCidade,
      },
      showLoading: true,
      checkToken: true,
    };

    return this._connectorApi.get<ITermUse[]>(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        if (error.status === 403) {
          return of(error.error);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  setBanksList (banks: Array<IBank>): void {
    this._automaticDebitStoreService.setBanks(this.sortTheBanksInAscendingOrder(banks));
  }

  getDataForForm (codeCollector: number): Observable<IBankRules> {
    let cityId = this.getSelectedCity()?.id;

    const req: IRequest = {
      endPoint: this.GET_DATA_FOR_FORM,
      checkToken: true,
      queryString: {
        codigoArrecadador: codeCollector,
        idCidade: cityId,
      },
      showLoading: true,
    };

    return this._connectorApi.get(req).pipe(
      map((response: IBankRulesResponse) => ({
        bankName: response?.arrecadador,
        bankNumber: response?.numeroBanco,
        isAutomaticDebitNewFlow: response?.insereDiretoNoSan,
        agency: {
          hasVerifierDigit: response?.agenciaDV === BooleanStringValues.S,
          fillLeftZeros: response?.preencherZerosEsquerdaAG === BooleanStringValues.S,
          digitsLength: Number(response?.qtdDigitosAgDebAuto),
        },
        account: {
          hasVerifierDigit: response?.contaDV === BooleanStringValues.S,
          fillLeftZeros: response?.preencherZerosEsquerdaCC === BooleanStringValues.S,
          digitsLength: Number(response?.qtdDigitosCcDebAuto),
        },
      })),
    );
  }

  requestAutomaticDebit (bankCode: string, bankAgency: string, codeCollector: string) {
    let cityId = this.getSelectedCity()?.id;
    let installation = this._installationStore.getSelectedInstallation();

    const request: IRequest = {
      endPoint: this.REGISTER_AUTOMATIC_DEBT_V2,
      checkToken: true,
      body: {
        idLigacao: installation?.cdc,
        codigoArrecadador: codeCollector,
        codigoAgencia: bankAgency,
        codigoConta: bankCode,
      },
      queryString: {
        idCidade: cityId,
        idTipoServico: ServiceTypeId.SOLICITACAO_CADASTRO_MINHA_BRK,
      },
      showLoading: true,
    };

    return this._connectorApi.post(request).pipe(
      switchMap((res) => {
        this._router.navigate(['home-logado/servicos/debito-automatico/sucesso']);
        return of(res);
      }),
      catchError(() => {
        throw Error('Serviço Indisponível, por favor, tente novamente mais tarde');
      }),
    );
  }

  registerAutomaticDebt (
    bankCode: number,
    bankAgency: string,
    bankAccount: string,
    bankName: string,
    idLigacao: string,
    idCidade: string,
  ) {
    const req: IRequest = {
      endPoint: this.REGISTER_AUTOMATIC_DEBT,
      checkToken: true,
      body: {
        codBanco: bankCode,
        nomeBanco: bankName,
        conta: bankAccount,
        agencia: bankAgency,
      },
      queryString: {
        codCdc: idLigacao,
        idCidade: idCidade,
      },
      showLoading: true,
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res) => {
        this._router.navigate(['home-logado/servicos/debito-automatico/sucesso']);
        return of(res);
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  setRegisterTermUsePrivate (action: string, idTermoUsoVersao: number, idCidade?: number) {
    let idLigacao = this._installationStore.getSelectedInstallation();
    return this._termsUseService.setRegisterTermUsePrivate(
      action,
      idTermoUsoVersao,
      idCidade,
      idLigacao?.cdc,
    );
  }

  removeAutomaticDebit () {
    let idCidade = this.getSelectedCity()?.id;
    let cdc = this._installationStore.getSelectedInstallation()?.cdc;
    let term: ITermAutomaticDebit = this._termsUseService.getAutomaticDebitTerms();

    const req: IRequest = {
      endPoint: this.REVOKE_TERMS_USE_URL,
      checkToken: true,
      body: {
        codBanco: term.bankId,
        nomeBanco: term.bankName,
      },
      queryString: {
        idCidade: idCidade,
        idLigacao: cdc,
      },
      showLoading: true,
    };

    return this._connectorApi.post(req).pipe(
      switchMap((res) => {
        return of(res);
      }),
      catchError((error) => {
        return of(false);
      }),
    );
  }

  removeAutomaticDebitTermsOfUse (idTermoUsoVersao: number) {
    let idLigacao = this._installationStore.getSelectedInstallation()?.cdc || null;
    let action: string = actionsTermUse.revoke;
    let idCidade = this._citiesStore.getSelectedCity()?.id || null;

    return this._termsUseService.setRegisterTermUsePrivate(
      action,
      idTermoUsoVersao,
      idCidade,
      idLigacao,
    );
  }

  private sortTheBanksInAscendingOrder (banks: Array<IBank>): Array<IBank> {
    banks = banks.slice().sort((atual, prox) => {
      if (atual.order > prox.order) return 1;
      if (atual.order < prox.order) return -1;
      return 0;
    });
    return banks;
  }

  setSelectedBank (bank: IBank): void {
    this._automaticDebitStoreService.setSelectedBank(bank);
  }

  getBanksList (): Array<IBank> {
    return this._automaticDebitStoreService.getBanks();
  }

  getBankSelected (): IBank {
    return this._automaticDebitStoreService.getSelectedBank();
  }

  getBillingType (): IBillingType {
    return this._automaticDebitStoreService.getBillingType();
  }

  setBillingType (billingType: IBillingType): void {
    this._automaticDebitStoreService.setBillingType(billingType);
  }

  resetBillingType (): void {
    this._automaticDebitStoreService.resetBillingType();
  }

  registeredAutomaticDebit (item: boolean | IBillingType): boolean {
    return typeof item !== 'boolean' && item?.idTipoCobranca === meiosPagamento.debitoAutomatico;
  }

  reset (): void {
    this._automaticDebitStoreService.resetState();
  }

  getNoticeType (): number {
    return this._automaticDebitStoreService.getNoticeType();
  }

  setNoticeType (noticeType: number): void {
    this._automaticDebitStoreService.setNoticeType(noticeType);
  }

  getSelectedCity (): INewCity {
    return this._citiesStore.getSelectedCity();
  }

  setReplaceAutomaticDebit (replace: boolean): void {
    this._automaticDebitStoreService.setReplaceAutomaticDebit(replace);
  }

  getReplaceAutomaticDebit (): boolean {
    return this._automaticDebitStoreService.getReplaceAutomaticDebit();
  }

  resetReplaceAutomaticDebit (): void {
    this._automaticDebitStoreService.resetReplaceAutomaticDebit();
  }

  selectBank (bank: IBank, basePath: string): void {
    this.setSelectedBank(bank);
    this.userRedirected(this._router.url);
    this.goToBank(bank, basePath);
  }

  goToBank (bank: IBank, basePath: string) {
    if (bank.allowForm && this.onDashboard()) {
      return this._router.navigate(['home-logado/servicos/debito-automatico/cadastro']);
    }

    this._router.navigate([`${basePath}/servicos/debito-automatico/iniciar-cadastro`]);
  }
}
