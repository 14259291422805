import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IReportLeakModel } from './models';
import {
  AddAddress,
  AddCdc,
  AddDocument,
  AddName,
  AddNote,
  AddPhoneNumber,
  AddProportion,
  AddReferencePoint,
  AddReportLeak,
  AddSuccessMessage,
  AddNeighborhoodWaterSupply,
  AddWaterSupply,
  AddWhereItOccurs,
  AddZipCode,
  ResetReportLeak,
} from './report-leak.actions';
import { ReportLeakState } from './report-leak.state';

@Injectable({
  providedIn: 'root',
})
export class ReportLeakStoreService {
  @Select(ReportLeakState.getState)
  public reportLeakState$: Observable<IReportLeakModel>;

  constructor(private _store: Store) {}

  public getReportLeak() {
    return this._store.selectSnapshot(ReportLeakState.getState);
  }

  public getCdc() {
    return this._store.selectSnapshot(ReportLeakState.getCdc);
  }

  public getName() {
    return this._store.selectSnapshot(ReportLeakState.getName);
  }

  public getNote() {
    return this._store.selectSnapshot(ReportLeakState.getNote);
  }

  public getPhoneNumber() {
    return this._store.selectSnapshot(ReportLeakState.getPhoneNumber);
  }

  public getNeighborhoodWaterSupply() {
    return this._store.selectSnapshot(ReportLeakState.getNeighborhoodWaterSupply);
  }

  public getWaterSupply() {
    return this._store.selectSnapshot(ReportLeakState.getWaterSupply);
  }

  public getProportion() {
    return this._store.selectSnapshot(ReportLeakState.getProportion);
  }

  public getReferencePoint() {
    return this._store.selectSnapshot(ReportLeakState.getReferencePoint);
  }

  public getWhereItOccurs() {
    return this._store.selectSnapshot(ReportLeakState.getWhereItOccurs);
  }

  public getZipCode() {
    return this._store.selectSnapshot(ReportLeakState.getZipCode);
  }

  public getSuccessMessage() {
    return this._store.selectSnapshot(ReportLeakState.getSuccessMessage);
  }

  @Dispatch()
  public resetState() {
    return new ResetReportLeak();
  }

  @Dispatch()
  public setReportLeak(reportLeak: IReportLeakModel) {
    return new AddReportLeak(reportLeak);
  }

  @Dispatch()
  public setAddress(address: string) {
    return new AddAddress(address);
  }

  @Dispatch()
  public setCdc(cdc: number) {
    return new AddCdc(cdc);
  }

  @Dispatch()
  public setDocument(document: number) {
    return new AddDocument(document);
  }

  @Dispatch()
  public setName(name: string) {
    return new AddName(name);
  }

  @Dispatch()
  public setNote(note: string) {
    return new AddNote(note);
  }

  @Dispatch()
  public setPhoneNumber(phoneNumber: number) {
    return new AddPhoneNumber(phoneNumber);
  }

  @Dispatch()
  public setNeighborhoodWaterSupply(neighborhoodWaterSupply: string) {
    return new AddNeighborhoodWaterSupply(neighborhoodWaterSupply);
  }

  @Dispatch()
  public setWaterSupply(waterSupply: string) {
    return new AddWaterSupply(waterSupply);
  }

  @Dispatch()
  public setProportion(proportion: string) {
    return new AddProportion(proportion);
  }

  @Dispatch()
  public setReferencePoint(reference: string) {
    return new AddReferencePoint(reference);
  }

  @Dispatch()
  public setWhereItOccurs(occurs: string) {
    return new AddWhereItOccurs(occurs);
  }

  @Dispatch()
  public setZipCode(zipCode: number) {
    return new AddZipCode(zipCode);
  }

  @Dispatch()
  public setSuccessMessage(successMessage: string) {
    return new AddSuccessMessage(successMessage);
  }
}
