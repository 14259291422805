import { IAcnDynamicComponentManifest } from '@acn/angular';

export const accountDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'account.cpf-cnpj',
    loadChildren: () =>
      import('./account/components/account-cpf-cnpj-modal/account-cpf-cnpj-modal.module').then(
        (m) => m.AccountCpfCnpjModalModule,
      ),
  },
  {
    path: 'account.reccurrent-billing',
    loadChildren: () =>
      import('./account/components/account-recurrent-billing-modal/account-recurrent-billing-modal.module').then(
        (m) => m.AccountRecurrentBillingModalModule,
      ),
  },
];
