<footer class="container">
  <section>
    <acn-icon class="logo" icon="old-logo"></acn-icon>
  </section>

  <ul class="menu services">
    <li>
      <a
        class="link"
        [routerLink]="baseUrl + '/atendimento'"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.sac' | transloco"
      >
        {{ 'footer.sac' | transloco }}
      </a>
    </li>
    <li>
      <a
        class="link"
        href="https://www.brkambiental.com.br/noticias"
        target="_blank"
        rel="noopener"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.news' | transloco"
      >
        {{ 'footer.news' | transloco }}
      </a>
    </li>
    <li>
      <a
        class="link"
        href="https://www.brkambiental.com.br/quem-somos"
        target="_blank"
        rel="noopener"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.institute' | transloco"
      >
        {{ 'footer.institute' | transloco }}
      </a>
    </li>
    <li>
      <a
        class="link"
        href="https://www.brkambiental.com.br/compliance"
        target="_blank"
        rel="noopener"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.compliance' | transloco"
      >
        {{ 'footer.compliance' | transloco }}
      </a>
    </li>
    <li>
      <a
        class="link"
        href="https://www.canalconfidencial.com.br/brkambiental"
        target="_blank"
        rel="noopener"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.security' | transloco"
      >
        {{ 'footer.security' | transloco }}
      </a>
    </li>
    <li>
      <a
        class="link"
        href="https://www.ri.brkambiental.com.br"
        target="_blank"
        rel="noopener"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'footer.investors' | transloco"
      >
        {{ 'footer.investors' | transloco }}
      </a>
    </li>
  </ul>

  <section class="contact">
    <ul class="menu contact">
      <li>
        <a
          class="link"
          href="http://www.sertras.com/v3/brk-ambiental-portal-fornecedores/"
          target="_blank"
          rel="noopener"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.suppliers' | transloco"
        >
          {{ 'footer.suppliers' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="link"
          href="https://www.brkambiental.com.br/imprensa"
          target="_blank"
          rel="noopener"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.press' | transloco"
        >
          {{ 'footer.press' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="link"
          href="https://www.brkambiental.com.br/trabalhe-conosco"
          target="_blank"
          rel="noopener"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.workWithUs' | transloco"
        >
          {{ 'footer.workWithUs' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="link"
          routerLink="/politica-de-privacidade"
          target="blank"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.privacy' | transloco"
        >
          {{ 'footer.privacy' | transloco }}
        </a>
      </li>
      <li *ngIf="isOnDashboard && showSiteTerms">
        <a
          class="link"
          routerLink="/termos-de-uso"
          target="_blank"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.terms' | transloco"
        >
          {{ 'footer.terms' | transloco }}
        </a>
      </li>
      <li>
        <a
          class="link"
          [href]="lgpdFormLink"
          target="_blank"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.dataProtection' | transloco"
        >
          {{ 'footer.dataProtection' | transloco }}
        </a>
      </li>
      <li *ngIf="isOnDashboard && hasCreditCard && showCreditCardTerms">
        <a
          class="link"
          routerLink="/termos-de-uso-do-cartao"
          target="_blank"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.termsCard' | transloco"
        >
          {{ 'footer.termsCard' | transloco }}
        </a>
      </li>
      <li *ngIf="isOnDashboard && hasAutomaticDebit && showAutomaticDebitTerms">
        <a
          class="link"
          target="_blank"
          routerLink="/cdc/termos-de-uso-debito-automatico"
          acnGtm
          [gtmCategory]="gtmCategory"
          [gtmAction]="gtmAction"
          [gtmLabel]="'footer.termsAutomaticDebit' | transloco"
        >
          {{ 'footer.termsAutomaticDebit' | transloco }}
        </a>
      </li>
    </ul>
  </section>

  <section>
    <ng-container *ngIf="selectedCity && selectedCity.isAttended && selectedCity.phones">
      <ul class="menu phones">
        <li>
          <a
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'footer.attendance' | transloco"
            [attr.href]="isOnMobile ? 'tel:' + selectedCity.phones.telAtendimento : null"
          >
            <span class="is-bold">{{ 'footer.attendance' | transloco }}</span>
            <span>{{ selectedCity.phones.telAtendimento }}</span>
          </a>
        </li>
        <li>
          <a
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'footer.ombudsman' | transloco"
            [attr.href]="isOnMobile ? 'tel:' + selectedCity.phones.telOuvidoria : null"
          >
            <span class="is-bold">{{ 'footer.ombudsman' | transloco }} </span>
            <span>{{ selectedCity.phones.telOuvidoria }}</span>
          </a>
        </li>
        <li>
          <a
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'footer.deficiency' | transloco"
            [attr.href]="isOnMobile ? 'tel:' + selectedCity.phones.telAuditivo : null"
          >
            <span class="is-bold">{{ 'footer.deficiency' | transloco }} </span>
            <span>{{ selectedCity.phones.telAuditivo }}</span>
          </a>
        </li>
      </ul>
    </ng-container>
  </section>

  <section>
    <div class="live">
      <div class="channel">
        <p>{{ 'footer.channels' | transloco }}</p>
        <div class="channel__items">
          <a
            class="link"
            href="https://www.facebook.com/brk.ambiental"
            target="_blank"
            rel="noopener"
            acnGtm
            [gtmCategory]="gtmCategorySocial"
            [gtmAction]="gtmActionSocial"
            gtmLabel="Facebook"
          >
            <acn-icon class="icon" icon="facebook-circle"></acn-icon>
          </a>

          <a
            class="link"
            href="https://www.linkedin.com/company/brkambiental"
            target="_blank"
            rel="noopener"
            acnGtm
            [gtmCategory]="gtmCategorySocial"
            [gtmAction]="gtmActionSocial"
            gtmLabel="Linkedin"
          >
            <acn-icon class="icon" icon="linkedin-circle"></acn-icon>
          </a>

          <a
            class="link"
            href="https://twitter.com/brkambiental"
            target="_blank"
            rel="noopener"
            acnGtm
            [gtmCategory]="gtmCategorySocial"
            [gtmAction]="gtmActionSocial"
            gtmLabel="Twitter"
          >
            <acn-icon class="icon" icon="twitter-circle"></acn-icon>
          </a>
          <a
            class="link"
            href="https://www.instagram.com/brkambiental/"
            target="_blank"
            rel="noopener"
          >
            <acn-icon class="icon" icon="instagram-circle"></acn-icon>
          </a>

          <a
            class="link"
            href="https://www.youtube.com/channel/UCSqadBMo5v6E8P2e0oPhzww"
            target="_blank"
            rel="noopener"
            acnGtm
            [gtmCategory]="gtmCategorySocial"
            [gtmAction]="gtmActionSocial"
            gtmLabel="Youtube"
          >
            <acn-icon class="icon" icon="youtube-circle"></acn-icon>
          </a>
        </div>
      </div>

      <div *ngIf="!isOnMobile && selectedCity" class="socialReason is-size-10">
        <ng-container *ngTemplateOutlet="unitInfo"></ng-container>
      </div>
      <div class="copyright" [innerHTML]="'footer.copyright' | transloco"></div>
    </div>
  </section>
  <section>
    <div *ngIf="isOnMobile && selectedCity" class="socialReason is-size-10">
      <ng-container *ngTemplateOutlet="unitInfo"></ng-container>
    </div>
  </section>
</footer>

<ng-template #unitInfo>
  <ng-container *ngIf="selectedCity?.unidIncricaoEstadual">
    {{
      'footer.unitInfoIncription'
        | transloco
          : {
              razaoSocial: selectedCity.unidRazao,
              cnpj: selectedCity.unidCnpj,
              inscricaoEstadual: selectedCity?.unidIncricaoEstadual,
              endereco: selectedCity.unidEndereco,
              unidadeCidade: selectedCity.unidCidade,
              unidUf: selectedCity.unidUf,
              unidCep: selectedCity.unidCep
            }
    }}
  </ng-container>
  <ng-container *ngIf="!selectedCity?.unidIncricaoEstadual">
    {{
      'footer.unitInfoNoIncription'
        | transloco
          : {
              razaoSocial: selectedCity.unidRazao,
              cnpj: selectedCity.unidCnpj,
              endereco: selectedCity.unidEndereco,
              unidadeCidade: selectedCity.unidCidade,
              unidUf: selectedCity.unidUf,
              unidCep: selectedCity.unidCep
            }
    }}
  </ng-container>
</ng-template>
