export const CHANNEL_CONFIG = {
  default: {
    visibility: {
      'home-cards-default': true,
      'home-cards-totem': false,
      'home-cards-app': false,
    },
    parameters: {
      'cliente-sudeste': undefined,
      'campanha-black-friday': undefined,
    },
    routesPermission: {
      duplicate: true,
    },
  },
  portal: {
    visibility: {
      'home-cards-default': true,
      'home-cards-totem': false,
      'home-cards-app': false,
    },
    parameters: {
      'cliente-sudeste': 'Olá, cliente do sudeste',
      'campanha-black-friday': false,
    },
    routesPermission: {
      duplicate: true,
    },
  },
  app: {
    visibility: {
      'home-cards-default': false,
      'home-cards-totem': false,
      'home-cards-app': true,
    },
    routesPermission: {
      duplicate: true,
    },
  },
  totem: {
    visibility: {
      'home-cards-default': false,
      'home-cards-totem': true,
      'home-cards-app': false,
    },
    routesPermission: {
      duplicate: false,
    },
  },
};
