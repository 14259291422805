import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { TextualAnswers } from '@features/request-cut/enumerators';
import { IRequestCutPersonalData, IRequestCutStateModel } from './models';
import { RequestCutState } from './request-cut.state';

import {
  ResetRequestCut,
  SetUrlBack,
  SetHydrometerAvailability,
  SetCutServiceOrder,
  SetCutData,
  SetPersonalData,
  SetSelectedCutType,
  SetProtocolCode,
} from './request-cut.actions';
import { IRequestCutServiceOrder } from '@features/request-cut/services/models/request-cut-situation.model';
import {
  ICutTypeList,
  ICutTypes,
} from '@features/request-cut/services/models/request-cut-schedule.model';

@Injectable({
  providedIn: 'root',
})
export class RequestCutStoreService {
  @Select(RequestCutState.getState)
  public requestCutState$: Observable<IRequestCutStateModel>;

  @Select(RequestCutState.getHydrometerAvailability)
  public hydrometerAvailability$: Observable<TextualAnswers>;

  @Select(RequestCutState.getCutServiceOrder)
  public serviceOrder$: Observable<IRequestCutServiceOrder>;

  @Select(RequestCutState.getCutData)
  public cutData$: Observable<ICutTypes>;

  @Select(RequestCutState.getProtocolCode)
  public protocolCode$: Observable<string>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setHydrometerAvailability(accessible: TextualAnswers) {
    return new SetHydrometerAvailability(accessible);
  }

  @Dispatch()
  public setUrlBack(url: string) {
    return new SetUrlBack(url);
  }

  @Dispatch()
  public setCutServiceOrder(serviceOrder: IRequestCutServiceOrder) {
    return new SetCutServiceOrder(serviceOrder);
  }

  @Dispatch()
  public setCutData(data: ICutTypes) {
    return new SetCutData(data);
  }

  @Dispatch()
  public setPersonalData(personalData: IRequestCutPersonalData) {
    return new SetPersonalData(personalData);
  }

  @Dispatch()
  public setSelectedCutType(cutType: ICutTypeList) {
    return new SetSelectedCutType(cutType);
  }

  @Dispatch()
  public reset() {
    return new ResetRequestCut();
  }

  @Dispatch()
  public setProtocolCode(protocolCode: string) {
    return new SetProtocolCode(protocolCode);
  }

  getHydrometerAvailability(): TextualAnswers {
    return this._store.selectSnapshot(RequestCutState.getHydrometerAvailability);
  }

  getUrlBack(): string {
    return this._store.selectSnapshot(RequestCutState.getUrlBack);
  }

  getPersonalData(): IRequestCutPersonalData {
    return this._store.selectSnapshot(RequestCutState.getPersonalData);
  }

  getCutServiceOrder(): IRequestCutServiceOrder {
    return this._store.selectSnapshot(RequestCutState.getCutServiceOrder);
  }

  getCutData(): ICutTypes {
    return this._store.selectSnapshot(RequestCutState.getCutData);
  }

  getSelectedCutType(): ICutTypeList {
    return this._store.selectSnapshot(RequestCutState.getSelectedCutType);
  }

  getProtocolCode(): string {
    return this._store.selectSnapshot(RequestCutState.getProtocolCode);
  }
}
