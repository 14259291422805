import { Injectable } from '@angular/core';
import { servicesList } from '@shared/enumerators';

export interface ICrmList {
  idService: servicesList;
  idCidade: number | string;
  cpfCnpj?: string;
  cdc?: number | string;
  cdcNumber?: number
}

export interface ICrmData {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class CrmProtocolService {
  private _crmList: Array<ICrmData> = [];

  createCrmKey(crmList: ICrmList): ICrmData {
    return {
      id: Object.keys(crmList).sort().map(element => crmList[element]).join('-'),
    }
  }
  hasCrmList(val: ICrmList): boolean {
    let key = this.createCrmKey(val);
    return this._crmList.some((value) => value.id === key.id);
  }

  setCrmList(val: ICrmList) {
    let key = this.createCrmKey(val);
    this._crmList.push(key);
    return true;
  }

  resetCrmList(){
    this._crmList = [];
    return true;
  }
}
