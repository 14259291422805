import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env';
import { AppModule } from './app/app.module';
import { getAcnConfiguration } from './app/get-acn-configuration';
import { applyPolyfills, defineCustomElements } from '@acn/ui/loader';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', async () => {
  await applyPolyfills();
  const [config] = await Promise.all([
    // Carrega as configurações da aplicação
    getAcnConfiguration(),
    defineCustomElements(window),
  ]);
  // Salva as configurações da aplicação em uma variável global para ser usada
  // na injeção de dependências
  window['$$__brk-config'] = config;
  platformBrowserDynamic().bootstrapModule(AppModule).catch();
});
