import { validateCelular } from './celular.validator';
import { validateCEP } from './cep.validator';
import { validateCNPJ } from './cnpj.validator';
import { validateCPF } from './cpf.validator';
import { validateData } from './date.validator';
import { validateEmail } from './email.validator';
import { validatePhone } from './phone.validator';
import { validateFullname } from './fullname.validator';
import { validatePassDate } from './pass-date.validator';
import { validateAgency } from './bankAgency.validator';
import { validateBankAccount } from './bankAccount.validator';
import { validateBirthAge } from './birthAge.validator';

export const validate = {
  celular: validateCelular,
  cep: validateCEP,
  cnpj: validateCNPJ,
  cpf: validateCPF,
  data: validateData,
  email: validateEmail,
  telefone: validatePhone,
  nomeCompleto: validateFullname,
  dateInPass: validatePassDate,
  bankAgency: validateAgency,
  bankAccount: validateBankAccount,
  birthAge: validateBirthAge
};
