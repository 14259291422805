import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  Reset,
  ResetRestorePasswordData,
  SetFirstAccess,
  SetRestorePasswordData,
  SetRestorePasswordType,
  SetUser,
  UpdateUserEmail,
  UpdateUserPhone,
} from './user.actions';
import { IUser } from '@acn/angular';
import { IUserStore } from './models/user-store.model';
import { IRestorePassword } from './models/restore-password.model';

@State<IUserStore>({
  name: 'user',
  defaults: {
    userInfo: null,
    restorePassword: null,
    firstAccess: false,
  },
})
@Injectable()
export class UserState {
  @Selector()
  public static getUser(state: IUserStore): IUser {
    return state.userInfo;
  }

  @Selector()
  public static getUserName(state: IUserStore): string {
    return state.userInfo.primeiroNome;
  }

  @Selector()
  public static getUserEmail(state: IUserStore): string {
    return state.userInfo.email;
  }

  @Selector()
  public static getUserPhone(state: IUserStore): string {
    return state.userInfo.telMovel;
  }

  @Selector()
  public static getRestoreData(state: IUserStore): IRestorePassword {
    return state.restorePassword;
  }

  @Selector()
  public static getFirstAccess(state: IUserStore): boolean {
    return state.firstAccess;
  }

  @Selector()
  public static getUserId(state: IUserStore): number {
    return state.userInfo.id;
  }

  @Action(SetUser)
  public setUser(ctx: StateContext<IUserStore>, { payload }: SetUser) {
    const state = ctx.getState();
    ctx.setState({ ...state, userInfo: { ...state.userInfo, ...payload } });
  }

  @Action(UpdateUserPhone)
  public updateUserPhone(ctx: StateContext<IUserStore>, { payload }: UpdateUserPhone) {
    const state = ctx.getState();
    ctx.setState({ ...state, userInfo: { ...state.userInfo, telMovel: payload } });
  }

  @Action(UpdateUserEmail)
  public updateUserEmail(ctx: StateContext<IUserStore>, { payload }: UpdateUserEmail) {
    const state = ctx.getState();
    ctx.setState({ ...state, userInfo: { ...state.userInfo, email: payload } });
  }

  @Action(SetRestorePasswordData)
  public setRestorePasswordData(
    ctx: StateContext<IUserStore>,
    { payload }: SetRestorePasswordData,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, restorePassword: payload });
  }

  @Action(ResetRestorePasswordData)
  public resetRestorePasswordData(ctx: StateContext<IUserStore>) {
    const state = ctx.getState();
    ctx.setState({ ...state, restorePassword: null });
  }

  @Action(Reset)
  public reset(ctx: StateContext<Reset>) {
    ctx.setState({ userInfo: null, restorePassword: null });
  }

  @Action(SetRestorePasswordType)
  public setRestorePasswordType(
    ctx: StateContext<IUserStore>,
    { payload }: SetRestorePasswordType,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, restorePassword: { ...state.restorePassword, type: payload } });
  }

  @Action(SetFirstAccess)
  public setFirstAccess(ctx: StateContext<IUserStore>, { payload }: SetFirstAccess) {
    const state = ctx.getState();
    ctx.setState({ ...state, firstAccess: payload });
  }
}
