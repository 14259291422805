import { IBillingType } from '@features/account/models/billing-type.model';
import { IBank } from '@features/services/models/bank.model';
import { IBankData } from './models/bankData.model';

export class Reset {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset automatic debit service';
}

export class ResetBanks {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset banks array';
}

export class ResetNoticeType {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset notice type';
}

export class ResetSelectedBank {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset selected bank';
}

export class ResetBillingType {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset billing type';
}

export class ResetReplaceAutomaticDebit{
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Reset replace automatic debit';
}

export class AddSelectedBank {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add selected bank';
  constructor(public payload: IBank) {}
}

export class GetReplaceAutomaticDebit {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] get replace automatic debit';
  constructor(public payload: boolean) {}
}

export class AddBanksList {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add banks list';
  constructor(public payload: IBank[]) {}
}

export class AddBankData {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add banks data';
  constructor(public payload: IBankData) {}
}



export class AddBillingType {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add billing type';
  constructor(public payload: IBillingType) {}
}

export class AddNoticeType {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add notice type';
  constructor(public payload: number) {}
}

export class AddReplaceAutomaticDebit {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add replace automatic debit';
  constructor(public payload: boolean) {}
}

export class AddIsAutomaticDebitNewFlow {
  public static readonly type = '[AUTOMATIC DEBIT SERVICE] Add is automatic debit new flow';
  constructor(public payload: boolean) {}
}