import { IUserInfo } from '@shared/models/user-info.model';
import { IRestorePassword } from './models/restore-password.model';

export class SetUser {
  public static readonly type = '[SetUser] action';
  constructor(public payload: IUserInfo) {}
}

export class SetRestorePasswordData {
  public static readonly type = '[USER] set restore password data';
  constructor(public payload: IRestorePassword) {}
}

export class SetRestorePasswordType {
  public static readonly type = '[USER] set restore password type';
  constructor(public payload: string) {}
}

export class ResetRestorePasswordData {
  public static readonly type = '[USER] reset restore password data';
}

export class Reset {
  public static readonly type = '[RESET]';
}

export class SetFirstAccess {
  public static readonly type = '[USER] set first access';
  constructor(public payload: boolean) {}
}

export class UpdateUserPhone {
  public static readonly type = '[Update User] update phone';
  constructor(public payload: string) {}
}
export class UpdateUserEmail {
  public static readonly type = '[Update User] update e-mail';
  constructor(public payload: string) {}
}
