import { Inject, Injectable } from '@angular/core';
import { IRequest, AcnConnectorService } from '@acn/angular';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { Observable } from 'rxjs';
import { InstallmentPartner } from '@shared/models/InstallmentPartner';
import { InstallationStoreService } from '@store/dashboard';
import { DuplicateService } from '@features/duplicate/services/duplicate.service';
import { take } from 'rxjs/operators';
import { WINDOW } from '../window/window.token';
import { IPartnerURL } from './contracts/IPartnerURL.contract';
import { EasyDuplicateService } from '@features/easy-duplicate/services/easy-duplicate.service';
import { AccountService } from '@features/account/services/account.service';

const BILL_TYPE = {
  GUIDE: 'guia',
  INVOICE: 'fatura',
};
@Injectable({
  providedIn: 'root',
})
export class InstallmentService {
  private readonly BASE_URL = 'parcelamento/public/parceiros';
  private readonly BASE_FLEXPAG_URL = 'parcelamento/public/gerarUrl/flexpag';
  private readonly BASE_PARCELE_NA_HORA_URL = 'parcelamento/gerarUrl';
  private readonly BASE_PARCELE_NA_HORA_URL_PUBLIC = 'parcelamento/public/gerarUrl';

  constructor (
    private _connectorApi: AcnConnectorService,
    private _selectCityService: SelectCityService,
    private _installationStoreService: InstallationStoreService,
    private _duplicateService: DuplicateService,
    private _easyDuplicateService: EasyDuplicateService,
    private _accountService: AccountService,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  getPartners (): Observable<InstallmentPartner[]> {
    const idCity = this._selectCityService.getSelectedCity()?.id;

    const request: IRequest = {
      endPoint: this.BASE_URL,
      checkToken: false,
      showLoading: true,
      queryString: {
        idCidade: idCity,
      },
    };

    return this._connectorApi.get(request);
  }

  goToFlexpag (idCity: string, idUnity: string): void {

    const request: IRequest = {
      endPoint: this.BASE_FLEXPAG_URL,
      checkToken: false,
      showLoading: true,
      queryString: this._getQueryForFlexpag(idCity, idUnity),
    };

    this._connectorApi
      .get(request)
      .pipe(take(1))
      .subscribe(
        (res: IPartnerURL) => {
          this.window.open(res.url, '_blank');
        },
        (err) => {
          throw new Error('Erro ao gerar o parcelamento da sua conta. Tente novamente mais tarde.');
        },
      );
  }
  goToParceleNaHora (idCity: string): void {
    const selectedInstallation = this._installationStoreService.getSelectedInstallation();
    const onDashboard = this._accountService.onDashboard();

    const request: IRequest = {
      endPoint: onDashboard ? this.BASE_PARCELE_NA_HORA_URL : this.BASE_PARCELE_NA_HORA_URL_PUBLIC,
      checkToken: false,
      showLoading: true,
      queryString: this._getQueryForParceleNaHora(onDashboard, idCity, selectedInstallation?.cdc),
    };

    this._connectorApi
      .get(request)
      .pipe(take(1))
      .subscribe(
        (res: IPartnerURL) => {
          this.window.open(res.url, '_blank');
        },
        (err) => {
          throw new Error('Erro ao gerar o parcelamento da sua conta. Tente novamente mais tarde.');
        },
      );
  }

  private _getQueryForParceleNaHora (usePublicURL: boolean, idCity:string, cdc: string) {
    if (usePublicURL) {
      const document = this._getLoggedDocument();
      return {
        idCidade: idCity,
        idLigacao: cdc,
        tipo: document.type,
        idPagamento: document.id,
      };
    }

    const document = this._getPublicDocument();
    return {
      idCidade: idCity,
      tipo: document.type,
      idPagamento: document.id,
    };
  }

  private _getQueryForFlexpag (idCity: string, idUnity: string) {

    const onDashboard = this._accountService.onDashboard();

    if (onDashboard) {
      const document = this._getLoggedDocument();
      return {
        idCidade: idCity,
        idUnidade: idUnity,
        faturaGuia: document.type,
        idFaturaGuia: document.id,
      };
    }

    const document = this._getPublicDocument();

    return {
      idCidade: idCity,
      idUnidade: idUnity,
      faturaGuia: document.type,
      idFaturaGuia: document.id,
    };
  }


  private _getLoggedDocument () {
    const guide = this._duplicateService.getCurrentGuide();
    const invoice = this._duplicateService.getCurrentInvoice();

    const type = guide ? BILL_TYPE.GUIDE : BILL_TYPE.INVOICE;
    const billId = guide ? guide.idGuia : invoice?.idFatura;

    return {
      type,
      id: billId,
    };
  }
  private _getPublicDocument () {
    const isGuide = this._easyDuplicateService.getIsGrouped();
    const type = isGuide ? BILL_TYPE.GUIDE : BILL_TYPE.INVOICE;
    const grouped = this._easyDuplicateService.getGroupedDuplicate();
    const singleDuplicate = this._easyDuplicateService.getSelectedDuplicate();
    const billId = isGuide ? grouped?.guia.idGuia : singleDuplicate?.idFatura;

    return {
      type: type,
      id: billId,
    };
  }
}
