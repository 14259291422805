import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { SetRedirectedFrom, SetRedirectedTo } from './redirection-store.actions';

export interface RedirectionStateModel {
  redirectedTo: string;
  redirectedFrom: string;
}

@State<RedirectionStateModel>({
  name: 'redirection',
  defaults: {
    redirectedFrom: '',
    redirectedTo: '',
  },
})
@Injectable()
export class RedirectionState {
  @Selector()
  public static getState(state: RedirectionStateModel) {
    return state;
  }

  @Selector()
  public static getRedirectedFrom(state: RedirectionStateModel): string {
    return state.redirectedFrom;
  }
  @Selector()
  public static getRedirectedTo(state: RedirectionStateModel): string {
    return state.redirectedTo;
  }

  @Action(SetRedirectedTo)
  public setRedirectTo(ctx: StateContext<RedirectionStateModel>, { payload }: SetRedirectedTo) {
    const state = ctx.getState();
    ctx.setState({ ...state, redirectedTo: payload });
  }

  @Action(SetRedirectedFrom)
  public setRedirecTo(ctx: StateContext<RedirectionStateModel>, { payload }: SetRedirectedFrom) {
    const state = ctx.getState();
    ctx.setState({ ...state, redirectedFrom: payload });
  }
}
