import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetIsLogin, SetIsEmail } from './login.actions';

export interface LoginStateModel {
  isLogin: boolean;
  isEmail: boolean;
}

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    isLogin: false,
    isEmail: true,
  },
})
@Injectable()
export class LoginState {
  @Selector()
  public static getState(state: LoginStateModel) {
    return state;
  }

  @Selector()
  public static isLogin(state: LoginStateModel) {
    return state.isLogin;
  }

  @Selector()
  public static isEmail(state: LoginStateModel) {
    return state.isEmail;
  }

  @Action(SetIsLogin)
  public setIsLogin(ctx: StateContext<LoginStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({ ...state, isLogin: payload });
  }
  @Action(SetIsEmail)
  public setIsEmail(ctx: StateContext<LoginStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({ ...state, isEmail: payload });
  }
}
