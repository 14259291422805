import { Injectable } from '@angular/core';
import { isFunction } from '@acn/angular';
import { HandTalkOptions } from '@features/hand-talk/models/hand-talk-options';
import { Entries } from '@shared/utils/entries';

interface Validation<T> {
  fn: (value: any) => boolean;
  defaultValue: () => T;
}

function createValidation<T>(
  valueOrFn: readonly T[] | ((value: any) => boolean),
  defaultValue: () => T,
): Validation<T> {
  return {
    fn: isFunction(valueOrFn) ? valueOrFn : (value) => valueOrFn.includes(value),
    defaultValue,
  };
}

@Injectable({ providedIn: 'root' })
export class HandTalkService {
  private readonly _validations: ReadonlyMap<
    keyof HandTalkOptions,
    Validation<HandTalkOptions[keyof HandTalkOptions]>
  > = new Map<keyof HandTalkOptions, Validation<HandTalkOptions[keyof HandTalkOptions]>>()
    .set(
      'side',
      createValidation<HandTalkOptions['side']>(['right', 'left'], () => 'right'),
    )
    .set(
      'align',
      createValidation<HandTalkOptions['align']>(['default', 'top', 'bottom'], () => 'default'),
    )
    .set(
      'avatar',
      createValidation<HandTalkOptions['avatar']>(['HUGO', 'MAYA'], () => 'MAYA'),
    );

  validateOptions(options: Partial<HandTalkOptions | Record<string, any>>): HandTalkOptions {
    const entries = Object.entries(options) as Entries<HandTalkOptions>;
    return entries.reduce((object, [key, value]) => {
      const validator = this._validations.get(key);
      if (validator) {
        const isValid = validator.fn(value);
        const defaultValue = validator.defaultValue();
        if (!isValid && typeof value !== 'undefined') {
          console.warn(
            `[HandTalk] Configuração "${key}" com valor "${value}" não é válida. Será usado o valor padrão "${defaultValue}"`,
          );
        }
        value = isValid ? value : defaultValue;
      }
      return { ...object, [key]: value };
    }, {} as HandTalkOptions);
  }
}
