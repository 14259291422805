import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { CustomRouterStateSerializer } from './router/router-state.serializer';
import { DEVTOOLS_REDUX_CONFIG, OPTIONS_CONFIG, STATES_MODULES } from './store.config';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(STATES_MODULES, OPTIONS_CONFIG),
    NgxsStoragePluginModule.forRoot({
      key: [
        'dashboard.cities',
        'register',
        'dashboard.steps',
        'dashboard.contact',
        'dashboard.installation',
        'dashboard.billing',
        'dashboard.duplicate',
        'dashboard.consumption',
        'dashboard.login',
        'dashboard.report',
        'dashboard.creditCard',
        'dashboard.changeHolder',
        'dashboard.autoReconnection',
        'dashboard.requestCut',
        'dashboard.newInstallation',
        'app.user',
        'app.useTerms',
        'home.easyDuplicate',
        'home.changeExpiringDate',
        'home.automaticDebitService',
        'home.reportLeak',
      ],
      storage: 0,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
  providers: [{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }],
})
export class NgxsStoreModule {}
