import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Reset, ResetSteps, SetCurrentStep, SetSteps } from './steps.actions';
import { Injectable } from '@angular/core';

export interface StepsStateModel {
  steps: string[];
  currentStep: number;
}

@State<StepsStateModel>({
  name: 'steps',
  defaults: {
    steps: [],
    currentStep: 0,
  },
})
@Injectable()
export class StepsState {
  @Selector()
  public static getState(state: StepsStateModel) {
    return state;
  }

  @Selector()
  public static getSteps(state: StepsStateModel) {
    return state.steps;
  }

  @Selector()
  public static getCurrentStep(state: StepsStateModel) {
    return state.currentStep;
  }

  @Action(Reset)
  public reset(ctx: StateContext<StepsStateModel>) {
    ctx.setState({ steps: [], currentStep: 0 });
  }

  @Action(SetSteps)
  public setSteps(ctx: StateContext<StepsStateModel>, { payload }: SetSteps) {
    const state = ctx.getState();
    ctx.setState({ ...state, steps: payload });
  }

  @Action(SetCurrentStep)
  public setCurrentStep(ctx: StateContext<StepsStateModel>, { payload }: SetCurrentStep) {
    const state = ctx.getState();
    ctx.setState({ ...state, currentStep: payload });
  }

  @Action(ResetSteps)
  public resetSteps(ctx: StateContext<StepsStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, steps: [], currentStep: 0 });
  }
}
