import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { INewInstallationStateModel } from './models';
import {
  ResetNewInstallation,
  SetHasDebits,
  SetHolderData,
  SetHolderDocument,
  SetInstallationData,
  SetInstallationDocs,
  SetNewInstallationAddressData,
  SetNewInstallationData,
  SetNewInstallationDocuments,
  SetProtocolList,
  SetRequestingDocs,
  SetSelectedNewInstallation,
  SetUrlBack,
} from './new-installation.actions';

const INITIAL_STATE: INewInstallationStateModel = {
  urlBack: null,
  newInstallationData: null,
  documents: null,
  addressData: null,
  holderDocument: null,
  hasDebits: null,
  protocolList: null,
  requestingDocs: null,
  holderData: null,
  selectedNewInstallation: null,
  installationData: null,
  installationDocs: null,
};
@State<INewInstallationStateModel>({
  name: 'newInstallation',
  defaults: INITIAL_STATE,
})
@Injectable()
export class NewInstallationState {
  @Selector()
  public static getState(state: INewInstallationStateModel) {
    return state;
  }

  @Selector()
  public static getUrlBack(state: INewInstallationStateModel) {
    return state.urlBack;
  }

  @Selector()
  public static getInstallationData(state: INewInstallationStateModel) {
    return state.newInstallationData;
  }

  @Selector()
  public static getInstallationDocuments(state: INewInstallationStateModel) {
    return state.documents;
  }

  @Selector()
  public static getAddressData(state: INewInstallationStateModel) {
    return state.addressData;
  }

  @Selector()
  public static getHolderDocument(state: INewInstallationStateModel) {
    return state.holderDocument;
  }

  @Selector()
  public static getHasDebits(state: INewInstallationStateModel) {
    return state.hasDebits;
  }

  @Selector()
  public static getProtocolList(state: INewInstallationStateModel) {
    return state.protocolList;
  }

  @Selector()
  public static getHolderData(state: INewInstallationStateModel) {
    return state.holderData;
  }

  @Selector()
  public static getRequestingDocs(state: INewInstallationStateModel) {
    return state.requestingDocs;
  }

  @Selector()
  public static getInstallationDocs(state: INewInstallationStateModel) {
    return state.installationDocs;
  }

  @Selector()
  public static getSelectedNewInstallation(state: INewInstallationStateModel) {
    return state.selectedNewInstallation;
  }

  @Selector()
  public static getInstalationData(state: INewInstallationStateModel) {
    return state.installationData;
  }

  @Action([ResetNewInstallation])
  public reset(ctx: StateContext<INewInstallationStateModel>) {
    ctx.patchState(INITIAL_STATE);
  }

  @Action(SetUrlBack)
  public setUrlBack(ctx: StateContext<INewInstallationStateModel>, { payload }: SetUrlBack) {
    ctx.patchState({ urlBack: payload });
  }

  @Action(SetNewInstallationData)
  public setNewInstallationData(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetNewInstallationData,
  ) {
    ctx.patchState({ newInstallationData: payload });
  }

  @Action(SetNewInstallationDocuments)
  public setNewInstallationDocuments(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetNewInstallationDocuments,
  ) {
    ctx.patchState({ documents: payload });
  }

  @Action(SetNewInstallationAddressData)
  public setNewInstallationAddressData(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetNewInstallationAddressData,
  ) {
    ctx.patchState({ addressData: payload });
  }

  @Action(SetHolderDocument)
  public setHolderDocument(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetHolderDocument,
  ) {
    ctx.patchState({ holderDocument: payload });
  }

  @Action(SetHasDebits)
  public setHasDebits(ctx: StateContext<INewInstallationStateModel>, { payload }: SetHasDebits) {
    ctx.patchState({ hasDebits: payload });
  }

  @Action(SetProtocolList)
  public setProtocolList(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetProtocolList,
  ) {
    ctx.patchState({ protocolList: payload });
  }

  @Action(SetRequestingDocs)
  public setRequestingDocs(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetRequestingDocs,
  ) {
    ctx.patchState({ requestingDocs: payload });
  }

  @Action(SetInstallationDocs)
  public setInstallationDocs(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetInstallationDocs,
  ) {
    ctx.patchState({ installationDocs: payload });
  }

  @Action(SetHolderData)
  public setHolderData(ctx: StateContext<INewInstallationStateModel>, { payload }: SetHolderData) {
    ctx.patchState({ holderData: payload });
  }

  @Action(SetSelectedNewInstallation)
  public setSelectedNewInstallation(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetSelectedNewInstallation,
  ) {
    ctx.patchState({ selectedNewInstallation: payload });
  }

  @Action(SetInstallationData)
  public setInstallationData(
    ctx: StateContext<INewInstallationStateModel>,
    { payload }: SetInstallationData,
  ) {
    ctx.patchState({ installationData: payload });
  }
}
