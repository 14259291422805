import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BillingState, BillingStateModel } from './billing.state';
import {
  IDebitResponse,
  IBillResponse,
  IReconnectResponse,
} from '@features/billing/services/models';
import {
  SetDebits,
  SetBills,
  ResetBilling,
  ShowDebits,
  PayAll,
  SetDebitsLoaded,
  SetBillsLoaded,
  Reconnection,
  SetLoadedReconnection,
} from './billing.actions';
import { IBill, IDebits, IReconnect } from './models';

@Injectable({
  providedIn: 'root',
})
export class BillingStoreService {
  @Select(BillingState.getState)
  public billingState$: Observable<BillingStateModel>;

  @Select(BillingState.getShowDebits)
  public showDebits$: Observable<boolean>;

  @Select(BillingState.getPayAll)
  public payAll$: Observable<boolean>;

  @Select(BillingState.getReconnection)
  public reconnection$: Observable<IReconnect>;

  @Select(BillingState.getDebitStatus)
  public loadedDebitos$: Observable<boolean>;

  @Select(BillingState.getAllDebits)
  public getAllDebits$: Observable<{ debitos: IDebits; totalDebitos: number }>;

  @Select(BillingState.getAllBills)
  public getAllBills$: Observable<{ debitos: IDebits; contasPagas: Array<IBill> }>;

  @Select(BillingState.getBillStatus)
  public loadedBills$: Observable<boolean>;

  @Select(BillingState.getLoadedReconnection)
  public loadedReconnection$: Observable<boolean>;

  @Select(BillingState.getAllLoadedStatus)
  public getAllLoadedStatus$: Observable<boolean>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setDebits(debits: IDebitResponse) {
    return new SetDebits(debits);
  }

  @Dispatch()
  public setBills(bills: IBillResponse) {
    return new SetBills(bills);
  }

  @Dispatch()
  public setDebitsLoaded(status: boolean) {
    return new SetDebitsLoaded(status);
  }

  @Dispatch()
  public setBillsLoaded(status: boolean) {
    return new SetBillsLoaded(status);
  }

  @Dispatch()
  public setLoadedReconnection(status: boolean) {
    return new SetLoadedReconnection(status);
  }

  @Dispatch()
  public showDebits(status: boolean) {
    return new ShowDebits(status);
  }

  @Dispatch()
  public payAll(status: boolean) {
    return new PayAll(status);
  }

  @Dispatch()
  public reconnection(status: IReconnectResponse) {
    return new Reconnection(status);
  }

  @Dispatch()
  public resetBilling() {
    return new ResetBilling();
  }

  public hasHistory() {
    const state = this._store.selectSnapshot(BillingState.getState);
    return !!(state.totalDebitos > 0 || state.contasPagas.length);
  }

  public getShowDebits(): boolean {
    const state = this._store.selectSnapshot(BillingState.getState);
    return state.showDebits;
  }
}
