import { State, Action, Selector, StateContext } from '@ngxs/store';
import { SetAvailableFeatures, SetOnMobileAction, SetUnsupportedBrowser } from './settings.actions';
import { Injectable } from '@angular/core';

export interface ISettingsStateModel {
  isConnected: boolean;
  onMobile: boolean;
  userUnsupporterBrowserAgreement: boolean;
  features: Array<string>;
}

@State<ISettingsStateModel>({
  name: 'settings',
  defaults: {
    isConnected: false,
    onMobile: false,
    userUnsupporterBrowserAgreement: false,
    features: [],
  },
})
@Injectable()
export class SettingsState {
  @Selector()
  public static getState(state: ISettingsStateModel) {
    return state;
  }

  @Selector()
  public static getOnMobile(state: ISettingsStateModel) {
    return state.onMobile;
  }

  @Selector()
  public static getFeatures(state: ISettingsStateModel) {
    return state.features;
  }

  @Selector()
  public static getUnsupportedAgreement(state: ISettingsStateModel) {
    return state.userUnsupporterBrowserAgreement;
  }

  @Action(SetOnMobileAction)
  public add(ctx: StateContext<ISettingsStateModel>, { payload }: SetOnMobileAction) {
    const state = ctx.getState();
    ctx.setState({ ...state, onMobile: payload });
  }

  @Action(SetUnsupportedBrowser)
  public setUnsupportedBrowser(
    ctx: StateContext<ISettingsStateModel>,
    { payload }: SetUnsupportedBrowser,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, userUnsupporterBrowserAgreement: payload });
  }

  @Action(SetAvailableFeatures)
  public setAvailableFeatures(
    ctx: StateContext<ISettingsStateModel>,
    { payload }: SetAvailableFeatures,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, features: payload });
  }
}
