import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, take, takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';

import { AcnFormValidations, BaseFormComponent } from '@acn/angular';
import { SmsService } from './../../services/sms/sms.service';

@Component({
  selector: 'app-sms-form',
  templateUrl: './sms-form.component.html',
  styleUrls: ['./sms-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsFormComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  @ViewChildren('code') components: QueryList<ElementRef<HTMLAcnFormInputElement>>;

  public onMobile = false;
  public isBrowser: boolean;
  public hasError = false;
  public showTimer = false;
  public timer: number;
  public gtmCategory = 'Botao';
  private _initialTimer = 15;
  private _currentInput = 0;
  private _compArr: Array<ElementRef<HTMLAcnFormInputElement>>;
  private _formInputs: FormGroup;
  private _initialValue: any;

  @Output() send: EventEmitter<any> = new EventEmitter();

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.stopImmediatePropagation();

    switch (event.key) {
      case 'Enter':
        this.checkSubmit();
        break;
      case 'Backspace':
        this.onDelete();
        break;
    }
  }

  constructor(
    private _smsService: SmsService,
    private _formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this._setupForm();
  }

  ngAfterViewInit() {
    this._compArr = this.components.toArray();
  }

  private _onCheckErrors() {
    if (this._currentInput === 0) {
      this.hasError = true;
      return;
    }

    this.hasError = false;

    const index = Object.values(this._formInputs.value).indexOf('');
    if (index < 0) {
      this.form.controls.all.setValue('true');
    }

    if (index >= 0) {
      this.form.controls.all.setValue('');
    }

    this.form.controls.all.updateValueAndValidity({ emitEvent: false });
  }

  private _setupForm() {
    this.form = this._formBuilder.group({
      inputs: this._formBuilder.group({
        code1: [''],
        code2: [''],
        code3: [''],
        code4: [''],
        code5: [''],
      }),
      all: ['', [AcnFormValidations.required]],
    });

    this._formInputs = this.form.controls.inputs as FormGroup;
    this._initialValue = this._formInputs.value;

    this._formInputs.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this._currentInput = Object.values(data).indexOf('');
      this.setFocus(true);
      this._onCheckErrors();
    });
  }

  private _setTimer() {
    this.timer = this._initialTimer;
    const start = this._initialTimer;

    timer(1000, 1000)
      .pipe(
        take(start + 1),
        map((i) => start - i),
      )
      .subscribe((i) => {
        if (i < 1) {
          this.showTimer = false;
          this.timer = this._initialTimer;
          this.changeDetectorRef.detectChanges();
          return;
        }
        this.timer = i;
        this.changeDetectorRef.detectChanges();
      });
  }

  setFocus(isOn: boolean) {
    if (!isOn) {
      return;
    }

    if (this._currentInput >= 0 && this._compArr[this._currentInput]) {
      this._compArr[this._currentInput].nativeElement.setFocus();
      return;
    }

    if (this._currentInput < 0) {
      this._compArr[this._compArr.length - 1].nativeElement.setFocus();
    }
  }

  private _updateForm() {
    this.showTimer = true;
    this._formInputs.reset(this._initialValue);

    this.form.controls.all.setValue('');
    this.form.controls.all.updateValueAndValidity({ emitEvent: false });

    this.hasError = false;

    this._setTimer();
  }

  onDelete() {
    const index = Object.values(this._formInputs.value).indexOf('');
    this._currentInput = index >= 0 ? index - 1 : this._compArr.length - 1;

    if (this._currentInput >= 0) {
      this.setFocus(true);
    }
  }

  resendCode() {
    this._smsService
      .getSmsVerification()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (!data) {
          return;
        }

        this._updateForm();
      });
  }

  checkSubmit() {
    const canSubmit = this.onSubmit(false);
    this.hasError = !canSubmit;

    if (canSubmit) {
      this.submit();
    }
  }

  submit() {
    this._smsService
      .validateCodeSms(Object.values(this._formInputs.value).join(''))
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data.hasOwnProperty('code')) {
          return;
        }
        this.send.emit();
      });
  }
}
