import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AttendanceState } from './attendance.state';
import { Reset, ResetFaq, SetAttendanceFaq, SetAttendanceFaqQuestion, SetDefaultAttendancePlatform } from './attendance.actions';
import { IFaq, IFaqQuestion } from '@features/attendance/models/faq.model';

@Injectable({
  providedIn: 'root',
})
export class AttendanceStoreService {
  @Select(AttendanceState.getState)
  public faqState$: Observable<IFaq>;

  @Select(AttendanceState.getDefaultAttendance)
  public useDefaultAttendancePlatform$: Observable<boolean>;

  constructor(private _store: Store) {}

  public getFaq() {
    return this._store.selectSnapshot(AttendanceState.getFaq);
  }

  @Dispatch()
  public resetFaq(){
    return new ResetFaq();
  }

  @Dispatch()
  public resetState() {
    return new Reset();
  }

  @Dispatch()
  public setFaq(faq: IFaq) {
    return new SetAttendanceFaq(faq);
  }

  @Dispatch()
  public setFaqQuestion(question: IFaqQuestion) {
    return new SetAttendanceFaqQuestion(question);
  }

  @Dispatch()
  public setDefaultAttendancePlatform(useDefaultPlatform: boolean) {
    return new SetDefaultAttendancePlatform(useDefaultPlatform);
  }
}
