import { catchError, switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { IRequest, AcnConnectorService } from '@acn/angular';
import { of } from 'rxjs';

import { SelectCityService } from '@features/select-city/services/select-city.service';
import { BaseService } from '@shared/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CampanhaService extends BaseService {
  private readonly CAMPANHA_CONSULTA_URL = 'servicos/public/campanhas';

  constructor(
    protected _injector: Injector,
    private selectCityService: SelectCityService,
    private _connectorApi: AcnConnectorService,
  ) {
    super(_injector);
  }

  getSelectedCity() {
    return this.selectCityService.getSelectedCity();
  }

  getActiveCampanhas(idServico: number | string, idTipoServico: number | null) {
    try {
      const city = this.getSelectedCity();
      if (city && city.idUnidade) {
        const req: IRequest = {
          endPoint: this.CAMPANHA_CONSULTA_URL,
          queryString: {
            idCidade: city.idUnidade,
            idServico: idServico,
            idTipoServico: idTipoServico
          },
          showLoading: false,
          checkToken: false,
        };

        return this._connectorApi.get<any>(req).pipe(
          switchMap((res) => {
            return of(res);
          }),
          catchError(() => {
            return of(undefined);
          }),
        );
      } else {
        return of(undefined);
      }
    } catch (e) {
      return of(undefined);
    }
  }
}
