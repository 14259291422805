import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IRequestCutStateModel } from '@store/dashboard/states/request-cut/models';
import {
  ResetRequestCut,
  SetCutData,
  SetCutServiceOrder,
  SetHydrometerAvailability,
  SetPersonalData,
  SetSelectedCutType,
  SetUrlBack,
  SetProtocolCode,
} from './request-cut.actions';

const INITIAL_STATE: IRequestCutStateModel = {
  hydrometerAvailability: null,
  urlBack: null,
  cutServiceOrder: null,
  cutData: null,
  personalData: null,
  selectedCutType: null,
  protocolCode: null,
};

@State<IRequestCutStateModel>({
  name: 'requestCut',
  defaults: INITIAL_STATE,
})
@Injectable()
export class RequestCutState {
  @Selector()
  public static getState(state: IRequestCutStateModel) {
    return state;
  }

  @Selector()
  public static getHydrometerAvailability(state: IRequestCutStateModel) {
    return state.hydrometerAvailability;
  }

  @Selector()
  public static getUrlBack(state: IRequestCutStateModel) {
    return state.urlBack;
  }

  @Selector()
  public static getPersonalData(state: IRequestCutStateModel) {
    return state.personalData;
  }

  @Selector()
  public static getCutServiceOrder(state: IRequestCutStateModel) {
    return state.cutServiceOrder;
  }

  @Selector()
  public static getCutData(state: IRequestCutStateModel) {
    return state.cutData;
  }

  @Selector()
  public static getSelectedCutType(state: IRequestCutStateModel) {
    return state.selectedCutType;
  }

  @Selector()
  public static getProtocolCode(state: IRequestCutStateModel) {
    return state.protocolCode;
  }

  @Action([ResetRequestCut])
  public reset(ctx: StateContext<IRequestCutStateModel>) {
    ctx.patchState(INITIAL_STATE);
  }

  @Action(SetHydrometerAvailability)
  public setHydrometerAvailability(
    ctx: StateContext<IRequestCutStateModel>,
    { payload }: SetHydrometerAvailability,
  ) {
    ctx.patchState({ hydrometerAvailability: payload });
  }

  @Action(SetUrlBack)
  public setUrlBack(ctx: StateContext<IRequestCutStateModel>, { payload }: SetUrlBack) {
    ctx.patchState({ urlBack: payload });
  }

  @Action(SetPersonalData)
  public setPersonalData(ctx: StateContext<IRequestCutStateModel>, { payload }: SetPersonalData) {
    ctx.patchState({ personalData: payload });
  }

  @Action(SetCutServiceOrder)
  public setCutServiceOrder(
    ctx: StateContext<IRequestCutStateModel>,
    { payload }: SetCutServiceOrder,
  ) {
    ctx.patchState({ cutServiceOrder: payload });
  }

  @Action(SetCutData)
  public setCutData(ctx: StateContext<IRequestCutStateModel>, { payload }: SetCutData) {
    ctx.patchState({ cutData: payload });
  }

  @Action(SetSelectedCutType)
  public setSelectedCutType(
    ctx: StateContext<IRequestCutStateModel>,
    { payload }: SetSelectedCutType,
  ) {
    ctx.patchState({ selectedCutType: payload });
  }

  @Action(SetProtocolCode)
  public setProtocolCode(ctx: StateContext<IRequestCutStateModel>, { payload }: SetProtocolCode) {
    const state = ctx.getState();
    ctx.setState({ ...state, protocolCode: payload });
  }
}
