import { IAcnDynamicComponentManifest } from '@acn/angular';

export const automaticDebitServiceDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'automaticDebitService.error',
    loadChildren: () =>
      import(
        '../features/automatic-debit-service/components/automatic-debit-service-error-modal/automatic-debit-service-error-modal.module'
      ).then((m) => m.AutomaticDebitServiceErrorModalModule),
  },
];
