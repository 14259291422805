import { ChangeExpiringDateStoreService } from '@store/home';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { CitiesStoreService } from '@store/dashboard/states/cities';
import { BaseService } from '@shared/services/base/base.service';
import { RedirectionStoreService } from '@store/app';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { InvoiceSimulatorService } from '../../invoice-simulator-service/service/invoice-simulator.service';
import { CampanhaService } from '../../../shared/services/campanha/campanha.service';
import { ServiceId } from '@shared/enumerators/service-id.enum';

@Injectable({
  providedIn: 'root',
})
export class LandingService extends BaseService {
  public menu$ = this._translocoService.selectTranslateObject('menu', {}, 'landing');
  public citiesList$ = this._citiesStore.citiesList$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public listCityServices$ = this._citiesStore.selectedCityServices$;

  constructor(
    private _changeExpiringDateService: ChangeExpiringDateStoreService,
    private _translocoService: TranslocoService,
    private _citiesStore: CitiesStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _selectCityService: SelectCityService,
    protected _injector: Injector,
    private _serviceInvoice: InvoiceSimulatorService,
    private _serviceCampanha: CampanhaService,
  ) {
    super(_injector);
  }

  setChangeExpiringDate() {
    this._changeExpiringDateService.setChangeExpiringDate(true);
  }

  getCampanhas() {
    return this._serviceCampanha.getActiveCampanhas(ServiceId.PAGINA_PRINCIPAL, null);
  }

  scrollTop(elementRef: ElementRef) {
    this._screenService.scrollTop(elementRef);
  }

  getServiceList(idCity) {
    return this._selectCityService.getCityServices(idCity);
  }

  getSelectedCity() {
    return this._selectCityService.getSelectedCity();
  }

  setCallbackOnChooseCity(url: string) {
    this._redirectionStore.setRedirectedFrom(url);
  }

  setScrollWidth(el: ElementRef) {
    this._screenService.setScrollWidth(el);
  }

  setBackHome() {
    const baseUrl = this._serviceInvoice.getOnDashboard() ? 'home-logado' : 'home';
    this._serviceInvoice.setUrlBack(`/${baseUrl}`);
  }
}
