import { IAcnDynamicComponentManifest } from '@acn/angular';

export const automaticDebitTermOfUseDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'automaticDebitTermUse.revocation',
    loadChildren: () =>
      import('../features/installation/components/installation-modal-revocation-term/modal-revocation-term.module').then(
        (m) => m.InstallationModalRevocationTermModule,
      ),
  },
  {
    path: 'automaticDebitTermUse.successRevocation',
    loadChildren: () =>
      import('../features/installation/components/installation-success-revocation-term/installation-success-revocation-term.module').then(
        (m) => m.InstallationSuccessRevocationTermModule,
      ),
  },
  {
    path: 'automaticDebitTermUse.failureRevocation',
    loadChildren: () =>
      import('../features/installation/components/installation-failure-revocation-term/installation-failure-revocation-term.module').then(
        (m) => m.InstallationFailureRevocationTermModule,
      ),
  },
];
