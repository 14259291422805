<div class="services">
  <div class="services__content container">
    <p
      class="services__title is-medium"
      [ngClass]="isOnMobile ? 'is-size-20' : 'is-size-40'"
      [innerHTML]="'landing.home.services.title' | transloco"
    ></p>

    <div *ngIf="!selectedCity" class="chooseCity">
      <p
        class="is-medium chooseCity__title"
        [innerHTML]="'landing.home.services.chooseCity' | transloco"
      ></p>
      <acn-button
        routerLink="/selecao-de-cidade"
        acnGtm
        class="button is-fullWidth"
        gtmCategory="Botao"
        gtmAction="Escolher_Outra_Cidade"
      >
        {{ 'landing.home.services.chooseMyCity' | transloco }}
      </acn-button>
    </div>

    <ng-container *ngIf="selectedCity">
      <div
        class="services__container"
        (swipeleft)="onSwipeLeft()"
        (swiperight)="onSwipeRight()"
        data-cy="landing-services-carousel-container"
      >
        <ng-container *ngIf="!isOnMobile">
          <acn-carousel class="carousel" #carousel loop="false" items-per-page="4">
            <ng-container *ngTemplateOutlet="cards"> </ng-container>
          </acn-carousel>
        </ng-container>

        <ng-container *ngIf="isOnMobile">
          <acn-carousel
            class="carousel"
            space="16"
            data-cy="landing-services-carousel"
            #carousel
            hide-navigation
          >
            <ng-container *ngTemplateOutlet="cards"> </ng-container>
          </acn-carousel>
        </ng-container>
      </div>

      <div class="services__footer">
        <a
          class="link is-size-14 is-bold"
          routerLink="/home/servicos"
          *ngIf="!isOnMobile"
          acnGtm
          gtmCategory="Botao"
          gtmAction="Todos_Servicos"
        >
          {{ 'landing.home.services.seeAllServices' | transloco }}
          <acn-icon class="icon" icon="fa-th-large"></acn-icon>
        </a>

        <acn-button
          *ngIf="isOnMobile"
          routerLink="/home/servicos"
          class="button is-fullWidth"
          acnGtm
          gtmCategory="Botao"
          gtmAction="Todos_Servicos"
        >
          {{ 'landing.home.services.seeAllServices' | transloco }}
        </acn-button>
      </div>
    </ng-container>
  </div>
  <div class="split" *ngIf="isOnMobile">
    <acn-split></acn-split>
  </div>
</div>

<ng-template #cards>
  <acn-ticket class="services__ticket" *ngFor="let card of cardsList; index as i">
    <div slot="header">
      <img [src]="card.image" [alt]="card.description | transloco" class="card__img" />
    </div>
    <div slot="footer" class="cardFooter">
      <a
        class="card__link"
        [attr.data-cy]="'landing-services-card-0' + i"
        (click)="setRouting(card)"
        acnGtm
        gtmCategory="Card"
        [gtmAction]="card.gtmAction"
      >
        <p
          class="is-bold has-text-blue is-size-20 ticket-title"
          [innerHTML]="card.title | transloco"
        ></p>
        <p class="is-size-16 is-medium">{{ card.description | transloco }}</p>
        <div *ngIf="card.callToAction" class="is-bold is-size-14 has-text-blue callToAction">
          {{ card.callToAction | transloco }}
        </div>
      </a>
    </div>
  </acn-ticket>
</ng-template>
