import { I18nModule } from '@acn/angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SupportChannelsRoutingModule } from './support-channels-routing.module';
import { SupportChannelsComponent } from './components/support-channels/support-channels.component';

@NgModule({
  declarations: [
    SupportChannelsComponent
  ],
  imports: [
    CommonModule,
    SupportChannelsRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    I18nModule.forChild('supportChannels'),
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SupportChannelsModule { }
