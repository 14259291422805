import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IReportLeakModel } from './models';
import {
  AddCdc,
  AddName,
  AddNote,
  AddPhoneNumber,
  AddProportion,
  AddReferencePoint,
  AddSuccessMessage,
  AddNeighborhoodWaterSupply,
  AddWaterSupply,
  AddWhereItOccurs,
  AddZipCode,
  ResetReportLeak,
} from './report-leak.actions';

const INITIAL_STATE: IReportLeakModel = {
  waterSupply: null,
  neighborhoodWaterSupply: null,
  name: null,
  phoneNumber: null,
  proportion: null,
  whereItOccurs: null,
  cdc: null,
  note: null,
  referencePoint: null,
  zipCode: null,
  neighbourhood: null,
  street: null,
  number: null,
  successMessage: null,
}

@State<IReportLeakModel>({
  name: 'reportLeak',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ReportLeakState {
  @Selector()
  public static getState(state: IReportLeakModel) {
    return state;
  }

  @Selector()
  public static getCdc(state: IReportLeakModel) {
    return state.cdc;
  }

  @Selector()
  public static getName(state: IReportLeakModel) {
    return state.name;
  }

  @Selector()
  public static getNote(state: IReportLeakModel) {
    return state.note;
  }

  @Selector()
  public static getPhoneNumber(state: IReportLeakModel) {
    return state.phoneNumber;
  }

  @Selector()
  public static getNeighborhoodWaterSupply(state: IReportLeakModel) {
    return state.neighborhoodWaterSupply;
  }

  @Selector()
  public static getWaterSupply(state: IReportLeakModel) {
    return state.waterSupply;
  }

  @Selector()
  public static getProportion(state: IReportLeakModel) {
    return state.proportion;
  }

  @Selector()
  public static getReferencePoint(state: IReportLeakModel) {
    return state.referencePoint;
  }

  @Selector()
  public static getWhereItOccurs(state: IReportLeakModel) {
    return state.whereItOccurs;
  }

  @Selector()
  public static getZipCode(state: IReportLeakModel) {
    return state.zipCode;
  }

  @Selector()
  public static getSuccessMessage(state: IReportLeakModel) {
    return state.successMessage;
  }

  @Action(ResetReportLeak)
  public resetReportLeak(ctx: StateContext<IReportLeakModel>) {
    ctx.setState({ ...INITIAL_STATE });
  }

  @Action(AddNeighborhoodWaterSupply)
  public addNeighborhoodWaterSupply(
    ctx: StateContext<IReportLeakModel>,
    { payload }: AddNeighborhoodWaterSupply,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, neighborhoodWaterSupply: payload });
  }

  @Action(AddWaterSupply)
  public addWaterSupply(ctx: StateContext<IReportLeakModel>, { payload }: AddWaterSupply) {
    const state = ctx.getState();
    ctx.setState({ ...state, waterSupply: payload });
  }

  @Action(AddProportion)
  public addProportion(ctx: StateContext<IReportLeakModel>, { payload }: AddProportion) {
    const state = ctx.getState();
    ctx.setState({ ...state, proportion: payload });
  }

  @Action(AddWhereItOccurs)
  public addWhereItOccurs(ctx: StateContext<IReportLeakModel>, { payload }: AddWhereItOccurs) {
    const state = ctx.getState();
    ctx.setState({ ...state, whereItOccurs: payload });
  }

  @Action(AddZipCode)
  public addZipCode(ctx: StateContext<IReportLeakModel>, { payload }: AddZipCode) {
    const state = ctx.getState();
    ctx.setState({ ...state, zipCode: payload });
  }

  @Action(AddReferencePoint)
  public addReferencePoint(ctx: StateContext<IReportLeakModel>, { payload }: AddReferencePoint) {
    const state = ctx.getState();
    ctx.setState({ ...state, referencePoint: payload });
  }

  @Action(AddName)
  public addName(ctx: StateContext<IReportLeakModel>, { payload }: AddName) {
    const state = ctx.getState();
    ctx.setState({ ...state, name: payload });
  }

  @Action(AddPhoneNumber)
  public addPhoneNumber(ctx: StateContext<IReportLeakModel>, { payload }: AddPhoneNumber) {
    const state = ctx.getState();
    ctx.setState({ ...state, phoneNumber: payload });
  }

  @Action(AddCdc)
  public addCdc(ctx: StateContext<IReportLeakModel>, { payload }: AddCdc) {
    const state = ctx.getState();
    ctx.setState({ ...state, cdc: payload });
  }

  @Action(AddNote)
  public addNote(ctx: StateContext<IReportLeakModel>, { payload }: AddNote) {
    const state = ctx.getState();
    ctx.setState({ ...state, note: payload });
  }

  @Action(AddSuccessMessage)
  public addSuccessMessage(ctx: StateContext<IReportLeakModel>, { payload }: AddSuccessMessage) {
    const state = ctx.getState();
    ctx.setState({ ...state, successMessage: payload });
  }
}
