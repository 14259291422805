<acn-ticket *ngIf="loading">
  <div class="ticket loading" slot="header">
    <acn-loading-placeholder class="loading__title"></acn-loading-placeholder>
    <acn-loading-placeholder class="loading__value"></acn-loading-placeholder>
    <acn-loading-placeholder class="loading__title"></acn-loading-placeholder>
  </div>
  <div class="ticket loading" slot="footer">
    <acn-loading-placeholder class="loading__subtitle"></acn-loading-placeholder>
  </div>
</acn-ticket>

<acn-ticket class="ticket" *ngIf="showCard">
  <ng-container *ngTemplateOutlet="statusContent"></ng-container>
</acn-ticket>

<ng-template #statusContent>
  <ng-container *transloco="let text; scope: 'consumption'">
    <div slot="header" class="ticket__header">
      <p
        data-cy="current-month"
        class="is-size-14 ticket__text is-medium"
        [innerHTML]="text('consumption.status.thisMonth', { month: content?.currentMonth })"
      ></p>

      <div class="variation" [class.percentage--no-consumption]="!content?.hasConsumptionHistory">
        <div
          class="percentage variation__item"
          *ngIf="content.hasConsumptionHistory && content.consumptionVariation?.porcentagem !== 0"
        >
          <p
            class="is-size-40 is-number"
            [ngClass]="content.hasConsumptionHistory ? 'has-text-blue' : 'gray-text'"
          >
            {{ displayPercentage }}%
          </p>
        </div>
        <div
          class="drop variation__item"
          [ngClass]="{
            rounded:
              content.hasConsumptionHistory && content.consumptionVariation?.porcentagem === 0
          }"
        >
          <acn-icon icon="fa-drop" class="icon has-text-blue"></acn-icon>
        </div>
      </div>
      <p
        data-cy="same-consumption"
        *ngIf="content.hasConsumptionHistory && content.consumptionVariation?.porcentagem == 0"
        class="is-size-14 ticket__text"
        [innerHTML]="text('consumption.status.same')"
      ></p>
      <p
        data-cy="higher-consumption"
        *ngIf="content?.hasConsumptionHistory && content.consumptionVariation?.porcentagem > 0"
        class="is-size-14 ticket__text is-medium"
        [innerHTML]="text('consumption.status.higher', { month: content?.pastMonth })"
      ></p>
      <p
        data-cy="lower-consumption"
        *ngIf="content?.hasConsumptionHistory && content?.consumptionVariation?.porcentagem < 0"
        class="is-size-14 ticket__text"
        [innerHTML]="text('consumption.status.lower', { month: content?.pastMonth })"
      ></p>
    </div>

    <div slot="footer" class="ticket__footer">
      <a
        routerLink="/home-logado/historico-de-consumo"
        acnGtm
        [gtmCategory]="gtmCategory"
        gtmAction="Entenda_O_Consumo"
        data-cy="understand"
        class="is-size-14 is-bold"
      >
        {{ text('consumption.status.understand') }}
      </a>
    </div>
  </ng-container>
</ng-template>
