import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

function isInstanceOfAny(event: Event, targets: readonly Type<Event>[]): boolean {
  return targets.some((target) => event instanceof target);
}

@Injectable({ providedIn: 'root' })
export class AcnLoaderService {
  constructor(private readonly router: Router) {}

  private readonly _startEvents = [NavigationStart] as const;
  private readonly _endEvents = [NavigationEnd, NavigationCancel, NavigationError] as const;
  private readonly _listenEvents = [...this._startEvents, ...this._endEvents] as const;
  private readonly _destroy$ = new Subject<void>();
  private readonly _isLoading$ = new BehaviorSubject(0);

  readonly isLoading$ = this._isLoading$.pipe(
    map((isLoading) => !!isLoading),
    distinctUntilChanged(),
    debounceTime(100),
  );

  show(): void {
    this._isLoading$.next(this._isLoading$.value + 1);
  }

  hide(): void {
    this._isLoading$.next(Math.max(this._isLoading$.value - 1, 0));
  }

  /**
   * @description Mostra a tela de loading quando estiver mudando de rota
   */
  startRoutingLoader(): void {
    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((event) => isInstanceOfAny(event, this._listenEvents)),
      )
      .subscribe((event) => {
        if (isInstanceOfAny(event, this._startEvents)) {
          this.show();
        }
        if (isInstanceOfAny(event, this._endEvents)) {
          this.hide();
        }
      });
  }

  destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
