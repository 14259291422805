import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ConsumptionState } from './consumption.state';
import { IConsumptionMonth, IConsumptionStatus } from './models';
import { SetConsumptionHistory, ResetState } from './consumption.actions';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionStoreService {
  @Select(ConsumptionState.getConsumptionHistory)
  public consumptionHistory$: Observable<Array<IConsumptionMonth>>;

  @Select(ConsumptionState.getConsumptionVariation)
  public consumptionVariation$: Observable<IConsumptionStatus>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setConsumptionHistory(history: Array<IConsumptionMonth>) {
    return new SetConsumptionHistory(history);
  }

  @Dispatch()
  public resetState() {
    return new ResetState();
  }
}
