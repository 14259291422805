import { IAcnDynamicComponentManifest } from '@acn/angular';

export const loginDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'login.notFound',
    loadChildren: () =>
      import('./login/components/login-modal-not-found/login-modal-not-found.module').then(
        (m) => m.LoginModalNotFoundModule,
      ),
  },
  {
    path: 'login.attendance',
    loadChildren: () =>
      import('./login/components/login-modal-attendance/login-modal-attendance.module').then(
        (m) => m.LoginModalAttendanceModule,
      ),
  },
  {
    path: 'login.logout',
    loadChildren: () =>
      import('./login/components/login-modal-logout/login-modal-logout.module').then(
        (m) => m.LoginModalLogoutModule,
      ),
  },
  {
    path: 'login.duplicateNumber',
    loadChildren: () =>
      import(
        './login/components/login-modal-duplicate-number/login-modal-duplicate-number.module'
      ).then((m) => m.LoginModalDuuplicateNumberModule),
  },
  {
    path: 'login.genericModal',
    loadChildren: () =>
      import('./login/components/login-modal-generic/login-modal-generic.module').then(
        (m) => m.LoginModalGenericModule,
      ),
  },
];
