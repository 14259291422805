import { IAcnDynamicComponentManifest } from '@acn/angular';

export const billingDynamicComponentsManifestDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'app-campaign-modal',
    loadChildren: () =>
      import(
        '../shared/components/campaign-modal/campaign-modal.module'
      ).then((m) => m.CampaignModalModule),
  },
];
