/**
 * Verifica se o campo digitado é válido
 *
 * @returns inválido caso campo apresente erro, caso contrário, retorna válido
 */
export function differentDigits(number: string | number): boolean {
  const numberToCheck = `${number}`;

  return numberToCheck.split('').some((digit, i, digits) => {
    return digit !== digits[0];
  });
}
