import { Component, OnInit, Input } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';

import { InstallationService } from '@features/installation/services/installation.service';
import { AccountService } from '@features/account/services/account.service';
import { BaseComponent } from '@acn/angular';

import { ICreditCard, IInstallation } from '@store/dashboard';
import { IBillingType } from '@features/account/models/billing-type.model';
import { meiosPagamento } from '@shared/enumerators/meiosPagamento.enum';
import { IBillingDetail } from '@features/account/models/billing-detail.model';
import { AutomaticDebitServiceService } from '@features/automatic-debit-service/services/automatic-debit-service.service';

@Component({
  selector: 'app-credit-or-debit-card',
  templateUrl: './credit-or-debit-card.component.html',
  styleUrls: ['./credit-or-debit-card.component.scss'],
})
export class CreditOrDebitCardComponent extends BaseComponent implements OnInit {
  public bgImage: string;
  public onMobile = false;
  public billingDetail: IBillingDetail;
  public isDebitCard = false;
  public isCreditCard = false;
  public itemCreditCard: ICreditCard;
  private _creditCard;
  private _billingType: IBillingType;
  private _installation: IInstallation;
  public hasGenericError = false;

  set creditCard(value: ICreditCard) {
    const number =
      value && value.number ? this._completeNumber(value.number) : this._completeNumber('');

    const code =
      value && value.code ? this._completeNumber(value.code, 3) : this._completeNumber('', 3);

    this._creditCard = { ...value, number, code };
  }

  get creditCard() {
    return this._creditCard;
  }
  @Input() baseImgUrl: string;

  constructor(
    private _accountService: AccountService,
    private _installationService: InstallationService,
    private _automaticDebitService: AutomaticDebitServiceService,
  ) {
    super();
  }

  ngOnInit() {
    this._getCardDue();
    this._getCardData();
    this._getSelectedInstallation();
    this._getBillingType();
    this._setScreen();
  }

  private _getCardDue() {
    this._accountService.getCardInfo().pipe(take(1)).subscribe();
  }

  private _isDebitCard(): boolean {
    return this._billingType?.idTipoCobranca === meiosPagamento.debitoAutomatico;
  }

  private _getCardData() {
    this._accountService.cardInfo$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      if (data) {
        this.creditCard = data;
        this.isCreditCard = true;
        this.itemCreditCard = this._creditCard;
        this._setImage();
      } else {
        this.isCreditCard = false;
      }
    });
  }

  private _setScreen() {
    this._accountService.onMobile$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.onMobile = data));
  }

  private _setImage() {
    this.bgImage = `${this.baseImgUrl}/img/credit-card/flag-${this.creditCard.brand}.svg`;
  }

  private _chunkNumber(number: string) {
    return number.match(/.{1,4}/g).join(' ');
  }

  private _getBillingType(): void {
    if (this._installation && this._installation.cdc && this._installation.idCidade) {
      this._billingType = this._automaticDebitService.getBillingType();

      if (!this._billingType) {
        this._accountService
          .billingType(this._installation.cdc, this._installation.idCidade, this.hasGenericError)
          .pipe(takeUntil(this.onDestroy))
          .subscribe((item: IBillingType) => {
            this._billingType = item;
            this._automaticDebitService.setBillingType(item);
            this._hasBillingType();
          });
      } else {
        this._hasBillingType();
      }
    }
  }

  private _hasBillingType(): void {
    this.isDebitCard = this._isDebitCard();
    if (this._billingType && this._billingType.detalheCobranca) {
      this.billingDetail = this._billingType.detalheCobranca[0];
    }
  }

  private _getSelectedInstallation() {
    this._installationService.selectedInstallation$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((installation) => (this._installation = installation));
  }

  private _completeNumber(value: string, length: number = 16) {
    while (value.length < length) {
      value += '*';
    }

    return this._chunkNumber(value);
  }
}
