import { BaseComponent } from '@acn/angular';
import { Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { InstallmentPartner } from '../../models/InstallmentPartner';
import { MessageService } from '@shared/services/messages/messages.service';
import { ServiceId } from '@shared/enumerators/service-id.enum';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageContract } from '@shared/services/messages/contracts/Message.contract';
import { InstallmentService } from './../../services/installment/installment.service';
import { AttendanceService } from '@features/attendance/services/attendance.service';

const DEFAULT_MESSAGE =
  '<div><p>Nós trabalhamos com parceiros para que você tenha as melhores condições de parcelamento! Logo abaixo, você encontrará um ou mais parceiros e poderá escolher o melhor para você. </p><br/><p>Após parcelar sua conta, a confirmação do pagamento pode levar até 24 horas.</p><br/><p>Importante: Se você tiver cadastro em outro tipo de pagamento, avise ao seu banco com antecedência para evitar duplicidade de pagamento</p></div>';

const PARTNERS = {
  PARCELE_NA_HORA: 1,
  FLEXPAG: 2,
};

@Component({
  selector: 'app-installment-partners',
  templateUrl: './installment-partners.component.html',
  styleUrls: ['./installment-partners.component.scss'],
})
export class InstallmentPartnersComponent extends BaseComponent implements OnInit {
  public message: SafeHtml = '';
  public partners: InstallmentPartner[] = [];

  @HostBinding('class.dashboard') onDashboard = false;
  @Input() selectedCityId: string;
  @Input() selectedUnityId: string;

  constructor (
    private _messageService: MessageService,
    private _domSanitizer: DomSanitizer,
    private _installmentService: InstallmentService,
    private _attendanceService: AttendanceService,
  ) {
    super();
  }

  ngOnInit (): void {
    this.getInicialMessage();
    this.getPartners();
    this.onDashboard = this._attendanceService.onDashboard();
  }

  sanetizeHTML (htmlText: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(htmlText);
  }

  private getPartners () {
    this._installmentService
      .getPartners()
      .pipe(take(1))
      .subscribe((res: InstallmentPartner[]) => {
        this.partners = res;
      });
  }

  public selectPartner (partner: InstallmentPartner) {
    if (partner.id === PARTNERS.PARCELE_NA_HORA) {
      this._installmentService.goToParceleNaHora(this.selectedCityId);
    }
    if (partner.id === PARTNERS.FLEXPAG) {
      this._installmentService.goToFlexpag(this.selectedCityId, this.selectedUnityId);
    }
  }

  private getInicialMessage () {
    this._messageService
      .getMessageByServiceIdAndType(ServiceId.SEGUNDA_VIA_FACIL, 'PARCELAMENTO_TELA_INICIAL')
      .pipe(take(1))
      .subscribe(
        (res: MessageContract) => {
          this.message = this.sanetizeHTML(res.descricao);
        },
        () => {
          this.message = this.sanetizeHTML(DEFAULT_MESSAGE);
        },
      );
  }
}
