import { IInvoiceDetail, IGuideDetail } from '@features/duplicate/services/models';

export class SetCurrentInvoice {
  public static readonly type = '[Duplicate] Set Current invoice';
  constructor(public payload: IInvoiceDetail) {}
}

export class SetCurrentGuide {
  public static readonly type = '[Duplicate] Set Current guide';
  constructor(public payload: IGuideDetail) {}
}

export class ResetDuplicateStore {
  public static readonly type = '[Duplicate] Reset store';
}

export class Reset {
  public static readonly type = '[RESET]';
}

export class SetHeaderBgSmall {
  public static readonly type = '[Duplicate] Set Header BG Small';
  constructor(public payload: boolean) {}
}
