import { IInstallation } from './models/installation.model';

export class AddLinkedInstallationList {
  public static readonly type = '[Cdc] Add list of linked installations';
  constructor(public payload: Array<IInstallation>) {}
}
export class AddInstallation {
  public static readonly type = '[Cdc] Add installations';
  constructor(public payload: { installationId: string; nickname: string }) {}
}

export class EditInstallation {
  public static readonly type = '[Cdc] Edit installations';
  constructor(public payload: { installationId: string; nickname: string }) {}
}


export class RemoveInstallation {
  public static readonly type = '[Cdc] Remove installations';
  constructor(public payload: string) {}
}

export class AddUnlinkedInstallationList {
  public static readonly type = '[Cdc] Add list of unlinked installations';
  constructor(public payload: Array<IInstallation>) {}
}

export class SelectedInstallation {
  public static readonly type = '[Cdc] Select installation';
  constructor(public payload: string) {}
}

export class SetEmailBilling {
  public static readonly type = '[Cdc] Set installation has email billing';
  constructor(public payload: boolean) {}
}

export class RemoveSelectedInstallation {
  public static readonly type = '[Cdc] Remove Selected installation';
}

export class Reset {
  public static readonly type = '[RESET]';
}
