import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AcnSecurityAccessService } from '../acn-security-access.service';
import { AcnConfigurationService } from '../../core/configuration/acn-configuration.service';
import { AcnAuthenticationService } from '../authentication/acn-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AcnAuthenticationGuard implements CanActivate, CanActivateChild {
  private readonly LOGIN_ROUTE = '/login';

  constructor(
    private authenticationService: AcnAuthenticationService,
    private router: Router,
    private configuration: AcnConfigurationService,
    private acnSecurityAccessService: AcnSecurityAccessService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.isLogged(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.isLogged(route, state);
  }

  private isLogged(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.authenticationService.isLoggedIn().pipe(
      switchMap(async (isLogged) => {
        if (!isLogged) {
          return await this.authenticationService.getNewToken().toPromise();
        }

        return isLogged;
      }),
      map((res) => {
        if (!res) {
          this.authenticationService.redirectUrl = state.url;
          this.acnSecurityAccessService.next({
            type: 'invalidKey',
            route: state.url,
          });

          return this.router.createUrlTree(
            [this.configuration?.settings?.routerLogin || this.LOGIN_ROUTE],
            {
              queryParams: { redirect: state.url },
            },
          );
        }

        return res;
      }),
    );
  }
}
