import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ICity } from '@store/dashboard/states/cities/models/city.model';
import { BaseComponent } from '@acn/angular';
import { takeUntil } from 'rxjs/operators';
import { ICreditCard } from '@store/dashboard';
import { AccountService } from '@features/account/services/account.service';
import { ITermAutomaticDebit } from '@store/app';
import { WINDOW } from '@shared/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  @Input() isOnMobile = false;
  @Input() isOnDashboard = false;
  @Input() selectedCity: ICity;
  public baseUrl: string;
  public gtmCategory = 'Botao';
  public gtmAction = 'Menu_Rodape';
  public gtmCategorySocial = 'Link';
  public gtmActionSocial = 'Social_Rodape';
  public hasCreditCard = false;
  public hasAutomaticDebit = false;
  public showSiteTerms = true;
  public showCreditCardTerms = true;
  public showAutomaticDebitTerms = true;
  public lgpdFormLink: string;
  constructor(
    private _accountService: AccountService,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    super();
  }

  @HostListener('window:storage', ['$event'])
  updateStorage(event: StorageEvent): void {
    if (event.key === 'app.useTerms') {
      const stringTerms = JSON.parse(this.window.localStorage.getItem('app.useTerms'));
      this.showSiteTerms = stringTerms.site;
      this.showCreditCardTerms = stringTerms.creditCard;
      this.showAutomaticDebitTerms = stringTerms.automaticDebit?.active || false;
    }
  }

  ngOnInit(): void {
    this.lgpdFormLink = this._accountService.settings.privacyUrl;
    this.baseUrl = this.isOnDashboard ? '/home-logado' : '/home';

    if (this.isOnDashboard) {
      this._getCardInfo();
      this._getAutomaticDebit();
    }
  }

  private _getCardInfo(): void {
    this._accountService.cardInfo$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((card: ICreditCard) => {
        if (card !== null) {
          this.hasCreditCard = true;
        }
      });
  }

  private _getAutomaticDebit(): void {
    this._accountService.automaticDebitTerms$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((term: ITermAutomaticDebit) => {
        this.hasAutomaticDebit = !!term?.active;
      });
  }
}
