import { IMobileLabPhones } from '@features/services/models/mobile-lab-phones.model';
import { ICityServicesList } from './../models/city-services-list.model';
import { ChangeExpiringDateStoreService } from '@store/home';
import { IAllowedDaysChangeExpiring } from '@features/services/models/allowed-days-to-change-expiring.model';
import { AcnConnectorService, IRequest } from '@acn/angular';
import { Injectable, Injector } from '@angular/core';

import { CitiesStoreService, InstallationStoreService } from '@store/dashboard';
import { RedirectionStoreService } from '@store/app';
import { BaseService } from '@shared/services/base/base.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  public onMobile$ = this._settingsStore.onMobile$;
  public selectedCity$ = this._citiesStore.selectedCity$;
  public listCityServices$ = this._citiesStore.selectedCityServices$;
  public selectedInstallation$ = this._installationStore.selectedInstallation$;

  private readonly SERVICES_CITY_URL = 'servicos/servicosCidade';
  private readonly SERVICES_GET_EXPIRING = 'servicos/consultaDiasPermitidos';
  private readonly SERVICES_SET_EXPIRING = 'servicos/alterarVencimentoFatura';
  private readonly SERVICES_MOBILE_LAB_PHONES = 'servicos/labMovel/telefones';

  constructor(
    protected _injector: Injector,
    private _citiesStore: CitiesStoreService,
    private _installationStore: InstallationStoreService,
    private _connectorApi: AcnConnectorService,
    private _changeExpiringDateStore: ChangeExpiringDateStoreService,
    private _redirectionStore: RedirectionStoreService,
    private _route: Router,
  ) {
    super(_injector);
  }

  onDashboard() {
    return this._settingsStore.onDashboard();
  }

  presentModal() {
    this.showModal({
      componentId: 'change.expiring.date.modal',
      canClose: false,
    });
  }

  showErrorModal() {
    this.showModal({
      componentId: 'change.expiring.date.error.modal',
      canClose: true,
    });
  }

  setChangeExpiringDate(value: boolean) {
    this._changeExpiringDateStore.setChangeExpiringDate(value);
  }

  getCityServices(idCity) {
    const req: IRequest = {
      endPoint: this.SERVICES_CITY_URL,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<ICityServicesList>(req).pipe(
      tap((res) => {
        this.changeSelectedCityServices(res);
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  getSelectedCityServices() {
    return this._citiesStore.getSelectedCityServices();
  }

  changeSelectedCityServices(city: ICityServicesList) {
    this._citiesStore.addSelectedCityServices(city);
  }

  // chamada da api de dias permitidos para troca de vencimento
  getExpiringDays(codCdc, idCity) {
    const req: IRequest = {
      endPoint: this.SERVICES_GET_EXPIRING,
      queryString: {
        codCdc: codCdc,
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };

    return this._connectorApi.get<IAllowedDaysChangeExpiring>(req).pipe(
      tap((res) => {
        if (res.permiteTrocarDiaVencimento) {
          return;
        }
        if (!res.excecaoAtiva) {
          this._route.navigate(['home-logado/servicos/alterar-data-vencimento/erro-restrito']);
        } else {
          this._route.navigate(['home-logado/servicos/alterar-data-vencimento/erro-bloqueado']);
        }
      }),
      catchError(() => {
        this._route.navigate(['home-logado/servicos/alterar-data-vencimento/erro']);
        return of(false);
      }),
    );
  }

  // chamada da api de troca de data de vencimento
  setNewExpiringDay(codCdc, idCity, newDay) {
    const req: IRequest = {
      endPoint: this.SERVICES_SET_EXPIRING,
      queryString: {
        codCdc: codCdc,
        idCidade: idCity,
      },
      body: {
        novoDiaVencimento: newDay,
      },
      showLoading: true,
      checkToken: false,
    };
    return this._connectorApi.post(req).pipe(
      catchError(() => {
        return of(false);
      }),
    );
  }

  getMobileLabPhones(idCity) {
    const req: IRequest = {
      endPoint: this.SERVICES_MOBILE_LAB_PHONES,
      queryString: {
        idCidade: idCity,
      },
      showLoading: true,
      checkToken: false,
    };
    return this._connectorApi.get<IMobileLabPhones>(req);
  }

  setCallbackOnChooseCity(url: string) {
    this._redirectionStore.setRedirectedFrom(url);
  }
}
