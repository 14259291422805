import { IAcnDynamicComponentManifest } from '@acn/angular';

export const creditCardDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'credit-card.benefits',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-benefits/automatic-credit-card-modal-benefits.module'
      ).then((m) => m.AutomaticCreditCardModalBenefitsModule),
  },
  {
    path: 'credit-card.success',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-success/automatic-credit-card-modal-success.module'
      ).then((m) => m.AutomaticCreditCardModalSuccessModule),
  },
  {
    path: 'credit-card.expiring',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-expiring/automatic-credit-card-modal-expiring.module'
      ).then((m) => m.AutomaticCreditCardModalExpiringModule),
  },
  {
    path: 'credit-card-profile.success',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-success-profile/automatic-credit-card-modal-success-profile.module'
      ).then((m) => m.AutomaticCreditCardModalSuccessProfileModule),
  },
  {
    path: 'credit-card.error',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-error/automatic-credit-card-modal-error.module'
      ).then((m) => m.AutomaticCreditCardModalErrorModule),
  },
  {
    path: 'credit-card.edit',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-edit/automatic-credit-card-modal-edit.module'
      ).then((m) => m.AutomaticCreditCardModalEditModule),
  },
  {
    path: 'credit-card.delete',
    loadChildren: () =>
      import(
        './payment/automatic-credit-card/components/automatic-credit-card-modal-delete/automatic-credit-card-modal-delete.module'
      ).then((m) => m.AutomaticCreditCardModalDeleteModule),
  },
  {
    path: 'credit-card.automatic',
    loadChildren: () =>
      import(
        './payment/credit-card/components/credit-card-automatic-payment-modal/credit-card-automatic-payment-modal.module'
      ).then((m) => m.CreditCardAutomaticPaymentModalModule),
  },
  {
    path: 'credit-card.payment.success',
    loadChildren: () =>
      import(
        './payment/credit-card/components/credit-card-payment-success-modal/credit-card-payment-success-modal.module'
      ).then((m) => m.CreditCardPaymentSuccessModalModule),
  },
  {
    path: 'credit-card.payment.error',
    loadChildren: () =>
      import(
        './payment/credit-card/components/credit-card-payment-error-modal/credit-card-payment-error-modal.module'
      ).then((m) => m.CreditCardPaymentErrorModalModule),
  },
  {
    path: 'credit-card.payment.processing',
    loadChildren: () =>
      import(
        './payment/credit-card/components/credit-card-payment-processing-modal/credit-card-payment-processing-modal.module'
      ).then((m) => m.CreditCardPaymentProcessingModalModule),
  },
  {
    path: 'credit-card.automatic.failed',
    loadChildren: () =>
      import(
        './payment/credit-card/components/credit-card-automatic-payment-failed-modal/credit-card-automatic-payment-failed-modal.module'
      ).then((m) => m.CreditCardAutomaticPaymentFailedModalModule),
  },
];
