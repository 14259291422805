import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AcnAuthenticationService } from '@acn/angular';

@Injectable({
  providedIn: 'root',
})
export class HomeUnloggedGuard implements CanActivate {
  constructor(private authenticationService: AcnAuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, stateRoute: RouterStateSnapshot) {
    return this.isNotLogged(stateRoute);
  }

  private isNotLogged(stateRoute: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.isLoggedIn().pipe(
      map((isLogged) => {
        return !isLogged;
      }),
      tap((value) => {
        if (!value) {
          this.router.navigate(['/home-logado']);
        } else {
          this.authenticationService.logout();
        }
      }),
    );
  }
}
