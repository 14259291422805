import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { CreditCardState } from './states/credit-card/credit-card.state';
import { LoginState } from './states/login/login.state';

import {
  CitiesState,
  ContactState,
  StepsState,
  InstallationState,
  BillingState,
  DuplicateState,
  ConsumptionState,
  ReportState,
  ChangeHolderState,
  AutoReconnectionState,
  RequestCutState,
  NewInstallationState,
  InvoiceSimulatorState,
  CalendarMeteringState,
} from './states';

export * from './states';

export const DashboardStates = [
  CitiesState,
  ContactState,
  StepsState,
  InstallationState,
  BillingState,
  DuplicateState,
  ConsumptionState,
  CreditCardState,
  LoginState,
  ReportState,
  ChangeHolderState,
  AutoReconnectionState,
  RequestCutState,
  NewInstallationState,
  InvoiceSimulatorState,
  CalendarMeteringState
];

@State({
  name: 'dashboard',
  children: DashboardStates,
})
@Injectable()
export class DashboardStateModule {}
