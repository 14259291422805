import { ICity, INewCity } from './models/city.model';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { IBreakingNews } from '@features/attendance/models/breaking-news.model';

export class AddCitiesList {
  public static readonly type = '[Cities] Add cities list';
  constructor(public payload: ICity[]) {}
}
export class AddSelectedCity {
  public static readonly type = '[Cities] Add selected city';
  constructor(public payload: INewCity) {}
}

export class AddSelectedCityServices {
  public static readonly type = '[Cities] Add selected city services';
  constructor(public payload: ICityServicesList) {}
}

export class AddNotSelectedCity {
  public static readonly type = '[Cities] Add not selected city';
  constructor(public payload: INewCity) {}
}
export class ResetSelectedCity {
  public static readonly type = '[Cities] Remove selected city';
}

export class SetRedirecTo {
  public static readonly type = '[Cities] Set redirection';
  constructor(public payload: string) {}
}

export class ResetCities {
  public static readonly type = '[Cities] Reset cities array';
}

export class ResetServices {
  public static readonly type = '[Cities] Reset cities services';
}

export class AddBreakingNewsisClosed {
  public static readonly type = '[Cities] Add Breaking News is Closed';
  constructor(public payload: boolean) {}
}
export class BreakingNewsNotice {
  public static readonly type = '[Cities] Add Breaking News Notice';
  constructor(public payload: IBreakingNews) {}
}

export class AddWaterShortageAlert {
  public static readonly type = '[Cities] Add water shortage alert';
  constructor(public payload: string) {}
}

export class ResetWaterShortageAlert {
  public static readonly type = '[Cities] Reset water shortage alert';
}

export class ResetCityAndServices {
  public static readonly type = '[Cities] Reset City and Services';
}
