import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { CitiesState, ICitiesStateModel } from './cities.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {
  AddBreakingNewsisClosed,
  AddCitiesList,
  AddNotSelectedCity,
  AddSelectedCity,
  AddSelectedCityServices,
  AddWaterShortageAlert,
  BreakingNewsNotice,
  ResetCities,
  ResetCityAndServices,
  ResetSelectedCity,
  ResetServices,
  ResetWaterShortageAlert,
  SetRedirecTo,
} from './cities.actions';
import { ICity, INewCity } from './models/city.model';
import { normalize } from '@acn/angular';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { IBreakingNews } from '@features/attendance/models/breaking-news.model';
import { ActiveService } from '@shared/services/active-service/active-service';

@Injectable({
  providedIn: 'root',
})
export class CitiesStoreService {
  @Select(CitiesState)
  public citiesState$: Observable<ICitiesStateModel>;

  @Select(CitiesState.getSelectedCity)
  public selectedCity$: Observable<INewCity>;

  @Select(CitiesState.getSelectedCityServices)
  public selectedCityServices$: Observable<ICityServicesList>;

  @Select(CitiesState.getCitiesList)
  public citiesList$: Observable<ICity[]>;

  @Select(CitiesState.getNotSelectedCity)
  public notSelectedCity$: Observable<INewCity>;

  @Select(CitiesState.getBreakingNewsIsClosed)
  public breakingNewsIsClosed$: Observable<boolean>;

  @Select(CitiesState.breakingNewsNotice)
  public breakingNewsNotice$: Observable<IBreakingNews>;

  @Select(CitiesState.waterShortageAlert)
  public waterShortageAlert$: Observable<string>;

  constructor(private _store: Store) {}

  @Dispatch()
  public addCitiesList(citiesList: ICity[]) {
    return new AddCitiesList(citiesList);
  }

  @Dispatch()
  public addBreakingNewsIsClosed(hasBreakingNews: boolean) {
    return new AddBreakingNewsisClosed(hasBreakingNews);
  }

  @Dispatch()
  public breakingNewsNotice(breakingNewsNotice: IBreakingNews) {
    return new BreakingNewsNotice(breakingNewsNotice);
  }

  @Dispatch()
  public addSelectedCity(city: INewCity) {
    return new AddSelectedCity(city);
  }

  @Dispatch()
  public addSelectedCityServices(city: ICityServicesList) {
    return new AddSelectedCityServices(city);
  }

  @Dispatch()
  public addNotSelectedCity(city: INewCity) {
    return new AddNotSelectedCity(city);
  }

  @Dispatch()
  public addWaterShortageAlert(waterShortageAlert: string) {
    return new AddWaterShortageAlert(waterShortageAlert);
  }

  @Dispatch()
  public setRedirectTo(url: string) {
    return new SetRedirecTo(url);
  }

  @Dispatch()
  public resetSelectedCity() {
    return new ResetSelectedCity();
  }

  @Dispatch()
  public resetCities() {
    return new ResetCities();
  }

  @Dispatch()
  public resetServices() {
    return new ResetServices();
  }

  @Dispatch()
  public resetWaterShortageAlert() {
    return new ResetWaterShortageAlert();
  }

  @Dispatch()
  public resetCityAndServices() {
    return new ResetCityAndServices();
  }

  public findCity(city: string) {
    return this._store
      .selectSnapshot(CitiesState.getCitiesList)
      .filter((_city) => normalize(_city.nome).toLowerCase() === city);
  }

  public findCityById(cityId: number) {
    return this._store
      .selectSnapshot(CitiesState.getCitiesList)
      .filter((_city) => _city.id === cityId);
  }

  getSelectedCity() {
    return this._store.selectSnapshot(CitiesState.getSelectedCity);
  }

  getSelectedCityServices() {
    return this._store.selectSnapshot(CitiesState.getSelectedCityServices);
  }

  getSelectedCityServicesAsActiveService(): ActiveService {
    return ActiveService.create(this.getSelectedCityServices());
  }

  getRedirectTo() {
    return this._store.selectSnapshot(CitiesState.getRedirectTo);
  }

  getHasCitiesList() {
    return this._store.selectSnapshot(CitiesState.getHasCitiesList);
  }

  getWaterShortageAlert() {
    return this._store.selectSnapshot(CitiesState.waterShortageAlert);
  }
}
