import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UseTermsState, UseTermsStateModel } from './use-terms.state';
import { SetSiteTerms, SetCreditCardTerms, SetAutomaticDebitTerms } from './use-terms.actions';
import { ITermAutomaticDebit } from '.';

@Injectable({
  providedIn: 'root',
})
export class UseTermsStoreService {

  @Select(UseTermsState.getState)
  public useTerms$: Observable<UseTermsStateModel>;

  @Select(UseTermsState.getSiteTerms)
  public siteTerms$: Observable<boolean>;

  @Select(UseTermsState.getCreditCardTerms)
  public cardTerms$: Observable<boolean>;

  @Select(UseTermsState.getAutomaticDebitTerms)
  public automaticDebitTerms$: Observable<ITermAutomaticDebit>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setSiteTerms(status: boolean) {
    return new SetSiteTerms(status);
  }

  @Dispatch()
  public setCreditCardTerms(status: boolean) {
    return new SetCreditCardTerms(status);
  }

  @Dispatch()
  public setAutomaticDebitTerms(status: ITermAutomaticDebit) {
    return new SetAutomaticDebitTerms(status);
  }

  public getSiteTerms() {
    return this._store.selectSnapshot(UseTermsState.getSiteTerms);
  }

  public getCreditCardTerms() {
    return this._store.selectSnapshot(UseTermsState.getCreditCardTerms);
  }

  public getAutomaticDebitTerms() {
    return this._store.selectSnapshot(UseTermsState.getAutomaticDebitTerms);
  }
}
