import { ModuleWithProviders, NgModule } from '@angular/core';

import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_SCOPE,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { AcnConfigurationService } from '@acn/angular/core/configuration';

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule]
})
export class I18nModule {
  static forRoot(): ModuleWithProviders<TranslocoModule> {
    return {
      ngModule: TranslocoModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useFactory: (configuration: AcnConfigurationService) =>
            translocoConfig({
              availableLangs: ['pt-br'],
              defaultLang: 'pt-br',
              fallbackLang: 'pt-br',
              prodMode: configuration.settings.production,
            }),
          deps: [AcnConfigurationService],
        },
        { provide: TRANSLOCO_LOADER, useExisting: TranslocoHttpLoader },
      ],
    };
  }

  static forChild(id: string): ModuleWithProviders<TranslocoModule> {
    return {
      ngModule: TranslocoModule,
      providers: [{ provide: TRANSLOCO_SCOPE, useValue: id }],
    };
  }
}
