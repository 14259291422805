<ng-container *transloco="let text">
  <div class="contentNotAvailable">
    <section class="content">
      <div class="image">
        <img
          class="picture"
          [src]="baseImageUrl + '/img/automatic-debt/warning.png'"
          alt="Alerta"
        />
      </div>
      <div class="info__details">
        <p [ngClass]="onMobile ? 'is-size-14' : 'is-size-16'" [innerHTML]="text(content)"></p>
      </div>
    </section>
  </div>
</ng-container>
