import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { SetChangeExpiringDate } from './change-expiring-date.actions';

export interface ChangeExpiringDateStateModel {
  changeExpiringDate: boolean;
}

const INITIAL_STATE = {
  changeExpiringDate: false,
};

@State<ChangeExpiringDateStateModel>({
  name: 'changeExpiringDate',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ChangeExpiringDateState {
  @Selector()
  public static getState(state: ChangeExpiringDateStateModel) {
    return state;
  }

  @Selector()
  public static getChangeExpiringDate(state: ChangeExpiringDateStateModel) {
    return state.changeExpiringDate;
  }

  @Action(SetChangeExpiringDate)
  public setChangeExpiringDate(
    ctx: StateContext<ChangeExpiringDateStateModel>,
    { payload }: SetChangeExpiringDate,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, changeExpiringDate: payload });
  }
}
