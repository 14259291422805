import { IReconnectionSchedule, IReconnectionTypes } from './models/auto-reconnection-state.model';
import { easelLocationOptions } from '@features/auto-reconnection/enumerators/easel-location-options.enum';
import { IAutoReconnection, IAutoReconnectionStateModel } from './models';

export class SetAutoReconnection {
  public static readonly type = '[AutoReconnection] Set auto reconnection';
  constructor(public payload: IAutoReconnectionStateModel) {}
}

export class SetEaselLocation {
  public static readonly type = '[AutoReconnection] Set easel location';
  constructor(public payload: easelLocationOptions) {}
}

export class SetName {
  public static readonly type = '[AutoReconnection] Set name';
  constructor(public payload: string) {}
}

export class SetEmail {
  public static readonly type = '[AutoReconnection] Set email';
  constructor(public payload: string) {}
}

export class SetDocument {
  public static readonly type = '[AutoReconnection] Set document';
  constructor(public payload: string) {}
}

export class SetPhone {
  public static readonly type = '[AutoReconnection] Set phone';
  constructor(public payload: string) {}
}

export class SetUrlBack {
  public static readonly type = '[AutoReconnection] Set url back';
  constructor(public payload: string) {}
}

export class SetCutRisk {
  public static readonly type = '[AutoReconnection] Set cut risk';
  constructor(public payload: boolean) {}
}

export class SetProtocol {
  public static readonly type = '[AutoReconnection] Set protocol';
  constructor(public payload: string) {}
}

export class SetBody {
  public static readonly type = '[AutoReconnection] Set body';
  constructor(public payload: IAutoReconnection) {}
}

export class SetReconnectionTypes {
  public static readonly type = '[AutoReconnection] Set ReconnectionTypes';
  constructor(public payload: IReconnectionSchedule) {}
}

export class SetSelectedReconnection {
  public static readonly type = '[AutoReconnection] Set selected reconnection type';
  constructor(public payload: IReconnectionTypes) {}
}

export class SetIdServicesSanLinkAV {
  public static readonly type = '[AutoReconnection] Set IdServicesSanLinkAV';
  constructor(public payload: number) {}
}

export class ResetAutoReconnection {
  public static readonly type = '[AutoReconnection] reset auto reconnection';
}
