import { BaseComponent } from '@acn/angular';
import { Component, Input, OnInit } from '@angular/core';
import { cityUnitRegion } from '@shared/enumerators/city-unit-region.enum';

@Component({
  selector: 'app-main-payment-method',
  templateUrl: './main-payment-method.component.html',
  styleUrls: ['./main-payment-method.component.scss'],
})
export class MainPaymentMethod extends BaseComponent implements OnInit{
  @Input() barcode?: string;
  @Input() idBilling: number;
  @Input() billingType: string;
  @Input() idCity: number;
  @Input() usePix: boolean;

  public copied = false;
  public useBarcode = null;

  ngOnInit(): void {
    this.useBarcode = !this.usePix;
  }

  toggleText () {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 5000);
  }
}
