import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { INewCity } from '@store/dashboard';

import { ISanitationCard } from './models/sanitation-card.model';

@Component({
  selector: 'app-landing-sanitation',
  templateUrl: './landing-sanitation.component.html',
  styleUrls: ['./landing-sanitation.component.scss'],
})
export class LandingSanitationComponent implements OnInit {
  public cardsList: Array<ISanitationCard>;
  @ViewChild('carousel') carousel: ElementRef<HTMLAcnCarouselElement>;
  @Input() isOnMobile = false;
  @Input() selectedCityInformation: INewCity;

  ngOnInit(): void {
    this._setCardsValues();
  }

  onSwipeLeft() {
    if (this.carousel) {
      this.carousel.nativeElement.next();
    }
  }

  onSwipeRight() {
    if (this.carousel) {
      this.carousel.nativeElement.prev();
    }
  }

  private _setCardsValues(): void {
    this.cardsList = [
      {
        icon: 'landing.home.sanitation.benefits.item1.icon',
        title: 'landing.home.sanitation.benefits.item1.title',
        description: 'landing.home.sanitation.benefits.item1.description',
      },
      {
        icon: 'landing.home.sanitation.benefits.item2.icon',
        title: 'landing.home.sanitation.benefits.item2.title',
        description: 'landing.home.sanitation.benefits.item2.description',
      },
      {
        icon: 'landing.home.sanitation.benefits.item3.icon',
        title: 'landing.home.sanitation.benefits.item3.title',
        description: 'landing.home.sanitation.benefits.item3.description',
      },
    ];
  }
}
