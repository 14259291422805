import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-not-holder-warning',
  templateUrl: './not-holder-warning.component.html',
  styleUrls: ['./not-holder-warning.component.scss'],
})
export class NotHolderWarningComponent {
  @Input() content: string;
  @Input() baseImageUrl: string;
  @Input() onMobile: boolean;
}
