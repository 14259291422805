import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { IInstallation } from './models/installation.model';
import { InstallationState, InstallationStateModel } from './installation.state';
import {
  AddLinkedInstallationList,
  SelectedInstallation,
  AddUnlinkedInstallationList,
  RemoveInstallation,
  SetEmailBilling,
  AddInstallation,
  RemoveSelectedInstallation,
  EditInstallation,
} from './installation.actions';
import { Reset } from '../steps/steps.actions';

@Injectable({
  providedIn: 'root',
})
export class InstallationStoreService {
  @Select(InstallationState)
  public installationState$: Observable<InstallationStateModel>;

  @Select(InstallationState.installationList)
  public installationList$: Observable<Array<IInstallation>>;

  @Select(InstallationState.installationUnlinkedList)
  public installationUnlinkedList$: Observable<Array<IInstallation>>;

  @Select(InstallationState.selectedInstallation)
  public selectedInstallation$: Observable<IInstallation>;

  @Select(InstallationState.selectedInstallationId)
  public selectedInstallationId$: Observable<string>;

  constructor(private _store: Store) {}

  @Dispatch()
  public addLinkedInstallationList(installationList: IInstallation[]) {
    return new AddLinkedInstallationList(installationList);
  }

  @Dispatch()
  public addUnlinkedInstallationList(installationList: IInstallation[]) {
    return new AddUnlinkedInstallationList(installationList);
  }

  @Dispatch()
  public selectedInstallation(installationId: string) {
    return new SelectedInstallation(installationId);
  }

  @Dispatch()
  public addInstallation(installation: { installationId: string; nickname: string }) {
    return new AddInstallation(installation);
  }

  @Dispatch()
  public editInstallation(installation: { installationId: string; nickname: string }) {
    return new EditInstallation(installation);
  }


  @Dispatch()
  public removeInstallation(installationId: string) {
    return new RemoveInstallation(installationId);
  }

  @Dispatch()
  public setEmailBilling(status: boolean) {
    return new SetEmailBilling(status);
  }

  @Dispatch()
  public removeSelectInstallation() {
    return new RemoveSelectedInstallation();
  }

  @Dispatch()
  public reset() {
    return new Reset();
  }

  getSelectedInstallation() {
    return this._store.selectSnapshot(InstallationState.selectedInstallation);
  }

  getInstallationById(installationId: string) {
    return this._store
      .selectSnapshot(InstallationState.installationList)
      .find((item) => item.cdc === installationId);
  }

  getInstallationUnlinkedList(): IInstallation[] {
    return this._store.selectSnapshot(InstallationState.installationUnlinkedList);
  }
}
