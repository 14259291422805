import { Component, OnInit, Input } from '@angular/core';

import { INewCity } from '@store/dashboard';
import { LandingService } from '../../services/landing.service';

@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.scss'],
})
export class LandingBannerComponent implements OnInit {
  @Input() selectedCity: INewCity;
  @Input()
  get isOnMobile(): boolean {
    return this._isOnMobile;
  }
  @Input() urlBannerMobile: string;
  @Input() urlBannerLink?: string;

  set isOnMobile(isMobile: boolean) {
    this._isOnMobile = isMobile;
    this.break = !this._isOnMobile ? '<br>' : '';
  }

  private _isOnMobile = false;

  public baseImgUrl: string;
  public break: string;
  public gtmCategory = 'Botao';

  constructor(private _landingService: LandingService) {}

  ngOnInit(): void {
    this.baseImgUrl = this._landingService.baseImgUrl;
  }

  goToLink() {
    if (!this.urlBannerLink) return;
    window.open(this.urlBannerLink, '_blank');
  }
}
