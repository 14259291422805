import { State, Action, StateContext } from '@ngxs/store';

import { AddNotification, UpdateNotification, RemoveNotification } from './notification.actions';
import { IAcnNotification } from '@acn/ui';
import { Injectable } from '@angular/core';

@State<IAcnNotification[]>({
  name: 'notifications',
  defaults: [],
})
@Injectable()
export class NotificationState {
  @Action(AddNotification)
  public add(ctx: StateContext<IAcnNotification[]>, { payload }: AddNotification) {
    ctx.setState([...ctx.getState(), payload]);

    if (payload.sticky) {
      return;
    }

    setTimeout(() => {
      ctx.dispatch(new UpdateNotification({ ...payload, show: false }));
    }, payload.timeOut);

    setTimeout(() => {
      ctx.dispatch(new RemoveNotification(payload.id));
    }, payload.timeOut + 1100);
  }

  @Action(UpdateNotification)
  public update(ctx: StateContext<IAcnNotification[]>, { payload }: UpdateNotification) {
    ctx.setState(
      ctx.getState().map((notification) => {
        if (notification.id === payload.id) {
          notification = { ...notification, ...payload };
        }

        return notification;
      }),
    );
  }

  @Action(RemoveNotification)
  public remove(ctx: StateContext<IAcnNotification[]>, { id }: RemoveNotification) {
    ctx.setState(ctx.getState().filter((notification) => notification.id !== id));
  }
}
