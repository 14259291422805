import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICity } from '@store/dashboard/states/cities/models/city.model';
import { AcnAuthenticationService, BaseComponent } from '@acn/angular';
import { Router } from '@angular/router';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { servicesList } from '@shared/enumerators/servicesList.enum';
import { Subscription } from 'rxjs';
import { auditTime, take, takeUntil } from 'rxjs/operators';
import { IBreakingNews } from '@features/attendance/models/breaking-news.model';
import { servicesCategory } from '@shared/enumerators/servicesCategory.enum';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { ActiveService } from '@shared/services/active-service/active-service';

@Component({
  selector: 'app-landing-menu',
  templateUrl: './landing-menu.component.html',
  styleUrls: ['./landing-menu.component.scss'],
})
export class LandingMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  public showMenu = false;
  public showSubMenu = false;
  public gtmCategory = 'Botao';
  public gtmAction = 'Menu_Topo';
  public displayLogin: boolean;
  public showReconnect = false;
  public showReconnectAuto = false;
  public showMyRequests = false;
  private _listService$: Subscription;
  public breakingNews: boolean;
  public attendanceLink: string;
  public unselectedCityBreakingNews = true;
  public reconnectUrl = '/home/servicos/religa';
  public reconnectAutoUrl = '/home/servicos/religa-auto';

  @Input() selectedCity: ICity;
  @Input() isOnMobile: boolean;
  @Input() isBack = false;
  @Input() parentRoute: string;

  constructor(
    private _authService: AcnAuthenticationService,
    private _router: Router,
    private _selectCity: SelectCityService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.displayLogin = this.selectedCity ? this.selectedCity.isAttended : true;
    this.unselectedCityBreakingNews = this.selectedCity?.isAttended;
    this._getServiceList();
    this.getBreakingNews();
    this.setAttendance();
  }

  ngOnDestroy(): void {
    if (this._listService$) {
      this._listService$.unsubscribe();
    }
  }

  public setAttendance() {
    this.attendanceLink = this.selectedCity ? '/home/atendimento' : '/home/info/canais/atendimento';
  }

  public showServices(): boolean {
    if (this.selectedCity == null) {
      return true;
    }
    this.unselectedCityBreakingNews = false;
    return this.selectedCity.isAttended;
  }

  public toggleMenu(): void {
    this.showMenu = !this.showMenu;

    if (this.showSubMenu) {
      this.showSubMenu = false;
    }
  }

  public toggleSubMenu(): void {
    this.showSubMenu = !this.showSubMenu;
  }

  back() {
    this._resetRedirectUrl();
    window.history.back();
  }

  navigateHome() {
    this._router.navigate(['/home']);
  }

  _resetRedirectUrl() {
    this._authService.redirectUrl = undefined;
  }

  private _getServiceList(): void {
    if (this.selectedCity && this.selectedCity.isAttended) {
      this._listService$ = this._selectCity.selectedCityServices$
        .pipe(takeUntil(this.onDestroy), auditTime(0))
        .subscribe((data) => {
          if (!data) {
            this._getServicesFromApi();
          } else {
            this._setServices(data);
          }
        });
    }
  }

  private _getServicesFromApi() {
    if (!this.selectedCity) {
      return;
    }
    this._selectCity
      .getCityServices(this.selectedCity.id)
      .pipe(take(1))
      .subscribe((data: ICityServicesList) => {
        this._setServices(data);
      });
  }

  private _setServices(serviceList: ICityServicesList) {
    const activeService = ActiveService.create(serviceList);
    this.showReconnect = activeService.isServiceActive(
      servicesCategory.information,
      servicesList.religa,
    );
    this.showMyRequests = activeService.isServiceActive(
      servicesCategory.information,
      servicesList.acompanhamentoDeSolicitacoes,
    );
    this.showReconnectAuto = activeService.isServiceActive(
      servicesCategory.attendance,
      servicesList.religaAuto,
    );
    this.changeDetectorRef.markForCheck();
  }

  getBreakingNews() {
    this._selectCity.breakingNewsNotice$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: IBreakingNews) => (this.breakingNews = !!res));
  }
}
