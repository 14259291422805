import { ValidationErrors } from '@angular/forms';

export function bankSharedValidation(value: string): ValidationErrors {
  if (value.match(/-/gi)?.length > 1) {
    return { code: 1, message: 'format invalid' };
  } else if (value.match(/0/gi)?.length === value.length) {
    return { code: 1, message: 'format invalid' };
  } else if (value.split('')[0] === '-' || value.split('')[value.length - 1] === '-') {
    return { code: 1, message: 'format invalid' };
  } else {
    return { code: 0, message: 'success' };
  }
}
