<nav class="menu">
  <div class="menu__content container">
    <div class="info-grouper">
      <div class="menu__mobile" *ngIf="isOnMobile && breakingNews"></div>
      <acn-icon
        icon="fa-bars"
        class="bars"
        *ngIf="isOnMobile"
        (click)="toggleMenu()"
        ht-clickable
      ></acn-icon>
      <a
        class="logo"
        routerLink="/home"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'logo' | transloco"
      >
        <acn-icon icon="logo" [alt]="'logo' | transloco"></acn-icon>
      </a>
      <a
        *ngIf="isOnMobile && displayLogin"
        class="link is-size-14 is-bold"
        routerLink="/login"
        data-cy="landing-menu-login"
        >{{ 'login' | transloco }}</a
      >
      <div *ngIf="isOnMobile && !displayLogin"></div>
      <div *ngIf="!isOnMobile">
        <ng-container *ngTemplateOutlet="city"></ng-container>
      </div>
    </div>
    <div class="actions-wrapper" *ngIf="!isOnMobile">
      <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
    </div>
  </div>
</nav>

<div class="menu__panel animated fadeIn" [class.active]="showMenu" *ngIf="isOnMobile">
  <div class="menu__grid">
    <div class="panel animated slideInLeft">
      <div class="close" (click)="toggleMenu()" ht-clickable>
        <acn-icon class="icon" icon="fa-close" width="9px" height="9px"></acn-icon>
        {{ 'close' | transloco }}
      </div>
      <ng-container *ngTemplateOutlet="city"></ng-container>

      <div class="options">
        <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
      </div>
    </div>
    <div class="modal" (click)="toggleMenu()"></div>
  </div>
</div>

<ng-template #city>
  <div class="city">
    <acn-icon
      class="icon"
      icon="fa-map-marker-alt"
      [width]="!isOnMobile ? '10px' : '16px'"
      [height]="!isOnMobile ? '12px' : '16px'"
    ></acn-icon>

    <a
      class="link"
      routerLink="/selecao-de-cidade"
      acnGtm
      [gtmCategory]="gtmCategory"
      [gtmAction]="gtmAction"
      [gtmLabel]="'menu.chooseCity' | transloco"
    >
      <ng-container *ngIf="!selectedCity">
        {{ 'menu.chooseCity' | transloco }}
      </ng-container>

      <ng-container *ngIf="selectedCity">
        <span [innerHTML]="'menu.youAreIn' | transloco"> </span>
        <span
          class="is-bold location-area"
          [innerHTML]="selectedCity.nome + ' - ' + selectedCity.uf"
        ></span>
      </ng-container>
    </a>
  </div>
</ng-template>

<ng-template #mainMenu>
  <ul class="main-menu">
    <li class="menu__item" *ngIf="!isOnMobile && displayLogin">
      <a
        routerLink="/login"
        class="link"
        data-cy="landing-menu-login"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'login' | transloco"
        >{{ 'login' | transloco }}</a
      >
    </li>
    <li class="menu__item">
      <a
        href="https://www.brkambiental.com.br/quem-somos"
        target="_blank"
        rel="noopener"
        class="link"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'menu.institutional' | transloco"
      >
        {{ 'menu.institutional' | transloco }}
        <acn-icon icon="fa-book" class="icon" *ngIf="isOnMobile"></acn-icon>
      </a>
    </li>
    <li class="menu__item" *ngIf="showServices()">
      <a
        class="link"
        [class.active]="showSubMenu"
        (click)="isOnMobile ? toggleSubMenu() : null"
        [routerLink]="!isOnMobile ? '/home/servicos' : null"
        routerLinkActive="active"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'menu.services.title' | transloco"
      >
        {{ 'menu.services.title' | transloco }}
        <acn-icon
          *ngIf="isOnMobile"
          [icon]="showSubMenu ? 'fa-close' : 'fa-th-large'"
          class="icon"
          [class.active]="showSubMenu"
        ></acn-icon>
      </a>

      <ul
        class="submenu"
        [ngClass]="showSubMenu ? 'animated fadeIn delay-05 active' : null"
        *ngIf="isOnMobile"
      >
        <li class="submenu__item">
          <a
            routerLink="/home/servicos/segunda-via-facil"
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.easyDuplicate' | transloco"
            >{{ 'menu.services.submenu.easyDuplicate' | transloco }}</a
          >
        </li>
        <li class="submenu__item">
          <a
            routerLink="/home-logado/historico-de-contas"
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.bills' | transloco"
            >{{ 'menu.services.submenu.bills' | transloco }}</a
          >
        </li>
        <li class="submenu__item">
          <a
            routerLink="/home-logado/historico-de-consumo"
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.consumption' | transloco"
            >{{ 'menu.services.submenu.consumption' | transloco }}</a
          >
        </li>
        <li class="submenu__item" *ngIf="!selectedCity || showMyRequests">
          <a
            routerLink="/home-logado/servicos/minhas-solicitacoes"
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.myRequests' | transloco"
            >{{ 'menu.services.submenu.myRequests' | transloco }}</a
          >
        </li>
        <li class="submenu__item" *ngIf="!selectedCity || showReconnectAuto || showReconnect">
          <a
            [routerLink]="showReconnectAuto ? reconnectAutoUrl : reconnectUrl"
            class="link"
            data-cy="landing-menu-reconnect"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.reconnect' | transloco"
            >{{ 'menu.services.submenu.reconnect' | transloco }}</a
          >
        </li>
        <li class="submenu__item">
          <a
            routerLink="/home/servicos"
            (click)="toggleMenu()"
            class="link"
            acnGtm
            [gtmCategory]="gtmCategory"
            [gtmAction]="gtmAction"
            [gtmLabel]="'menu.services.submenu.all' | transloco"
            >{{ 'menu.services.submenu.all' | transloco }}</a
          >
        </li>
      </ul>
    </li>
    <li class="menu__item">
      <a
        [routerLink]="attendanceLink"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="toggleMenu()"
        class="link"
        acnGtm
        [gtmCategory]="gtmCategory"
        [gtmAction]="gtmAction"
        [gtmLabel]="'menu.sac' | transloco"
      >
        {{ 'menu.sac' | transloco }}

        <div class="alert" *ngIf="breakingNews && !unselectedCityBreakingNews"></div>

        <acn-icon icon="fa-phone-alt" class="icon" *ngIf="isOnMobile"></acn-icon>
      </a>
    </li>
  </ul>
</ng-template>
