import { Router } from '@angular/router';
import { ICity } from '@store/dashboard/states/cities/models/city.model';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { LandingService } from '@features/landing/services/landing.service';

import { IServiceCard } from './models/service-card.model';

import { auditTime, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@acn/angular';

import { servicesList } from '@shared/enumerators/servicesList.enum';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { servicesCategory } from '@shared/enumerators/servicesCategory.enum';
import { ActiveService } from '@shared/services/active-service/active-service';
import { ActiveServiceCategory } from '@shared/services/active-service/active-service-category';

@Component({
  selector: 'app-landing-services',
  templateUrl: './landing-services.component.html',
  styleUrls: ['./landing-services.component.scss'],
})
export class LandingServicesComponent extends BaseComponent implements OnInit {
  @ViewChild('carousel') carousel: ElementRef<HTMLAcnCarouselElement>;

  @Input() isOnMobile = false;
  @Input() selectedCity: ICity;

  public showReconnect = false;
  public showChangeExpiring = false;
  public showReconnectAuto = false;
  public showRequestFollowUp = false;
  public showChangeHolder = false;
  public showPix = false;
  public showSimuladorTarifa = false;
  public showReportLeak = false;
  public cardsList: Array<IServiceCard>;
  public baseImgUrl: string;
  private _showAutomaticDebit = false;
  private _showEasyDuplicate = false;

  constructor(
    private _landingService: LandingService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.baseImgUrl = this._landingService.baseImgUrl;
    this._setDefaultCards();
    this._getServiceList();
  }

  onSwipeLeft() {
    this.carousel.nativeElement.next();
  }

  onSwipeRight() {
    this.carousel.nativeElement.prev();
  }

  setRouting(item: IServiceCard) {
    if (item.showModal) {
      this._landingService.setChangeExpiringDate();
    }
    this.router.navigate([item.link]);
  }

  private _setDefaultCards(): void {
    this.cardsList = [
      {
        image: `${this.baseImgUrl}/img/home/card-minha-conta.png`,
        title: 'landing.home.carousel.myAccount.title',
        description: 'landing.home.carousel.myAccount.description',
        link: '/home-logado/historico-de-contas',
        gtmAction: 'Minhas_contas',
        callToAction: 'landing.home.carousel.myAccount.callToAction',
      },
      {
        image: `${this.baseImgUrl}/img/home/card-historico-consumo.png`,
        title: 'landing.home.carousel.history.title',
        description: 'landing.home.carousel.history.description',
        link: '/home-logado/historico-de-consumo',
        gtmAction: 'Historico_De_Consumo',
        callToAction: 'landing.home.carousel.history.callToAction',
      },
    ];
  }

  private _setDynamicCards(): void {
    if (!this.selectedCity?.isAttended) {
      return;
    }

    if (this.showPix) {
      this.cardsList.splice(0, 0, {
        image: `${this.baseImgUrl}/img/home/card-pix.png`,
        title: 'landing.home.carousel.pix.title',
        description: 'landing.home.carousel.pix.description',
        link: '/home/servicos/segunda-via-facil',
        gtmAction: 'Pix_Ou_Cartao_de_Credito',
        callToAction: 'landing.home.carousel.pix.callToAction',
      });
    }

    if (this.showChangeHolder) {
      this.cardsList.splice(1, 0, {
        image: `${this.baseImgUrl}/img/home/card-troca-de-titularidade.png`,
        title: 'landing.home.carousel.changeHolder.title',
        description: 'landing.home.carousel.changeHolder.description',
        link: '/home/servicos/troca-de-titularidade',
        gtmAction: 'Troca_de_Titularidade',
        callToAction: 'landing.home.carousel.changeHolder.callToAction',
      });
    }

    if (this.showRequestFollowUp) {
      this.cardsList.splice(2, 0, {
        image: `${this.baseImgUrl}/img/home/card-minhas-solicitacoes.png`,
        title: 'landing.home.carousel.myRequests.title',
        description: 'landing.home.carousel.myRequests.description',
        link: '/home-logado/servicos/minhas-solicitacoes',
        gtmAction: 'Minhas_Solicitacoes',
        callToAction: 'landing.home.carousel.myRequests.callToAction',
      });
    }

    this._setReconnectDynamicCard();

    if (this._showEasyDuplicate) {
      this.cardsList.splice(4, 0, {
        image: `${this.baseImgUrl}/img/home/card-segunda-via-facil.png`,
        title: 'landing.home.carousel.easy2via.title',
        description: 'landing.home.carousel.easy2via.description',
        link: '/home/servicos/segunda-via-facil',
        gtmAction: 'Segunda_Via_Facil',
        callToAction: 'landing.home.carousel.easy2via.callToAction',
      });
    }

    if (this.showSimuladorTarifa) {
      this.cardsList.splice(5, 0, {
        image: `${this.baseImgUrl}/img/home/card-minha-conta.png`,
        title: 'landing.home.carousel.simuladortarifa.title',
        description: 'landing.home.carousel.simuladortarifa.description',
        link: '/home/servicos/simulador-tarifa',
        gtmAction: 'simulador_tarifa',
        callToAction: 'landing.home.carousel.simuladortarifa.callToAction',
      });
    }

    if (this.showChangeExpiring) {
      this.cardsList.splice(6, 0, {
        image: `${this.baseImgUrl}/img/home/card-alterar-data-vencimento.png`,
        title: 'landing.home.carousel.changeExpiring.title',
        description: 'landing.home.carousel.changeExpiring.description',
        link: '/home/servicos/alterar-data-vencimento/area-aberta',
        gtmAction: 'Alterar_data_de_vencimento',
        callToAction: 'landing.home.carousel.changeExpiring.callToAction',
        showModal: true,
      });
    }

    if (this._showAutomaticDebit) {
      this.cardsList.splice(7, 0, {
        image: `${this.baseImgUrl}/img/home/card-debito-automatico.png`,
        title: 'landing.home.carousel.automaticDebit.title',
        description: 'landing.home.carousel.automaticDebit.description',
        link: '/home/servicos/debito-automatico',
        gtmAction: 'Debito_Automatico',
        callToAction: 'landing.home.carousel.automaticDebit.callToAction',
      });
    }

    if (this.showReportLeak) {
      this.cardsList.push({
        image: `${this.baseImgUrl}/img/home/card-informar-vazamento.png`,
        title: 'landing.home.carousel.reportLeak.title',
        description: 'landing.home.carousel.reportLeak.description',
        link: '/home/servicos/informar-vazamento-de-agua',
        gtmAction: 'Notificar_Vazamento',
        callToAction: 'landing.home.carousel.reportLeak.callToAction',
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  private _setReconnectDynamicCard(): void {
    if (this.showReconnectAuto || this.showReconnect) {
      this.cardsList.splice(3, 0, {
        image: `${this.baseImgUrl}/img/home/card-religa.png`,
        title: 'landing.home.carousel.reconnect.title',
        description: 'landing.home.carousel.reconnect.description',
        link: this.showReconnectAuto ? '/home/servicos/religa-auto' : '/home/servicos/religa',
        gtmAction: 'Religa',
        callToAction: 'landing.home.carousel.reconnect.callToAction',
      });
    }
  }

  private _getServiceList(): void {
    if (this.selectedCity?.isAttended) {
      this._landingService.listCityServices$
        .pipe(takeUntil(this.onDestroy), auditTime(0))
        .subscribe((res) => {
          if (!res) {
            this._getServicesFromApi();
          } else {
            this._setServices(res);
          }
        });
    }
  }

  private _getServicesFromApi() {
    if (!this.selectedCity) {
      return;
    }
    this._landingService
      .getServiceList(this.selectedCity.id)
      .pipe(take(1))
      .subscribe((data) => {
        this._setServices(data);
      });
  }

  private _setServices(services: ICityServicesList) {
    const activeService = ActiveService.create(services);
    const information = activeService.getCategory(servicesCategory.information);
    const payment = activeService.getCategory(servicesCategory.payment);
    const attendance = activeService.getCategory(servicesCategory.attendance);
    this._setInformationServices(information);
    this._setPaymentServices(payment);
    this._setAttendanceServices(attendance);
    this._setDynamicCards();
  }

  private _setInformationServices(service: ActiveServiceCategory) {
    this.showReconnect = service.isServiceActive(servicesList.religa);
    this.showRequestFollowUp = service.isServiceActive(servicesList.acompanhamentoDeSolicitacoes);
    this.showChangeHolder = service.isServiceActive(servicesList.trocaTitularidade);
  }

  private _setPaymentServices(service: ActiveServiceCategory) {
    this._showAutomaticDebit = service.isServiceActive(servicesList.debitoAutomatico);
    this._showEasyDuplicate = service.isServiceActive(servicesList.segundaViaFacil);
    this.showPix = service.isFunctionalityActive(
      servicesList.segundaViaFacil,
      servicesList.segundaViaFacilPix,
    );
  }

  private _setAttendanceServices(service: ActiveServiceCategory) {
    this.showChangeExpiring = service.isServiceActive(servicesList.alterarDataVencimento);
    this.showReconnectAuto = service.isServiceActive(servicesList.religaAuto);
    this.showReportLeak = service.isServiceActive(servicesList.notificarVazamento);
    this.showSimuladorTarifa = service.isServiceActive(servicesList.simuladorTarifa);
  }
}
