import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { RegisterState } from './register.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { IRegisterStateModel } from './models/register-state.model';
import {
  SetPct,
  SetUserDocument,
  SetUserCdc,
  SetCdcName,
  SetPhone,
  SetEmail,
  SetPassword,
  ResetRegister,
  SetEdit,
  SetCdcCategory,
  SetCityCode,
  SetCurrentStep,
  SetTerms,
} from './register.actions';

@Injectable({
  providedIn: 'root',
})
export class RegisterStoreService {
  @Select(RegisterState)
  public registerState$: Observable<IRegisterStateModel>;

  @Select(RegisterState.getUserName)
  public userName$: Observable<string>;

  @Select(RegisterState.getUserDocument)
  public document$: Observable<string>;

  @Select(RegisterState.getUserCdc)
  public cdc$: Observable<string>;

  @Select(RegisterState.getUserCdcName)
  public cdcName$: Observable<string>;

  @Select(RegisterState.getUserPhone)
  public phone$: Observable<string>;

  @Select(RegisterState.getUserEmail)
  public email$: Observable<string>;

  @Select(RegisterState.getUserPassword)
  public password$: Observable<string>;

  @Select(RegisterState.getPct)
  public pct$: Observable<number>;

  @Select(RegisterState.getCityCode)
  public cityCode$: Observable<number>;

  @Select(RegisterState.getCdcCategory)
  public cdcCategory$: Observable<string>;

  @Select(RegisterState.getIsEditing)
  public isEditing$: Observable<boolean>;

  @Select(RegisterState.getTerms)
  public acceptedTerms$: Observable<boolean>;

  constructor(private _store: Store) {}

  @Dispatch()
  public setUserDocument(document: string) {
    return new SetUserDocument(document);
  }

  @Dispatch()
  public setUserCdc(cdc: string) {
    return new SetUserCdc(cdc);
  }

  @Dispatch()
  public setCdcName(name: string) {
    return new SetCdcName(name);
  }

  @Dispatch()
  public setPhone(phone: string) {
    return new SetPhone(phone);
  }

  @Dispatch()
  public setEmail(email: string) {
    return new SetEmail(email);
  }

  @Dispatch()
  public setPassword(password: string) {
    return new SetPassword(password);
  }

  @Dispatch()
  public setPct(pct: number) {
    return new SetPct(pct);
  }

  @Dispatch()
  public setEdit(status: boolean) {
    return new SetEdit(status);
  }

  @Dispatch()
  public setTerms(status: boolean) {
    return new SetTerms(status);
  }

  @Dispatch()
  public setCityCode(cityCode: number) {
    return new SetCityCode(cityCode);
  }

  @Dispatch()
  public setCdcCategory(cdcCategory: string) {
    return new SetCdcCategory(cdcCategory);
  }

  @Dispatch()
  public resetRegister() {
    return new ResetRegister();
  }

  @Dispatch()
  public setCurrentStep(step: number) {
    return new SetCurrentStep(step);
  }

  navigateToStep(step: string) {
    const steps = this._store.selectSnapshot(RegisterState.getSteps);
    this.setCurrentStep(steps.indexOf(step));
  }

  nextStep() {
    const currentStep = this._store.selectSnapshot(RegisterState.getCurrentStep);
    const steps = this._store.selectSnapshot(RegisterState.getSteps);

    if (currentStep + 1 < steps.length) {
      this.setCurrentStep(currentStep + 1);
      return steps[currentStep + 1];
    }

    return null;
  }

  prevStep() {
    const currentStep = this._store.selectSnapshot(RegisterState.getCurrentStep);
    const steps = this._store.selectSnapshot(RegisterState.getSteps);

    if (currentStep - 1 >= 0) {
      this.setCurrentStep(currentStep - 1);
      return steps[currentStep - 1];
    }

    return null;
  }

  currentOrWelcomePage(){
    const steps = this._store.selectSnapshot(RegisterState.getSteps);
    let currentStep = this._store.selectSnapshot(RegisterState.getCurrentStep);
    if (currentStep > steps.length - 1 || currentStep <= 0) {
      currentStep = 0;
    }
    return steps[currentStep] || null;
  }

  currentOrFirstStep(): string {
    const steps = this._store.selectSnapshot(RegisterState.getSteps);
    let currentStep = this._store.selectSnapshot(RegisterState.getCurrentStep);
    if (currentStep > steps.length - 1 || currentStep <= 0) {
      currentStep = 1;
    }
    return steps[currentStep];
  }
}
