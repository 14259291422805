import {
  DuplicateDetail,
  GroupedDuplicate,
} from './../../../../features/easy-duplicate/models/easy-duplicate';
import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { EasyDuplicateState, EasyDuplicateStateModel } from './easy-duplicate.state';
import {
  SetOwner,
  SetDocType,
  SetDocument,
  SetCity,
  SetEasyDuplicateDoc,
  SetEasyDuplicateCdc,
  Reset,
  SetGroupedDuplicate,
  SetSelectedDuplicate,
  SetIsGrouped,
  SetPixData,
} from './easy-duplicate.actions';
import { IDuplicate } from '@features/easy-duplicate';
import { ICity } from '@store/dashboard';
import { IPix } from '@features/duplicate/services/models/pix.model';

@Injectable({
  providedIn: 'root',
})
export class EasyDuplicateStoreService {
  @Select(EasyDuplicateState.getState)
  public easyInvoiceState$: Observable<EasyDuplicateStateModel>;

  @Select(EasyDuplicateState.getOwner)
  public getOwner$: Observable<string>;

  @Select(EasyDuplicateState.getOwnerDocType)
  public getOwnerDocType$: Observable<string>;

  @Select(EasyDuplicateState.getOwnerDocument)
  public getDocument$: Observable<string>;

  @Select(EasyDuplicateState.getCity)
  public getCity$: Observable<ICity>;

  @Select(EasyDuplicateState.getDuplicate)
  public getCdcDuplicate$: Observable<IDuplicate>;

  @Select(EasyDuplicateState.getDuplicates)
  public getDocDuplicates$: Observable<Array<IDuplicate>>;

  @Select(EasyDuplicateState.getIsGrouped)
  public isGrouped$: Observable<boolean>;

  @Select(EasyDuplicateState.getGroupedDuplicate)
  public groupedDuplicate$: Observable<GroupedDuplicate>;

  @Select(EasyDuplicateState.getSelectedDuplicate)
  public selectedDuplicate$: Observable<DuplicateDetail>;

  @Select(EasyDuplicateState.getPixData)
  public pixData$: Observable<IPix>;

  constructor(private _store: Store) {}

  public getOwner() {
    return this._store.selectSnapshot(EasyDuplicateState.getOwner);
  }

  public getOwnerDocType() {
    return this._store.selectSnapshot(EasyDuplicateState.getOwnerDocType);
  }

  public getOwnerDocument() {
    return this._store.selectSnapshot(EasyDuplicateState.getOwnerDocument);
  }

  public getCity() {
    return this._store.selectSnapshot(EasyDuplicateState.getCity);
  }

  public getCdcDuplicate() {
    return this._store.selectSnapshot(EasyDuplicateState.getDuplicate);
  }

  public getDocDuplicates() {
    return this._store.selectSnapshot(EasyDuplicateState.getDuplicates);
  }

  public getGroupedDuplicate(){
    return this._store.selectSnapshot(EasyDuplicateState.getGroupedDuplicate);
  }

  public getSelectedDuplicate(){
    return this._store.selectSnapshot(EasyDuplicateState.getSelectedDuplicate);
  }

  public getIsGrouped() {
    return this._store.selectSnapshot(EasyDuplicateState.getIsGrouped);
  }

  public getPixData(){
    return this._store.selectSnapshot(EasyDuplicateState.getPixData);
  }

  @Dispatch()
  public resetState() {
    return new Reset();
  }

  @Dispatch()
  public setOwner(owner: boolean) {
    return new SetOwner(owner);
  }

  @Dispatch()
  public setOwnerDocType(ownerDocType: string) {
    return new SetDocType(ownerDocType);
  }

  @Dispatch()
  public setOwnerDocument(ownerDoc: string) {
    return new SetDocument(ownerDoc);
  }

  @Dispatch()
  public setCity(city: ICity) {
    return new SetCity(city);
  }

  @Dispatch()
  public setEasyDuplicateDoc(duplicates: Array<IDuplicate>) {
    return new SetEasyDuplicateDoc(duplicates);
  }

  @Dispatch()
  public setEasyDuplicateCdc(duplicate: IDuplicate) {
    return new SetEasyDuplicateCdc(duplicate);
  }

  @Dispatch()
  public setIsGrouped(isGrouped: boolean) {
    return new SetIsGrouped(isGrouped);
  }

  @Dispatch()
  public setGroupedDuplicate(groupedDuplicate: GroupedDuplicate) {
    return new SetGroupedDuplicate(groupedDuplicate);
  }

  @Dispatch()
  public setSelectedDuplicate(selectedDuplicate: DuplicateDetail) {
    return new SetSelectedDuplicate(selectedDuplicate);
  }

  @Dispatch()
  public setPixData(pixData: IPix) {
    return new SetPixData(pixData);
  }
}
