import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-action-menu',
  templateUrl: './side-action-menu.component.html',
  styleUrls: ['./side-action-menu.component.scss'],
})
export class SideActionMenuComponent implements OnInit {
  public options;

  ngOnInit(): void {
    this.buildOptions();
  }

  private buildOptions(): void {
    this.options = [
      {
        icon: 'fa-volume-up',
        action: this.onSetToDoAlert,
      },
      {
        icon: 'fa-hand-paper',
        action: this.onSetToDoAlert,
      },
      {
        icon: 'fa-adjust',
        action: this.onSetToDoAlert,
      },
      {
        icon: 'fa-search-plus',
        action: this.onSetToDoAlert,
      },
      {
        icon: 'fa-search-minus',
        action: this.onSetToDoAlert,
      },
    ];
  }

  onSetToDoAlert(): void {
    alert('To Do');
  }
}
