import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { IAcnScreenSize } from './models/acn-screen-size.model';
import { fromEvent, Observable, of } from 'rxjs';
import { WINDOW } from '@shared/services/window/window.token';
import { auditTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AcnScreenService {
  readonly screenSize$: Observable<IAcnScreenSize> = isPlatformBrowser(this.platformId)
    ? fromEvent(this.window, 'resize').pipe(
        map(() => this._getScreenSize()),
        distinctUntilChanged(
          (valueA, valueB) => valueA.width === valueB.width && valueA.height === valueB.height,
        ),
        auditTime(250),
        startWith(this._getScreenSize()),
      )
    : of({ width: 1366, height: 768 });

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  private _getScreenSize(): IAcnScreenSize {
    const width =
      this.document.body.clientWidth ||
      this.document.documentElement.clientWidth ||
      this.window.innerWidth;

    const height =
      this.document.body.clientHeight ||
      this.document.documentElement.clientHeight ||
      this.window.innerHeight;

    return { width, height };
  }
}
