import { ITermAutomaticDebit } from '.';

export class SetSiteTerms {
  public static readonly type = '[UseTerms] site terms';
  constructor(public payload: boolean) {}
}

export class SetCreditCardTerms {
  public static readonly type = '[UseTerms] credit card terms';
  constructor(public payload: boolean) {}
}

export class SetAutomaticDebitTerms {
  public static readonly type = '[UseTerms] automatic debit terms';
  constructor(public payload: ITermAutomaticDebit) {}
}
