import { InjectionToken, PLATFORM_ID, Provider } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export const WINDOW = new InjectionToken<Window>('WINDOW', {
  providedIn: 'root',
  factory: () => window,
});

export const WindowProvider: Provider = {
  provide: WINDOW,
  useFactory: (platformId: string) => {
    if (isPlatformBrowser(platformId) && typeof window !== 'undefined') {
      return window;
    }
    return {};
  },
  deps: [PLATFORM_ID],
};
