import { OnDestroy, Injectable, Component } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

/**
 * Componente Base contendo métodos e variáveis comnuns para criação de componentes
 */
@Component({
  selector: 'lib-base-component',
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  /**
   * Subject utilizado no cancelamento (unsubscribe) de inscrições (subscribe) a Observables
   */
  protected onDestroy = new Subject<void>();

  /**
   * Hook executado na destruição do componente que cancela (unsubscribe) as inscrições dos Observables
   */
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
