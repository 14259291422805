import { Component, OnInit } from '@angular/core';

import { LandingService } from '@features/landing/services/landing.service';
import { BaseComponent } from '@acn/angular';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-landing-campaigns',
  templateUrl: './landing-campaigns.component.html',
  styleUrls: ['./landing-campaigns.component.scss'],
})
export class LandingCampaignsComponent extends BaseComponent implements OnInit {
  public onMobile: boolean;
  public backgroundImageGirl: string;
  public backgroundImageKid: string;

  constructor(private _landingService: LandingService) {
    super();
  }

  ngOnInit(): void {
    const baseImgUrl = this._landingService.baseImgUrl;

    this._landingService.onMobile$.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.onMobile = data;
      const imgGirl = data ? 'home/girl-mobile.jpg' : 'home/girl-desktop.jpg';
      this.backgroundImageGirl = `url(${baseImgUrl}/img/${imgGirl})`;

      const imgKid = data ? 'home/kid-mobile.jpg' : 'home/kid-desktop.jpg';
      this.backgroundImageKid = `url(${baseImgUrl}/img/${imgKid})`;
    });
  }
}
