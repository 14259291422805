import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';

import { Reset, ResetState, SetConsumptionHistory } from './consumption.actions';
import { IConsumptionMonth, IConsumptionStatus } from './models/index';

export interface ConsumptionStateModel {
  history: Array<IConsumptionMonth>;
  variation: IConsumptionStatus;
}

@State<ConsumptionStateModel>({
  name: 'consumption',
  defaults: {
    history: [],
    variation: null,
  },
})
@Injectable()
export class ConsumptionState {
  @Selector()
  public static getState(state: ConsumptionStateModel) {
    return state;
  }

  @Selector()
  public static getConsumptionHistory(state: ConsumptionStateModel) {
    return state.history;
  }

  @Selector()
  public static getConsumptionVariation(state: ConsumptionStateModel) {
    return state.variation;
  }

  @Action(SetConsumptionHistory)
  public setConsumptionHistory(
    ctx: StateContext<ConsumptionStateModel>,
    { payload }: SetConsumptionHistory,
  ) {
    const state = ctx.getState();
    let variation: IConsumptionStatus = null;

    if (payload.length) {
      const { competenciaMes, temHistorico, variacaoConsumo } = payload[payload.length - 1];

      if (payload[payload.length - 2]) {
        variation = {
          pastMonth: payload[payload.length - 2].competenciaMes.toLocaleLowerCase(),
          hasConsumptionHistory: temHistorico,
          consumptionVariation: {
            ...variacaoConsumo,
            porcentagem: Math.ceil(variacaoConsumo.porcentagem),
          },
          currentMonth: competenciaMes.toLowerCase(),
        };
      }
    }

    ctx.setState({ ...state, history: payload, variation });
  }

  @Action([Reset, ResetState])
  public reset(ctx: StateContext<ConsumptionStateModel>) {
    ctx.setState({ history: [], variation: null });
  }
}
