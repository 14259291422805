import { BrkPreloadingPriorityEnum } from '@shared/services/brk-preloading-strategy/brk-preloading-priority.enum';

export interface BrkPreloadingOptions {
  priority?: BrkPreloadingPriorityEnum;
}

export class BrkPreloading {
  private constructor(public readonly priority: BrkPreloadingPriorityEnum) {}

  static readonly key = '__preload_config__';

  static create(options?: BrkPreloadingOptions): BrkPreloading {
    return new BrkPreloading(options?.priority ?? BrkPreloadingPriorityEnum.low);
  }
}
