export class SetContactName {
  public static readonly type = '[Contact] set name';
  constructor(public payload: string) {}
}

export class SetContactCdc {
  public static readonly type = '[Contact] set cdc';
  constructor(public payload: string) {}
}

export class SetContactEmail {
  public static readonly type = '[Contact] set email';
  constructor(public payload: string) {}
}

export class SetContactCity {
  public static readonly type = '[Contact] set city';
  constructor(public payload: string) {}
}

export class SetContactPhone {
  public static readonly type = '[Contact] set phone';
  constructor(public payload: string) {}
}

export class ResetContact {
  public static readonly type = '[Contact] reset contact';
}

export class Reset {
  public static readonly type = '[RESET]';
}
