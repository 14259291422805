import { IModal } from './models/modal.model';

export class ShowModal {
  public static readonly type = '[Modal] show modal';
  constructor(public payload: IModal) {}
}

export class HideModal {
  public static readonly type = '[Modal] hide modal';
}
