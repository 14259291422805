import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@acn/angular';
import { LandingService } from '@features/landing/services/landing.service';
import { ICity } from '@store/dashboard/states/cities';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;
  public onMobile = false;
  public selectedCity: ICity;
  public isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private _platformId,
    public componentRef: ElementRef,
    public landingService: LandingService,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit() {
    if (!this.isBrowser) {
      return;
    }
    this._setScreen();
    this._getSelectedCity();
    this.landingService.setBackHome();
  }

  ngAfterViewInit() {
    if (!this.isBrowser) {
      return;
    }
    setTimeout(() => {
      this.landingService.setScrollWidth(this.content);
    }, 50);
  }

  /**
   * Verifica se está em um device touch
   */
  private _setScreen() {
    this.landingService.onMobile$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.onMobile = data));
  }

  /**
   * Busca a cidade selecionada no store
   */
  private _getSelectedCity() {
    this.landingService.selectedCity$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => (this.selectedCity = data));
  }
}
