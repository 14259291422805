import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IError } from './models/error.model';

/**
 * Serviço de apoio para tratamento de erros
 */
@Injectable({
  providedIn: 'root',
})
export class AcnErrorService {
  /** @ignore */
  private errorSubject = new Subject<IError>();

  /** @ignore */
  public error$ = this.errorSubject.asObservable();

  /** @ignore */
  constructor() {}

  /**
   * Pega o próximo objeto de IError
   */
  next(value: IError) {
    this.errorSubject.next(value);
  }
}
