import { State, Action, Selector, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  ResetFaq,
  SetAttendanceFaq,
  SetAttendanceFaqQuestion,
  SetDefaultAttendancePlatform,
} from './attendance.actions';
import { IFaq, IFaqQuestion } from '@features/attendance/models/faq.model';

export interface AttendanceStateModel {
  faq: IFaq;
  question: IFaqQuestion;
  useDefaultAttendancePlatform: boolean;
}
const INITIAL_STATE = {
  faq: null,
  question: null,
  useDefaultAttendancePlatform: true,
};

@State<AttendanceStateModel>({
  name: 'attendance',
  defaults: INITIAL_STATE,
})
@Injectable()
export class AttendanceState {
  @Selector()
  public static getState (state: AttendanceStateModel) {
    return state;
  }

  @Selector()
  public static getFaq (state: AttendanceStateModel): IFaq {
    return state.faq;
  }

  @Selector()
  public static getFaqQuestions (state: AttendanceStateModel): IFaqQuestion {
    return state.question;
  }

  @Selector()
  public static getDefaultAttendance (state: AttendanceStateModel): boolean {
    return state.useDefaultAttendancePlatform;
  }

  @Action(SetAttendanceFaq)
  public setFaq (ctx: StateContext<AttendanceStateModel>, { payload }: SetAttendanceFaq) {
    const state = ctx.getState();
    ctx.setState({ ...state, faq: payload });
  }

  @Action(SetDefaultAttendancePlatform)
  public setDefaultAttendancePlatform (
    ctx: StateContext<AttendanceStateModel>,
    { payload }: SetDefaultAttendancePlatform,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, useDefaultAttendancePlatform: payload });
  }

  @Action(SetAttendanceFaqQuestion)
  public setFaqQuestions (
    ctx: StateContext<AttendanceStateModel>,
    { payload }: SetAttendanceFaqQuestion,
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, question: payload });
  }

  @Action(ResetFaq)
  public resetFaq (ctx: StateContext<AttendanceStateModel>) {
    const state = ctx.getState();
    ctx.setState({ ...state, faq: null, question: null });
  }
}
