<div>
  <div class="campaign-block" *ngIf="!onMobile; else mobileCampaign">
    <div class="content-container">
      <div class="fixed-content">
        <div class="content is-medium">
          <h1 class="big-title">
            <span class="text-highlight-blue-50 is-bold">Sua conta</span><br />
            de água disponível quando quiser
          </h1>
          <p class="medium-text text-line">
            Aqui você acessa segunda via, realiza pagamentos, consulta histórico de consumo, troca
            titularidade e muito mais, sem sair de casa.
          </p>
          <acn-button
            class="button is-white is-fullWidth margin-button"
            routerLink="/home/servicos"
            acnGtm
            gtmCategory="button"
            gtmAction="Acessar_Servicos_Home"
          >
            Acessar serviços
          </acn-button>

          <acn-button
            class="button is-secondary--white is-fullWidth margin-button"
            routerLink="/login"
            acnGtm
            gtmCategory="button"
            gtmAction="Logar_Minha_BRK_Home"
          >
            Entrar na Minha BRK
          </acn-button>
        </div>
      </div>
      <a *ngIf="redirectUrl" class="clickable-layer" target="_blank" [href]="redirectUrl"></a>
    </div>
    <div class="campaign-banner">
      <img *ngIf="imageDesktopURL; else loadingBlock" [src]="imageDesktopURL" class="image" alt="" />
      <ng-template #loadingBlock>
        <div class="loading-block"></div>
      </ng-template>
    </div>
  </div>
  <ng-template #mobileCampaign>
    <section
      *ngIf="imageMobileURL; else pendingBlock"
      [style.background-image]="imageMobileURL ? 'url(' + backgroundMobileURL + ')' : ''"
      class="mobile-campaign-block header"
    >
      <app-landing-banner
        class="container"
        [isOnMobile]="onMobile"
        [selectedCity]="selectedCity"
        [urlBannerMobile]="imageMobileURL"
        [urlBannerLink]="redirectUrl"
      ></app-landing-banner>
    </section>
    <ng-template #pendingBlock>
      <div class="panding-block"></div>
    </ng-template>
  </ng-template>
  <div></div>
</div>
