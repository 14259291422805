import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnselectedCityServiceGuard } from '@shared/guards/unselected-city.guard';
import { SupportChannelsComponent } from './components/support-channels/support-channels.component';
import { titleKey } from '@shared/services/title/title-key';
import { MetaTags } from '@shared/services/meta/meta-tags';

const routes: Routes = [
  {
    path: '',
    component: SupportChannelsComponent,
    data: {
      redirectTo: '/home/atendimento',
      [titleKey]: 'Canais de atendimento ao cliente - Fale com a BRK',
      [MetaTags.key]: MetaTags.create({
        description:
          'Atendimento por WhatsApp e chat. Fale com um de nossos atendentes em tempo real. Acesso as dúvidas frequentes, endereço das lojas e muito mais.',
        robots: true,
      }),
    },
    canActivate: [UnselectedCityServiceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportChannelsRoutingModule {}
