import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ISettingsStateModel, SettingsState } from './settings.state';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { SetAvailableFeatures, SetOnMobileAction, SetUnsupportedBrowser } from './settings.actions';
import { RouterService } from '../../../router/router.service';
import { coerceArray } from '@ngneat/transloco';
import { AcnConfigurationService } from '@acn/angular';

@Injectable({
  providedIn: 'root',
})
export class SettingsStoreService {
  @Select(SettingsState)
  public citiesState$: Observable<ISettingsStateModel>;

  @Select(SettingsState.getOnMobile)
  public onMobile$: Observable<boolean>;

  constructor(
    private _store: Store,
    private _routerService: RouterService,
    private readonly acnConfigurationService: AcnConfigurationService,
  ) {}

  @Dispatch()
  public setOnMobile(onMobile: boolean) {
    return new SetOnMobileAction(onMobile);
  }

  @Dispatch()
  public setUnsupportedBrowserAgreement(agreement: boolean) {
    return new SetUnsupportedBrowser(agreement);
  }

  @Dispatch()
  public setAvailableFeatures(features: Array<string>) {
    return new SetAvailableFeatures(features);
  }

  private _getURL(): URL {
    return new URL(
      this._routerService.getCurrentUrl(),
      this.acnConfigurationService.settings.baseUrl,
    );
  }

  public onDashboard() {
    // É necessário usarmos somente o 'pathname' aqui, pois pode haver
    // o termo 'home-logado' dentro do query parameter ou do fragment
    // exemplo: /login?redirect=%2Fhome-logado%2Fservicos%2Fcorte-a-pedido%2Fdebitos#home-logado
    // nesse exemplo não estamos no home-logado
    return this._getURL().pathname.includes('home-logado');
  }

  public onRegister() {
    return this._routerService.getCurrentUrl().includes('cadastro');
  }

  getUnsupportedAgreement() {
    return this._store.selectSnapshot(SettingsState.getUnsupportedAgreement);
  }

  getOnMobile() {
    return this._store.selectSnapshot(SettingsState.getOnMobile);
  }

  getFeatures() {
    return this._store.selectSnapshot(SettingsState.getFeatures);
  }

  hasFeatures(featureFlags: string | Array<string>) {
    const features = this.getFeatures();
    return coerceArray(featureFlags).every((current) => features.includes(current));
  }
}
