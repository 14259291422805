import { FormControl, AbstractControl } from '@angular/forms';
import { AcnFormValidations } from '../validators/acn-form-validations';

export function formControlError(
  formControl: FormControl | AbstractControl,
  label: string = null,
): string {
  for (const propertyName in formControl.errors) {
    if (
      formControl.errors.hasOwnProperty(propertyName) &&
      (formControl.dirty || formControl.touched)
    ) {
      return AcnFormValidations.getErrorMsg(label, propertyName, formControl.errors[propertyName]);
    }
  }
  return null;
}
