import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maskEmail' })
export class MaskEmailPipe implements PipeTransform {
  private readonly LAST_CHARACTERS = 3;
  transform(value: string): string {
    let maskedEmail = '';
    if (value && value.includes('@')) {
      const emailSplitted = value.split('@');
      const userSegment = emailSplitted[0];
      const domainSegment = emailSplitted[1];

      if (userSegment.length >= this.LAST_CHARACTERS) {
        maskedEmail =
          userSegment.slice(0, -3).replace(/./g, '•') + userSegment.slice(-3) + '@' + domainSegment;

        return maskedEmail;
      }
    }
  }
}
