import { AcnAuthorization } from './../authorization/acn-authorization.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AcnConfigurationService } from '../../core/configuration/acn-configuration.service';
import { ISession, IUser } from './../../models/index';
import { AcnSessionService } from '../session/acn-session.service';
import { AcnLoaderService } from '../../core/connector/acn-loader.service';

/**
 * Serviço responsável pelo tratamento de Login
 */
@Injectable({
  providedIn: 'root',
})
export class AcnAuthenticationService {
  /** @ignore */
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  /** @ignore */
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  private session: ISession;
  private jwtHelper = new JwtHelperService();

  public redirectUrl: string;

  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private configuration: AcnConfigurationService,
    private sessionService: AcnSessionService,
    private loaderService: AcnLoaderService,
    private acnAuthorization: AcnAuthorization,
  ) {}

  /**
   * Realiza a autenticação do usuário e senha
   *
   * @returns Retorna throwError caso seja inválido, ou permite acesso ao usuário caso seja válido
   */
  public login(login: string, senha: string): Observable<any> {
    this.loaderService.show();
    const authenticationService = this.http.post<IUser>(
      this.configuration.settings.baseUrl + '/api/auth/v2/login',
      { login, senha },
      {
        withCredentials: true,
      },
    );

    return authenticationService.pipe(
      map((res: any) => {
        this.doLoginUser(res.accessToken, res.refreshToken);
        return true;
      }),
      catchError((error) => {
        this.loaderService.hide();
        return throwError(error);
      }),
      finalize(() => this.loaderService.hide()),
    );
  }
  /**
   * Verifica se o usuário está logado
   *
   * @returns Retorna um observable com um valor booleano
   */
  public isLoggedIn(): Observable<boolean> {
    const refreshToken = this.getRefreshToken();
    const isRefreshTokenExpired = this.jwtHelper.isTokenExpired(refreshToken);

    if (isRefreshTokenExpired) {
      this._doLogoutUser();
      return of(false);
    }

    const token = this.getAccessToken();
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);

    if (token && !isTokenExpired) {
      this.setSession(token, refreshToken);
      return of(true);
    }

    return of(false);
  }

  public getNewToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return of(false);
    }

    return this.http
      .post(
        this.configuration.settings.baseUrl + '/api/auth/refresh',
        { refreshToken },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((res: any) => {
          this.doLoginUser(res.accessToken, res.refreshToken);

          return true;
        }),
      );
  }

  /**
   * Remove o token do local storage e realiza o logout do usuário
   *
   * @returns redireciona o usuário para página de logout
   */
  public logout(): void {
    this._doLogoutUser();
  }

  /**
   * Retorna token JWT do local storage
   *
   */
  public getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  /**
   * Retorna refresh token do local storage
   *
   */
  public getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  /**
   * Retorna url para redirect caso ela exista
   *
   */
  public getRedirectUrl(): string {
    return this.redirectUrl ? this.redirectUrl : undefined;
  }

  /**
   * Armazena informacoes do usuario logado
   *
   */
  public doLoginUser(jwt: string, refresh: string) {
    this.setSession(jwt, refresh);
  }

  private setSession(token: string, refresh: string) {
    const session = this.jwtHelper.decodeToken(token);
    const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
    const isExpired = this.jwtHelper.isTokenExpired(token);

    const loggedUser: IUser = {
      email: session.email,
      id: session.clienteId,
      cpfCnpj: session.cpfCnpj,
      nome: session.nome,
    };

    const sessionObj: ISession = {
      loggedUser,
      expirationDate,
      isExpired,
    };

    this.sessionService.setSession(sessionObj);
    this.session = sessionObj;
    this._storeTokens(token, refresh);
  }

  /**
   * Revoga a autenticacao do usuario
   */
  private _doLogoutUser() {
    this.session = null;
    this.sessionService.clear();
    this._removeTokens();
    this.acnAuthorization.clear();
  }

  /**
   * Armazena tokens JWT e Refresh no local storage
   */
  private _storeTokens(jwt: string, refresh: string) {
    localStorage.setItem(this.ACCESS_TOKEN, jwt);
    localStorage.setItem(this.REFRESH_TOKEN, refresh);
  }

  /**
   * Remove tokens do local storage
   */
  private _removeTokens() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
