import { IUpdateInstallation } from './models/update-installation.model';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { catchError, finalize, map, mapTo, pluck, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { AcnConnectorService, AcnLoaderService, IRequest, orderBy } from '@acn/angular';
import {
  CitiesStoreService,
  IInstallation,
  InstallationStoreService,
  StepStoreService,
} from '@store/dashboard';
import { SelectCityService } from '@features/select-city/services/select-city.service';
import { LackOfWaterService } from '@features/lack-of-water/services/lack-of-water.service';
import { BaseService } from '@shared/services/base/base.service';
import { IUpdateInstallationResponse } from './models/update-installation-response.model';
import { LoginService } from '@features/login/services/login.service';
import { servicesList } from '@shared/enumerators/servicesList.enum';
import { AutomaticDebitServiceService } from '@features/automatic-debit-service/services/automatic-debit-service.service';
import { TermUseService } from '@features/terms/services/term-use.service';
import { servicesCategory } from '@shared/enumerators/servicesCategory.enum';
import { ICityServicesList } from '@features/services/models/city-services-list.model';
import { EmailBillingResponseModel } from '@features/installation/services/models/email-billing-response.model';
import { InstallationConfiguration } from '@features/installation/services/models/installation-configuration.model';
import { EmailBillingResponseModelWithCdc } from '@features/installation/services/models/email-billing-response-model-with-cdc.model';
import { OptinWithCdc } from '@features/installation/services/models/optin-with-cdc.model';
import { AddInstallationModel } from '@features/installation/services/models/add-installation.model';
import { ActiveService } from '@shared/services/active-service/active-service';
import {
  AddInstallationResponseModel,
  AddInstallationResponseTypeEnum,
} from '@features/installation/services/models/add-installation-response.model';
import { OptInService } from '@features/opt-in/services/opt-in.service';
import { SetOptInItem } from '@features/opt-in/models/set-opt-in.model';
import { OptInTypeEnum } from '@features/opt-in/models/opt-in-type.enum';
import { IItemOptIn } from '@features/opt-in/models/opt-in.model';

@Injectable({
  providedIn: 'root',
})
export class InstallationService extends BaseService {
  onMobile$ = this._settingsStore.onMobile$;
  selectedInstallation$ = this._installationStore.selectedInstallation$;
  installationList$ = this._installationStore.installationList$;
  installationUnlinkedList$ = this._installationStore.installationUnlinkedList$;
  translatedManagementMsg$ = this._translocoService.selectTranslateObject(
    'management.messages',
    {},
    'installation',
  );

  readonly ADD_INSTALLATION_URL = 'usuario/cdc/vinculaCdc';
  readonly REMOVE_INSTALLATION_URL = 'usuario/cdc/desvinculaCdc';
  readonly INSTALLATION_EMAIL_BILLING_URL = 'usuario/contaEmail';
  readonly NICKNAME_EDIT_URL = 'usuario/cdc/apelido';

  constructor (
    injector: Injector,
    private _connectorApi: AcnConnectorService,
    private _installationStore: InstallationStoreService,
    private _selectCityService: SelectCityService,
    private _router: Router,
    private _citiesStore: CitiesStoreService,
    private _stepStore: StepStoreService,
    private _translocoService: TranslocoService,
    private _loginService: LoginService,
    private _automaticDebitService: AutomaticDebitServiceService,
    private _termsUseService: TermUseService,
    private _lackOfWaterService: LackOfWaterService,
    private loaderService: AcnLoaderService,
    private optInService: OptInService,
  ) {
    super(injector);
  }

  onDashboard () {
    return this._settingsStore.onDashboard();
  }

  scrollTop (el: ElementRef) {
    this._screenService.scrollTop(el);
  }

  navigateToAvailableInstallationList () {
    this._router.navigate(['/cdc/vinculo']);
  }

  removeSelectedInstallation () {
    this._installationStore.removeSelectInstallation();
  }

  changeInstallation (installationId: string, shouldRedirect?: boolean) {
    const inst = this._installationStore.getInstallationById(installationId);
    return of(true).pipe(
      switchMap(() => {
        const selectedCity = this._citiesStore.getSelectedCity();
        const selectedCityID = Number(selectedCity?.id);

        if (!selectedCity || inst.idCidade !== selectedCityID) {
          return this._selectCityService.selectCity(inst.idCidade);
        }

        return of(true);
      }),
      switchMap(() => {
        const oldCdc = this._installationStore.getSelectedInstallation();

        this._installationStore.selectedInstallation(installationId);
        return this._selectCityService.getCityServices(inst.idCidade).pipe(
          switchMap(() => {
            const currentCdc = this._installationStore.getSelectedInstallation();

            if (shouldRedirect) {
              this._termsUseService.redirectUser(false, false, false, inst);
            }

            if (currentCdc.cdc !== oldCdc?.cdc) {
              return this._hasAWaterShortageWarning();
            }

            return of(true);
          }),
        );
      }),
    );
  }

  private _hasAWaterShortageWarning (): Observable<boolean> {
    return this._lackOfWaterService.checkWaterShortageWarning().pipe(
      take(1),
      tap((res) => {
        if (res?.description) {
          this._citiesStore.addWaterShortageAlert(res.description);
          this._displayWaterScarcityModal();
        } else {
          this._citiesStore.resetWaterShortageAlert();
        }
      }),
      mapTo(true),
      catchError(() => {
        this._citiesStore.resetWaterShortageAlert();
        return of(false);
      }),
    );
  }

  private _displayWaterScarcityModal (): void {
    this.showModal({
      componentId: 'lackOfWater.warning',
      canClose: false,
    });
  }

  acceptanceOfPendingTermsOfUse (): boolean {
    const billingType = this._automaticDebitService.getBillingType();

    if (
      this._automaticDebitService.registeredAutomaticDebit(billingType) &&
      this._termsUseService.originOfDebtIsSan(billingType)
    ) {
      const active = this._termsUseService.automaticDebitTermAccepted(billingType);
      this._termsUseService.setAutomaticDebitTerms({
        active,
        bankId: billingType.detalheCobranca[0].codigoBanco,
        bankName: billingType.detalheCobranca[0].nomeBanco,
      });

      return !active;
    }
    this._termsUseService.setAutomaticDebitTerms({ active: false, bankId: null, bankName: null });

    return false;
  }

  setEmailBilling (status: boolean) {
    this._installationStore.setEmailBilling(status);
  }

  updateNicknameInstallation (
    installation: { nickname: string; cdc: string; cityId: number },
    showLoading = false,
  ): Observable<IUpdateInstallationResponse> {
    const apelido = installation.nickname || '';

    const req: IRequest = {
      endPoint: this.NICKNAME_EDIT_URL,
      showLoading,
      body: {
        codCdc: installation.cdc,
        idCidade: installation.cityId,
        apelido,
      },
    };
    return this._connectorApi.post<IUpdateInstallationResponse>(req).pipe(
      map(() => {
        this._installationStore.editInstallation({
          installationId: installation.cdc,
          nickname: apelido,
        });

        return {
          error: false,
          installationId: installation.cdc,
        };
      })
    );
  }

  updateInstallation (
    installation: IUpdateInstallation,
    showLoading = false,
  ): Observable<IUpdateInstallationResponse> {
    const apelido = installation.nickname || '';

    const req: IRequest = {
      endPoint: this.ADD_INSTALLATION_URL,
      showLoading,
      body: {
        codCdc: installation.installationId,
        idCidade: installation.cityId,
        apelido,
        tipoVinculo: installation.type,
      },
    };
    return this._connectorApi.post<IUpdateInstallationResponse>(req).pipe(
      map(() => {
        this._installationStore.addInstallation({
          installationId: installation.installationId,
          nickname: apelido,
        });

        return {
          error: false,
          installationId: installation.installationId,
          isAdding: installation.status,
        };
      }),
      catchError(() =>
        of({
          error: true,
          installationId: installation.installationId,
          isAdding: installation.status,
        }),
      ),
    );
  }

  removeInstallation (installation: IUpdateInstallation, showLoading = false) {
    const req: IRequest = {
      endPoint: this.REMOVE_INSTALLATION_URL,
      showLoading,
      body: {
        codCdc: installation.installationId,
        idCidade: installation.cityId,
      },
    };
    return this._connectorApi.post<IUpdateInstallationResponse>(req).pipe(
      map(() => {
        this._installationStore.removeInstallation(installation.installationId);

        return {
          error: false,
          installationId: installation.installationId,
          isAdding: installation.status,
        };
      }),
      catchError(() => {
        return of({
          error: true,
          installationId: installation.installationId,
          isAdding: installation.status,
        });
      }),
    );
  }

  updateInstallationList (installationList: IUpdateInstallation[]) {
    const requests = installationList.map((installation) =>
      installation.status
        ? this.updateInstallation(installation, true)
        : this.removeInstallation(installation, true),
    );

    return forkJoin(requests);
  }

  addInstallationWithEmailBillingAndOptIn (
    installation: AddInstallationModel,
  ): Observable<AddInstallationResponseModel[]> {
    const requests$: [
      Observable<AddInstallationResponseModel | null>,
      Observable<AddInstallationResponseModel | null>,
    ] = [of(null), of(null)];
    if (installation.sendEmailBilling) {
      requests$[0] = this.setEmailBillingStatus(
        installation.cityId,
        installation.installationId,
        installation.emailBilling,
      ).pipe(
        map((response) => ({
          ...response,
          type: AddInstallationResponseTypeEnum.emailBilling,
        })),
      );
    }
    if (installation.sendOptinEmail || installation.sendOptinSMS) {
      const optinUpdates: SetOptInItem[] = [];
      if (installation.sendOptinEmail) {
        optinUpdates.push({
          ativo: installation.optinEmail,
          idFuncionalidade: OptInTypeEnum.Email,
        });
      }
      if (installation.sendOptinSMS) {
        optinUpdates.push({ ativo: installation.optinSMS, idFuncionalidade: OptInTypeEnum.SMS });
      }
      if (optinUpdates.length) {
        requests$[1] = this.updateOptin(
          installation.cityId,
          installation.installationId,
          optinUpdates,
        );
      }
    }
    return this.updateInstallation(installation).pipe(
      switchMap((response) =>
        forkJoin(requests$).pipe(
          map((results) => [
            {
              ...response,
              type: AddInstallationResponseTypeEnum.installation,
            },
            ...results.filter((result) => !!result),
          ]),
        ),
      ),
    );
  }

  addInstallationsWithEmailBillingAndOptIn (
    installations: AddInstallationModel[],
  ): Observable<AddInstallationResponseModel[]> {
    if (!installations.length) {
      return of([]);
    }
    this.loaderService.show();
    const addInstallationsRequests$ = installations.map((installation) =>
      this.addInstallationWithEmailBillingAndOptIn(installation),
    );
    return forkJoin(addInstallationsRequests$).pipe(
      map((responses) => responses.reduce((acc, item) => [...acc, ...item], [])),
      finalize(() => {
        this.loaderService.hide();
      }),
    );
  }

  onLogout () {
    this._loginService.onLogout();
  }

  navigateToCitySelection () {
    this.setCallback('/home/atendimento');
    this._selectCityService.setInfoCities(9999, 999);
    this._router.navigate(['/selecao-de-cidade']);
  }

  setCallback (url: string) {
    this._selectCityService.setCallback(url);
  }

  getEmailBillingStatus (
    idCidade: number,
    idLigacao: string,
    showLoading = false,
  ): Observable<EmailBillingResponseModel> {
    const req: IRequest = {
      endPoint: this.INSTALLATION_EMAIL_BILLING_URL,
      showLoading,
      queryString: {
        codCdc: idLigacao,
        idCidade: idCidade,
      },
    };

    return this._connectorApi.get<EmailBillingResponseModel>(req).pipe(
      map((res) => ({ entrega: !!res?.entrega })),
      catchError((err) => of({ entrega: false, error: true, message: err.message })),
    );
  }

  getEmailBillingListStatus (
    installationList: IInstallation[],
    showLoading = true,
  ): Observable<EmailBillingResponseModel[]> {
    if (!installationList.length) {
      return of([]);
    }
    const requests = installationList.map((installation) =>
      this.getEmailBillingStatus(installation.idCidade, installation.cdc, showLoading),
    );
    return forkJoin(requests);
  }

  setEmailBillingStatus (
    idCidade: number,
    idLigacao: string,
    entrega: boolean,
    showLoading = false,
  ): Observable<IUpdateInstallationResponse> {
    const req: IRequest = {
      endPoint: this.INSTALLATION_EMAIL_BILLING_URL,
      showLoading,
      body: {
        codCdc: idLigacao,
        idCidade: idCidade,
        entrega,
      },
    };
    return this._connectorApi.post<IUpdateInstallationResponse>(req).pipe(
      map(() => ({ error: false, installationId: idLigacao, status: true })),
      catchError(() => of({ error: true, installationId: idLigacao, status: false })),
    );
  }

  setEmailBillingListStatus (
    installationList: Array<{ cdc: string; cityId: number; delivery: boolean }>,
  ): Observable<IUpdateInstallationResponse[]> {
    if (!installationList.length) {
      return of([]);
    }

    const requests = installationList.map((installation) =>
      this.setEmailBillingStatus(
        installation.cityId,
        installation.cdc,
        installation.delivery,
        true,
      ),
    );

    return forkJoin(requests);
  }

  setSteps (steps: Array<string>) {
    this._stepStore.setSteps(steps);
  }

  resetSteps () {
    this._stepStore.resetSteps();
  }

  getInstallationListOrderedByAddress (includeUnlinked = false) {
    return combineLatest([
      this.selectedInstallation$,
      this.installationList$,
      this.installationUnlinkedList$,
    ]).pipe(
      map(([selectedInstallation, installationList, unlinkedList]) => {
        const list = includeUnlinked
          ? [...installationList, ...unlinkedList]
          : [...installationList];

        if (selectedInstallation) {
          const selected = list.find((inst) => inst.cdc === selectedInstallation.cdc);
          const listInstallation = list.filter((inst) => inst.cdc !== selectedInstallation.cdc);

          return [selected, ...orderBy(listInstallation, 'endereco')];
        }

        return orderBy(list, 'endereco');
      }),
    );
  }

  navigate (route: string) {
    this._router.navigate([route]);
  }

  getSelectedInstallation (): IInstallation {
    return this._installationStore.getSelectedInstallation();
  }

  getOptin (idCidade: number, idLigacao: string): Observable<IItemOptIn[] | null> {
    return this.optInService.getOptIn(idCidade, idLigacao).pipe(pluck('canaisComunicacao'));
  }

  private _getServicesPerCities (idCities: number[]): Observable<Map<number, ActiveService>> {
    if (!idCities.length) {
      return of(new Map());
    }
    // Distinct idCities
    idCities = [...new Set(idCities)];
    const selectedCityServices = this._selectCityService.getSelectedCityServices();
    const observables$: Observable<ICityServicesList>[] = idCities.map((idCidade) => {
      if (selectedCityServices?.idCidade === idCidade) {
        return of(selectedCityServices);
      }
      return this._selectCityService.getCityServicesData(idCidade).pipe(
        catchError(() => {
          const services: ICityServicesList = {
            listaServicos: [],
            idCidade,
            idUnidade: 0,
          };
          return of(services);
        }),
      );
    });
    return forkJoin(observables$).pipe(
      map((cityServiceLists) => {
        return cityServiceLists.reduce(
          (mapServices, item) => mapServices.set(item.idCidade, ActiveService.create(item)),
          new Map<number, ActiveService>(),
        );
      }),
    );
  }

  private _getOptinForCdcs (installations: IInstallation[]): Observable<Map<string, OptinWithCdc>> {
    if (!installations.length) {
      return of(new Map());
    }
    const observables$: Observable<OptinWithCdc>[] = installations.map((installation) =>
      this.getOptin(installation.idCidade, installation.cdc).pipe(
        map((optins) => ({ optin: optins ?? [], cdc: installation.cdc, error: false })),
        catchError(() => of({ cdc: installation.cdc, optin: [], error: true })),
      ),
    );
    return forkJoin(observables$).pipe(
      map((optins) =>
        optins.reduce(
          (mapOptins, item) => mapOptins.set(item.cdc, item),
          new Map<string, OptinWithCdc>(),
        ),
      ),
    );
  }

  private _getEmailBillingForCdcs (
    installations: IInstallation[],
  ): Observable<Map<string, EmailBillingResponseModelWithCdc>> {
    if (!installations.length) {
      return of(new Map());
    }
    const observables$: Observable<EmailBillingResponseModelWithCdc>[] = installations.map(
      (installation) =>
        this.getEmailBillingStatus(installation.idCidade, installation.cdc).pipe(
          map((emailBilling) => ({ ...emailBilling, cdc: installation.cdc })),
        ),
    );
    return forkJoin(observables$).pipe(
      map((emailBillings) =>
        emailBillings.reduce(
          (mapBilling, item) => mapBilling.set(item.cdc, item),
          new Map<string, EmailBillingResponseModelWithCdc>(),
        ),
      ),
    );
  }

  getInstallationsWithConfigurations (
    installations: IInstallation[],
  ): Observable<Map<string, InstallationConfiguration>> {
    if (!installations.length) {
      return of(new Map());
    }
    this.loaderService.show();
    const notHolderConfiguration: InstallationConfiguration = {
      emailBillingEnabled: false,
      optinEmailEnabled: false,
      optinSMSEnabled: false,
      optinEmail: false,
      optinSMS: false,
      emailBilling: false,
      emailBillingError: false,
      optinError: false,
    };
    // Filter only holder installations
    const installationsHolder = installations.filter((installation) => installation.titular);
    // Get all services per city of all cities
    const services$ = this._getServicesPerCities(
      installationsHolder.map((installation) => installation.idCidade),
    );
    return services$.pipe(
      switchMap((serviceMap) => {
        // Get installations with opt in
        const installationsOptin = installationsHolder.filter(
          filterInstallationsForOptIn(serviceMap),
        );
        // Get all optins for the installations
        const optins$ = this._getOptinForCdcs(installationsOptin);
        // Get installations with email billing
        const installationsEmailBilling = installationsHolder.filter(
          filterInstallationsForEmailBilling(serviceMap),
        );
        // Get all email billing for the installations
        const emailBilling$ = this._getEmailBillingForCdcs(installationsEmailBilling);
        // Fork join all requests
        const observable$: Observable<Map<string, InstallationConfiguration>> = forkJoin([
          optins$,
          emailBilling$,
        ]).pipe(
          map(([optinMap, emailBillingMap]) =>
            // For each installation we need to create a new item in the Record
            installations.reduce((installationMap, installation) => {
              if (!installation.titular) {
                // If not holder, return the default object
                return installationMap.set(installation.cdc, { ...notHolderConfiguration });
              }
              const service = serviceMap.get(installation.idCidade);
              const optin = optinMap.get(installation.cdc);
              const emailBilling = emailBillingMap.get(installation.cdc);
              return installationMap.set(installation.cdc, {
                ...getEmailBillingConfig(service, emailBilling),
                ...getOptinConfig(service, optin),
              });
            }, new Map<string, InstallationConfiguration>()),
          ),
        );
        return observable$.pipe(
          finalize(() => {
            this.loaderService.hide();
          }),
        );
      }),
    );
  }

  getInstallationUnlinkedList (): IInstallation[] {
    return this._installationStore.getInstallationUnlinkedList();
  }

  updateOptin (
    idCidade: number,
    idLigacao: string,
    payload: SetOptInItem[],
  ): Observable<AddInstallationResponseModel> {
    const response: AddInstallationResponseModel = {
      installationId: idLigacao,
      error: false,
      type: AddInstallationResponseTypeEnum.optin,
      optinTypes: payload.map((dto) => dto.idFuncionalidade),
    };
    return this.optInService.setOptIn(idCidade, idLigacao, { canaisComunicacao: payload }).pipe(
      map(() => response),
      catchError(() => of({ ...response, error: true })),
    );
  }
}

function filterInstallationsForEmailBilling (
  serviceMap: Map<number, ActiveService>,
): (installation: IInstallation) => boolean {
  return (installation) =>
    serviceMap
      .get(installation.idCidade)
      .isServiceActive(servicesCategory.attendance, servicesList.receberContasPorEmail);
}

function filterInstallationsForOptIn (
  serviceMap: Map<number, ActiveService>,
): (installation: IInstallation) => boolean {
  return (installation) => {
    const services = serviceMap.get(installation.idCidade);
    const optinAtivo = services.isMultipleFunctionalityActive(
      servicesCategory.information,
      servicesList.communication,
      [servicesList.optInByEmail, servicesList.optInBySms],
    );
    return optinAtivo.get(servicesList.optInBySms) || optinAtivo.get(servicesList.optInByEmail);
  };
}

function getEmailBillingConfig (
  service: ActiveService,
  emailBilling: EmailBillingResponseModelWithCdc | null,
): Pick<InstallationConfiguration, 'emailBilling' | 'emailBillingEnabled' | 'emailBillingError'> {
  const emailBillingError = !!emailBilling?.error;
  const emailBillingEnabled =
    !!emailBilling &&
    !emailBillingError &&
    service.isServiceActive(servicesCategory.attendance, servicesList.receberContasPorEmail);
  return {
    emailBillingEnabled,
    emailBilling: emailBillingEnabled && emailBilling.entrega,
    emailBillingError,
  };
}

function getOptinConfig (
  service: ActiveService,
  optin: OptinWithCdc | null,
): Pick<
  InstallationConfiguration,
  'optinSMS' | 'optinSMSEnabled' | 'optinEmail' | 'optinEmailEnabled' | 'optinError'
> {
  const optinAtivo = service.isMultipleFunctionalityActive(
    servicesCategory.information,
    servicesList.communication,
    [servicesList.optInByEmail, servicesList.optInBySms],
  );
  const optinError = !!optin?.error;
  const optinSMSAtivo = !!optin && !optinError && optinAtivo.get(servicesList.optInBySms);
  const optinEmailAtivo = !!optin && !optinError && optinAtivo.get(servicesList.optInByEmail);
  return {
    optinSMSEnabled: optinSMSAtivo,
    optinEmailEnabled: optinEmailAtivo,
    optinSMS:
      optinSMSAtivo && optin.optin.some((opt) => opt.idFuncionalidade === OptInTypeEnum.SMS),
    optinEmail:
      optinEmailAtivo && optin.optin.some((opt) => opt.idFuncionalidade === OptInTypeEnum.Email),
    optinError,
  };
}
