import { IAcnDynamicComponentManifest } from '@acn/angular';

export const LackOfWaterDynamicComponentsDto: Array<IAcnDynamicComponentManifest> = [
  {
    path: 'lackOfWater.warning',
    loadChildren: () =>
      import('./lack-of-water/components/modal-lack-of-water/modal-lack-of-water.module').then(
        (m) => m.ModalLackOfWaterModule,
      ),
  },
];
