import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { AcnConfigurationService } from '../configuration/acn-configuration.service';

/**
 * Serviço responsável pelo tratamento de Login
 */
@Injectable({
  providedIn: 'root',
})
export class AcnChannelService {
  /** @ignore */
  private channel: string = undefined;
  /** @ignore */
  private $channel = new BehaviorSubject<string | undefined>(undefined);

  /**
   * Construtor
   */
  constructor(private configService: AcnConfigurationService) {
    this.setChannel(this.configService.settings.defaultChannel);
  }

  /**
   * Define um channel
   */
  public setChannel(channel: string): void {
    this.channel = channel;
    this.$channel.next(channel);
  }

  /**
   *
   * @returns Retorna um Observable de $channel
   */
  public getChannel(): Observable<string> {
    return this.$channel.asObservable();
  }

  /**
   * Limpa a sessao
   */
  public clear(): void {
    this.$channel.next(undefined);
  }

  /**
   *
   * @returns Retorna as configuracoes de canal da aplicacao
   */
  public getChannelSettings() {
    return this.configService.settings.channelSettings;
  }

  /**
   *
   * @returns Retorna o valor da chave de configuracao do canal corrente indicada
   */
  public getParameters(key: string) {
    const params = this.configService.settings.channelSettings[this.channel];
    return params['parameters'] ? params['parameters'][key] : undefined;
  }

  /**
   *
   * @returns Retorna o status de visibilidade da chave indicada
   */
  public getVisibility(key: string) {
    const params = this.configService.settings.channelSettings[this.channel];
    return params['visibility'] ? params['visibility'][key] : undefined;
  }

  /**
   *
   * @returns Retorna se a funcionalidade esta ligada ou nao para o canal corrente
   */
  public getFeature(key: string) {
    const params = this.configService.settings.channelSettings[this.channel];
    return params['features'] ? params['features'][key] : undefined;
  }

  /**
   *
   * @returns Retorna o texto parametrizado configurado por canal
   */
  public getChannelText(key: string) {
    const params = this.configService.settings.channelSettings[this.channel];
    return params['text'] ? params['text'][key] : undefined;
  }

  /**
   *
   * @returns Retorna se o canal possui acesso a rota especificada
   */
  public getRoutePermission(key: string) {
    const params = this.configService.settings.channelSettings[this.channel];
    return params['routesPermission'] ? params['routesPermission'][key] : undefined;
  }
}
