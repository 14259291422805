import { IFaq, IFaqQuestion } from '@features/attendance/models/faq.model';

export class Reset {
  public static readonly type = '[RESET]';
}

export class ResetFaq {
  public static readonly type = '[FAQ] RESET';
}

export class SetAttendanceFaq {
  public static readonly type = '[FAQ] Set faq';
  constructor(public payload: IFaq) {}
}

export class SetAttendanceFaqQuestion {
  public static readonly type = '[FAQ] Set faq question';
  constructor(public payload: IFaqQuestion) {}
}

export class SetDefaultAttendancePlatform {
  public static readonly type = '[ATTENDANCE] Set default platform';
  constructor(public payload: boolean) {}
}