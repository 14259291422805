import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AcnLogHttpService } from './log-http.service';
import { ILogLevel } from './models/log-level.enum';
import { ILog } from './models/log.model';
import { AcnConfigurationService } from '../configuration/acn-configuration.service';

/**
 * Serviço para tratamento de Logs
 */
@Injectable({
  providedIn: 'root',
})
export class AcnLogService {
  /** @ignore */
  private navigatorInfo: string = undefined;

  /**
   * Constructor
   */
  constructor(
    private httpService: AcnLogHttpService,
    private _acnSettings: AcnConfigurationService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  /**
   * Pega as informações do navegador
   *
   * @returns retorna uma string com as informações
   */
  // tslint:disable-next-line: cyclomatic-complexity
  public getNavigatorInfo(): string {
    if (isPlatformBrowser(this.platformId)) {
      const nav = navigator;
      if (!this.navigatorInfo && nav) {
        for (const obj in nav) {
          if (obj) {
            this.navigatorInfo += obj + ':' + JSON.stringify(nav[obj]) + '\n';
          }
        }
      }
    }

    return this.navigatorInfo;
  }

  /**
   * Aplica as configurações para o log de informação
   */
  public info(operation: string, message: string): void {
    this._log(ILogLevel.INFO, operation, message);
  }

  /**
   * Aplica as configurações para o log de cuidado
   */
  public warn(operation: string, message: string): void {
    this._log(ILogLevel.WARN, operation, message);
  }

  /**
   * Aplica as configurações para o log de erro
   */
  public error(operation: string, message: Error | string): void {
    this._log(ILogLevel.ERROR, operation, message);
  }

  /**
   * Cria e envia o log para o servidor
   */
  // tslint:disable-next-line: cyclomatic-complexity
  private _log(level: ILogLevel, operation: string, message: Error | string): void {
    if (!this._acnSettings.settings) {
      return;
    }

    const callServer =
      this._acnSettings.settings.apiConfigs &&
      this._acnSettings.settings.apiConfigs['log'].path &&
      level >= this._acnSettings.settings.logLevel;

    //   console.log('x', message, callServer)

    if (message && callServer) {
      // Garante que o stack de erro seja enviado para o servidor
      let strMessage: string = message instanceof Error ? message.stack : message;
      strMessage = operation + ' - ' + strMessage;

      const metaData: ILog = {
        apiError: '',
        logLevel: ILogLevel[level.toString()],
        dateTime: new Date().toISOString(),
        hostname: window.location.hostname,
        href: window.location.href,
        navigatorInfo: this.getNavigatorInfo(),
      };

      // Envia o log para o servidor
      this.httpService
        .logOnServer(strMessage, metaData)
        .toPromise()
        .catch((error) => console.error('Não foi possível enviar logs', error.message));
    }
  }
}
