import { Component, Input, OnInit } from '@angular/core';
import { INewCity } from '@store/dashboard';
import { LandingService } from '../../services/landing.service';

@Component({
  selector: 'app-landing-banner-not-comercial',
  templateUrl: './landing-banner-not-comercial.component.html',
  styleUrls: ['./landing-banner-not-comercial.component.scss'],
})
export class LandingBannerNotComercialComponent implements OnInit {
  @Input() selectedCity: INewCity;
  @Input() isOnMobile = false;

  public gtmCategory = 'Botao';
  public baseImgUrl: string;

  constructor(private _landingService: LandingService) {}

  ngOnInit(): void {
    this.baseImgUrl = this._landingService.baseImgUrl;
  }
}
