<div
  data-cy="landing-sanitation-carousel"
  (swipeleft)="onSwipeLeft()"
  (swiperight)="onSwipeRight()"
  class="content"
>
  <div class="header">
    <p
      [ngClass]="isOnMobile ? 'is-size-20' : 'is-size-40'"
      class="is-medium header__line"
      [innerHTML]="'landing.home.sanitation.title' | transloco"
      data-cy="title"
    ></p>
    <p class="header__line" [ngClass]="isOnMobile ? 'is-size-14' : 'is-size-20'" data-cy="text1">
      {{ 'landing.home.sanitation.text1' | transloco }}
    </p>
    <p class="header__line" [ngClass]="isOnMobile ? 'is-size-14' : ' is-size-20'" data-cy="text2">
      {{ 'landing.home.sanitation.text2' | transloco }}
    </p>
  </div>
  <div class="cards">
    <acn-carousel *ngIf="isOnMobile" class="carousel" #carousel hide-navigation>
      <ng-container *ngTemplateOutlet="cards"></ng-container>
    </acn-carousel>

    <ng-container *ngIf="!isOnMobile">
      <ng-container *ngTemplateOutlet="cards"></ng-container>
    </ng-container>
  </div>

  <a
    target="_blank"
    rel="noopener"
    class="link__sanitation"
    [href]="'landing.home.sanitation.urlLink' | transloco"
  >
    <acn-button
      [label]="'landing.home.sanitation.titleLink' | transloco"
      class="button is-blue is-fullWidth"
    >
    </acn-button>
  </a>
</div>

<ng-template #cards>
  <acn-card class="card shadow-less" *ngFor="let card of cardsList">
    <div class="card__header">
      <acn-icon
        [icon]="card?.icon | transloco"
        class="has-text-blue-50 icon-size-card-sanitation"
      ></acn-icon>
    </div>

    <div class="card__footer">
      <p class="has-text-blue is-bold" data-cy="cardTitle">
        {{ card?.title | transloco }}
      </p>
      <span class="card__description" data-cy="cardDescription">{{
        card?.description | transloco
      }}</span>
    </div>
  </acn-card>
</ng-template>
