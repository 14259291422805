// export function validateEmail(email) {
// tslint:disable-next-line: max-line-length
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

/**
 * Verifica se o e-mail possui um valor válido
 *
 * @param email
 * @returns retorna um código e uma mensagem descrevendo o erro ou sucesso caso seja valido
 */
export function validateEmail(email) {
  if (!email) {
    return { code: 1, message: 'value is empty' };
  }
  // tslint:disable-next-line:max-line-length
  const expReg: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email.match(expReg)) {
    return { code: 2, message: 'format invalid' };
  }
  return { code: 0, message: 'success' };
}
